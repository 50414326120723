<template>
  <v-container fluid fill-height style="padding: 0px; margin: 0px">
    <template v-for="(value, key) in ids">
      <v-row v-if="key.match('is_active')" :key="key">
        <v-col cols="12">
          <v-divider :id="ssp"></v-divider>
        </v-col>
        <v-col
          cols="3"
          style="padding-right: 0px"
          v-if="schain_is_activeList.includes(ssp)"
        >
          <p
            :id="ssp"
            style="margin-top: 10px; font-size: 17px; font-weight: bold"
          >
            {{ getAlias($t(ssp)) }}<span style="color: red"> (*)</span>
          </p>
        </v-col>
        <v-col cols="3" style="padding-right: 0px" v-else>
          <p style="margin-top: 10px; font-size: 17px; font-weight: bold">
            {{ getAlias($t(ssp)) }}
          </p>
        </v-col>
        <v-col cols="9" style="padding-left: 0px">
          <v-btn-toggle
            v-model="ids[key]"
            mandatory
            @change="resetActiveButton(key, ids[key])"
          >
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[0]"
              depressed
              rounded
            >
              Disabled
            </v-btn>
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[1]"
              depressed
              rounded
            >
              Active
            </v-btn>
            <v-btn
              style="margin: 0px !important"
              class="mx-2"
              :active-class="optionsColor[2]"
              depressed
              rounded
            >
              Paused
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="
            key == 'gumgum_is_active' ||
            key == 'criteo_is_active' ||
            key == 'nextmillennium_is_active'
          "
          style="padding-bottom: 0px"
        >
          <p style="margin-bottom: 10px">(Only one of the IDs is mandatory)</p>
        </v-col>
      </v-row>
      <v-col v-else :key="key" style="max-width: 25%">
        <p style="margin-bottom: 5px; font-size: 15px">{{ $t(key) }}</p>
        <v-text-field
          v-if="Object.keys(ids).length == 2"
          v-model="ids[key]"
          dense
          solo
          style="margin-top: 10px; width: 300px; font-size: 14px"
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            manageoptionals(key)
              ? required
              : []
          "
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 3"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            manageoptionals(key)
              ? required
              : []
          "
          style="margin-top: 10px; width: 300px; font-size: 14px"
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 4"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            manageoptionals(key)
              ? required
              : []
          "
          style="margin-top: 10px; width: 290px; font-size: 14px"
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 5"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            manageoptionals(key)
              ? required
              : []
          "
          style="margin-top: 10px; width: 212px; font-size: 14px"
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
        <v-text-field
          v-if="Object.keys(ids).length == 6"
          v-model="ids[key]"
          dense
          solo
          :rules="
            ids[key.substring(0, key.search('_')) + '_is_active'] == 1 &&
            manageoptionals(key)
              ? required
              : []
          "
          style="margin-top: 10px; width: 200px; font-size: 14px"
          :disabled="ids[key.substring(0, key.search('_')) + '_is_active'] != 1"
          :name="key"
          @input="$emit('update-ssp', { key: key, value: ids[key] })"
        >
        </v-text-field>
      </v-col>
    </template>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    props: {
      ssp: {
        type: String,
        default: () => ''
      },
      ids: {
        type: Object,
        default: () => {}
      }
    },
    data: () => {
      return {
        status: true,
        init: true,
        val: 0,
        dis: false,
        required: [(v) => !!v || 'Required'],
        optionsColor: [
          'red white--text',
          'green white--text',
          'orange white--text'
        ],
        schain_is_activeList: [
          'fueldigital',
          'fueldigitalix',
          'goodad',
          'gravity',
          'groupm',
          'moneytag',
          'plista',
          'quantum',
          'quantumimprove',
          'staila',
          'traffective'
        ],
        optionals: [
          'smilewanted_position_type',
          'nextmillennium_group_id',
          'nextmillennium_placement_id',
          'ix_endpoint',
          'goodad_member',
          'onetag_ext',
          'adot_placement_id',
          'gumgum_zone',
          'gumgum_pub_id',
          'gumgum_slot',
          'gumgum_product',
          'pubmatic_ad_slot',
          'pubmatic_placement_id',
          'pubmaticbis_ad_slot',
          'pubmaticbis_placement_id',
          'rubicon_position',
          'rubiconbis_position',
          'rise_placement_id',
          'criteo_zone_id',
          'criteo_network_id',
          'adswizz_preroll_zonealias',
          'adswizz_midroll_zonealias',
          'adswizz_preroll_companion_zonealias',
          'adswizz_midroll_companion_zonealias'
        ]
      }
    },
    computed: {
      ...mapGetters(['appCustomer'])
    },
    created() {},

    methods: {
      getAlias(ssp) {
        if (this.appCustomer.alias != '') {
          let aliases = JSON.parse(this.appCustomer.alias)
          Object.keys(aliases).forEach((key) => {
            Object.defineProperty(
              aliases,
              this.$t(key),
              Object.getOwnPropertyDescriptor(aliases, key)
            )
            delete aliases[key]
          })
          if (aliases[ssp] === undefined) return ssp
          else return ssp + ' [' + aliases[ssp] + '] '
        } else return ssp
      },
      manageoptionals(key) {
        if (key.substring(0, key.search('_')).match('gumgum')) {
          if (
            this.ids['gumgum_zone'] == '' &&
            this.ids['gumgum_pub_id'] == '' &&
            this.ids['gumgum_product'] &&
            this.ids['gumgum_slot']
          )
            return true
        }
        if (key.substring(0, key.search('_')).match('criteo'))
          if (
            this.ids['criteo_zone_id'] == '' &&
            this.ids['criteo_network_id'] == ''
          )
            return true
        if (key.substring(0, key.search('_')).match('nextmillennium'))
          if (
            this.ids['nextmillennium_placement_id'] == '' &&
            this.ids['nextmillennium_group_id'] == ''
          )
            return true
        if (this.optionals.includes(key)) {
          return false
        }
        return true
      },
      resetActiveButton(key, value) {
        this.$nextTick(() => {
          if (value != 1) this.dis = true
          else this.dis = false
          this.$emit('update-ssp', { key: key, value: value })
        })
      },
      show(key, value) {},
      UpdateStatus(item) {
        this.status = !item
        return item
      }
    }
  }
</script>

<style lang="scss">
  .ssp_row {
    margin-left: 10px;
  }
  .positionCb {
    margin-top: 10px;
    margin-left: 50px;
  }
</style>
