<template>
  <div id="segmentContent">
    <v-container
      v-if="
        $store.getters.is_superadmin == false &&
        $store.state.appCustomer.id != $route.params.customer_id &&
        $store.state.appCustomer.id != '1086' &&
        $store.state.appCustomer.id != '1094' &&
        $store.state.appUser.email != 'alfonso@revenuemaker.es'
      "
    >
      <accessdenied />
    </v-container>
    <v-container v-else fluid style="padding-top: 0px">
      <v-card id="segmentList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col>
              <h3 class="cnx">
                <strong> Custom tageting management</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="7" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            v-model="selectedKeys"
            :search="search"
            :headers="headersKeys"
            :items="customTargetingList"
            selectable-key="id"
            show-select
            hide-default-footer
            :disable-pagination="true"
            class="elevation-1"
          >
            <template v-slot:[`item.type`]="props">
              <td>
                {{ $t(props.item.type) }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="props">
              <td style="float: right; margin-right: 10px">
                <v-icon
                  size="20"
                  color="blue"
                  class="mr-2"
                  @click="openDialogEdit(props.item)"
                >
                  mdi-pencil
                </v-icon>
                <deleteKey :item="props.item" @keyDeleted="deleteKey">
                </deleteKey>
              </td>
            </template>
            <template v-slot:top>
              <h4 style="padding: 5px">
                Uncheck the keys that you want to deactivate and save below the
                table.
              </h4>
              <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="150"
                    style="height: 35px; margin-right: 10px; margin: 5px"
                    class="white--text notcapital"
                    color="#1966D2"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loading"
                    @click="$router.push($route.path + '?key=new')"
                  >
                    <v-icon left> mdi-plus </v-icon>
                    New key-value
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeDialog()">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ getToolbarTitle() }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        text
                        @click="submitKey"
                        :loading="submitLoading"
                        :disabled="submitLoading"
                      >
                        Submit
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-form ref="form" lazy-validation>
                    <v-list three-line subheader>
                      <v-subheader>Key</v-subheader>
                      <v-list-item>
                        <v-list-item-content style="padding-left: 20px">
                          <v-list-item-title>Name</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-row no-gutters
                              ><v-col cols="4">
                                <v-text-field
                                  style="padding-top: 0px"
                                  v-model="key.name"
                                  :rules="required"
                                >
                                </v-text-field>
                              </v-col> </v-row
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content style="padding-left: 20px">
                          <v-list-item-title
                            >Display name (optional)</v-list-item-title
                          >
                          <v-list-item-subtitle
                            ><v-row no-gutters
                              ><v-col cols="4">
                                <v-text-field
                                  style="padding-top: 0px"
                                  v-model="key.display_name"
                                >
                                </v-text-field>
                              </v-col> </v-row
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                      <v-subheader>Values</v-subheader>
                      <v-row>
                        <v-col cols="3" style="padding-left: 50px">
                          <v-list-item-title>Value type</v-list-item-title>
                          <v-radio-group
                            v-model="key.type"
                            :rules="required"
                            column
                            style="margin-top: 5px"
                          >
                            <v-radio label="Dynamic" value="dynamic"></v-radio>
                            <v-radio
                              label="Predefined"
                              value="predefined"
                            ></v-radio>
                            <v-radio
                              label="Predefined dynamic"
                              value="both"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col v-if="showValues()">
                          <v-list-item-title
                            >Targeting values</v-list-item-title
                          >
                          <v-data-table
                            :search="search"
                            :headers="headersValues"
                            :items="values"
                            hide-default-footer
                            :disable-pagination="true"
                            class="elevation-1"
                            style="margin-right: 5px"
                          >
                            <template v-slot:top>
                              <v-btn
                                width="120"
                                style="
                                  height: 30px;
                                  margin-right: 10px;
                                  margin: 5px;
                                "
                                class="white--text notcapital"
                                color="#1966D2"
                                :loading="loading"
                                @click="dialog2 = true"
                              >
                                <v-icon left> mdi-plus </v-icon>
                                New values
                              </v-btn>
                            </template>
                            <template v-slot:[`item.actions`]="props">
                              <td style="float: right; margin-right: 10px">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ma-2"
                                      text
                                      icon
                                      color="#000"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteValue(props.item.name)"
                                    >
                                      <v-icon size="20" color="red"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </td>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialog2"
                transition="dialog-bottom-transition"
                max-width="850"
              >
                <template>
                  <v-card style="height: 100%">
                    <v-col
                      cols="12"
                      style="
                        text-align: right;
                        padding: 10px 10px 0px 40px;
                        height: 5px;
                      "
                    >
                      <v-icon color="black" @click="dialog2 = false"
                        >mdi-close</v-icon
                      >
                    </v-col>
                    <v-card-title primary-title style="padding-top: 10px">
                      <v-row>
                        <v-col cols="12">
                          <h3 style="color: black">Add values</h3>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text style="margin-top: 10px">
                      <div
                        style="
                          width: 99%;
                          height: 100%;
                          border-color: black;
                          border: solid;
                        "
                        align="center"
                        class="justify-center"
                      >
                        <v-row style="padding-top: 10px">
                          <v-col cols="6" style="margin-left: 10px">
                            <v-form v-model="valid">
                              <p
                                align="left"
                                style="
                                  margin-bottom: 3px;
                                  padding-left: 3px;
                                  font-size: 15px;
                                "
                              >
                                Values
                              </p>
                              <v-textarea
                                v-model="valuesMultiples"
                                name="input-7-4"
                                outlined
                                shaped
                                style="padding-bottom: 10px"
                                :rules="rowrules"
                              ></v-textarea>
                            </v-form>
                            {{ lineCount }} / 100
                          </v-col>
                          <v-col
                            cols="5"
                            style="margin-top: 10%; margin-left: 10px"
                          >
                            <p
                              align="left"
                              style="
                                margin-bottom: 3px;
                                padding-left: 3px;
                                font-size: 14px;
                              "
                            >
                              Enter one value per line, up to 100
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" justify="left">
                            <span v-if="added">
                              <v-alert dense text type="success" color="green">
                                Placements added successfully</v-alert
                              >
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-card-actions align="center" class="justify-end">
                      <v-row>
                        <v-col cols="2" />
                        <v-col cols="3">
                          <v-btn
                            style="border-color: black; margin-right: 10px"
                            class="white--text notcapital"
                            color="black"
                            width="240"
                            dense
                            @click="saveValues()"
                          >
                            <v-icon left> mdi-content-save-move </v-icon> Save
                          </v-btn>
                        </v-col>
                        <v-col cols="1" />
                        <v-col cols="3">
                          <v-btn
                            style="border-color: black; margin-left: 10px"
                            class="white--text notcapital"
                            color="red"
                            width="240"
                            dense
                            @click="$emit('hideDialog'), (uploadDialog = true)"
                          >
                            <v-icon left> mdi-cancel </v-icon> Cancel
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions align="right" class="justify-end">
          <v-col cols="6" align="right">
            <v-btn
              width="170"
              style="height: 40px; margin-right: 10px"
              class="white--text"
              color="black"
              :loading="loading"
              @click="saveActivatedKeys()"
            >
              <v-icon left> mdi-content-save </v-icon>
              Save
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapActions, mapGetters } from 'vuex'
  import { SaveSelectedCustomer } from '@/store/action_types'
  import delete_key from './delete_key.vue'

  export default {
    name: 'customTargetingList',
    components: {
      deleteKey: delete_key
    },
    data() {
      return {
        headersKeys: [
          { text: 'Targeting key', align: 'left', value: 'name' },
          { text: 'Display name', align: 'left', value: 'display_name' },
          { text: 'Type', align: 'left', value: 'type' },
          { text: 'Sample values', align: 'left', value: 'value' },
          { text: 'Actions', align: 'right', value: 'actions' }
        ],
        headersValues: [
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Actions', align: 'right', value: 'actions' }
        ],
        customer: {
          id: this.$route.params.customer_id
        },
        key: {
          customer_id: this.$route.params.customer_id,
          name: '',
          type: '',
          display_name: ''
        },
        selectedKey: {},
        required: [(v) => !!v || 'Required'],
        rowrules: [(v) => this.lineCount < 100 || 'Maximum rows exceeded'],
        loading: false,
        search: '',
        showArchived: false,
        actionsuccess: false,
        timeout: 3000,
        messageAction: '',
        item: [],
        segmentsList: [],
        customTargetingList: [],
        selectedKeys: [],
        dialog: false,
        dialog2: false,
        values: [],
        valid: false,
        valuesMultiples: '',
        added: false,
        submitLoading: false
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'appCustomer']),
      lineCount: function () {
        // Return number of lines using regex if not empty
        return this.valuesMultiples.length
          ? this.valuesMultiples.split(/\r\n|\r|\n/).length
          : 0
      }
    },
    watch: {
      selectedKeys: async function (val, oldVal) {
        console.log(val)
      },
      appCustomer: async function (val, oldVal) {}
    },
    async created() {
      await this.getCustomKeysList(this.$route.params.customer_id)
    },
    methods: {
      ...mapActions([SaveSelectedCustomer]),
      async getCustomKeysList() {
        try {
          const customkeysResponse = await axios.get(
            `/api/getcustomkeys/${this.$route.params.customer_id}`
          )
          var customkeys = customkeysResponse.data

          customkeys['keys'].forEach((elementKey) => {
            var values = []
            customkeys['values'].forEach((elementValue) => {
              if (elementValue.key_id == elementKey.id) {
                values.push(elementValue.value)
              }
            })
            elementKey.value = String(values)
          })
          this.customTargetingList = customkeys['keys']
          this.customTargetingList.forEach((element) => {
            if (element.is_active == 1) {
              this.selectedKeys.push(element)
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      saveValues() {
        const arrayOfValues = this.valuesMultiples.split('\n')
        arrayOfValues.forEach(async (val) => {
          var exists = false
          this.values.forEach((element) => {
            if (element.name == val.trim()) exists = true
          })
          if (exists == false && val.trim() != '')
            this.values.push({ name: val.trim() })
          try {
            this.dialog2 = false
            this.valuesMultiples = ''
          } catch (error) {
            console.log(error)
          }
        })
      },
      async submitKey() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        try {
          this.submitLoading = true
          const output = {
            key: this.key,
            value: this.values
          }
          const response = await axios.post(
            `/api/savecustomtargetingkey`,
            output
          )
          if (response.data == 'ok') {
            this.loadingProcess()
            await this.getCustomKeysList(this.$route.params.customer_id)
          }
        } catch (error) {
          console.log(error)
        }
      },
      async saveActivatedKeys() {
        this.submitLoading = true
        const output = {
          customer_id: this.$route.params.customer_id,
          activatedKeys: []
        }
        this.selectedKeys.forEach((key) => {
          output.activatedKeys.push(key.id)
        })
        const responseActivatedKeys = await axios.post(
          `/api/manageactivatedkeys`,
          output
        )
        if (responseActivatedKeys.data == 'ok') {
          this.messageAction = 'Keys updated successfully'
          setTimeout(() => (this.submitLoading = false), 1000)
          setTimeout(() => (this.actionsuccess = true), 1000)
          await this.getCustomKeysList(this.$route.params.customer_id)
        }
      },
      loadingProcess() {
        this.messageAction = 'Key added successfully'
        setTimeout(() => (this.submitLoading = false), 1000)
        setTimeout(() => (this.dialog = false), 1000)
        setTimeout(() => (this.actionsuccess = true), 1000)
        setTimeout(() => (this.key.name = ''), 1000)
        setTimeout(() => (this.key.display_name = ''), 1000)
        setTimeout(() => (this.key.type = ''), 1000)
        setTimeout(() => (this.values = []), 1000)
        setTimeout(() => this.$router.push(this.$route.path), 1000)
        setTimeout(() => this.$refs.form.resetValidation(), 1000)
      },
      showValues() {
        if (this.key.type != '' && this.key.type != 'dynamic') {
          return true
        } else return false
      },
      deleteValue(name) {
        this.values.forEach((element) => {
          if (element.name == name) {
            var index = -1
            for (let i = 0; i < this.values.length; i++) {
              if (this.values[i].name == name) {
                index = i
              }
            }
            this.values.splice(index, 1)
          }
        })
      },
      async openDialogEdit(item) {
        this.$router.push(this.$route.path + '?key=' + item.id)
        this.dialog = true
        this.key = Object.assign({}, item)
        this.key.customer_id = this.$route.params.customer_id
        this.selectedKey = Object.assign({}, item)
        if (item.value != '') {
          const arr = item.value.split(',')
          this.values = []
          arr.forEach((element) => {
            this.values.push({ name: element })
          })
        }
      },
      async closeDialog() {
        this.$router.push(this.$route.path)
        this.dialog = false
        this.key.name = ''
        this.key.display_name = ''
        this.key.type = ''
        this.values = []
        this.$refs.form.resetValidation()
      },
      async deleteKey(key) {
        const deleteKeyResponse = await axios.get(`/api/deletekey/${key.id}`)
        if (deleteKeyResponse.data == 'ok') {
          this.messageAction = 'Key deleted successfully'
          this.actionsuccess = true
          await this.getCustomKeysList(this.$route.params.customer_id)
        }
      },
      getToolbarTitle() {
        if (this.$route.query.key == 'new') return 'Adding a new Key-Value'
        else {
          return 'Updating the key ' + this.selectedKey.name
        }
      }
    }
  }
</script>
<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
    width: 42%;
  }
</style>
