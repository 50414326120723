<template>
  <div id="siteContent">
    <v-container fluid style="padding-top: 0px">
      <v-card-title primary-title style="padding: 0px">
        Creatives
      </v-card-title>
      <v-card-text style="padding-left: 0px; padding-right: 0px">
        <v-row style="margin-top: 20px">
          <v-col cols="3">
            <v-menu
              v-model="menuAddingCreative"
              :close-on-content-click="true"
              :nudge-width="0"
              offset-y
              style="width: 100%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="border-color: black"
                  class="white--text notcapital"
                  color="black"
                  width="220"
                  dense
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Add creative
                </v-btn>
              </template>
              <v-card>
                <v-list style="padding: 2px 2px 0px 2px">
                  <v-list-item style="padding: 2px 2px 0px 2px">
                    <v-list-item-content style="padding: 2px 2px 0px 2px">
                      <v-btn
                        style="border-color: black; width: 100%"
                        class="white--text notcapital"
                        color="black"
                        dense
                        @click="showDialogNewCreative('new')"
                      >
                        <v-icon left> mdi-plus </v-icon> New creative
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list style="padding: 0px 2px 0px 2px">
                  <v-list-item style="padding: 2px 2px 0px 2px">
                    <v-list-item-content style="padding: 2px 2px 0px 2px">
                      <v-btn
                        style="border-color: black; width: 100%"
                        class="white--text notcapital"
                        color="black"
                        dense
                        @click="showDialogExistingCreative()"
                      >
                        <v-icon left> mdi-selection-search </v-icon> Existing
                        creative
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <p
              style="
                position: relative;
                top: 30px;
                left: 5px;
                font-size: 14px;
                color: black;
              "
            >
              {{ numberOfCreativesCreated }}

              creatives created (
              <a
                :style="[
                  showArchived ? { color: 'black' } : { color: '#f28704' }
                ]"
                @click="showArchived = false"
                ><u
                  ><strong>{{ numberOfActiveCreatives }}</strong> active
                  creatives</u
                ></a
              >
              /
              <a
                :style="[
                  showArchived ? { color: '#f28704' } : { color: 'black' }
                ]"
                @click="showArchived = true"
                ><u
                  ><strong>{{ numberOfArchivedCreatives }}</strong> archived
                  creatives</u
                ></a
              >
              )
            </p>
          </v-col>
          <v-col cols="2" />
          <v-col cols="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            style="padding-top: 20px; padding-left: 0px; padding-right: 0px"
          >
            <v-container fluid style="padding-right: 0px">
              <v-data-table
                :headers="headers"
                :sort-by="['name']"
                :items-per-page="10"
                :items="computedCreatives"
                :search="search"
                item-key="id"
                class="elevation-1"
                hide-default-footer
                :disable-pagination="true"
                style="background-color: #f9f9fb"
              >
                <template v-slot:item="props">
                  <tr>
                    <td
                      v-if="props.item.is_archived == 0"
                      style="width: 23%; cursor: pointer"
                    >
                      {{ props.item.name }}
                    </td>
                    <td
                      v-else
                      style="width: 23%; cursor: pointer; opacity: 0.5"
                    >
                      {{ props.item.name }}
                    </td>
                    <td
                      v-if="props.item.is_archived == 0"
                      style="width: 20%; cursor: pointer"
                    >
                      {{
                        props.item.mediatype[0].toUpperCase() +
                        props.item.mediatype.slice(1)
                      }}
                    </td>
                    <td
                      v-else
                      style="width: 20%; cursor: pointer; opacity: 0.5"
                    >
                      {{
                        props.item.mediatype[0].toUpperCase() +
                        props.item.mediatype.slice(1)
                      }}
                    </td>
                    <td
                      v-if="props.item.is_archived == 0"
                      style="width: 20%; cursor: pointer"
                    >
                      {{ props.item.adomain }}
                    </td>
                    <td
                      v-else
                      style="width: 20%; cursor: pointer; opacity: 0.5"
                    >
                      {{ props.item.adomain }}
                    </td>
                    <td
                      v-if="props.item.is_archived == 0"
                      style="width: 20%; cursor: pointer"
                    >
                      {{ props.item.w }} x {{ props.item.h }}
                    </td>
                    <td
                      v-else
                      style="width: 20%; cursor: pointer; opacity: 0.5"
                    >
                      {{ props.item.w }} x {{ props.item.h }}
                    </td>
                    <td style="padding: 0px">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="#000"
                            v-bind="attrs"
                            v-on="on"
                            @click="editItem(props.item)"
                          >
                            <v-icon size="20">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit the creative</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="#000"
                            v-bind="attrs"
                            v-on="on"
                            @click="duplicateCreative(props.item)"
                          >
                            <v-icon size="20">mdi-content-duplicate</v-icon>
                          </v-btn>
                        </template>
                        <span>Duplicate the creative</span>
                      </v-tooltip>
                      <v-tooltip v-if="props.item.is_archived == 0" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="#000"
                            v-bind="attrs"
                            v-on="on"
                            @click="showConfirmationDialog(props.item)"
                          >
                            <v-icon size="20">mdi-archive-arrow-down</v-icon>
                          </v-btn>
                        </template>
                        <span>Archive the creative</span>
                      </v-tooltip>
                      <v-tooltip v-else top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-2"
                            text
                            icon
                            color="#000"
                            v-bind="attrs"
                            v-on="on"
                            @click="unarchiveCreative(props.item)"
                          >
                            <v-icon size="20">mdi-archive-arrow-up</v-icon>
                          </v-btn>
                        </template>
                        <span>Unarchive the creative</span>
                      </v-tooltip>
                    </td>
                  </tr></template
                >
              </v-data-table>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveCreative"
      />
      <creativeEdit
        :parent-dialog="newCreativeDialog"
        :type="typeActionCreative"
        @hideDialog="hideDialog"
        @submitted="newCreative"
      />
      <existingCreative
        :parent-dialog="existingCreativeDialog"
        @hideDialog="existingCreativeDialog = false"
        @associate="associateCreativeToThisLineItem"
      />
    </v-container>
  </div>
</template>

<script>
  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedCreative } from '@/store/mutation_types'
  import creativeEdit from '@/pages/management/dsp/creative/edit.vue'
  import existingCreative from '@/pages/management/dsp/creative/existing.vue'
  import {
    GetOrders,
    GetCreatives,
    SaveSelectedCreative,
    UpdateSelectedCreative
  } from '@/store/action_types'
  import axios from 'axios'
  import ConfirmAction from '@/pages/management/actions/confirmAction.vue'

  export default {
    components: { ConfirmAction, creativeEdit, existingCreative },

    data() {
      return {
        headers: [
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('media_type'),
            align: 'left',
            value: 'media_type'
          },
          {
            text: this.$t('domain'),
            align: 'left',
            value: 'domain'
          },
          {
            text: this.$t('size'),
            align: 'left',
            value: 'size'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: '',
            sortable: false
          }
        ],
        timeout: 3000,
        showArchived: false,
        search: '',
        numberOfCreativesCreated: 0,
        numberOfActiveCreatives: 0,
        numberOfArchivedCreatives: 0,
        confirmActionDialog: false,
        confirmationAction: '',
        item: [],
        newCreativeDialog: false,
        existingCreativeDialog: false,
        typeActionCreative: '',
        menuAddingCreative: false
      }
    },
    computed: {
      ...mapGetters(['selectedCreative', 'creatives', 'selectedLineItem']),
      computedCreatives() {
        return this.creatives
          .filter((el) => {
            return this.showArchived ? el.is_archived == 1 : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },

    async created() {
      await this.GetCreatives(this.$route.params.lineitem_id)
      this.manageNumberOfCreatives()
    },
    methods: {
      ...mapMutations([SetSelectedCreative]),
      ...mapActions({
        GetOrders,
        GetCreatives,
        SaveSelectedCreative,
        UpdateSelectedCreative
      }),
      async showDialogExistingCreative() {
        const sizes_response = await axios.get(
          `/api/getdspcreativesizes/${this.$route.params.customer_id}/${this.$route.params.lineitem_id}/${this.selectedLineItem.mediatype}`
        )
        let sizes = sizes_response.data
        if (sizes.length > 0) this.existingCreativeDialog = true
        else this.$emit('errornocreative')
      },
      showDialogNewCreative(type) {
        this.typeActionCreative = type
        this.newCreativeDialog = true
      },
      hideDialog() {
        this.SetSelectedCreative({})
        this.newCreativeDialog = false
        this.typeActionCreative = ''
      },
      async newCreative(creative) {
        creative.line_item_id = this.$route.params.lineitem_id
        creative.mediatype = this.selectedLineItem.mediatype
        const addingCreative = await this.SaveSelectedCreative(creative)
        if (addingCreative == true || addingCreative.data == true) {
          this.$emit('creativeadded')
          await this.GetCreatives(this.$route.params.lineitem_id)
          this.manageNumberOfCreatives()
        }
      },
      editItem(creative) {
        this.SetSelectedCreative(creative)
        this.showDialogNewCreative('edit')
      },

      async duplicateCreative(item) {
        const creative_duplicated = Object.assign({}, item)
        creative_duplicated.id = undefined
        let nameExists = true
        let i = 1
        while (nameExists == true) {
          creative_duplicated.name = item.name + ' (' + i + ')'
          const verifyNameFromApi = await axios.get(
            `/api/dspcreativenameexists/${creative_duplicated.name}/${creative_duplicated.line_item_id}`
          )
          nameExists = verifyNameFromApi.data
          i++
        }
        try {
          await this.SaveSelectedCreative(creative_duplicated)
          await this.GetCreatives(this.$route.params.lineitem_id)
          this.$emit('creativeduplicated')
        } catch (error) {
          console.log(error)
        }
      },
      async associateCreativeToThisLineItem(creative) {
        var payload = {
          creative_id: creative.id,
          line_item_id: this.$route.params.lineitem_id
        }
        try {
          await axios.post(`/api/associatecreative`, payload)
          this.$emit('creativeadded')
          await this.GetCreatives(this.$route.params.lineitem_id)
          this.manageNumberOfCreatives()
        } catch (error) {
          console.log(error)
        }
      },
      GetCreativesArchived() {
        return this.creatives.filter((el) => {
          return el.is_archived == 1
        })
      },
      GetCreativesUnarchived() {
        return this.creatives.filter((el) => {
          return el.is_archived == 0
        })
      },
      manageNumberOfCreatives() {
        var creatives_unarchived = this.GetCreativesUnarchived()
        var creatives_archived = this.GetCreativesArchived()
        this.numberOfCreativesCreated =
          creatives_unarchived.length + creatives_archived.length
        this.numberOfActiveCreatives = creatives_unarchived.length
        this.numberOfArchivedCreatives = creatives_archived.length
      },
      showConfirmationDialog(item) {
        this.item = item
        this.$router.push(this.$route.path + '?action=confirmArchiving')
        this.confirmActionDialog = true
        this.confirmationAction = 'archive'
      },
      async archiveCreative(item) {
        item.is_archived = 1
        await this.UpdateSelectedCreative(item)
        await this.GetCreatives(this.$route.params.lineitem_id)
        this.messageAction = 'Creative archived successfully'
        this.actionsuccess = true
        await this.manageNumberOfCreatives()
        this.$router.push(this.$route.path + '?settings=creatives')
      },
      async unarchiveCreative(item) {
        item.is_archived = 0
        this.UpdateSelectedCreative(item)
        await this.GetCreatives(this.$route.params.lineitem_id)
        this.messageAction = 'Creative unarchived successfully'
        this.actionsuccess = true
        await this.manageNumberOfCreatives()
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
</style>
