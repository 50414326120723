<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="hideDialog()"
  >
    <template>
      <v-card style="height: 100%; overflow-y: hidden">
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="hideDialog()">mdi-close</v-icon>
        </v-col>
        <v-card-title primary-title style="padding-top: 10px">
          <v-row>
            <v-col cols="12">
              <h3 v-if="typeaction == 'new'" style="color: black">
                Adding a new creative
              </h3>
              <h3 v-if="typeaction == 'edit'" style="color: black">
                Editing {{ selectedCreative.name }}
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-card-text style="padding-top: 0px; padding-bottom: 0px">
            <v-container>
              <v-row class="nopadding">
                <v-col cols="6" class="nopadding">
                  <p style="margin-bottom: 5px">
                    {{ $t('creative_name') }}
                  </p>
                  <v-text-field
                    v-model="creative.name"
                    :rules="required"
                    dense
                    solo
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <!--  <v-row>
                <v-col class="nopadding" cols="6"
                  ><v-select
                    v-model="selected_sizes"
                    :items="size_choices"
                    item-text="name"
                    item-value="value"
                    multiple
                    clearable
                    dense
                    outlined
                  >
                  </v-select
                ></v-col>
              </v-row>-->
              <v-row class="nopadding">
                <v-col class="nopadding" cols="3">
                  <p style="margin-bottom: 5px">Width</p>
                </v-col>
                <v-col class="nopadding" cols="3">
                  <p style="margin-bottom: 5px">Height</p>
                </v-col>
              </v-row>
              <v-row class="nopadding">
                <v-col class="nopadding" cols="3"
                  ><v-text-field
                    v-model="creative.w"
                    type="number"
                    :rules="size_rules"
                    dense
                    solo
                    outlined
                    text
                  >
                  </v-text-field
                ></v-col>
                <v-col class="nopadding" cols="3"
                  ><v-text-field
                    v-model="creative.h"
                    type="number"
                    :rules="size_rules"
                    dense
                    solo
                    outlined
                    text
                  >
                  </v-text-field
                ></v-col>
              </v-row>
              <v-row class="nopadding">
                <v-col cols="6" class="nopadding">
                  <p style="margin-bottom: 5px">
                    {{ $t('domain_name') }}
                  </p>
                  <v-text-field
                    v-model="creative.adomain"
                    :rules="domainRules"
                    dense
                    solo
                    outlined
                    class="nopadding"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="nopadding">
                <v-col cols="2">
                  <p>Content</p>
                </v-col>
                <v-col cols="10">
                  <v-textarea
                    ref="domain"
                    v-model="creative.content"
                    :rules="required"
                    name="input-7-4"
                    rows="5"
                    outlined
                    shaped
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions align="right" class="justify-end">
          <v-row style="padding-bottom: 20px">
            <v-col cols="7" />
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="border-color: black; margin-right: 10px"
              class="white--text notcapital"
              color="black"
              width="130"
              dense
              @click="submit()"
            >
              <v-icon left> mdi-content-save-move </v-icon> Save
            </v-btn>
            <v-btn
              style="border-color: black; margin-left: 20px"
              class="white--text notcapital"
              color="red"
              width="130"
              dense
              @click="$emit('hideDialog')"
            >
              <v-icon left> mdi-cancel </v-icon> Cancel
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  const sizes_list_display = require('@/assets/styles/sizes_display.js')
  const sizes_list_video = require('@/assets/styles/sizes_video.js')

  import { mapGetters } from 'vuex'
  export default {
    components: {},
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      type: {
        default: '',
        type: String
      }
    },
    data() {
      return {
        is_domain_valid: true,
        creative: {
          line_item_id: this.$route.params.lineitem_id,
          mediatype: ''
        },
        dialog: false,
        submitLoading: false,
        selected_sizes: '',
        size_choices: sizes_list_display,
        errors: [],
        valid: false,
        typeaction: 'test',
        size_rules: [
          (v) => !!v || 'Required',
          (v) => (v && Number(v) > 0.0) || 'Value should be above 0'
        ],
        domainRules: [
          (v) => !!v || 'Required',
          (v) => this.is_domain_valid || 'Please enter a valid domain URL.'
        ],
        required: [(v) => !!v || 'Required']
      }
    },
    computed: {
      ...mapGetters(['selectedLineItem', 'selectedCreative'])
    },
    watch: {
      selectedCreative() {
        if (this.selectedCreative != undefined) {
          if (this.selectedCreative.id != undefined) {
            this.creative = Object.assign({}, this.selectedCreative)
          } else {
            this.creative = {
              line_item_id: this.$route.params.lineitem_id,
              mediatype: ''
            }
          }
        }
      },
      parentDialog() {
        this.dialog = this.parentDialog
      },
      type() {
        this.typeaction = this.type
        if (this.typeaction == 'new') {
          if (this.$refs.form != undefined) this.$refs.form.reset()
        } else this.creative = Object.assign({}, this.selectedCreative)
      },
      'creative.adomain': async function (val, oldVal) {
        if (
          /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            val
          ) == false
        )
          this.is_domain_valid = false
        else this.is_domain_valid = true
      }
    },
    async created() {
      if (this.selectedLineItem.mediatype == 'display')
        this.size_choices = sizes_list_display
      else if (this.selectedLineItem.mediatype == 'video')
        this.size_choices = sizes_list_video
    },
    methods: {
      submit() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        if (this.creative.adomain != '')
          this.creative.adomain = this.creative.adomain.toLowerCase()
        this.$emit('submitted', this.creative)
        setTimeout(() => (this.submitLoading = false), 1500)
        setTimeout(() => this.$emit('hideDialog'), 1500)
      },
      hideDialog() {
        this.$emit('hideDialog')
      }
    }
  }
</script>

<style scoped>
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
</style>
