<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @click:outside="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        style="cursor: pointer"
        @click="dialogAdvertiser = true"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-avatar color="grey lighten-3">
          <v-icon> mdi-plus </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <a><u>Add an advertiser</u></a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-col
        cols="12"
        style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
      >
        <v-icon color="black" @click="dialog = false">mdi-close</v-icon>
      </v-col>
      <v-card-title>
        <span class="text-h5">Add an advertiser</span>
      </v-card-title>
      <v-card-text style="padding-bottom: 0px">
        <v-container>
          <v-row class="nopadding">
            <v-col class="nopadding" cols="5">
              <p style="font-size: 14px">Advertiser name</p>
              <v-text-field
                v-model="advertiser.name"
                :loading="submitLoading"
                :disabled="submitLoading"
                dense
                solo
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-row v-if="advertiserAdded" style="margin-top: 30px">
        <v-col>
          <v-alert dense text type="success" color="green">
            Advertiser added successfully</v-alert
          >
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="submitLoading"
          :disabled="advertiser.name.length == 0"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="120"
          dense
          @click="submit"
        >
          Save
          <v-icon right> mdi-check-circle </v-icon>
        </v-btn>
        <v-btn
          style="margin: 10px"
          class="white--text notcapital"
          color="red"
          width="120"
          dense
          @click="cancel"
        >
          Cancel
          <v-icon right> mdi-close-circle-outline </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,
        pageName: '',
        advertiser: {
          name: ''
        },
        advertiserAdded: false,
        submitLoading: false
      }
    },
    methods: {
      cancel() {
        this.advertiser.name = ''
        this.dialog = false
      },
      async submit() {
        this.advertiser.customer_id = this.$route.params.customer_id
        this.submitLoading = true
        setTimeout(() => (this.advertiserAdded = true), 1000)
        this.$emit('advertiserAdded', this.advertiser)
        setTimeout(() => (this.submitLoading = false), 2000)
        setTimeout(() => (this.advertiserAdded = false), 2000)
        setTimeout(() => (this.dialog = false), 800)
        setTimeout(() => (this.advertiser.name = ''), 800)
      }
    }
  }
</script>
