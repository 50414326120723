<template>
  <div class="form-group">
    <label for="country">{{ $t('your_country') }}</label>
    <br /><br />
    <v-select
      v-model="country"
      dense
      solo
      item-text="name"
      :rules="required"
      :items="countryList"
      class="form-control"
      @change="$emit('changed')"
    >
    </v-select>
  </div>
</template>

<script>
  const countries = require('@/assets/styles/countries.js')
  export default {
    name: 'CountryField',
    props: {
      defaultCountry: {
        default: '',
        type: String
      }
    },
    data() {
      return {
        country: '',
        countryList: countries,
        required: [(v) => !!v || 'Required']
      }
    },
    watch: {
      defaultCountry() {
        this.country = this.defaultCountry
      },
      country() {
        this.$emit('countryUpdate', this.country)
      }
    },
    created() {
      this.country = this.defaultCountry
    }
  }
</script>
