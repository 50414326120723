<template>
  <div id="PageContent">
    <v-container fluid style="padding-top: 0px">
      <v-card id="PageList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col>
              <h3 class="cnx">
                <strong> List of proposals</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 0px"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newProposal()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a new proposal
              </v-btn>
            </v-col>
            <v-col cols="4" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="$route.params.site_id != '0'">
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :sort-by="['name']"
                  :items-per-page="10"
                  :items="proposals"
                  :search="search"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :disable-pagination="true"
                  :single-expand="false"
                  show-expand
                  :expanded.sync="expanded"
                  style="background-color: #f9f9fb; cursor: pointer"
                  @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
                >
                  <template v-slot:[`item.proposal_state`]="props">
                    <td>
                      <div class="proposal_details_col" style="display: flex">
                        <div class="proposal_details_col">
                          <span
                            v-if="props.item.proposal_state == 'Proposed'"
                            class="dot"
                            style="background-color: orange"
                          ></span>
                          <span
                            v-if="props.item.proposal_state == 'Accepted'"
                            class="dot"
                            style="background-color: #f9e500"
                          ></span>
                          <span
                            v-if="props.item.proposal_state == 'Activated'"
                            class="dot"
                            style="background-color: #00d615"
                          ></span>
                          <span
                            v-if="props.item.proposal_state == 'Draft'"
                            class="dot"
                            style="background-color: gray"
                          ></span>
                          <span
                            v-if="props.item.proposal_state == 'Rejected'"
                            class="dot"
                            style="background-color: red"
                          ></span>
                        </div>
                        <div class="proposal_details_col">
                          <strong>{{ props.item.proposal_state }}</strong>
                        </div>
                      </div>
                    </td>
                  </template>
                  <template v-slot:[`item.actions`]="props">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="#000"
                          v-bind="attrs"
                          v-on="on"
                          @click="editItem(props.item)"
                        >
                          <v-icon size="20">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit the proposal</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="#000"
                          v-bind="attrs"
                          v-on="on"
                          @click="duplicateProposal(props.item)"
                        >
                          <v-icon size="20">mdi-content-duplicate</v-icon>
                        </v-btn>
                      </template>
                      <span>Duplicate the proposal</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ma-2"
                          text
                          icon
                          color="#000"
                          v-bind="attrs"
                          v-on="on"
                          @click="goToDeals(props.item)"
                        >
                          <v-icon size="20">mdi-table-arrow-up</v-icon>
                        </v-btn>
                      </template>
                      <span>Manage the deals</span>
                    </v-tooltip>
                  </template>
                  <template v-if="loading" v-slot:expanded-item="">
                    <td class="proposal_details" :colspan="12">
                      <v-progress-linear
                        indeterminate
                        color="indigo"
                      ></v-progress-linear>
                    </td>

                    <!-- here use a loaded you prefer -->
                  </template>
                  <template v-else v-slot:expanded-item="{ headers, item }">
                    <td class="proposal_details" :colspan="headers.length">
                      <v-container
                        style="padding-top: 10px; padding-right: 0px"
                      >
                        <div style="display: flex">
                          <div style="flex: 1">
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Status :</p>
                              </div>
                              <div class="proposal_details_col">
                                <span
                                  v-if="item.proposal_state == 'Proposed'"
                                  class="dot"
                                  style="background-color: orange"
                                ></span>
                                <span
                                  v-if="item.proposal_state == 'Accepted'"
                                  class="dot"
                                  style="background-color: #f9e500"
                                ></span>
                                <span
                                  v-if="item.proposal_state == 'Activated'"
                                  class="dot"
                                  style="background-color: #00d615"
                                ></span>
                                <span
                                  v-if="item.proposal_state == 'Draft'"
                                  class="dot"
                                  style="background-color: gray"
                                ></span>
                                <span
                                  v-if="item.proposal_state == 'Rejected'"
                                  class="dot"
                                  style="background-color: red"
                                ></span>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.proposal_state }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Proposal ID :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.proposal_id }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Revision number :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.revision_number }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Proposal name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.name }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Buyer accepted :</p>
                              </div>
                              <div
                                v-if="item.buyer_accepted == 0"
                                class="proposal_details_col"
                              >
                                <strong>False</strong>
                              </div>
                              <div
                                v-if="item.buyer_accepted == 1"
                                class="proposal_details_col"
                              >
                                <strong>True</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Seller accepted :</p>
                              </div>
                              <div
                                v-if="item.seller_accepted == 0"
                                class="proposal_details_col"
                              >
                                <strong>False</strong>
                              </div>
                              <div
                                v-if="item.seller_accepted == 1"
                                class="proposal_details_col"
                              >
                                <strong>True</strong>
                              </div>
                            </div>
                          </div>
                          <v-divider vertical></v-divider>
                          <div style="flex: 1; padding-left: 20px">
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Number of deals :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.nb_deals }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Deals type :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.deal_type }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Buyer name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.buyer_name }}</strong>
                              </div>
                            </div>
                            <div
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Seller name :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{ item.seller_name }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="item.proposal_state != 'Draft'"
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Create time :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{
                                  getDateFromTimeStamp(
                                    item.create_time
                                  ).replace('T', ' ')
                                }}</strong>
                              </div>
                            </div>
                            <div
                              v-if="
                                item.create_time != item.update_time &&
                                item.proposal_state != 'Draft'
                              "
                              class="proposal_details_col"
                              style="display: flex"
                            >
                              <div>
                                <p>Update time :</p>
                              </div>
                              <div class="proposal_details_col">
                                <strong>{{
                                  getDateFromTimeStamp(
                                    item.update_time
                                  ).replace('T', ' ')
                                }}</strong>
                              </div>
                            </div>
                          </div>
                          <div style="flex: 1; text-align: right">
                            <div v-if="item.proposal_state != 'Draft'">
                              <v-btn
                                style="margin: 10px"
                                class="white--text notcapital"
                                color="black"
                                width="200"
                                dense
                                @click="goToDeals(item)"
                              >
                                Manage the deals
                                <v-icon right> mdi-arrow-right </v-icon>
                              </v-btn>
                              <v-btn
                                v-if="
                                  item.seller_accepted == 0 &&
                                  item.proposal_state == 'Proposed'
                                "
                                :loading="submitLoadingAccept"
                                :disabled="submitLoadingAccept"
                                style="margin: 10px"
                                class="white--text notcapital"
                                color="green"
                                width="200"
                                dense
                                @click="acceptProposal(item)"
                              >
                                Accept proposal
                                <v-icon right> mdi-check-circle </v-icon>
                              </v-btn>
                              <rejectProposal
                                v-if="item.proposal_state != 'Rejected'"
                                :item="item"
                                @proposalRejected="rejectProposal"
                              >
                              </rejectProposal>
                              <deleteProposal
                                v-else
                                :item="item"
                                @proposalDeleted="deleteProposal"
                              >
                              </deleteProposal>
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>

                        <div
                          v-if="item.proposal_state != 'Draft'"
                          style="
                            margin-top: 20px;
                            margin-bottom: 10px;
                            font-size: 14px;
                          "
                        >
                          <v-row>
                            <v-col cols="2">
                              <h3>Notes</h3>
                            </v-col>
                            <v-col cols="10" style="text-align: right">
                              <addNote
                                v-if="item.proposal_state != 'Rejected'"
                                :item="item"
                                @noteAdded="addNote"
                              >
                              </addNote>
                            </v-col>
                          </v-row>
                        </div>

                        <div
                          v-if="item.proposal_state != 'Draft'"
                          style="padding-right: 10px"
                        >
                          <p v-if="item.notes.length == 0">
                            There is no note to show.
                          </p>
                          <v-expansion-panels v-else v-model="panel" multiple>
                            <v-expansion-panel
                              v-for="(value, key) in item.notes"
                              :key="key"
                            >
                              <v-expansion-panel-header
                                style="padding-top: 5px; padding-bottom: 5px"
                                ><template style="padding: 0px"
                                  ><v-col
                                    v-if="value.created_by == 'Seller'"
                                    cols="8"
                                    >{{ value.created_by }} (You)</v-col
                                  >
                                  <v-col v-else cols="8">{{
                                    value.created_by
                                  }}</v-col>
                                  <v-col cols="4" style="text-align: right"
                                    >{{
                                      getDateFromTimeStampRegularFormat(
                                        value.create_time
                                      )
                                    }}
                                  </v-col></template
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-divider></v-divider>

                                <p
                                  style="
                                    font-size: 15px;
                                    padding-left: 10px;
                                    margin-top: 10px;
                                  "
                                >
                                  Content : {{ value.content }}
                                </p>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                        <v-divider v-if="item.notes.length == 0"></v-divider>

                        <v-row style="margin-top: 10px">
                          <v-col
                            cols="11"
                            align="left"
                            justify="left"
                            style="margin-left: 15px"
                          >
                            <span v-if="errors.length > 0" width="700">
                              <v-alert
                                v-for="error in errors"
                                :key="error"
                                color="#db2727"
                                type="error"
                                dense
                                >{{ error }}</v-alert
                              >
                            </span>
                          </v-col>
                        </v-row>

                        <v-card-actions
                          v-if="
                            item.proposal_id == 0 || item.proposal_id == null
                          "
                          class="d-flex flex-row-reverse"
                        >
                          <v-btn
                            :loading="submitLoading"
                            :disabled="submitLoading"
                            style="margin: 10px"
                            class="white--text notcapital"
                            color="black"
                            width="300"
                            dense
                            @click="sendProposal(item)"
                          >
                            Send the proposal request
                            <v-icon right> mdi-email-fast </v-icon>
                          </v-btn>
                        </v-card-actions>
                        <v-card-actions
                          v-else
                          class="d-flex"
                          style="padding-left: 0px"
                        >
                          <v-btn
                            v-if="item.proposal_state != 'Rejected'"
                            :loading="submitLoading"
                            :disabled="submitLoading"
                            style="margin: 10px; margin-left: 0px"
                            class="white--text notcapital"
                            color="black"
                            width="200"
                            dense
                            @click="sendProposal(item)"
                          >
                            Update proposal
                            <v-icon right> mdi-update </v-icon>
                          </v-btn>

                          <deleteProposal
                            v-if="item.proposal_state != 'Rejected'"
                            :item="item"
                            @proposalDeleted="deleteProposal"
                          >
                          </deleteProposal>
                        </v-card-actions>
                      </v-container>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-snackbar v-model="proposalsent" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ proposal_message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import delete_proposal from './delete_proposal.vue'
  import reject_proposal from './reject_proposal.vue'
  import add_note from './add_note.vue'

  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedProposal } from '@/store/mutation_types'
  import {
    GetProposals,
    GetDeals,
    GetNotes,
    SaveSelectedProposal,
    SaveSelectedDeal,
    SaveSelectedNote
  } from '@/store/action_types'
  export default {
    components: {
      deleteProposal: delete_proposal,
      rejectProposal: reject_proposal,
      addNote: add_note
    },

    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'proposal_id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('buyer_name'),
            align: 'left',
            value: 'buyer_name'
          },
          {
            text: this.$t('seller_name'),
            align: 'left',
            value: 'seller_name'
          },
          {
            text: this.$t('status'),
            align: 'left',
            value: 'proposal_state'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
            sortable: false
          }
        ],
        search: '',
        loadingProposal: false,
        showArchived: false,
        proposalsent: false,
        timeout: 3000,
        expanded: [],
        singleExpand: false,
        submitLoading: false,
        submitLoadingAccept: false,
        loading: false,
        proposal_message: 'Proposal request sent successfully',
        errors: [],
        panel: []
      }
    },
    computed: {
      ...mapGetters([
        'proposals',
        'deals',
        'notes',
        'appCustomer',
        'selectedProposal'
      ])
    },
    watch: {
      async appCustomer() {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          await this.GetProposals(this.appCustomer.id)
          this.$router.push({
            name: 'proposal_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
      }
    },

    async created() {
      await this.GetProposals(this.$route.params.customer_id)
    },
    methods: {
      ...mapMutations([SetSelectedProposal]),
      ...mapActions({
        GetProposals,
        GetDeals,
        GetNotes,
        SaveSelectedProposal,
        SaveSelectedDeal,
        SaveSelectedNote
      }),
      newProposal() {
        this.$router.push({
          name: 'proposal_new',
          params: {
            customer_id: this.$route.params.customer_id
          }
        })
      },
      editItem(proposal) {
        this.SetSelectedProposal(proposal)
        this.$router.push({
          name: 'proposal_edit',
          params: {
            proposal_id: proposal.id,
            customer_id: this.$route.params.customer_id
          }
        })
      },
      goToDeals(proposal) {
        this.SetSelectedProposal(proposal)
        this.$router.push({
          name: 'deal_list',
          params: {
            proposal_id: proposal.id,
            customer_id: this.$route.params.customer_id
          }
        })
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      },
      getDateFromTimeStampRegularFormat(timestamp) {
        const date = new Date(timestamp * 1000)
        const timeDiff = date - Date.now()
        const diffDays = new Date().getDate() - date.getDate()
        const diffMonths = new Date().getMonth() - date.getMonth()
        const diffYears = new Date().getFullYear() - date.getFullYear()

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = day + '-' + month + '-' + year
        var time = hours + ':' + minutes
        var dateTime = fulldate + ' at ' + time
        if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
          return 'Today' + ' at ' + time
        } else if (diffYears === 0 && diffDays === 1) {
          return 'Yesterday' + ' at ' + time
        } else return dateTime
      },
      async InitializeProposal(proposal) {
        var proposalUpdated = {
          id: proposal.id,
          revision_number: null,
          proposal_state: 'Deleted',
          last_updated_by: 'Buyer',
          create_time: null,
          update_time: null
        }
        await this.SaveSelectedProposal(proposalUpdated)
        await this.GetDeals(proposal.id)
        this.deals.forEach(async (item) => {
          var deal = {
            id: item.id,
            deal_external_id: item.deal_external_id,
            deal_id: null,
            create_time: null,
            update_time: null
          }
          await this.SaveSelectedDeal(deal)
        })
      },
      async deleteProposal(proposal) {
        try {
          const response = await axios.post(`/api/deleteproposal`, proposal)
          await this.InitializeProposal(proposal)
          await this.GetProposals(this.$route.params.customer_id)
          this.proposalsent = true
          this.proposal_message = 'Proposal request deleted successfully'
        } catch (error) {
          return error
        }
      },
      async verifyDeals(proposal) {
        await this.GetDeals(proposal.id)
        if (this.deals.length == 0)
          this.errors.push(
            'You need to add at least a deal before sending your proposal request'
          )
        else
          this.deals.forEach((deal) => {
            if (deal.rate_amount == 0.0)
              this.errors.push(
                'The deal with id = ' +
                  deal.id +
                  ' has the value of 0 as rate amount'
              )
          })
      },
      async addNote(note) {
        try {
          var response = await axios.post(`/api/addnote`, note)
          const noteFromApi = response.data
          noteFromApi['Notes'].forEach((element) => {
            if (element.Content == note.content) {
              note.note_id = element.NoteId
              note.revision_number = element.RevisionNumber
              note.create_time =
                new Date(element.CreateTime.slice(0, -1)).getTime() / 1000
            }
          })
          await this.SaveSelectedNote(note)
          await this.GetProposals(this.$route.params.customer_id)
        } catch (error) {
          console.log(error)
        }
      },
      async rejectProposal(proposal) {
        try {
          var proposalToSend = await this.getProposalJson(proposal)
          var response = await axios.post(`/api/rejectproposal`, proposalToSend)
          const proposalFromApi = response.data
          var proposalToUpdate = {
            id: proposal.id,
            proposal_id: proposalFromApi.ProposalId,
            revision_number: proposalFromApi.RevisionNumber,
            proposal_state: proposalFromApi.ProposalState,
            last_updated_by: proposalFromApi.LastUpdatedBy,
            seller_accepted: proposalFromApi.SellerAccepted,
            create_time:
              new Date(proposalFromApi.CreatedTime.slice(0, -1)).getTime() /
              1000,
            update_time:
              new Date(proposalFromApi.UpdatedTime.slice(0, -1)).getTime() /
              1000
          }
          await this.SaveSelectedProposal(proposalToUpdate)
          await this.GetProposals(this.$route.params.customer_id)
        } catch (error) {
          console.log(error)
        }
      },
      async acceptProposal(proposal) {
        this.submitLoadingAccept = true
        try {
          var proposalToSend = await this.getProposalJson(proposal)
          var response = await axios.post(`/api/acceptproposal`, proposalToSend)
          const proposalFromApi = response.data
          var proposalToUpdate = {
            id: proposal.id,
            proposal_id: proposalFromApi.ProposalId,
            revision_number: proposalFromApi.RevisionNumber,
            proposal_state: proposalFromApi.ProposalState,
            last_updated_by: proposalFromApi.LastUpdatedBy,
            seller_accepted: proposalFromApi.SellerAccepted,
            create_time:
              new Date(proposalFromApi.CreatedTime.slice(0, -1)).getTime() /
              1000,
            update_time:
              new Date(proposalFromApi.UpdatedTime.slice(0, -1)).getTime() /
              1000
          }
          await this.SaveSelectedProposal(proposalToUpdate)
          this.submitLoadingAccept = false
          await this.GetProposals(this.$route.params.customer_id)
        } catch (error) {
          console.log(error)
        }
      },

      async sendProposal(proposal) {
        await this.verifyDeals(proposal)
        if (this.errors.length == 0) {
          this.submitLoading = true
          var proposalToSend = await this.getProposalJson(proposal)
          try {
            var response = {}
            if (proposal.proposal_state == 'Draft') {
              response = await axios.post(`/api/sendproposal`, proposalToSend)
              this.proposal_message = 'Proposal request sent successfully'
            } else {
              response = await axios.post(`/api/updateproposal`, proposalToSend)
              this.proposal_message = 'Proposal request updated successfully'
            }
            const proposalFromApi = response.data
            if (proposalFromApi.ProposalId != undefined) {
              var proposalUpdated = {
                id: proposal.id,
                proposal_id: proposalFromApi.ProposalId,
                revision_number: proposalFromApi.RevisionNumber,
                proposal_state: proposalFromApi.ProposalState,
                last_updated_by: proposalFromApi.LastUpdatedBy,
                seller_accepted: proposalFromApi.SellerAccepted,
                create_time:
                  new Date(proposalFromApi.CreatedTime.slice(0, -1)).getTime() /
                  1000,
                update_time:
                  new Date(proposalFromApi.UpdatedTime.slice(0, -1)).getTime() /
                  1000
              }
              //updating the proposal
              await this.SaveSelectedProposal(proposalUpdated)

              proposalFromApi.Deals.forEach(async (dealFromApi) => {
                var deal = {
                  id: 1,
                  deal_external_id: dealFromApi.ExternalDealId,
                  deal_id: dealFromApi.DealId,
                  create_time:
                    new Date(dealFromApi.CreateTime.slice(0, -1)).getTime() /
                    1000,
                  update_time:
                    new Date(dealFromApi.UpdateTime.slice(0, -1)).getTime() /
                    1000
                }
                //updating the deal
                await this.SaveSelectedDeal(deal)
              })
              this.proposalsent = true
              this.submitLoading = false
              await this.GetProposals(this.$route.params.customer_id)
            } else {
              this.submitLoading = false
              proposalFromApi.ErrorDetails.forEach((errors) => {
                errors.Reasons.forEach((reason) => {
                  this.errors.push(reason)
                })
              })
              setTimeout(() => (this.errors = []), 4000)
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          this.submitLoading = false
          setTimeout(() => (this.errors = []), 4000)
        }
      },
      async getProposalJson(proposal) {
        await this.GetDeals(proposal.id)
        const proposal_response = await axios.get(
          `/api/getproposalinfo/${proposal.id}`
        )
        const proposalinfo = proposal_response.data
        var dealsInTheProposal = []
        this.deals.forEach(async (deal) => {
          var item = {
            Id: deal.id,
            DisplayName: deal.name,
            ExternalDealId: deal.deal_external_id,
            PublisherIds: [],
            DealType: proposalinfo[0].deal_type,
            AuctionType: deal.auction_type,
            StartTime: this.getDateFromTimeStamp(deal.start_time),
            CreativeRestrictions: {
              CreativeFormat: deal.creative_format
            }
          }
          if (proposalinfo[0].deal_type == 'Preferred') {
            item['PreferredDealTerms'] = {
              RateType: 'CPM',
              Rate: {
                Amount: Number(deal.rate_amount),
                CurrencyCode: deal.rate_currency
              }
            }
          } else if (proposalinfo[0].deal_type == 'PrivateAuction') {
            item['PrivateAuctionTerms'] = {
              RateType: 'CPM',
              Rate: {
                Amount: deal.rate_amount,
                CurrencyCode: deal.rate_currency
              }
            }
          } else if (proposalinfo[0].deal_type == 'Guaranteed') {
            item['GuaranteedTerms'] = {
              RateType: 'CPM',
              Rate: {
                Amount: deal.rate_amount,
                CurrencyCode: deal.rate_currency
              },
              GuaranteedLooks: deal.guaranteed_looks,
              GuaranteedImpressions: deal.guaranteed_impressions
            }
          }
          if (deal.deal_id != null) item['DealId'] = deal.deal_id
          if (deal.end_time != null && deal.end_time != 0)
            item['EndTime'] = this.getDateFromTimeStamp(deal.end_time)
          dealsInTheProposal.push(item)
        })
        var proposalToSend = {
          DisplayName: proposalinfo[0].name,
          CustomerId: proposalinfo[0].customer_id,
          Buyer: {
            BuyerId: proposalinfo[0].buyer_id,
            BuyerName: proposalinfo[0].buyer_name
          },
          Seller: {
            SellerId: proposalinfo[0].seller_id,
            SellerName: proposalinfo[0].seller_name
          }
        }
        proposalToSend['Deals'] = dealsInTheProposal
        if (proposal.revision_number != undefined)
          proposalToSend['RevisionNumber'] = proposal.revision_number
        if (proposal.proposal_id != 0)
          proposalToSend['ProposalId'] = proposal.proposal_id

        return proposalToSend
      },
      async duplicateProposal(item) {
        this.SetSelectedProposal(item)
        const proposal_to_duplicate = Object.assign({}, this.selectedProposal)
        const proposal_duplicated = Object.assign({}, this.selectedProposal)
        proposal_duplicated.id = undefined
        proposal_duplicated.proposal_id = null
        proposal_duplicated.revision_number = null
        proposal_duplicated.proposal_state = 'Draft'
        proposal_duplicated.create_time = null
        proposal_duplicated.update_time = null
        proposal_duplicated.last_updated_by = 'Buyer'
        let nameExists = true
        let i = 1
        while (nameExists == true) {
          proposal_duplicated.name = item.name + ' (' + i + ')'
          const verifyNameFromApi = await axios.get(
            `/api/proposalnameexists/${proposal_duplicated.name}/${proposal_duplicated.customer_id}`
          )
          nameExists = verifyNameFromApi.data
          i++
        }
        try {
          let proposal_created = await this.SaveSelectedProposal(
            proposal_duplicated
          )
          await this.GetDeals(proposal_to_duplicate.id)
          for (const element of this.deals) {
            const deal_duplicated = Object.assign({}, element)
            deal_duplicated.id = undefined
            deal_duplicated.deal_id = null
            deal_duplicated.proposal_id = proposal_created.id
            deal_duplicated.name = element.name
            this.SaveSelectedDeal(deal_duplicated)
          }
          this.proposalsent = true
          this.proposal_message = 'Proposal duplicated successfully'
        } catch (error) {
          console.log(error)
        }
        await this.GetProposals(this.$route.params.customer_id)
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
  .proposal_details_row {
    padding-top: 20px !important;
    font-size: 14px;
  }
  .proposal_details_col {
    padding-left: 10px;
  }
  .proposal_details {
    cursor: initial;
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    padding-right: 5px !important;
    font-family: Roboto;
    background: whitesmoke;
    box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 25%),
      inset 0px -4px 8px -5px rgb(50 50 50 / 100%);
  }
  .element {
    display: inline-block;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
    width: 25%;
  }
</style>
