<template>
  <div id="PageContent">
    <v-container fluid style="padding-top: 0px">
      <v-card id="PageList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col>
              <h3 class="cnx">
                <strong> List of orders</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 0px"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newOrder()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a new order
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <p
                style="
                  position: relative;
                  top: 30px;
                  left: 5px;
                  font-size: 14px;
                  color: black;
                "
              >
                {{ numberOfOrdersCreated }}

                orders created (
                <a
                  :style="[
                    showArchived ? { color: 'black' } : { color: '#f28704' }
                  ]"
                  @click="showArchived = false"
                  ><u
                    ><strong>{{ numberOfActiveOrders }}</strong> active
                    orders</u
                  ></a
                >
                /
                <a
                  :style="[
                    showArchived ? { color: '#f28704' } : { color: 'black' }
                  ]"
                  @click="showArchived = true"
                  ><u
                    ><strong>{{ numberOfArchivedOrders }}</strong> archived
                    orders</u
                  ></a
                >
                )
              </p>
            </v-col>
            <v-col cols="2" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :sort-by="['name']"
                  :items-per-page="10"
                  :items="computedOrders"
                  :search="search"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :disable-pagination="true"
                  :single-expand="false"
                  style="background-color: #f9f9fb; cursor: pointer"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td style="width: 10%; font-size: 14px">
                        {{ props.item.id }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 40%; cursor: pointer"
                        @click="goToLineItems(props.item)"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-else
                        style="width: 40%; cursor: pointer; opacity: 0.5"
                        @click="goToLineItems(props.item)"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 30%; cursor: pointer"
                        @click="goToLineItems(props.item)"
                      >
                        {{ props.item.advertiser_name }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 1"
                        style="width: 30%; cursor: pointer; opacity: 0.5"
                        @click="goToLineItems(props.item)"
                      >
                        {{ props.item.advertiser_name }}
                      </td>
                      <td style="padding: 0px">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(props.item)"
                            >
                              <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit the order</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="goToLineItems(props.item)"
                            >
                              <v-icon size="20">mdi-table-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Manage the line items</span>
                        </v-tooltip>

                        <v-menu
                          ref="menuDuplication"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          rounded="lg"
                          left
                          bottom
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  class="ma-2"
                                  text
                                  icon
                                  color="#000"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon size="20"
                                    >mdi-content-duplicate</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Duplicate the line item</span>
                            </v-tooltip>
                          </template>
                          <v-card>
                            <v-list style="padding-bottom: 0px">
                              <v-list-item>
                                <v-radio-group
                                  v-model="selectedDuplicationLineItemOption"
                                  dense
                                  style="margin-top: 0px"
                                >
                                  <v-radio
                                    label="Without line items"
                                    value="withoutl"
                                  ></v-radio>
                                  <v-radio
                                    label="With line items"
                                    value="withl"
                                  ></v-radio>
                                </v-radio-group>
                              </v-list-item>
                              <v-divider
                                v-if="
                                  selectedDuplicationLineItemOption == 'withl'
                                "
                              ></v-divider>

                              <v-list-item
                                v-if="
                                  selectedDuplicationLineItemOption == 'withl'
                                "
                              >
                                <v-radio-group
                                  v-model="selectedDuplicationCreativeOption"
                                  dense
                                  style="margin-top: 5px"
                                >
                                  <v-radio
                                    label="Without creatives"
                                    value="withoutc"
                                  ></v-radio>
                                  <v-radio
                                    label="With creatives"
                                    value="withc"
                                  ></v-radio>
                                </v-radio-group>
                              </v-list-item>
                            </v-list>

                            <v-card-actions style="padding-top: 0px">
                              <v-spacer></v-spacer>

                              <v-btn
                                color="primary"
                                text
                                :disabled="duplication_disabled"
                                @click="duplicateOrder(props.item)"
                              >
                                Confirm
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                        <v-tooltip v-if="props.item.is_archived == 0" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="showConfirmationDialog(props.item)"
                            >
                              <v-icon size="20">mdi-archive-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <span>Archive the order</span>
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="unarchiveOrder(props.item)"
                            >
                              <v-icon size="20">mdi-archive-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Unarchive the order</span>
                        </v-tooltip>
                      </td>
                    </tr></template
                  >
                </v-data-table>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveOrder"
      />
      <v-snackbar v-model="orderOK" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedOrder } from '@/store/mutation_types'
  import {
    GetOrders,
    GetLineItems,
    GetCreatives,
    SaveSelectedOrder,
    SaveSelectedLineItem,
    SaveSelectedCreative,
    UpdateSelectedOrder,
    UpdateSelectedLineItem
  } from '@/store/action_types'
  import ConfirmAction from '@/pages/management/actions/confirmAction.vue'

  export default {
    components: { ConfirmAction },

    data() {
      return {
        headers: [
          {
            text: this.$t('Id'),
            align: 'left',
            value: 'id'
          },
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('advertiser_name'),
            align: 'left',
            value: 'advertiser_name'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
            sortable: false
          }
        ],
        search: '',
        showArchived: false,
        orderOK: false,
        timeout: 3000,
        submitLoading: false,
        submitLoadingAccept: false,
        loading: false,
        messageAction: 'Order added successfully',
        errors: [],
        numberOfOrdersCreated: 0,
        numberOfActiveOrders: 0,
        numberOfArchivedOrders: 0,
        confirmActionDialog: false,
        confirmationAction: '',
        item: [],
        selectedDuplicationLineItemOption: '',
        selectedDuplicationCreativeOption: '',
        duplication_disabled: true
      }
    },
    computed: {
      ...mapGetters([
        'orders',
        'lineitems',
        'appCustomer',
        'creatives',
        'selectedOrder'
      ]),
      computedOrders() {
        return this.orders
          .filter((el) => {
            return this.showArchived ? el.is_archived == 1 : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    watch: {
      async appCustomer() {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          await this.GetOrders(this.appCustomer.id)
          this.$router.push({
            name: 'order_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
        await this.GetOrders(this.appCustomer.id)
        this.manageNumberOfOrders()
      },
      selectedDuplicationCreativeOption(val) {
        if (this.selectedDuplicationCreativeOption != '')
          this.duplication_disabled = false
      },
      selectedDuplicationLineItemOption(val) {
        if (val == 'withoutl') {
          this.selectedDuplicationCreativeOption = ''
          this.duplication_disabled = false
        } else {
          this.duplication_disabled = true
        }
      }
    },

    async created() {
      await this.GetOrders(this.$route.params.customer_id)
      this.manageNumberOfOrders()
    },
    methods: {
      ...mapMutations([SetSelectedOrder]),
      ...mapActions({
        GetOrders,
        GetLineItems,
        GetCreatives,
        SaveSelectedOrder,
        SaveSelectedLineItem,
        SaveSelectedCreative,
        UpdateSelectedOrder,
        UpdateSelectedLineItem
      }),
      newOrder() {
        this.$router.push({
          name: 'order_new',
          params: {
            customer_id: this.$route.params.customer_id
          }
        })
      },
      editItem(order) {
        this.SetSelectedOrder(order)
        this.$router.push({
          name: 'order_edit',
          params: {
            order_id: order.id,
            customer_id: this.$route.params.customer_id
          }
        })
      },
      goToLineItems(order) {
        this.SetSelectedOrder(order)
        this.$router.push({
          name: 'lineitem_list',
          params: {
            order_id: order.id,
            customer_id: this.$route.params.customer_id
          }
        })
      },
      getOrdersArchived() {
        return this.orders.filter((el) => {
          return el.is_archived == 1
        })
      },
      getOrdersUnarchived() {
        return this.orders.filter((el) => {
          return el.is_archived == 0
        })
      },
      manageNumberOfOrders() {
        var orders_unarchived = this.getOrdersUnarchived()
        var orders_archived = this.getOrdersArchived()
        this.numberOfOrdersCreated =
          orders_unarchived.length + orders_archived.length
        this.numberOfActiveOrders = orders_unarchived.length
        this.numberOfArchivedOrders = orders_archived.length
      },

      async duplicateOrder(item) {
        this.$refs.menuDuplication.isActive = false
        const order_to_duplicate = Object.assign({}, item)
        const order_duplicated = Object.assign({}, item)
        order_duplicated.id = undefined
        let nameExists = true
        let i = 1
        while (nameExists == true) {
          order_duplicated.name = item.name + ' (' + i + ')'
          const verifyNameFromApi = await axios.get(
            `/api/dspordernameexists/${order_duplicated.name}/${order_duplicated.customer_id}`
          )
          nameExists = verifyNameFromApi.data
          i++
        }
        try {
          let order_created = await this.SaveSelectedOrder(order_duplicated)
          if (this.selectedDuplicationLineItemOption == 'withl') {
            this.duplicateLineItems(item.id, order_created.id)
          }
          await this.GetOrders(this.$route.params.customer_id)
          this.$refs.menuDuplication.isActive = false
          console.log(this.$refs.menuDuplication)
          this.messageAction = 'Line item duplicated successfully'
          this.actionsuccess = true
        } catch (error) {
          console.log(error)
        }
      },

      async duplicateLineItems(old_or_id, new_or_id) {
        await this.GetLineItems(old_or_id)
        for (const element of this.lineitems) {
          const line_item_duplicated = Object.assign({}, element)
          line_item_duplicated.id = undefined
          line_item_duplicated.order_id = new_or_id
          line_item_duplicated.name = element.name
          try {
            let line_item_created = await this.SaveSelectedLineItem(
              line_item_duplicated
            )
            if (this.selectedDuplicationCreativeOption == 'withc') {
              this.duplicateCreatives(element.id, line_item_created.id)
            }
          } catch (error) {
            console.log(error)
          }
        }
      },

      async duplicateCreatives(old_li_id, new_li_id) {
        await this.GetCreatives(old_li_id)
        for (const element of this.creatives) {
          const creative_duplicated = Object.assign({}, element)
          creative_duplicated.id = undefined
          creative_duplicated.line_item_id = new_li_id
          creative_duplicated.name = element.name
          try {
            await this.SaveSelectedCreative(creative_duplicated)
          } catch (error) {
            console.log(error)
          }
        }
      },

      showConfirmationDialog(item) {
        this.item = item
        this.$router.push(this.$route.path + '?action=confirmArchiving')
        this.confirmActionDialog = true
        this.confirmationAction = 'archive'
      },
      async archiveOrder(item) {
        item.is_archived = 1
        await this.UpdateSelectedOrder(item)
        this.SetSelectedOrder(item)
        await this.GetOrders(this.appCustomer.id)
        this.messageAction = 'Order archived successfully'
        this.orderOK = true
        this.manageNumberOfOrders()
      },
      async unarchiveOrder(item) {
        item.is_archived = 0
        this.UpdateSelectedOrder(item)
        this.SaveSelectedOrder(item)
        await this.GetOrders(this.appCustomer.id)
        this.messageAction = 'Order unarchived successfully'
        this.orderOK = true
        this.manageNumberOfOrders()
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      },
      getDateFromTimeStampRegularFormat(timestamp) {
        const date = new Date(timestamp * 1000)
        const timeDiff = date - Date.now()
        const diffDays = new Date().getDate() - date.getDate()
        const diffMonths = new Date().getMonth() - date.getMonth()
        const diffYears = new Date().getFullYear() - date.getFullYear()

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = day + '-' + month + '-' + year
        var time = hours + ':' + minutes
        var dateTime = fulldate + ' at ' + time
        if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
          return 'Today' + ' at ' + time
        } else if (diffYears === 0 && diffDays === 1) {
          return 'Yesterday' + ' at ' + time
        } else return dateTime
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
  }
  .proposal_details_row {
    padding-top: 20px !important;
    font-size: 14px;
  }
  .proposal_details_col {
    padding-left: 10px;
  }
  .proposal_details {
    cursor: initial;
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    padding-right: 5px !important;
    font-family: Roboto;
    background: whitesmoke;
    box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 25%),
      inset 0px -4px 8px -5px rgb(50 50 50 / 100%);
  }
  .element {
    display: inline-block;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
    width: 25%;
  }
</style>
