module.exports = [
  { code: 'IAB1', category: 'Arts & Entertainment' },
  { code: 'IAB1-1', category: 'Books & Literature' },
  { code: 'IAB1-2', category: 'Celebrity Fan/Gossip' },
  { code: 'IAB1-3', category: 'Fine Art' },
  { code: 'IAB1-4', category: 'Humor' },
  { code: 'IAB1-5', category: 'Movies' },
  { code: 'IAB1-6', category: 'Music' },
  { code: 'IAB1-7', category: 'Television' },
  { code: 'IAB2', category: 'Automotive' },
  { code: 'IAB2-1', category: 'Auto Parts' },
  { code: 'IAB2-2', category: 'Auto Repair' },
  { code: 'IAB2-3', category: 'Buying/Selling Cars' },
  { code: 'IAB2-4', category: 'Car Culture' },
  { code: 'IAB2-5', category: 'Certified Pre-Owned' },
  { code: 'IAB2-6', category: 'Convertible' },
  { code: 'IAB2-7', category: 'Coupe' },
  { code: 'IAB2-8', category: 'Crossover' },
  { code: 'IAB2-9', category: 'Diesel' },
  { code: 'IAB2-10', category: 'Electric Vehicle' },
  { code: 'IAB2-11', category: 'Hatchback' },
  { code: 'IAB2-12', category: 'Hybrid' },
  { code: 'IAB2-13', category: 'Luxury' },
  { code: 'IAB2-14', category: 'MiniVan' },
  { code: 'IAB2-15', category: 'Mororcycles' },
  { code: 'IAB2-16', category: 'Off-Road Vehicles' },
  { code: 'IAB2-17', category: 'Performance Vehicles' },
  { code: 'IAB2-18', category: 'Pickup' },
  { code: 'IAB2-19', category: 'Road-Side Assistance' },
  { code: 'IAB2-20', category: 'Sedan' },
  { code: 'IAB2-21', category: 'Trucks & Accessories' },
  { code: 'IAB2-22', category: 'Vintage Cars' },
  { code: 'IAB2-23', category: 'Wagon' },
  { code: 'IAB3', category: 'Business' },
  { code: 'IAB3-1', category: 'Advertising' },
  { code: 'IAB3-2', category: 'Agriculture' },
  { code: 'IAB3-3', category: 'Biotech/Biomedical' },
  { code: 'IAB3-4', category: 'Business Software' },
  { code: 'IAB3-5', category: 'Construction' },
  { code: 'IAB3-6', category: 'Forestry' },
  { code: 'IAB3-7', category: 'Government' },
  { code: 'IAB3-8', category: 'Green Solutions' },
  { code: 'IAB3-9', category: 'Human Resources' },
  { code: 'IAB3-10', category: 'Logistics' },
  { code: 'IAB3-11', category: 'Marketing' },
  { code: 'IAB3-12', category: 'Metals' },
  { code: 'IAB4', category: 'Careers' },
  { code: 'IAB4-1', category: 'Career Planning' },
  { code: 'IAB4-2', category: 'College' },
  { code: 'IAB4-3', category: 'Financial Aid' },
  { code: 'IAB4-4', category: 'Job Fairs' },
  { code: 'IAB4-5', category: 'Job Search' },
  { code: 'IAB4-6', category: 'Resume Writing/Advice' },
  { code: 'IAB4-7', category: 'Nursing' },
  { code: 'IAB4-8', category: 'Scholarships' },
  { code: 'IAB4-9', category: 'Telecommuting' },
  { code: 'IAB4-10', category: 'U.S. Military' },
  { code: 'IAB4-11', category: 'Career Advice' },
  { code: 'IAB5', category: 'Education' },
  { code: 'IAB5-1', category: '41832 Education' },
  { code: 'IAB5-2', category: 'Adult Education' },
  { code: 'IAB5-3', category: 'Art History' },
  { code: 'IAB5-4', category: 'Colledge Administration' },
  { code: 'IAB5-5', category: 'College Life' },
  { code: 'IAB5-6', category: 'Distance Learning' },
  { code: 'IAB5-7', category: 'English as a 2nd Language' },
  { code: 'IAB5-8', category: 'Language Learning' },
  { code: 'IAB5-9', category: 'Graduate School' },
  { code: 'IAB5-10', category: 'Homeschooling' },
  { code: 'IAB5-11', category: 'Homework/Study Tips' },
  { code: 'IAB5-12', category: 'K-6 Educators' },
  { code: 'IAB5-13', category: 'Private School' },
  { code: 'IAB5-14', category: 'Special Education' },
  { code: 'IAB5-15', category: 'Studying Business' },
  { code: 'IAB6', category: 'Family & Parenting' },
  { code: 'IAB6-1', category: 'Adoption' },
  { code: 'IAB6-2', category: 'Babies & Toddlers' },
  { code: 'IAB6-3', category: 'Daycare/Pre School' },
  { code: 'IAB6-4', category: 'Family Internet' },
  { code: 'IAB6-5', category: 'Parenting - K-6 Kids' },
  { code: 'IAB6-6', category: 'Parenting teens' },
  { code: 'IAB6-7', category: 'Pregnancy' },
  { code: 'IAB6-8', category: 'Special Needs Kids' },
  { code: 'IAB6-9', category: 'Eldercare' },
  { code: 'IAB7', category: 'Health & Fitness' },
  { code: 'IAB7-1', category: 'Exercise' },
  { code: 'IAB7-2', category: 'A.D.D.' },
  { code: 'IAB7-3', category: 'AIDS/HIV' },
  { code: 'IAB7-4', category: 'Allergies' },
  { code: 'IAB7-5', category: 'Alternative Medicine' },
  { code: 'IAB7-6', category: 'Arthritis' },
  { code: 'IAB7-7', category: 'Asthma' },
  { code: 'IAB7-8', category: 'Autism/PDD' },
  { code: 'IAB7-9', category: 'Bipolar Disorder' },
  { code: 'IAB7-10', category: 'Brain Tumor' },
  { code: 'IAB7-11', category: 'Cancer' },
  { code: 'IAB7-12', category: 'Cholesterol' },
  { code: 'IAB7-13', category: 'Chronic Fatigue Syndrome' },
  { code: 'IAB7-14', category: 'Chronic Pain' },
  { code: 'IAB7-15', category: 'Cold & Flu' },
  { code: 'IAB7-16', category: 'Deafness' },
  { code: 'IAB7-17', category: 'Dental Care' },
  { code: 'IAB7-18', category: 'Depression' },
  { code: 'IAB7-19', category: 'Dermatology' },
  { code: 'IAB7-20', category: 'Diabetes' },
  { code: 'IAB7-21', category: 'Epilepsy' },
  { code: 'IAB7-22', category: 'GERD/Acid Reflux' },
  { code: 'IAB7-23', category: 'Headaches/Migraines' },
  { code: 'IAB7-24', category: 'Heart Disease' },
  { code: 'IAB7-25', category: 'Herbs for Health' },
  { code: 'IAB7-26', category: 'Holistic Healing' },
  {
    code: 'IAB7-27', category: 'IBS/Crohns Disease'
  },
  { code: 'IAB7-28', category: 'Incest/Abuse Support' },
  { code: 'IAB7-29', category: 'Incontinence' },
  { code: 'IAB7-30', category: 'Infertility' },
  {
    code: 'IAB7-31', category: 'Mens Health'
  },
  { code: 'IAB7-32', category: 'Nutrition' },
  { code: 'IAB7-33', category: 'Orthopedics' },
  { code: 'IAB7-34', category: 'Panic/Anxiety Disorders' },
  { code: 'IAB7-35', category: 'Pediatrics' },
  { code: 'IAB7-36', category: 'Physical Therapy' },
  { code: 'IAB7-37', category: 'Psychology/Psychiatry' },
  { code: 'IAB7-38', category: 'Senor Health' },
  { code: 'IAB7-39', category: 'Sexuality' },
  { code: 'IAB7-40', category: 'Sleep Disorders' },
  { code: 'IAB7-41', category: 'Smoking Cessation' },
  { code: 'IAB7-42', category: 'Substance Abuse' },
  { code: 'IAB7-43', category: 'Thyroid Disease' },
  { code: 'IAB7-44', category: 'Weight Loss' },
  {
    code: 'IAB7-45', category: 'Womens Health'
  },
  { code: 'IAB8', category: 'Food & Drink' },
  { code: 'IAB8-1', category: 'American Cuisine' },
  { code: 'IAB8-2', category: 'Barbecues & Grilling' },
  { code: 'IAB8-3', category: 'Cajun/Creole' },
  { code: 'IAB8-4', category: 'Chinese Cuisine' },
  { code: 'IAB8-5', category: 'Cocktails/Beer' },
  { code: 'IAB8-6', category: 'Coffee/Tea' },
  { code: 'IAB8-7', category: 'Cuisine-Specific' },
  { code: 'IAB8-8', category: 'Desserts & Baking' },
  { code: 'IAB8-9', category: 'Dining Out' },
  { code: 'IAB8-10', category: 'Food Allergies' },
  { code: 'IAB8-11', category: 'French Cuisine' },
  { code: 'IAB8-12', category: 'Health/Lowfat Cooking' },
  { code: 'IAB8-13', category: 'Italian Cuisine' },
  { code: 'IAB8-14', category: 'Japanese Cuisine' },
  { code: 'IAB8-15', category: 'Mexican Cuisine' },
  { code: 'IAB8-16', category: 'Vegan' },
  { code: 'IAB8-17', category: 'Vegetarian' },
  { code: 'IAB8-18', category: 'Wine' },
  { code: 'IAB9', category: 'Hobbies & Interests' },
  { code: 'IAB9-1', category: 'Art/Technology' },
  { code: 'IAB9-2', category: 'Arts & Crafts' },
  { code: 'IAB9-3', category: 'Beadwork' },
  { code: 'IAB9-4', category: 'Birdwatching' },
  { code: 'IAB9-5', category: 'Board Games/Puzzles' },
  { code: 'IAB9-6', category: 'Candle & Soap Making' },
  { code: 'IAB9-7', category: 'Card Games' },
  { code: 'IAB9-8', category: 'Chess' },
  { code: 'IAB9-9', category: 'Cigars' },
  { code: 'IAB9-10', category: 'Collecting' },
  { code: 'IAB9-11', category: 'Comic Books' },
  { code: 'IAB9-12', category: 'Drawing/Sketching' },
  { code: 'IAB9-13', category: 'Freelance Writing' },
  { code: 'IAB9-14', category: 'Genealogy' },
  { code: 'IAB9-15', category: 'Getting Published' },
  { code: 'IAB9-16', category: 'Guitar' },
  { code: 'IAB9-17', category: 'Home Recording' },
  { code: 'IAB9-18', category: 'Investors & Patents' },
  { code: 'IAB9-19', category: 'Jewelry Making' },
  { code: 'IAB9-20', category: 'Magic & Illusion' },
  { code: 'IAB9-21', category: 'Needlework' },
  { code: 'IAB9-22', category: 'Painting' },
  { code: 'IAB9-23', category: 'Photography' },
  { code: 'IAB9-24', category: 'Radio' },
  { code: 'IAB9-25', category: 'Roleplaying Games' },
  { code: 'IAB9-26', category: 'Sci-Fi & Fantasy' },
  { code: 'IAB9-27', category: 'Scrapbooking' },
  { code: 'IAB9-28', category: 'Screenwriting' },
  { code: 'IAB9-29', category: 'Stamps & Coins' },
  { code: 'IAB9-30', category: 'Video & Computer Games' },
  { code: 'IAB9-31', category: 'Woodworking' },
  { code: 'IAB10', category: 'Home & Garden' },
  { code: 'IAB10-1', category: 'Appliances' },
  { code: 'IAB10-2', category: 'Entertaining' },
  { code: 'IAB10-3', category: 'Environmental Safety' },
  { code: 'IAB10-4', category: 'Gardening' },
  { code: 'IAB10-5', category: 'Home Repair' },
  { code: 'IAB10-6', category: 'Home Theater' },
  { code: 'IAB10-7', category: 'Interior Decorating' },
  { code: 'IAB10-8', category: 'Landscaping' },
  { code: 'IAB10-9', category: 'Remodeling & Construction' },
  { code: 'IAB11', category: 'Law, Gov t & Politics' },
  { code: 'IAB11-1', category: 'Immigration' },
  { code: 'IAB11-2', category: 'Legal Issues' },
  { code: 'IAB11-3', category: 'U.S. Government Resources' },
  { code: 'IAB11-4', category: 'Politics' },
  { code: 'IAB11-5', category: 'Commentary' },
  { code: 'IAB12', category: 'News' },
  { code: 'IAB12-1', category: 'International News' },
  { code: 'IAB12-2', category: 'National News' },
  { code: 'IAB12-3', category: 'Local News' },
  { code: 'IAB13', category: 'Personal Finance' },
  { code: 'IAB13-1', category: 'Beginning Investing' },
  { code: 'IAB13-2', category: 'Credit/Debt & Loans' },
  { code: 'IAB13-3', category: 'Financial News' },
  { code: 'IAB13-4', category: 'Financial Planning' },
  { code: 'IAB13-5', category: 'Hedge Fund' },
  { code: 'IAB13-6', category: 'Insurance' },
  { code: 'IAB13-7', category: 'Investing' },
  { code: 'IAB13-8', category: 'Mutual Funds' },
  { code: 'IAB13-9', category: 'Options' },
  { code: 'IAB13-10', category: 'Retirement Planning' },
  { code: 'IAB13-11', category: 'Stocks' },
  { code: 'IAB13-12', category: 'Tax Planning' },
  { code: 'IAB14', category: 'Society' },
  { code: 'IAB14-1', category: 'Dating' },
  { code: 'IAB14-2', category: 'Divorce Support' },
  { code: 'IAB14-3', category: 'Gay Life' },
  { code: 'IAB14-4', category: 'Marriage' },
  { code: 'IAB14-5', category: 'Senior Living' },
  { code: 'IAB14-6', category: 'Teens' },
  { code: 'IAB14-7', category: 'Weddings' },
  { code: 'IAB14-8', category: 'Ethnic Specific' },
  { code: 'IAB15', category: 'Science' },
  { code: 'IAB15-1', category: 'Astrology' },
  { code: 'IAB15-2', category: 'Biology' },
  { code: 'IAB15-3', category: 'Chemistry' },
  { code: 'IAB15-4', category: 'Geology' },
  { code: 'IAB15-5', category: 'Paranormal Phenomena' },
  { code: 'IAB15-6', category: 'Physics' },
  { code: 'IAB15-7', category: 'Space/Astronomy' },
  { code: 'IAB15-8', category: 'Geography' },
  { code: 'IAB15-9', category: 'Botany' },
  { code: 'IAB15-10', category: 'Weather' },
  { code: 'IAB16', category: 'Pets' },
  { code: 'IAB16-1', category: 'Aquariums' },
  { code: 'IAB16-2', category: 'Birds' },
  { code: 'IAB16-3', category: 'Cats' },
  { code: 'IAB16-4', category: 'Dogs' },
  { code: 'IAB16-5', category: 'Large Animals' },
  { code: 'IAB16-6', category: 'Reptiles' },
  { code: 'IAB16-7', category: 'Veterinary Medicine' },
  { code: 'IAB17', category: 'Sports' },
  { code: 'IAB17-1', category: 'Auto Racing' },
  { code: 'IAB17-2', category: 'Baseball' },
  { code: 'IAB17-3', category: 'Bicycling' },
  { code: 'IAB17-4', category: 'Bodybuilding' },
  { code: 'IAB17-5', category: 'Boxing' },
  { code: 'IAB17-6', category: 'Canoeing/Kayaking' },
  { code: 'IAB17-7', category: 'Cheerleading' },
  { code: 'IAB17-8', category: 'Climbing' },
  { code: 'IAB17-9', category: 'Cricket' },
  { code: 'IAB17-10', category: 'Figure Skating' },
  { code: 'IAB17-11', category: 'Fly Fishing' },
  { code: 'IAB17-12', category: 'Football' },
  { code: 'IAB17-13', category: 'Freshwater Fishing' },
  { code: 'IAB17-14', category: 'Game & Fish' },
  { code: 'IAB17-15', category: 'Golf' },
  { code: 'IAB17-16', category: 'Horse Racing' },
  { code: 'IAB17-17', category: 'Horses' },
  { code: 'IAB17-18', category: 'Hunting/Shooting' },
  { code: 'IAB17-19', category: 'Inline Skating' },
  { code: 'IAB17-20', category: 'Martial Arts' },
  { code: 'IAB17-21', category: 'Mountain Biking' },
  { code: 'IAB17-22', category: 'NASCAR Racing' },
  { code: 'IAB17-23', category: 'Olympics' },
  { code: 'IAB17-24', category: 'Paintball' },
  { code: 'IAB17-25', category: 'Power & Motorcycles' },
  { code: 'IAB17-26', category: 'Pro Basketball' },
  { code: 'IAB17-27', category: 'Pro Ice Hockey' },
  { code: 'IAB17-28', category: 'Rodeo' },
  { code: 'IAB17-29', category: 'Rugby' },
  { code: 'IAB17-30', category: 'Running/Jogging' },
  { code: 'IAB17-31', category: 'Sailing' },
  { code: 'IAB17-32', category: 'Saltwater Fishing' },
  { code: 'IAB17-33', category: 'Scuba Diving' },
  { code: 'IAB17-34', category: 'Skateboarding' },
  { code: 'IAB17-35', category: 'Skiing' },
  { code: 'IAB17-36', category: 'Snowboarding' },
  { code: 'IAB17-37', category: 'Surfing/Bodyboarding' },
  { code: 'IAB17-38', category: 'Swimming' },
  { code: 'IAB17-39', category: 'Table Tennis/Ping-Pong' },
  { code: 'IAB17-40', category: 'Tennis' },
  { code: 'IAB17-41', category: 'Volleyball' },
  { code: 'IAB17-42', category: 'Walking' },
  { code: 'IAB17-43', category: 'Waterski/Wakeboard' },
  { code: 'IAB17-44', category: 'World Soccer' },
  { code: 'IAB18', category: 'Style & Fashion' },
  { code: 'IAB18-1', category: 'Beauty' },
  { code: 'IAB18-2', category: 'Body Art' },
  { code: 'IAB18-3', category: 'Fashion' },
  { code: 'IAB18-4', category: 'Jewelry' },
  { code: 'IAB18-5', category: 'Clothing' },
  { code: 'IAB18-6', category: 'Accessories' },
  { code: 'IAB19', category: 'Technology & Computing' },
  { code: 'IAB19-1', category: '3-D Graphics' },
  { code: 'IAB19-2', category: 'Animation' },
  { code: 'IAB19-3', category: 'Antivirus Software' },
  { code: 'IAB19-4', category: 'C/C++' },
  { code: 'IAB19-5', category: 'Cameras & Camcorders' },
  { code: 'IAB19-6', category: 'Cell Phones' },
  { code: 'IAB19-7', category: 'Computer Certification' },
  { code: 'IAB19-8', category: 'Computer Networking' },
  { code: 'IAB19-9', category: 'Computer Peripherals' },
  { code: 'IAB19-10', category: 'Computer Reviews' },
  { code: 'IAB19-11', category: 'Data Centers' },
  { code: 'IAB19-12', category: 'Databases' },
  { code: 'IAB19-13', category: 'Desktop Publishing' },
  { code: 'IAB19-14', category: 'Desktop Video' },
  { code: 'IAB19-15', category: 'Email' },
  { code: 'IAB19-16', category: 'Graphics Software' },
  { code: 'IAB19-17', category: 'Home Video/DVD' },
  { code: 'IAB19-18', category: 'Internet Technology' },
  { code: 'IAB19-19', category: 'Java' },
  { code: 'IAB19-20', category: 'JavaScript' },
  { code: 'IAB19-21', category: 'Mac Support' },
  { code: 'IAB19-22', category: 'MP3/MIDI' },
  { code: 'IAB19-23', category: 'Net Conferencing' },
  { code: 'IAB19-24', category: 'Net for Beginners' },
  { code: 'IAB19-25', category: 'Network Security' },
  { code: 'IAB19-26', category: 'Palmtops/PDAs' },
  { code: 'IAB19-27', category: 'PC Support' },
  { code: 'IAB19-28', category: 'Portable' },
  { code: 'IAB19-29', category: 'Entertainment' },
  { code: 'IAB19-30', category: 'Shareware/Freeware' },
  { code: 'IAB19-31', category: 'Unix' },
  { code: 'IAB19-32', category: 'Visual Basic' },
  { code: 'IAB19-33', category: 'Web Clip Art' },
  { code: 'IAB19-34', category: 'Web Design/HTML' },
  { code: 'IAB19-35', category: 'Web Search' },
  { code: 'IAB19-36', category: 'Windows' },
  { code: 'IAB20', category: 'Travel' },
  { code: 'IAB20-1', category: 'Adventure Travel' },
  { code: 'IAB20-2', category: 'Africa' },
  { code: 'IAB20-3', category: 'Air Travel' },
  { code: 'IAB20-4', category: 'Australia & New Zealand' },
  { code: 'IAB20-5', category: 'Bed & Breakfasts' },
  { code: 'IAB20-6', category: 'Budget Travel' },
  { code: 'IAB20-7', category: 'Business Travel' },
  { code: 'IAB20-8', category: 'By US Locale' },
  { code: 'IAB20-9', category: 'Camping' },
  { code: 'IAB20-10', category: 'Canada' },
  { code: 'IAB20-11', category: 'Caribbean' },
  { code: 'IAB20-12', category: 'Cruises' },
  { code: 'IAB20-13', category: 'Eastern Europe' },
  { code: 'IAB20-14', category: 'Europe' },
  { code: 'IAB20-15', category: 'France' },
  { code: 'IAB20-16', category: 'Greece' },
  { code: 'IAB20-17', category: 'Honeymoons/Getaways' },
  { code: 'IAB20-18', category: 'Hotels' },
  { code: 'IAB20-19', category: 'Italy' },
  { code: 'IAB20-20', category: 'Japan' },
  { code: 'IAB20-21', category: 'Mexico & Central America' },
  { code: 'IAB20-22', category: 'National Parks' },
  { code: 'IAB20-23', category: 'South America' },
  { code: 'IAB20-24', category: 'Spas' },
  { code: 'IAB20-25', category: 'Theme Parks' },
  { code: 'IAB20-26', category: 'Traveling with Kids' },
  { code: 'IAB20-27', category: 'United Kingdom' },
  { code: 'IAB21', category: 'Real Estate' },
  { code: 'IAB21-1', category: 'Apartments' },
  { code: 'IAB21-2', category: 'Architects' },
  { code: 'IAB21-3', category: 'Buying/Selling Homes' },
  { code: 'IAB22', category: 'Shopping' },
  { code: 'IAB22-1', category: 'Contests & Freebies' },
  { code: 'IAB22-2', category: 'Couponing' },
  { code: 'IAB22-3', category: 'Comparison' },
  { code: 'IAB22-4', category: 'Engines' },
  { code: 'IAB23', category: 'Religion & Spirituality' },
  { code: 'IAB23-1', category: 'Alternative Religions' },
  { code: 'IAB23-2', category: 'Atheism/Agnosticism' },
  { code: 'IAB23-3', category: 'Buddhism' },
  { code: 'IAB23-4', category: 'Catholicism' },
  { code: 'IAB23-5', category: 'Christianity' },
  { code: 'IAB23-6', category: 'Hinduism' },
  { code: 'IAB23-7', category: 'Islam' },
  { code: 'IAB23-8', category: 'Judaism' },
  { code: 'IAB23-9', category: 'Latter-Day Saints' },
  { code: 'IAB23-10', category: 'Pagan/Wiccan' },
  { code: 'IAB24', category: 'Uncategorized' },
  { code: 'IAB25', category: 'Non-Standard Content' },
  { code: 'IAB25-1', category: 'Unmoderated UGC' },
  { code: 'IAB25-2', category: 'Extreme Graphic/Explicit Violence' },
  { code: 'IAB25-3', category: 'Pornography' },
  { code: 'IAB25-4', category: 'Profane Content' },
  { code: 'IAB25-5', category: 'Hate Content' },
  { code: 'IAB25-6', category: 'Under Construction' },
  { code: 'IAB25-7', category: 'Incentivized' },
  { code: 'IAB26', category: 'Illegal Contents' },
  { code: 'IAB26-1', category: 'Illegal Content' },
  { code: 'IAB26-2', category: 'Warez' },
  { code: 'IAB26-3', category: 'Spyware/Malware' },
  { code: 'IAB26-4', category: 'Copyright Infringement' }]