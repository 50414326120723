import { appCustomerGetters } from '@/store/items/appCustomer'
import { appUserGetters } from '@/store/items/appUser'
import { onboardingUserGetters } from '@/store/items/onboardingUser'

import { usersGetters } from '@/store/items/users'
import { selectedUserGetters } from '@/store/items/selectedUser'

import { customersGetters } from '@/store/items/customers'
import { selectedCustomerGetters } from '@/store/items/selectedCustomer'

import { proposalsGetters } from '@/store/items/proposals'
import { selectedProposalGetters } from '@/store/items/selectedProposal'

import { ordersGetters } from '@/store/items/orders'
import { selectedOrderGetters } from '@/store/items/selectedOrder'

import { dealsGetters } from '@/store/items/deals'
import { selectedDealGetters } from '@/store/items/selectedDeal'

import { lineitemsGetters } from '@/store/items/lineItems'
import { selectedLineItemGetters } from '@/store/items/selectedLineItem'

import { creativesGetters } from '@/store/items/creatives'
import { selectedCreativeGetters } from '@/store/items/selectedCreative'

import { notesGetters } from '@/store/items/notes'
import { selectedNoteGetters } from '@/store/items/selectedNote'

import { gamsetupsGetters } from '@/store/items/gamsetups'
import { selectedGamSetupGetters } from '@/store/items/selectedGamSetup'

import { optionscookielessGetters } from '@/store/items/optionscookieless'
import { selectedOptionsCookielessGetters } from '@/store/items/selectedOptionsCookieless'

import { storedRequestsGetters } from './items/storedRequests'
import { selectedStoredRequestGetters } from '@/store/items/selectedStoredRequest'

import { vastsGetters } from './items/vasts'
import { selectedVastGetters } from '@/store/items/selectedVast'

import { injectionsGetters } from './items/injections'
import { selectedInjectionGetters } from '@/store/items/selectedInjection'

import { sitesGetters } from '@/store/items/sites'
import { selectedSiteGetters } from './items/selectedSite'

const getters = {
  ...appCustomerGetters,
  ...appUserGetters,
  ...onboardingUserGetters,
  ...usersGetters,
  ...selectedUserGetters,
  ...customersGetters,
  ...selectedCustomerGetters,
  ...proposalsGetters,
  ...selectedProposalGetters,
  ...ordersGetters,
  ...selectedOrderGetters,
  ...dealsGetters,
  ...selectedDealGetters,
  ...lineitemsGetters,
  ...selectedLineItemGetters,
  ...creativesGetters,
  ...selectedCreativeGetters,
  ...notesGetters,
  ...selectedNoteGetters,
  ...gamsetupsGetters,
  ...optionscookielessGetters,
  ...selectedOptionsCookielessGetters,
  ...selectedGamSetupGetters,
  ...sitesGetters,
  ...selectedSiteGetters,
  ...storedRequestsGetters,
  ...selectedStoredRequestGetters,
  ...vastsGetters,
  ...selectedVastGetters,
  ...injectionsGetters,
  ...selectedInjectionGetters,
  currencies: (state) => state.currencies,
  errors: (state) => {
    const r = state.errors
    state.errors = []
    return r
  }
}
export default getters
