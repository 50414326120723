import { GetNotes } from '@/store/action_types'

import { SetNotes } from '@/store/mutation_types'

import axios from 'axios'


export const notesState = {
  notes: []
}

export const notesGetters = {
  notes: (state) => state.notes
}

export const notesActions = {
  async [GetNotes]({ commit }, proposal_id) {
    let url = `/api/proposals/notes/${proposal_id}`
    const response = await axios.get(url)
    commit(SetNotes, response.data)
    return response
  },
}

export const notesMutations = {
  [SetNotes](state, notes) {
    state.notes = notes
  }
}

export default notesActions
