import { GetOrders } from '@/store/action_types'

import { SetOrders } from '@/store/mutation_types'

import axios from 'axios'

export const ordersState = {
  orders: []
}

export const ordersGetters = {
  orders: (state) => state.orders
}

export const ordersActions = {
  async [GetOrders]({ commit }, customerId) {
    let url = `/api/getdsporders/${customerId}`
    const response = await axios.get(url)
    commit(SetOrders, response.data)
    return response
  }
}

export const ordersMutations = {
  [SetOrders](state, orders) {
    state.orders = orders
  }
}

export default ordersActions
