module.exports = [
    {
        id: '1',
        label: '1 - Linear / In-Stream'
    },
    {
        id: '2',
        label: '2 - Non-Linear / Overlay'
    }
]
