import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@okta/okta-vue'

import login from '@/pages/login/index.vue'
import register from '@/pages/login/contactregister.vue'

import index from '@/pages/index.vue'

import account from '@/pages/account/index.vue'
import user_edit from '@/pages/account/edit.vue'

import customer_list from '@/pages/customer/index.vue'
import customer_edit from '@/pages/customer/edit.vue'

import home_page from '@/pages/index.vue'

import site_list from '@/pages/management/site/index.vue'
import site_new from '@/pages/management/site/edit.vue'
import site_edit from '@/pages/management/site/edit.vue'
//import new_sites_update from '@/pages/amp/newSites.vue'
import stored_request_list from '@/pages/management/stored-request/list/index.vue'


//import fast from '@/pages/fast/index.vue'

import stored_request_new from '@/pages/management/stored-request/edit/edit.vue'
import stored_request_edit from '@/pages/management/stored-request/edit/edit.vue'

import vast_list from '@/pages/management/vast/index.vue'
import vast_new from '@/pages/management/vast/edit.vue'
import vast_edit from '@/pages/management/vast/edit.vue'

import deal_list from '@/pages/management/deal/deal/index.vue'
import deal_management from '@/pages/management/deal/deal/edit.vue'
import deal_rules from '@/pages/management/deal/deal/dealRules.vue'

import proposal_list from '@/pages/management/deal/proposal/index.vue'
import proposal_management from '@/pages/management/deal/proposal/edit.vue'

import order_list from '@/pages/management/dsp/order/index.vue'
import order_management from '@/pages/management/dsp/order/edit.vue'

import lineitem_list from '@/pages/management/dsp/line-item/index.vue'
import lineitem_management from '@/pages/management/dsp/line-item/edit.vue'
import lineitem_rules from '@/pages/management/dsp/line-item/lineItemRules.vue'

import segment_management from '@/pages/management/deal/segment/index.vue'
import custom_targeting_management from '@/pages/management/deal/customtargeting/index.vue'



import pbs_reporting from '@/pages/pbs-reporting/index.vue'
import pbs_reporting_filters from '@/pages/pbs-reporting/index.vue'
import billing_reporting from '@/pages/facturation/reporting.vue'
import billing_reporting_filters from '@/pages/facturation/reporting.vue'
import consentless_reporting from '@/pages/consentless-reporting/index.vue'
import customer_reporting from '@/pages/customer-reporting/index.vue'
import customer_reporting_filters from '@/pages/customer-reporting/index.vue'

import GamSetup from '@/pages/gamSetup/index.vue'
import GamSetup_edit from '@/pages/gamSetup/edit.vue'

import home from '@/pages/home.vue'
import contact from '@/pages/support/contact.vue'

import cookieless from '@/pages/cookieless/index.vue'
import optionscookieless_new from '@/pages/cookieless/edit.vue'
import optionscookieless_edit from '@/pages/cookieless/edit.vue'
//import web from '@/pages/web/index.vue'

import dashboard from '@/pages/dashboard/index.vue'

import axios from 'axios'

import sample_config from '@/config'

import tag_management_sites from '@/pages/tag_management/sites/index.vue'
import tag_management_sites_edit from '@/pages/tag_management/sites/edit.vue'
import tag_management_pages from '@/pages/tag_management/pages/index.vue'
import tag_management_pages_edit from '@/pages/tag_management/pages/PageDetails.vue'
import tag_management_page_general from '@/pages/tag_management/pages/general/index.vue'
import tag_management_page_placements from '@/pages/tag_management/pages/placements/index.vue'
import tag_management_page_tag from '@/pages/tag_management/pages/tag/index.vue'

import tag_management_page_placements_DisplayList from '@/pages/tag_management/pages/placements/list/index.vue'
import tag_management_page_placements_PageTypeParameters from '@/pages/tag_management/pages/placements/parameters/index.vue'

import { GetProfile } from '@/store/action_types'
import store from '@/store'

sample_config.oidc = {
  ...sample_config.oidc,
  ...{
    isAuthenticated: async () => {
      const idToken = await Vue.prototype.$auth.getIdToken()
      const accessToken = await Vue.prototype.$auth.getAccessToken()
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      let isAuthentified = !!(idToken && accessToken)
      if (isAuthentified && !store.state.appUser.id) {
        let user = await Vue.prototype.$auth.getUser()
        await store.dispatch(GetProfile, user)
      }
      return isAuthentified
    }
  }
}

Vue.use(Router)
Vue.use(Auth, sample_config.oidc)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: login,
      name: 'login'
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/implicit/callback',
      component: Auth.handleCallback()
    },
    {
      path: '/',
      component: index,
      meta: { requiresAuth: true },
      children: [
        { path: 'myaccount', component: account, name: 'account' },
        {
          path: 'users/new',
          component: user_edit,
          name: 'user_new'
        },
        {
          path: 'customers/:customer_id/users/new',
          component: user_edit,
          name: 'newuser_bycustomer'
        },
        {
          path: 'customers/:customer_id/users/:userId',
          component: user_edit,
          name: 'edituser_bycustomer'
        },
        {
          path: 'users/:userId',
          component: user_edit,
          name: 'user_edit'
        },
        { path: 'customers', component: customer_list, name: 'customer_list' },
        {
          path: 'customers/new',
          component: customer_edit,
          name: 'customer_new'
        },
        {
          path: 'customers/:customer_id',
          component: customer_edit,
          name: 'customer_edit'
        },
        {
          path: '',
          alias: 'management',
          component: home_page,
          name: 'Home'
        },
        { path: '/:customer_id/proposal/:proposal_id/deals', component: deal_list, name: 'deal_list' },
        { path: '/:customer_id/proposal/:proposal_id/deals/new', component: deal_management, name: 'deal_new' },
        { path: '/:customer_id/proposal/:proposal_id/deals/:deal_id', component: deal_rules, name: 'deal_edit' },

        { path: '/:customer_id/proposals', component: proposal_list, name: 'proposal_list' },
        { path: '/:customer_id/proposals/new', component: proposal_management, name: 'proposal_new' },
        { path: '/:customer_id/proposals/:proposal_id', component: proposal_management, name: 'proposal_edit' },

        { path: '/:customer_id/dsp/orders', component: order_list, name: 'order_list' },
        { path: '/:customer_id/dsp/orders/new', component: order_management, name: 'order_new' },
        { path: '/:customer_id/dsp/orders/:order_id', component: order_management, name: 'order_edit' },

        { path: '/:customer_id/dsp/order/:order_id/lineitems', component: lineitem_list, name: 'lineitem_list' },
        { path: '/:customer_id/dsp/order/:order_id/lineitems/new', component: lineitem_management, name: 'lineitem_new' },
        { path: '/:customer_id/dsp/order/:order_id/lineitems/:lineitem_id', component: lineitem_rules, name: 'lineitem_edit' },
        {
          path: 'management/:customer_id/sites',
          component: site_list,
          name: 'site_list',
          props: true
        },
        { path: '/:customer_id/segments', component: segment_management, name: 'segment_management' },
        { path: '/:customer_id/customtargeting', component: custom_targeting_management, name: 'custom_targeting_management' },

        /*{
          path: 'management/:type/:customer_id/sites/:site_id',
          component: new_sites_update,
          name: 'new_sites_update',
          props: true
        },*/
        {
          path: 'management/:customer_id/sites/new',
          component: site_new,
          name: 'site_new',
          props: true
        },
        {
          path: 'management/:customer_id/sites/:site_id/update',
          component: site_edit,
          name: 'site_edit',
          props: true
        },
        {
          path: 'management/:customer_id/sites/:site_id/storedrequests',
          component: stored_request_list,
          name: 'stored_request_list',
          props: true
        },
        {
          path: 'management/:customer_id/sites/:site_id/storedrequests/new',
          component: stored_request_new,
          name: 'stored_request_new',
          props: true
        },
        {
          path: 'management/:customer_id/sites/:site_id/storedrequests/:stored_request_id',
          component: stored_request_edit,
          name: 'stored_request_edit',
          props: true
        },
        {
          path: 'management/:customer_id/vast',
          component: vast_list,
          name: 'vast_list',
          props: true
        },
        {
          path: 'management/:customer_id/vast/new',
          component: vast_new,
          name: 'vast_new',
          props: true
        },
        {
          path: 'management/:customer_id/vast/:vast_id',
          component: vast_edit,
          name: 'vast_edit',
          props: true
        },
        {
          path: 'nexx-reporting',
          component: pbs_reporting,
          name: 'pbs_reporting',
          props: true
        },
        {
          path: 'nexx-reporting/filters/:customer_id',
          component: pbs_reporting_filters,
          name: 'pbs_reporting_filters',
          props: true
        },
        {
          path: 'billing-reporting',
          component: billing_reporting,
          name: 'billing_reporting',
          props: true
        },
        {
          path: 'billing-reporting/filters/:customer_id',
          component: billing_reporting_filters,
          name: 'billing_reporting_filters',
          props: true
        },
        {
          path: 'consentless-reporting',
          component: consentless_reporting,
          name: 'consentless_reporting',
          props: true
        },
        {
          path: 'customer-reporting',
          component: customer_reporting,
          name: 'customer_reporting',
          props: true
        },
        {
          path: 'customer-reporting/filters/:customer_id',
          component: customer_reporting_filters,
          name: 'customer_reporting_filters',
          props: true
        },
        {
          path: 'gamSetup',
          component: GamSetup,
          name: 'GamSetup',
          props: true
        },
        {
          path: 'gamSetup/new',
          component: GamSetup_edit,
          name: 'gamsetup_new'
        },
        {
          path: 'gamSetup/:gamSetup_id',
          component: GamSetup_edit,
          name: 'gamsetup_edit'
        },
        {
          path: '/home',
          component: dashboard,
          name: 'home'
        },
        {
          path: '/contact',
          component: contact,
          name: 'contact'
        },
        {
          path: '/cookieless',
          component: cookieless,
          name: 'cookieless'
        },
        {
          path: ':site_id/optionscookieless/new',
          component: optionscookieless_new,
          name: 'optionscookieless_new'
        },
        {
          path: ':site_id/optionscookieless/:oc_id',
          component: optionscookieless_edit,
          name: 'optionscookieless_edit'
        },
        /*{
          path: '/web',
          component: web,
          name: 'web'
        },*/
        {
          path: '/dashboard',
          component: dashboard,
          name: 'dashboard'
        },
        /* {
          path: 'management/fast/:customer_id/:site_id',
          component: fast,
          name: 'fast'
        },*/
        {
          path: 'tag_management/sites',
          component: tag_management_sites,
          name: 'tag_management_sites'
        },
        {
          path: 'tag_management/sites/:site_id',
          component: tag_management_sites_edit,
          name: 'tag_management_sites_edit'
        },
        {
          path: 'tag_management/sites/:site_id/pages',
          component: tag_management_pages,
          name: 'tag_management_pages'
        },
        {
          path: 'tag_management/sites/:site_id/pages/:page_id',
          component: tag_management_pages_edit,
          children: [
            {
              path: '',
              component: tag_management_page_general,
              name: 'tag_management_pageDetails'
            },
            {
              path: 'general',
              component: tag_management_page_general,
              name: 'tag_management_page_general'
            },
            {
              path: 'tag',
              component: tag_management_page_tag,
              name: 'tag_management_page_tag'
            },
            {
              path: 'placements',
              component: tag_management_page_placements,
              children: [
                {
                  path: '',
                  component: tag_management_page_placements_DisplayList,
                  name: 'tag_management_page_placements'
                },
                {
                  path: 'list',
                  component: tag_management_page_placements_DisplayList,
                  name: 'tag_management_page_placements_DisplayList'
                },
                {
                  path: 'parameters',
                  component: tag_management_page_placements_PageTypeParameters,
                  name: 'tag_management_page_placements_PageTypeParameters'
                }
              ]
            }
          ]
        }
      ]
    },
    { path: '*', component: home_page }
  ]
})

const onAuthRequired = async (to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !(await Vue.prototype.$auth.isAuthenticated())
  ) {
    // Navigate to custom login page and login
    next({ name: 'login' })
  } else {
    if (from.name == 'deal_edit' && to.name == 'deal_new')
      next({
        name: 'deal_list', params: {
          proposal_id: from.params.proposal_id
        }
      })
    if (from.name == 'lineitem_edit' && to.name == 'lineitem_new')
      next({
        name: 'lineitem_list', params: {
          order_id: from.params.order_id
        }
      })
    else next()
  }
}

router.beforeEach(onAuthRequired)

export default router
