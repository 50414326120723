<template>
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :permanent="$vuetify.breakpoint.mdAndUp"
    app
  >
    <v-list dense>
      <template v-for="item in items">
        <v-list-group
          v-if="item.children"
          :key="item.text"
          :value="false"
          active-class="black--text"
        >
          <template v-slot:activator>
            <v-list-item-icon
              style="margin-right: 12px; padding-bottom: 5px; color: #717171"
              @click="Activate(item)"
            >
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size: 12px; color: black"
              @click="Activate(item)"
            >
              {{ item.text }}
            </v-list-item-title>
          </template>
          <template v-for="child in item.children">
            <v-list-item
              :key="child.text"
              :value="true"
              :class="{ background: child.isActive }"
              @click="Activate(child)"
            >
              <template>
                <v-list-item-icon
                  style="
                    margin-left: 20px;
                    margin-right: 7px;
                    padding-bottom: 5px;
                  "
                >
                  <v-icon size="17">{{ child.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title style="font-size: 12px">
                  {{ child.text }}
                </v-list-item-title>
              </template>
            </v-list-item>
          </template>
        </v-list-group>
        <template v-else>
          <v-list-item
            v-if="item.text == $t('documentation')"
            :key="item.text"
            :class="{ background: item.isActive }"
            @click="Activate(item)"
          >
            <v-list-item-icon style="margin-right: 12px; padding-bottom: 5px">
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 12px">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            :key="item.text"
            :class="{ background: item.isActive }"
            @click="Activate(item)"
          >
            <v-list-item-icon style="margin-right: 12px; padding-bottom: 5px">
              <v-icon size="25">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 12px">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { GetSites, GetSelectedCustomer } from '@/store/action_types'

  export default {
    props: {
      parentDrawer: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        showBar: true,
        customer: {
          name: '',
          code: '',
          country: this.$store.state.appCustomer.country
        },
        appUser: {},
        drawer: false,
        isActive: false,
        tmp_items_superadmin: [
          {
            icon: 'mdi-account-tie',
            text: this.$t('customers'),
            name: 'customer_list',
            allow_user: false,
            allow_admin: false,
            allow_superadmin: true,
            isActive: false
          },
          {
            icon: 'mdi-table-cog',
            text: this.$t('configuration'),
            allow_user: false,
            allow_admin: false,
            allow_superadmin: true,
            children: [
              {
                icon: 'mdi-folder-outline',
                text: this.$t('inventory management'),
                name: 'new_sites',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              },
              {
                icon: 'mdi-play-box',
                text: this.$t('vast'),
                name: 'vast_list',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              }
            ]
          },
          {
            icon: 'mdi-table-cog',
            text: this.$t('campaigns_management'),
            name: 'order_list',
            allow_user: false,
            allow_admin: false,
            allow_superadmin: true
          },
          {
            icon: 'mdi-file-document-edit-outline',
            text: this.$t('deals management'),
            allow_user: false,
            allow_admin: false,
            allow_superadmin: true,
            children: [
              {
                icon: 'mdi-button-pointer ',
                text: this.$t('proposals'),
                name: 'proposal_list',
                allow_user: false,
                allow_admin: false,
                allow_superadmin: true,
                isActive: false
              },
              {
                icon: 'mdi-form-select',
                text: this.$t('first party segments'),
                name: 'segment_management',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              }
              /* {
                  icon: 'mdi-form-select',
                  text: this.$t('custom targeting management'),
                  name: 'custom_targeting_management',
                  allow_user: true,
                  allow_admin: true,
                  allow_superadmin: true,
                  isActive: false
                }*/
            ]
          },
          {
            icon: 'mdi-help-circle-outline',
            text: this.$t('documentation'),
            allow_user: true,
            allow_admin: true,
            allow_superadmin: true,
            isActive: false
          }
        ],

        tmp_items: [
          {
            icon: 'mdi-table-cog',
            text: this.$t('configuration'),
            allow_user: true,
            allow_admin: true,
            allow_superadmin: false,
            children: [
              {
                icon: 'mdi-folder-outline',
                text: this.$t('inventory management'),
                name: 'new_sites',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              },
              {
                icon: 'mdi-play-box',
                text: this.$t('vast'),
                name: 'vast_list',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              }
            ]
          },
          {
            icon: 'mdi-table-cog',
            text: this.$t('campaigns_management'),
            name: 'order_list',
            allow_user: false,
            allow_admin: false,
            allow_superadmin: true
          },
          {
            icon: 'mdi-file-document-edit-outline',
            text: this.$t('deals management'),
            allow_user: true,
            allow_admin: true,
            allow_superadmin: true,
            children: [
              {
                icon: 'mdi-button-pointer ',
                text: this.$t('proposals'),
                name: 'proposal_list',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              },
              {
                icon: 'mdi-form-select',
                text: this.$t('first party segments'),
                name: 'segment_management',
                allow_user: true,
                allow_admin: true,
                allow_superadmin: true,
                isActive: false
              }
            ]
          },
          {
            icon: 'mdi-cogs',
            text: this.$t('account_settings'),
            name: 'account',
            allow_user: false,
            allow_admin: true,
            allow_superadmin: true,
            isActive: false
          },
          {
            icon: 'mdi-help-circle-outline',
            text: this.$t('documentation'),
            allow_user: true,
            allow_admin: true,
            allow_superadmin: false,
            isActive: false
          },
          {
            icon: 'mdi-email-outline',
            text: this.$t('contact'),
            name: 'contact',
            allow_user: true,
            allow_admin: true,
            allow_superadmin: false,
            isActive: false
          }
        ]
      }
    },
    computed: {
      ...mapGetters(['sites', 'appCustomer', 'selectedCustomer']),
      computedSites() {
        return this.sites
          .filter((el) => {
            return this.archived ? true : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      },
      items() {
        if (this.appUser != undefined) {
          var items = this.appUser.is_superadmin
            ? this.tmp_items_superadmin
            : this.tmp_items
          var arraytoreturn = []
          var cust =
            this.selectedCustomer.id == undefined
              ? this.appCustomer
              : this.selectedCustomer
          items.forEach((item) => {
            if (item.text == this.$t('campaigns_management')) {
              if (
                this.appCustomer.id == '1070' ||
                this.appCustomer.id == '1118'
              )
                arraytoreturn.push(item)
            } else if (item.text == this.$t('deals management')) {
              if (
                (cust.deal_is_active == true &&
                  (this.appCustomer.id == '1086' ||
                    this.appCustomer.id == '1094' ||
                    this.appUser.email == 'alfonso@revenuemaker.es')) ||
                this.appUser.is_superadmin
              )
                arraytoreturn.push(item)
            } else if (
              item.children == undefined ||
              item.text != 'Configuration' ||
              this.appUser.is_superadmin
            )
              arraytoreturn.push(item)
            else {
              var childs = []
              item.children.forEach((child) => {
                childs.push(child)
              })
              item.children = childs
              arraytoreturn.push(item)
              childs = []
            }
          })
          return arraytoreturn
        }
        return false
      }
    },
    watch: {
      parentDrawer() {
        this.drawer = this.parentDrawer
      },
      drawer() {
        if (this.drawer == false) this.$emit('closed')
      },
      appCustomer() {
        this.GetSites(this.appCustomer.id)
      },
      '$route.name': async function (val) {
        if (val == 'account' || val == 'customer_list') {
          this.items.forEach((element) => {
            if (element.children)
              element.children.forEach((son) => {
                son.isActive = false
              })
            else if (element.name == val) {
              element.isActive = true
            } else element.isActive = false
          })
        }
      }
    },
    async created() {
      if (this.$store.getters.appUser != undefined)
        this.appUser = Object.assign({}, this.$store.getters.appUser)
      this.drawer = this.parentDrawer
      if (this.$route.name == 'home') this.showBar = false
      this.showBar = true
    },
    methods: {
      ...mapActions({ GetSites, GetSelectedCustomer }),
      Activate(item) {
        if (item.name != undefined) {
          this.items.forEach((element) => {
            if (element.children)
              element.children.forEach((son) => {
                son.isActive = false
              })
            else element.isActive = false
          })
          item.isActive = true
          if (item.name == 'new_sites') {
            var customerid = this.$store.state.appCustomer.id
            if (this.$route.params.customer_id != undefined) {
              if (
                this.$store.getters.is_superadmin &&
                this.$store.state.appCustomer.id !=
                  this.$route.params.customer_id
              ) {
                customerid = this.$route.params.customer_id
              }
            }
            this.$router
              .push({
                name: 'site_list',
                params: {
                  customer_id: customerid
                }
              })
              .catch(() => {})
          } else if (item.name == 'vast_list') {
            var customerid = this.$store.state.appCustomer.id
            this.$router
              .push({
                name: 'vast_list',
                params: {
                  customer_id: customerid
                }
              })
              .catch(() => {})
          } else if (item.name == 'proposal_list') {
            item.isActive = true
            var customerid = this.$store.state.appCustomer.id
            if (this.$route.params.customer_id != undefined) {
              if (
                this.$store.getters.is_superadmin &&
                this.$store.state.appCustomer.id !=
                  this.$route.params.customer_id
              ) {
                customerid = this.$route.params.customer_id
              }
            }
            this.$router
              .push({
                name: 'proposal_list',
                params: {
                  customer_id: customerid
                }
              })
              .catch(() => {})
          } else if (
            item.name == 'segment_management' ||
            item.name == 'custom_targeting_management'
          ) {
            item.isActive = true
            var customerid = this.$store.state.appCustomer.id
            if (this.$route.params.customer_id != undefined) {
              if (
                this.$store.getters.is_superadmin &&
                this.$store.state.appCustomer.id !=
                  this.$route.params.customer_id
              ) {
                customerid = this.$route.params.customer_id
              }
            }
            this.$router
              .push({
                name: item.name,
                params: {
                  customer_id: customerid
                }
              })
              .catch(() => {})
          } else if (item.name == 'new_storedrequests')
            this.$router
              .push({
                name: 'stored_request_list',
                params: {
                  site_id: '0'
                }
              })
              .catch(() => {})
          else if (item.name == 'injections')
            this.$router
              .push({
                name: 'injections',
                params: {
                  site_id: '0'
                }
              })
              .catch(() => {})
          else this.$router.push({ name: item.name }).catch(() => {})
        } else if (item.text == this.$t('documentation')) {
          window.open('https://developer.nexx360.io/', '_blank')
        }
      }
    }
  }
</script>

<style scoped>
  .background {
    background: #ffc170;
  }
</style>
