import {
  GetSelectedNote,
  SaveSelectedNote,
  UpdateSelectedNote
} from '@/store/action_types'

import {
  SetSelectedNote,
  ClearSelectedNote
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedNoteState = {
  selectedNote: {}
}

export const selectedNoteGetters = {
  selectedNote: (state) => state.selectedNote
}

export const selectedNoteActions = {
  async [GetSelectedNote]({ commit }, noteId) {
    const response = await axios.get('/api/ttdnote/' + noteId)
    commit(SetSelectedNote, response.data)
    return response
  },
  async [SaveSelectedNote]({ dispatch }, note) {
    if (note.id) {
      return dispatch(UpdateSelectedNote, note)
    }
    const response = await axios.post('/api/ttdnote', note)
    return response.data
  },

  async [UpdateSelectedNote]({ commit }, note) {
    const response = await axios.put(`/api/ttdnote/${note.id}`, note)
    return response
  }
}

export const selectedNoteMutations = {
  [SetSelectedNote](state, note) {
    state.selectedNote = note
  },
  [ClearSelectedNote](state) {
    state.selectedNote = {}
  }
}
