import {
  GetSelectedVast,
  UpdateSelectedVast,
  SaveSelectedVast
} from '@/store/action_types'


import {
  SetSelectedVast,
  ClearSelectedVast
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedVastState = {
  selectedVast: {}
}

export const selectedVastGetters = {
  selectedVast: (state) => state.selectedVast,
}

export const selectedVastActions = {
  async [GetSelectedVast]({ commit }, vast_id) {
    if (vast_id === 'new') return
    const response = await axios.get(`/api/vast/${vast_id}`)
    commit(SetSelectedVast, response.data)
    return response
  },
  async [UpdateSelectedVast]({ commit }, vast) {
    const response = await axios.put(
      `/api/vast/${vast.id}`,
      vast
    )
    commit(SetSelectedVast, vast)
    return response
  },
  async [SaveSelectedVast]({ dispatch, commit }, vast) {
    if (vast.id != undefined) {
      return dispatch(UpdateSelectedVast, vast)
    }

    const response = await axios.post('/api/vast', vast)

    commit(SetSelectedVast, response.data)
    return response
  }
}

export const selectedVastMutations = {
  [SetSelectedVast](state, vast) {
    state.selectedVast = vast
  },
  [ClearSelectedVast](state) {
    state.selectedVast = {}
  }
}
