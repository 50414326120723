<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title> Creating a new line item </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>
                  {{ $t('line_item_name')
                  }}<strong style="color: red"> *</strong>
                </p>
                <v-text-field
                  ref="Name"
                  v-model="lineitem.name"
                  :rules="required"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="red"
            width="150"
            dense
            @click="back"
          >
            <v-icon left> mdi-cancel </v-icon> Cancel
          </v-btn>
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="150"
            dense
            @click="createLineItem"
          >
            <v-icon left> mdi-content-save-move </v-icon> Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="actionsucess" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ messageAction }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SetSelectedLineItem } from '@/store/mutation_types'
  import {
    GetSelectedLineItem,
    SaveSelectedLineItem,
    GetSelectedOrder
  } from '@/store/action_types'

  export default {
    name: 'LineItemNew',
    components: {},
    data() {
      return {
        lineitem: {
          name: '',
          order_id: ''
        },
        submitLoading: false,
        actionsucess: false,
        timeout: 3000,
        required: [(v) => !!v || 'Required'],
        messageAction: 'Line item created successfully',
        errors: []
      }
    },
    computed: {
      ...mapGetters(['selectedLineItem', 'selectedOrder'])
    },
    watch: {},
    async created() {
      await this.GetSelectedOrder(this.$route.params.order_id)
      this.proposal = this.selectedOrder
      this.lineitem.order_id = this.selectedOrder.id
    },
    methods: {
      ...mapMutations([SetSelectedLineItem]),
      ...mapActions([
        GetSelectedLineItem,
        SaveSelectedLineItem,
        GetSelectedOrder
      ]),
      async submit() {},
      back() {
        this.$router.push({
          name: 'lineitem_list',
          params: {
            order_id: this.$route.params.order_id,
            customer_id: this.$route.params.customer_id
          }
        })
      },
      async createLineItem() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        const lineItemResponse = await this.SaveSelectedLineItem(
          this.lineitem
        ).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })
        this.actionsucess = true
        this.SetSelectedLineItem(lineItemResponse)
        setTimeout(
          () =>
            this.$router.push({
              name: 'lineitem_edit',
              params: {
                lineitem_id: lineItemResponse.id
              }
            }),
          2000
        )
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
</style>
