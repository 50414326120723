import {
  GetSelectedLineItem,
  SaveSelectedLineItem,
  UpdateSelectedLineItem
} from '@/store/action_types'

import {
  SetSelectedLineItem,
  ClearSelectedLineItem
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedLineItemState = {
  selectedLineItem: {}
}

export const selectedLineItemGetters = {
  selectedLineItem: (state) => state.selectedLineItem
}

export const selectedLineItemActions = {
  async [GetSelectedLineItem]({ commit }, lineitemId) {
    const response = await axios.get('/api/dsplineitem/' + lineitemId)
    commit(SetSelectedLineItem, response.data)
    return response
  },
  async [SaveSelectedLineItem]({ dispatch }, lineitem) {
    if (lineitem.id) {
      return dispatch(UpdateSelectedLineItem, lineitem)
    }
    const response = await axios.post('/api/dsplineitem', lineitem)
    return response.data
  },

  async [UpdateSelectedLineItem]({ commit }, lineitem) {
    const response = await axios.put(`/api/dsplineitem/${lineitem.id}`, lineitem)
    return response
  }
}

export const selectedLineItemMutations = {
  [SetSelectedLineItem](state, lineitem) {
    state.selectedLineItem = lineitem
  },
  [ClearSelectedLineItem](state) {
    state.selectedLineItem = {}
  }
}
