<template>
  <v-container style="height: 100px">
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="site"
          class="combobox"
          :items="computedSites"
          item-text="name"
          item-value="id"
          label="Select a website"
          return-object
          solo
          dense
          @change="redirect(site)"
        >
          <template v-slot:selection="data">
            <span v-if="data.item.is_archived == false" style="color: black">
              {{ data.item.name }}
            </span>
            <span v-else style="opacity: 0.5">
              {{ data.item.name }}
            </span>
          </template>
          <template v-slot:item="data">
            <span v-if="data.item.is_archived == false" style="color: black">
              {{ data.item.name }}
            </span>
            <span v-else style="opacity: 0.5">
              {{ data.item.name }}
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" v-if="false">
        <v-menu ref="menuRef" rounded bottom min-width="200px" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              style="padding: 0px 0px 0px 0px; min-width: 36px; height: 38px"
              class="black--text notcapital"
              color="white"
              v-on="on"
            >
              <v-icon> mdi-cog </v-icon>
            </v-btn>
          </template>
          <v-card>
            <div class="mx-auto text-left">
              <v-list class="justify-left">
                <v-list-item
                  v-if="computedSites.length != 0"
                  @click.stop="
                    $refs.menuRef.isActive = false
                    $emit('showDialog', 'update')
                  "
                  >{{ $t('update_site') }}</v-list-item
                >
                <v-list-item
                  v-if="computedSites.length != 0"
                  @click="duplicateSite()"
                  >{{ $t('duplicate_website') }}
                </v-list-item>
                <v-list-item v-if="isSiteArchived()" @click="archiveSite()"
                  >{{ $t('archive_website') }}
                </v-list-item>
                <v-list-item v-if="isSiteUnarchived()" @click="unarchiveSite()"
                  >{{ $t('unarchive_website') }}
                </v-list-item>
                <v-list-item
                  v-if="archived == false"
                  @click="
                    $emit('refresh', 400)
                    archived = !archived
                  "
                  >{{ $t('show_archives') }}
                </v-list-item>
                <v-list-item
                  v-else
                  @click="
                    $emit('refresh', 400)
                    archived = !archived
                  "
                  >{{ $t('hide_archives') }}
                </v-list-item>
                <v-list-item
                  @click.stop="
                    $refs.menuRef.isActive = false
                    $emit('showDialog', 'add')
                  "
                  >{{ $t('add_site') }}</v-list-item
                >
              </v-list>
            </div>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import {
    GetStoredRequests,
    GetSites,
    SaveSelectedSite,
    SaveSelectedStoredRequest,
    UpdateSelectedSite
  } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import axios from 'axios'
  import {
    SetSelectedSite,
    ClearSelectedSite,
    SetStoredRequests,
    SetAppCustomer
  } from '@/store/mutation_types'

  export default {
    name: 'SiteList',
    data() {
      return {
        site: {},
        archived: false,
        stored_request_duplicated: {},
        site_duplicated: {},
        stored_request_siteIDduplicated: ''
      }
    },
    computed: {
      ...mapGetters(['sites', 'selectedSite', 'appCustomer', 'storedRequests']),
      computedSites() {
        return this.sites
          .filter((el) => {
            return this.archived ? true : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    watch: {
      archived() {
        if (this.computedSites.length > 0)
          this.SetSelectedSite(this.computedSites[0])
      },
      selectedSite() {
        if (this.selectedSite != undefined) {
          if (this.selectedSite.id != undefined) {
            this.site = this.selectedSite
            this.GetStoredRequests(this.site.id)
          }
        }
      },
      async appCustomer() {
        await this.GetSites(this.appCustomer.id)
        this.site = this.selectedSite
        if (
          this.appCustomer.id != this.$route.params.customer_id ||
          this.$store.state.selectedSite.id != this.$route.params.site_id
        ) {
          if (this.$store.state.selectedSite.id != undefined) {
            this.$router.push({
              name: 'Home',
              params: {
                customer_id: this.appCustomer.id,
                site_id: this.$store.state.selectedSite.id
              }
            })
          } else {
            this.$router.push({
              name: 'Home',
              params: {
                customer_id: this.appCustomer.id,
                site_id: '0'
              }
            })
          }
        }
      }
    },
    async created() {
      this.GetSites(this.appCustomer.id)
      /* if (this.selectedSite.is_archived == 1) {
        this.SetSelectedSite(this.computedSites[0])
        this.site = this.selectedSite
        this.GetStoredRequests(this.site.id)
      }
      if (this.selectedSite.id !== undefined) {
        this.site = this.selectedSite
        this.GetStoredRequests(this.site.id)
      }
      if (
        this.appCustomer.id != this.$route.params.customer_id ||
        this.$store.state.selectedSite.id != this.$route.params.site_id
      ) {
        this.$router.push({
          name: 'Home',
          params: {
            customer_id: this.$route.params.customer_id,
            site_id: this.$route.params.site_id
          }
        })
      }*/
      /* if (
        this.$route.params.site_id != undefined ||
        this.$route.params.site_id != '0'
      ) {
        const urlSiteId = this.$route.params.site_id
        if (urlSiteId != '0' && urlSiteId != undefined) {
          try {
            const siteURL = await axios.get(`/api/getsitebyid/${urlSiteId}`)
            this.SetSelectedSite(siteURL.data)
          } catch (error) {
            if (this.computedSites.length == 0) {
              this.$router.push({
                name: 'Home',
                params: {
                  customer_id: this.appCustomer.id,
                  site_id: '0'
                }
              })
            } else {
              this.SetSelectedSite(this.computedSites[0])
              this.$router.push({
                name: 'Home',
                params: {
                  customer_id: this.appCustomer.id,
                  site_id: this.$store.state.selectedSite.id
                }
              })
            }
          }
        }
        this.site = this.selectedSite
      }
      if (this.site.id != undefined) this.GetStoredRequests(this.site.id)*/
    },
    methods: {
      ...mapActions({
        GetSites,
        GetStoredRequests,
        SaveSelectedSite,
        SaveSelectedStoredRequest,
        UpdateSelectedSite
      }),
      ...mapMutations([
        SetSelectedSite,
        ClearSelectedSite,
        SetStoredRequests,
        SetAppCustomer
      ]),
      redirect(site) {
        this.SetSelectedSite(site)
        this.$router.push({
          name: this.$route.name,
          params: {
            customer_id: this.$route.params.customer_id,
            site_id: site.id
          }
        })
      },
      isSiteArchived() {
        if (this.computedSites.length == 0) return false
        if (this.selectedSite != undefined) {
          if (this.selectedSite.is_archived == 0) return true
          else return false
        }
        return true
      },
      isSiteUnarchived() {
        if (this.computedSites.length == 0) return false
        if (this.selectedSite != undefined) {
          if (this.selectedSite.is_archived == 1) return true
          else return false
        }
        return true
      },
      async archiveSite() {
        this.$emit('refresh', 400)
        this.selectedSite.is_archived = 1
        await this.UpdateSelectedSite(this.selectedSite)
        if (this.computedSites.length > 0) {
          this.SetSelectedSite(this.computedSites[0])
        } else this.SetSelectedSite({})
      },
      unarchiveSite() {
        this.$emit('refresh', 400)
        this.selectedSite.is_archived = 0
        this.UpdateSelectedSite(this.selectedSite)
        this.SaveSelectedSite(this.selectedSite)
      },
      async duplicateSite() {
        this.$emit('refresh', 900)
        const site_to_duplicate = Object.assign({}, this.selectedSite)
        const site_duplicated = Object.assign({}, this.selectedSite)
        site_duplicated.id = undefined
        site_duplicated.name = this.selectedSite.name + ' _1'
        await this.SaveSelectedSite(site_duplicated)
        await this.GetStoredRequests(site_to_duplicate.id)

        this.storedRequests.forEach((element) => {
          const stored_request_duplicated = Object.assign({}, element)
          stored_request_duplicated.id = ''
          stored_request_duplicated.name = element.name + ' _1'
          stored_request_duplicated.site_id = this.selectedSite.id

          this.SaveSelectedStoredRequest(stored_request_duplicated)
        })
        this.SetSelectedSite(this.sites[this.sites.length - 1])
        this.GetStoredRequests(this.sites[this.sites.length - 1].id)
      }
    }
  }
</script>
<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
</style>
