module.exports = [
    { name: "aa", code: "Afar" },
    { name: "ab", code: "Abkhazian" },
    { name: "af", code: "Afrikaans" },
    { name: "am", code: "Amharic" },
    { name: "ar", code: "Arabic" },
    { name: "as", code: "Assamese" },
    { name: "ay", code: "Aymara" },
    { name: "az", code: "Azeri" },
    { name: "ba", code: "Bashkir" },
    { name: "be", code: "Belarusian" },
    { name: "bg", code: "Bulgarian" },
    { name: "bh", code: "Bihari" },
    { name: "bi", code: "Bislama" },
    { name: "bn", code: "Bengali" },
    { name: "bo", code: "Tibetan" },
    { name: "br", code: "Breton" },
    { name: "ca", code: "Catalan" },
    { name: "co", code: "Corsican" },
    { name: "cs", code: "Czech" },
    { name: "cy", code: "Welsh" },
    { name: "da", code: "Danish" },
    { name: "de", code: "German" },
    { name: "div", code: "Divehi" },
    { name: "dz", code: "Bhutani" },
    { name: "el", code: "Greek" },
    { name: "en", code: "English" },
    { name: "eo", code: "Esperanto" },
    { name: "es", code: "Spanish" },
    { name: "et", code: "Estonian" },
    { name: "eu", code: "Basque" },
    { name: "fa", code: "Farsi" },
    { name: "fi", code: "Finnish" },
    { name: "fj", code: "Fiji" },
    { name: "fo", code: "Faeroese" },
    { name: "fr", code: "French" },
    { name: "fy", code: "Frisian" },
    { name: "ga", code: "Irish" },
    { name: "gd", code: "Gaelic" },
    { name: "gl", code: "Galician" },
    { name: "gn", code: "Guarani" },
    { name: "gu", code: "Gujarati" },
    { name: "ha", code: "Hausa" },
    { name: "he", code: "Hebrew" },
    { name: "hi", code: "Hindi" },
    { name: "hr", code: "Croatian" },
    { name: "hu", code: "Hungarian" },
    { name: "hy", code: "Armenian" },
    { name: "ia", code: "Interlingua" },
    { name: "id", code: "Indonesian" },
    { name: "ie", code: "Interlingue" },
    { name: "ik", code: "Inupiak" },
    { name: "in", code: "Indonesian" },
    { name: "is", code: "Icelandic" },
    { name: "it", code: "Italian" },
    { name: "iw", code: "Hebrew" },
    { name: "ja", code: "Japanese" },
    { name: "ji", code: "Yiddish" },
    { name: "jw", code: "Javanese" },
    { name: "ka", code: "Georgian" },
    { name: "kk", code: "Kazakh" },
    { name: "kl", code: "Greenlandic" },
    { name: "km", code: "Cambodian" },
    { name: "kn", code: "Kannada" },
    { name: "ko", code: "Korean" },
    { name: "kok", code: "Konkani" },
    { name: "ks", code: "Kashmiri" },
    { name: "ku", code: "Kurdish" },
    { name: "ky", code: "Kirghiz" },
    { name: "kz", code: "Kyrgyz" },
    { name: "la", code: "Latin" },
    { name: "ln", code: "Lingala" },
    { name: "lo", code: "Laothian" },
    { name: "ls", code: "Slovenian" },
    { name: "lt", code: "Lithuanian" },
    { name: "lv", code: "Latvian" },
    { name: "mg", code: "Malagasy" },
    { name: "mi", code: "Maori" },
    { name: "mk", code: "FYRO Macedonian" },
    { name: "ml", code: "Malayalam" },
    { name: "mn", code: "Mongolian" },
    { name: "mo", code: "Moldavian" },
    { name: "mr", code: "Marathi" },
    { name: "ms", code: "Malay" },
    { name: "mt", code: "Maltese" },
    { name: "my", code: "Burmese" },
    { name: "na", code: "Nauru" },
    { name: "nb", code: "Norwegian (Bokmal)" },
    { name: "ne", code: "Nepali" },
    { name: "nl", code: "Dutch" },
    { name: "nl", code: "Dutch" },
    { name: "nn", code: "Norwegian" },
    { name: "no", code: "Norwegian (Bokmal)" },
    { name: "oc", code: "Occitan" },
    { name: "om", code: "Oromo" },
    { name: "or", code: "Oriya" },
    { name: "pa", code: "Punjabi" },
    { name: "pl", code: "Polish" },
    { name: "ps", code: "Pashto/Pushto" },
    { name: "pt", code: "Portuguese" },
    { name: "qu", code: "Quechua" },
    { name: "rm", code: "Rhaeto-Romanic" },
    { name: "rn", code: "Kirundi" },
    { name: "ro", code: "Romanian" },
    { name: "ru", code: "Russian" },
    { name: "rw", code: "Kinyarwanda" },
    { name: "sa", code: "Sanskrit" },
    { name: "sb", code: "Sorbian" },
    { name: "sd", code: "Sindhi" },
    { name: "sg", code: "Sangro" },
    { name: "sh", code: "Serbo-Croatian" },
    { name: "si", code: "Singhalese" },
    { name: "sk", code: "Slovak" },
    { name: "sl", code: "Slovenian" },
    { name: "sm", code: "Samoan" },
    { name: "sn", code: "Shona" },
    { name: "so", code: "Somali" },
    { name: "sq", code: "Albanian" },
    { name: "sr", code: "Serbian" },
    { name: "ss", code: "Siswati" },
    { name: "st", code: "Sesotho" },
    { name: "su", code: "Sundanese" },
    { name: "sv", code: "Swedish" },
    { name: "sw", code: "Swahili" },
    { name: "sx", code: "Sutu" },
    { name: "syr", code: "Syriac" },
    { name: "ta", code: "Tamil" },
    { name: "te", code: "Telugu" },
    { name: "tg", code: "Tajik" },
    { name: "th", code: "Thai" },
    { name: "ti", code: "Tigrinya" },
    { name: "tk", code: "Turkmen" },
    { name: "tl", code: "Tagalog" },
    { name: "tn", code: "Tswana" },
    { name: "to", code: "Tonga" },
    { name: "tr", code: "Turkish" },
    { name: "ts", code: "Tsonga" },
    { name: "tt", code: "Tatar" },
    { name: "tw", code: "Twi" },
    { name: "uk", code: "Ukrainian" },
    { name: "ur", code: "Urdu" },
    { name: "us", code: "English" },
    { name: "uz", code: "Uzbek" },
    { name: "vi", code: "Vietnamese" },
    { name: "vo", code: "Volapuk" },
    { name: "wo", code: "Wolof" },
    { name: "xh", code: "Xhosa" },
    { name: "yi", code: "Yiddish" },
    { name: "yo", code: "Yoruba" },
    { name: "zh", code: "Chinese" },
    { name: "zu", code: "Zul" }
]
