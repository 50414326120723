import { GetSites, GenerateTag, GenerateTagWeb } from '@/store/action_types'

import { SetSites, PushSites, ReformSites } from '@/store/mutation_types'

import axios from 'axios'

export const sitesState = {
  sites: []
}

export const sitesGetters = {
  sites: (state) => state.sites
}

export const sitesActions = {
  async [GetSites]({ commit }, customerId) {
    let url = `/api/customers/sites/${customerId}`
    const response = await axios.get(url)
    commit(SetSites, response.data)
    return response
  },
  async [GenerateTag]({ commit }, site) {
    const response = await axios.get('/api/tag/' + site)
    return response.data
  },
  async [GenerateTagWeb]({ commit }, ssps, customer_code) {
    let url = `/api/tagweb/${ssps}/${customer_code}`
    const response = await axios.get(url)

    return response.data
  }
}

export const sitesMutations = {
  [SetSites](state, sites) {
    state.sites = sites
  },
  [PushSites](state, site) {
    state.sites.push(site)
  },
  [ReformSites](state, site) {
    state.sites.forEach((element) => {
      if (element.id == site.id) element = { ...site }
    })
  }
}
