<template>
  <v-container
    fluid
    style="padding-left: 0px; padding-right: 0px; padding-top: 0px"
  >
    <v-container style="height: 78vh; padding-left: 20px">
      <v-row>
        <v-col cols="10" style="padding-top: 30px; padding-left: 0px">
          <v-form
            id="nexx360__sr__form"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row style="margin-left: 10px">
              <v-col>
                <v-row>
                  <v-col style="padding-bottom: 0px">
                    <span class="title_emplacement">
                      <strong>{{ addEditPlacement }} {{ site.name }} </strong>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="padding-top: 0px">
                    <span class="text">
                      {{ $t('fill_in_your_placement_details') }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span class="sous_titres">
                      {{ $t('placement_details') }}</span
                    >
                  </v-col>
                </v-row>
                <v-row no-gutters dense style="margin-top: 20px">
                  <v-col v-if="setDisable()" cols="4">
                    <p style="margin-bottom: 5px; font-size: 15px">ID</p>
                    <v-text-field
                      v-if="setDisable()"
                      v-model="storedRequest.id"
                      dense
                      solo
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="setDisable()" cols="1"> </v-col>
                  <v-col cols="6">
                    <p style="margin-bottom: 5px; font-size: 15px">Name</p>
                    <v-text-field
                      v-model="storedRequest.name"
                      dense
                      solo
                      :rules="nameRules"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters dense style="padding-top: 20px">
                  <v-col cols="3">
                    <p style="margin-bottom: 5px">Bid reduction</p>
                    <v-text-field
                      v-model="storedRequest.cpm_adjustment"
                      :rules="floatRule"
                      dense
                      solo
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters style="margin-top: 0px">
                  <table style="width: 100%; margin-top: 0px">
                    <tr>
                      <td style="width: 40%">
                        <v-switch
                          v-model="storedRequest.pricefloor_is_active"
                          label="Pricefloor"
                        ></v-switch>
                      </td>
                      <td>
                        <p style="margin-bottom: 5px; font-size: 15px">CPM</p>
                        <tr>
                          <td>
                            <v-text-field
                              v-model="storedRequest.pricefloor_cpm"
                              :rules="
                                storedRequest.pricefloor_is_active
                                  ? required
                                  : []
                              "
                              :disabled="!storedRequest.pricefloor_is_active"
                              style="width: 220px"
                              type="number"
                              min="0"
                              dense
                              solo
                            ></v-text-field>
                          </td>
                          <td>
                            <v-select
                              v-model="storedRequest.pricefloor_cur"
                              :items="currencies"
                              style="width: 130px"
                              solo
                              dense
                              outlined
                            >
                            </v-select>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-form
            id="nexx360__sr__form"
            ref="formssp"
            v-model="valid"
            lazy-validation
            style="margin-bottom: 200px"
          >
            <v-row style="margin-left: 10px">
              <v-col>
                <br />
                <v-row no-gutters>
                  <v-col cols="12" style="margin-bottom: 20px">
                    <span class="sous_titres">
                      {{ $t('choose_ssps_you_activate_immediately') }} </span
                    ><br />
                    <span
                      class="sous_titres"
                      style="font-size: 14px; font-weight: bold; color: red"
                    >
                      (*) : Don’t forget to configure Seller ID at the website
                      level for bidder aliases
                    </span>
                  </v-col>
                  <ssp
                    v-for="(ids, ssp) in formattedSspList"
                    :key="ssp"
                    :ids="ids"
                    :ssp="ssp"
                    @update-ssp="updateSsp($event)"
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          cols="2"
          style="float: right; padding-top: 0px; padding-right: 0px"
        >
          <div style="position: fixed; width: 100%">
            <v-navigation-drawer
              permanent
              style="width: 100%; height: 78vh; border-left: 0.1px solid black"
            >
              <v-list-item
                style="padding-left: 10px; background-color: #efefef"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    SSP List
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 13px">
                    Search SSP
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-text-field
                v-model="search"
                class="search"
                style="
                  padding: 7px;
                  padding-top: 0px;
                  background-color: #efefef;
                "
                dense
                append-icon="mdi-magnify"
                label="Search"
                single-line
                solo
                hide-details
              ></v-text-field>
              <v-divider></v-divider>
              <v-list dense nav style="background-color: white">
                <v-list-item
                  v-for="item in computedActivatedBidders"
                  :key="item"
                  link
                  style="cursor: pointer"
                  @click="scrollMeTo(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      style="
        height: 22vh;
        background-color: white;
        padding: 0px;
        border-top: 0.2px solid black;
        position: fixed;
      "
    >
      <div class="div_size">
        <v-row>
          <v-col cols="7" style="padding: 20px">
            <span v-if="errors.length > 0">
              <p
                v-for="error in errors"
                :key="error"
                style="
                  color: red;
                  font-size: 12px;
                  padding-top: 0px;
                  margin: 0px;
                "
              >
                {{ error }}
              </p>
            </span>
          </v-col>
          <v-col cols="1" />
          <v-col cols="4">
            <v-card-actions class="d-flex">
              <v-btn
                :loading="submitLoading"
                :disabled="submitLoading"
                style="margin: 10px; margin-left: auto; margin-right: 0"
                class="white--text notcapital"
                color="black"
                width="120"
                dense
                @click="submit"
              >
                <v-icon left> mdi-content-save-move </v-icon>
                {{ $t('save') }}
              </v-btn>
              <v-btn
                style="margin: 10px"
                class="white--text notcapital"
                color="red"
                width="120"
                dense
                @click="back"
              >
                <v-icon left> mdi-cancel </v-icon>
                {{ $t('cancel') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-row>
      <v-col>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="800"
          @click:outside="$emit('submitted')"
        >
          <template>
            <v-card>
              <v-container no-gutters dense>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <h2 style="text-indent: 2px">
                      {{ $t('placement_with_the_same_name_created') }}
                    </h2>
                    <br />
                    <p style="text-indent: 15px">
                      {{ $t('confirmation_placement_creation') }}
                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters dense>
                  <v-col cols="12">
                    <v-card-actions align="center" class="justify-center">
                      <v-col cols="3">
                        <v-btn
                          width="200"
                          style="height: 40px"
                          class="white--text"
                          color="black"
                          @click="confirmCreation"
                        >
                          {{ $t('yes') }}
                        </v-btn>
                      </v-col>
                      <v-col cols="1" />
                      <v-col cols="3">
                        <v-btn
                          width="200"
                          style="height: 40px"
                          class="white--text"
                          color="black"
                          @click="dialog = false"
                        >
                          {{ $t('no') }}
                        </v-btn>
                      </v-col>
                      <br />
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import axios from 'axios'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import {
    GetSelectedSite,
    GetStoredRequests,
    GetSelectedCustomer
  } from '@/store/action_types'
  import Ssp from '@/pages/management/stored-request/edit/ssp.vue'
  //import Size from '@/pages/management/stored-request/size.vue'
  import {
    GetSelectedStoredRequest,
    SaveSelectedStoredRequest
  } from '@/store/action_types'
  import {
    ClearSelectedStoredRequest,
    SetSelectedSite,
    SetSelectedCustomer
  } from '@/store/mutation_types'

  export default {
    components: {
      Ssp
      // Size
    },
    data() {
      return {
        site: {
          name: ''
        },
        search: '',
        addEditPlacement: this.$t('add placement to'),
        basicName: '',
        dialog: false,
        storedRequest: {},
        currencies: ['EUR', 'USD', 'GBP', 'JPY'],

        nameRules: [
          (v) =>
            !!v || `${this.$t('name')} ${this.$t('is')} ${this.$t('required')}`
        ],
        floatRule: [
          (v) => !!v || 'Required',
          (v) => /^\d+((\.\d+)|(\,\d+))?$/.test(v) || 'Decimal only',
          (v) => (v && Number(v) <= 1) || 'Max value : 1',
          (v) => (v && Number(v) > 0) || 'Value must be above 0'
        ],
        submitLoading: false,
        errors: [],
        id: '',
        valid: true,
        required: [(v) => !!v || 'Required'],
        activatedBidders: []
      }
    },
    computed: {
      ...mapGetters([
        'appCustomer',
        'selectedCustomer',
        'SspList',
        'SampleStoredRequest',
        'SelectedStoredRequest',
        'selectedSite',
        'storedRequests'
      ]),
      computedActivatedBidders() {
        return this.activatedBidders
          .filter((el) => {
            if (!this.search) return this.activatedBidders
            return el.toLowerCase().includes(this.search.toLowerCase())
          })
          .sort((a, b) => this.$t(a).localeCompare(this.$t(b)))
      },
      formattedSspList() {
        const output = {}
        this.SspList.forEach((ssp) => {
          const foundssp = this.activatedBidders.find(
            (element) => element == ssp
          )
          if (foundssp != undefined) {
            output[ssp] = {}
            Object.keys(this.storedRequest).forEach((key) => {
              if (
                key.substr(0, this.getPosition(key, '_', 1)) == ssp &&
                key != 'ix_endpoint'
              ) {
                output[ssp][key] = this.storedRequest[key]
              }
            })
          }
        })
        return output
      }
    },
    watch: {
      appCustomer() {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          this.$router.push({
            name: 'site_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
      }
    },
    async created() {
      await this.GetSelectedCustomer(this.appCustomer.id)
      if (this.selectedSite.id == undefined)
        await this.GetSelectedSite(this.$route.params.site_id)

      this.site.name = this.selectedSite.name
      if (this.$route.name != 'stored_request_new')
        this.addEditPlacement = this.$t('edit placement')
      if (
        this.SelectedStoredRequest.id == '' &&
        this.$route.params.stored_request_id != undefined
      )
        await this.GetSelectedStoredRequest(
          this.$route.params.stored_request_id
        )

      this.storedRequest = this.SelectedStoredRequest
      if (this.storedRequest.id == '') this.storedRequest.sizes.push([0, 0])
      await this.GetStoredRequests(this.selectedSite.id)
      this.basicName = this.storedRequest.name
      var response = await axios.get(
        `/api/getactivatedbidders/${this.$route.params.customer_id}`
      )
      if (this.$route.name == 'stored_request_new')
        this.storedRequest.cpm_adjustment = 1

      this.activatedBidders = response.data
    },
    methods: {
      ...mapActions([
        GetSelectedStoredRequest,
        SaveSelectedStoredRequest,
        GetSelectedSite,
        GetStoredRequests,
        GetSelectedCustomer
      ]),
      ...mapMutations([
        ClearSelectedStoredRequest,
        SetSelectedSite,
        SetSelectedCustomer
      ]),
      validate() {
        /*  this.errors = []
          if (this.storedRequest.sizes.length == 0)
            this.errors.push('Please add sizes')
          else if (
            this.storedRequest.sizes[0][0] == 0 &&
            this.storedRequest.sizes[0][1] == 0 &&
          ) {
            this.errors.push(
              this.$t('sizes') + ' ' + this.$t('are') + ' ' + this.$t('required')
            )
          }*/
        if (
          this.storedRequest.name == '' ||
          this.storedRequest.name == undefined
        ) {
          this.errors.push(
            this.$t('name') + ' ' + this.$t('is') + ' ' + this.$t('required')
          )
        }
        if (this.errors.length > 0) {
          return false
        }
        return true
      },
      async submit() {
        this.errors = []
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          if (this.storedRequest.name == '') {
            this.errors.push('Please provide a name to your placement.')
          }
          if (
            this.storedRequest.cpm_adjustment == '' ||
            this.storedRequest.cpm_adjustment == 0
          ) {
            this.errors.push(
              'Please provide a decimal number to the bid reduction field.'
            )
          }
        }
        if (!this.$refs.formssp.validate()) {
          this.errors.push(
            "Please provide the Ids of the SSPs that you've activated."
          )
        }
        if (this.errors.length > 0) {
          this.submitLoading = false
          return
        }
        if (this.basicName != this.storedRequest.name) {
          this.storedRequests.forEach((sr) => {
            if (sr.name == this.storedRequest.name.trim()) {
              this.dialog = true
              this.submitLoading = false
              return
            }
          })
        }

        if (this.dialog == false) {
          this.storedRequest.site_id = this.$route.params.site_id
          this.storedRequest.name = this.storedRequest.name.trim()
          this.storedRequest.cpm_adjustment = this.storedRequest.cpm_adjustment
            .toString()
            .replace(',', '.')
          if (this.storedRequest.pricefloor_is_active == false) {
            this.storedRequest.pricefloor_cpm = ''
          }
          this.CleanSSPs()
          this.storedRequest.name = this.storedRequest.name.replace("'", ' ')
          let output = await this.SaveSelectedStoredRequest(
            this.storedRequest
          ).catch(() => {
            this.errors = this.getErrors
            this.submitLoading = false
            return false
          })

          if (!output) {
            return
          }
          this.submitLoading = false
          this.ClearSelectedStoredRequest()
          await this.GetStoredRequests(this.$route.params.site_id)
          this.$router.push(
            '/management/' +
              this.appCustomer.id +
              '/sites/' +
              this.selectedSite.id +
              '/storedrequests'
          )
        }
      },
      async confirmCreation() {
        this.storedRequest.name = this.storedRequest.name.replace("'", ' ')
        this.storedRequest.site_id = this.$route.params.site_id
        this.storedRequest.name = this.storedRequest.name.trim()
        this.CleanSSPs()
        let output = await this.SaveSelectedStoredRequest(
          this.storedRequest
        ).catch(() => {
          this.errors = this.getErrors
          this.submitLoading = false
          return false
        })

        if (!output) {
          return
        }
        this.submitLoading = false
        this.ClearSelectedStoredRequest()
        this.$router.push(
          '/management/' +
            this.appCustomer.id +
            '/sites/' +
            this.selectedSite.id +
            '/storedrequests'
        )
      },
      updateSsp(elem) {
        this.storedRequest[elem['key']] = elem['value']
      },
      updateSize(item) {
        this.storedRequest.sizes[item.index][item.dimension] = item.value
      },
      deleteSize(index) {
        this.storedRequest.sizes.splice(index, 1)
      },
      setDisable() {
        if (this.storedRequest.id !== '') {
          this.id = 'ID'
          return true
        }
        return false
      },
      back() {
        this.$router.back()
      },
      scrollMeTo(ssp) {
        var element = document.getElementById(ssp)
        var top = element.offsetTop
        window.scrollTo(0, top)
      },
      getPosition(text, subString, index) {
        return text.split(subString, index).join(subString).length
      },
      CleanSSPs() {
        if (this.storedRequest.appnexus_is_active == false)
          this.storedRequest.appnexus_placement_id = ''
        if (this.storedRequest.appnexusbis_is_active == false)
          this.storedRequest.appnexusbis_placement_id = ''
        if (this.storedRequest.audienzz_is_active == false)
          this.storedRequest.audienzz_tag_id = ''
        if (this.storedRequest.gravity_is_active == false)
          this.storedRequest.gravity_placement_id = ''
        if (this.storedRequest.groupm_is_active == false)
          this.storedRequest.groupm_placement_id = ''
        if (this.storedRequest.gumgum_is_active == false) {
          this.storedRequest.gumgum_zone = ''
          this.storedRequest.gumgum_pub_id = ''
          this.storedRequest.gumgum_slot = ''
          this.storedRequest.gumgum_product = ''
        }
        if (this.storedRequest.improve_is_active == false) {
          this.storedRequest.improve_publisher_id = ''
          this.storedRequest.improve_placement_id = ''
        }
        if (this.storedRequest.inmobi_is_active == false) {
          this.storedRequest.inmobi_plc = ''
        }
        if (this.storedRequest.quantumimprove_is_active == false) {
          this.storedRequest.quantumimprove_placement_id = ''
        }

        if (this.storedRequest.ix_is_active == false) {
          this.storedRequest.ix_site_id = ''
          // this.storedRequest.ix_endpoint = ''
        }
        if (this.storedRequest.mediasquare_is_active == false) {
          this.storedRequest.mediasquare_zone = ''
          this.storedRequest.mediasquare_code = ''
        }
        if (this.storedRequest.medianet_is_active == false) {
          this.storedRequest.medianet_cid = ''
          this.storedRequest.medianet_crid = ''
        }
        if (this.storedRequest.mediagrid_is_active == false)
          this.storedRequest.mediagrid_uuid = ''
        if (this.storedRequest.freewheel_is_active == false)
          this.storedRequest.freewheel_zone_id = ''
        if (this.storedRequest.moneytag_is_active == false)
          this.storedRequest.moneytag_placement_id = ''
        if (this.storedRequest.netpoint_is_active == false)
          this.storedRequest.netpoint_tag_id = ''
        if (this.storedRequest.nextmillennium_is_active == false) {
          this.storedRequest.nextmillennium_placement_id = ''
          this.storedRequest.nextmillennium_group_id = ''
        }
        if (this.storedRequest.definemedia_is_active == false) {
          this.storedRequest.definemedia_mandant_id = ''
          this.storedRequest.definemedia_adslot_id = ''
        }
        if (this.storedRequest.eplanning_is_active == false) {
          this.storedRequest.eplanning_cid = ''
          this.storedRequest.eplanning_endpoint_code = ''
        }
        if (this.storedRequest.evolution_is_active == false) {
          this.storedRequest.evolution_key = ''
        }
        if (this.storedRequest.invibes_is_active == false)
          this.storedRequest.invibes_placement_id = ''
        if (this.storedRequest.openx_is_active == false) {
          this.storedRequest.openx_del_domain = ''
          this.storedRequest.openx_unit = ''
        }
        if (this.storedRequest.pubmatic_is_active == false) {
          this.storedRequest.pubmatic_placement_id = ''
          this.storedRequest.pubmatic_publisher_id = ''
          this.storedRequest.pubmatic_ad_slot = ''
        }
        if (this.storedRequest.pubmaticbis_is_active == false) {
          this.storedRequest.pubmaticbis_placement_id = ''
          this.storedRequest.pubmaticbis_publisher_id = ''
          this.storedRequest.pubmaticbis_ad_slot = ''
        }
        if (this.storedRequest.pulsepoint_is_active == false) {
          this.storedRequest.pulsepoint_cp = ''
          this.storedRequest.pulsepoint_ct = ''
        }
        if (this.storedRequest.rubicon_is_active == false) {
          this.storedRequest.rubicon_account_id = ''
          this.storedRequest.rubicon_site_id = ''
          this.storedRequest.rubicon_zone_id = ''
        }
        if (this.storedRequest.rubiconbis_is_active == false) {
          this.storedRequest.rubiconbis_account_id = ''
          this.storedRequest.rubiconbis_site_id = ''
          this.storedRequest.rubiconbis_zone_id = ''
        }
        if (this.storedRequest.rtbhouse_is_active == false) {
          this.storedRequest.rtbhouse_endpoint = ''
        }
        if (this.storedRequest.smartadserver_is_active == false) {
          this.storedRequest.smartadserver_network_id = ''
          this.storedRequest.smartadserver_site_id = ''
          this.storedRequest.smartadserver_page_id = ''
          this.storedRequest.smartadserver_format_id = ''
        }
        if (this.storedRequest.smartadserverbis_is_active == false) {
          this.storedRequest.smartadserverbis_network_id = ''
          this.storedRequest.smartadserverbis_site_id = ''
          this.storedRequest.smartadserverbis_page_id = ''
          this.storedRequest.smartadserverbis_format_id = ''
        }
        if (this.storedRequest.smartclip_is_active == false) {
          this.storedRequest.smartclip_sz = ''
          this.storedRequest.smartclip_s = ''
        }
        /* if (this.storedRequest.smartyads_is_active == false) {
          this.storedRequest.smartyads_host = ''
          this.storedRequest.smartyads_account_id = ''
        }*/
        if (this.storedRequest.vidoomy_is_active == false) {
          this.storedRequest.vidoomy_zone_id = ''
        }
        if (this.storedRequest.smilewanted_is_active == false) {
          this.storedRequest.smilewanted_zone_id = ''
          this.storedRequest.smilewanted_position_type = ''
        }
        if (this.storedRequest.sovrn_is_active == false) {
          this.storedRequest.sovrn_tag_id = ''
        }
        if (this.storedRequest.outbrain_is_active == false) {
          this.storedRequest.outbrain_publisher_id = ''
          this.storedRequest.outbrain_tag_id = ''
        }
        if (this.storedRequest.orbidder_is_active == false) {
          this.storedRequest.orbidder_account_id = ''
          this.storedRequest.orbidder_placement_id = ''
        }
        if (this.storedRequest.plista_is_active == false) {
          this.storedRequest.plista_placement_id = ''
        }
        if (this.storedRequest.prisma_is_active == false) {
          this.storedRequest.prisma_tag_id = ''
        }
        if (this.storedRequest.staila_is_active == false) {
          this.storedRequest.staila_placement_id = ''
        }
        if (this.storedRequest.triplelift_is_active == false)
          this.storedRequest.triplelift_inventory_code = ''
        if (this.storedRequest.triton_is_active == false)
          this.storedRequest.triton_stid = ''
        if (this.storedRequest.unruly_is_active == false)
          this.storedRequest.unruly_site_id = ''
        if (this.storedRequest.ttd_is_active == false)
          this.storedRequest.ttd_endpoint = ''
        if (this.storedRequest.verizon_is_active == false) {
          this.storedRequest.verizon_dcn = ''
          this.storedRequest.verizon_pos = ''
        }
        if (this.storedRequest.tappx_is_active == false) {
          this.storedRequest.tappx_key = ''
          this.storedRequest.tappx_host = ''
        }
        if (this.storedRequest.targetspot_is_active == false) {
          this.storedRequest.targetspot_publisher_id = ''
        }
        if (this.storedRequest.visx_is_active == false) {
          this.storedRequest.visx_uid = ''
        }
        if (this.storedRequest.pricefloor_is_active == false) {
          this.storedRequest.pricefloor_cpm = ''
        }
      }
    }
  }
</script>

<style scoped>
  @media (min-width: 1600px) {
    .div_size {
      width: 93%;
    }
    .search {
      width: 13.7%;
    }
  }
  @media (max-width: 1500px) {
    .div_size {
      width: 100%;
    }
    .search {
      width: 13%;
    }
  }
  .title_emplacement {
    font-size: 20px;
  }
  .sous_titres {
    font-size: 17px;
  }

  .sitename {
    font-size: 21px;
  }

  .text {
    font-size: 14px;
    padding-top: 10px;
    display: inline-block;
  }

  .combobox {
    padding-left: 50px;
    width: 370px;
  }
  .notcapital {
    text-transform: none !important;
  }
  .parameters {
    background-color: black;
  }
  .positionCb {
    margin-top: 10px;
    margin-left: 50px;
  }
  .no-shadow,
  .no-shadow::before {
    margin-left: 5px;
    box-shadow: none;
    background-color: transparent !important;
  }
</style>
