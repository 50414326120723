<template>
  <v-container fluid style="padding-left: 0px; padding-right: 0px">
    <v-card style="margin-left: 10px">
      <v-container style="height: 78vh; overflow: scroll; padding-left: 20px">
        <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
          <v-progress-circular indeterminate :value="60" color="blue-grey">
          </v-progress-circular>
        </v-overlay>
        <v-card-title
          primary-title
          style="background-color: #f9f9fb; padding-bottom: 0px"
          ><v-container>
            <v-row>
              <v-col>
                <h3 class="cnx">
                  <strong> {{ vastTitle }}</strong>
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref="form" lazy-validation>
          <v-card-text style="margin-top: 20px; padding-left: 40px">
            <v-row no-gutters dense>
              <v-col v-if="setDisable()" cols="4">
                <p style="margin-bottom: 5px; font-size: 15px">VF_ID</p>
                <v-text-field
                  v-if="setDisable()"
                  v-model="vast.id"
                  dense
                  solo
                  disabled
                ></v-text-field>
              </v-col>
              <v-col v-if="setDisable()" cols="1"> </v-col>
              <v-col cols="6">
                <p style="margin-bottom: 5px">VAST config name</p>
                <v-text-field
                  v-model="vast.name"
                  :rules="required"
                  dense
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-list-item-title>Callback option</v-list-item-title>
                <v-radio-group
                  v-model="option_callback"
                  :rules="required"
                  style="margin-top: 5px"
                >
                  <v-radio label="No callback" value="none"></v-radio>
                  <v-radio
                    label="VAST Callback"
                    value="vast_callback"
                  ></v-radio>
                  <v-radio
                    label="Google Tag for GAM competition"
                    value="google_tag"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="6"
                class="nospace"
                style="margin-left: 10px"
                v-if="option_callback == 'vast_callback'"
              >
                <p align="left">VAST callback</p>
                <v-textarea
                  v-model="vast.no_vast_callback"
                  :rules="httpRules"
                  class="nospace"
                  name="input-7-4"
                  auto-grow
                  outlined
                  shaped
                ></v-textarea> </v-col
              ><v-col
                cols="6"
                class="nospace"
                style="margin-left: 10px"
                v-if="option_callback == 'google_tag'"
              >
                <p align="left">Google Tag for GAM competition</p>
                <v-textarea
                  v-model="vast.waterfall_google_tag"
                  :rules="httpRules"
                  class="nospace"
                  name="input-7-4"
                  auto-grow
                  outlined
                  shaped
                ></v-textarea> </v-col
            ></v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="4">
                <p style="margin-bottom: 5px">Mimes type *</p>
                <v-select
                  v-model="selectedMimes"
                  :items="mimesList"
                  :rules="requiredArray"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row class="nospace">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="min_duration_is_active"
                  label="Define min duration"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="min_duration_is_active">
                <p style="margin-bottom: 5px">Min duration (seconds)</p>
                <v-text-field
                  v-model="vast.minduration"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nospace" style="margin-top: 0px">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="max_duration_is_active"
                  label="Define max duration"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="max_duration_is_active">
                <p style="margin-bottom: 5px">Max duration (seconds)</p>
                <v-text-field
                  v-model="vast.maxduration"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="8">
                <p style="margin-bottom: 5px">Protocols *</p>
                <v-select
                  v-model="selectedProtocols"
                  :items="protocolsList"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="startdelay_is_active"
                  label="Define start delay"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="startdelay_is_active">
                <p style="margin-bottom: 5px">Start delay</p>
                <v-text-field
                  v-model="vast.startdelay"
                  placeholder="0"
                  type="number"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
              <v-col v-if="startdelay_is_active"
                ><p
                  style="
                    margin-bottom: 5px;
                    margin-left: 20px;
                    color: gray;
                    font-size: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: relative;
                  "
                >
                  > 0 : Mid-Roll / 0 : Pre-Roll/ -1 : Generic Mid-Roll/ -2 :
                  Generic Post-Roll
                </p></v-col
              >
            </v-row>
            <v-row class="nospace">
              <v-col class="nospace" cols="3">
                <p style="margin-bottom: 5px">Placement</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="nospace" cols="3">
                <v-select
                  v-model="vast.placement"
                  :items="placementList"
                  item-text="label"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col class="nospace" cols="3">
                <p style="margin-bottom: 5px">Plcmt</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="nospace" cols="3">
                <v-select
                  v-model="vast.plcmt"
                  :items="plcmtList"
                  item-text="label"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col class="nospace" cols="3">
                <p style="margin-bottom: 5px">Linearity</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="nospace" cols="3">
                <v-select
                  v-model="vast.linearity"
                  :items="linearityList"
                  item-text="label"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <!--<v-row class="nospace">
          <v-col class="nospace" cols="3">
            <p style="margin-bottom: 5px">Sequence</p>
          </v-col>
        </v-row>
        <v-row
          ><v-col class="nospace" cols="3">
            <v-text-field
              v-model="vast.sequence"
              placeholder="0"
              type="number"
              min="0"
              dense
              solo
              outlined
              text
            >
            </v-text-field>
          </v-col>
        </v-row>-->
            <v-row class="nospace">
              <v-col cols="4">
                <v-switch
                  v-model="allowskip_is_active"
                  label="Allow video to be skipped"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="allowskip_is_active">
                <p>Skip minimum (seconds)</p>
                <v-text-field
                  v-model="vast.skipmin"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
              <v-col class="nospace" cols="2" v-if="allowskip_is_active">
                <p>Skip after (seconds)</p>
                <v-text-field
                  v-model="vast.skipafter"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="maxextended_is_active"
                  label="Define Maximum extended ad duration"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="4" v-if="maxextended_is_active">
                <p style="margin-bottom: 5px">
                  Maximum extended ad duration (seconds)
                </p>
                <v-text-field
                  v-model="vast.maxextended"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="minbitrate_is_active"
                  label="Define Minimum bit rate"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="minbitrate_is_active">
                <p style="margin-bottom: 5px">Minimum bit rate (Kbps)</p>

                <v-text-field
                  v-model="vast.minbitrate"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nospace">
              <v-col cols="4" style="padding-top: 0px; padding-bottom: 0px">
                <v-switch
                  v-model="maxbitrate_is_active"
                  label="Define Maximum bit rate"
                ></v-switch>
              </v-col>
              <v-col class="nospace" cols="2" v-if="maxbitrate_is_active">
                <p style="margin-bottom: 5px">Maximum bit rate (Kbps)</p>
                <v-text-field
                  v-model="vast.maxbitrate"
                  placeholder="0"
                  type="number"
                  min="0"
                  dense
                  solo
                  outlined
                  text
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row style="width: 100%" class="nospace">
              <v-col cols="4">
                <v-switch
                  v-model="boxingallowed"
                  label="Boxing allowed"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="8">
                <p style="margin-bottom: 5px">Creative attributes (battr)</p>
                <v-select
                  v-model="selectedBattr"
                  :items="battrList"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="8">
                <p style="margin-bottom: 5px">Playback methods</p>
                <v-select
                  v-model="selectedPlaybackmethod"
                  :items="playbackmethodList"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="8">
                <p style="margin-bottom: 5px">
                  Playback Cessation Modes (playbackend)
                </p>
                <v-select
                  v-model="vast.playbackend"
                  :items="playbackendList"
                  item-text="label"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="4">
                <p style="margin-bottom: 5px">Delivery</p>
                <v-select
                  v-model="selectedDelivery"
                  :items="deliveryList"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="8">
                <p style="margin-bottom: 5px">Ad position (pos)</p>
                <v-select
                  v-model="vast.pos"
                  :items="adpositionList"
                  item-text="label"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row no-gutters dense class="nospace">
              <v-col cols="4">
                <p style="margin-bottom: 5px">Api frameworks</p>
                <v-select
                  v-model="selectedApi"
                  :items="apiList"
                  item-text="label"
                  item-value="id"
                  multiple
                  solo
                  dense
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-snackbar
            v-model="actionsuccess"
            color="#4CAF4F"
            :timeout="timeout"
          >
            <v-icon size="25" style="padding: 5px"
              >mdi-check-circle-outline</v-icon
            >
            {{ messageAction }}
          </v-snackbar>
          <v-snackbar v-model="actionfail" color="red" :timeout="timeout">
            <v-icon size="25" style="padding: 5px">mdi-alert-circle</v-icon>
            {{ messageAction }}
          </v-snackbar>
        </v-form>
      </v-container>
      <v-container
        style="
          height: 22vh;
          background-color: white;
          padding: 0px;
          border-top: 0.2px solid black;
          position: fixed;
        "
      >
        <div class="div_size">
          <v-row>
            <v-col cols="8" style="padding: 20px">
              <span v-if="errors.length > 0">
                <p
                  v-for="error in errors"
                  :key="error"
                  style="
                    color: red;
                    font-size: 12px;
                    padding-top: 0px;
                    margin: 0px;
                  "
                >
                  {{ error }}
                </p>
              </span>
            </v-col>
            <v-col cols="4">
              <v-card-actions class="d-flex">
                <v-btn
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  style="margin: 10px"
                  class="white--text notcapital"
                  color="black"
                  width="180"
                  dense
                  @click="submit"
                >
                  <v-icon left> mdi-content-save-move </v-icon>
                  {{ $t('save') }}
                </v-btn>
                <v-btn
                  style="margin-left: 20px"
                  class="white--text notcapital"
                  color="red"
                  width="180"
                  dense
                  @click="back"
                >
                  <v-icon left> mdi-cancel </v-icon>
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  const mimes = require('@/assets/styles/vast-mimes.js')
  const protocols = require('@/assets/styles/vast-protocols.js')
  const placement = require('@/assets/styles/vast-placement.js')
  const linearity = require('@/assets/styles/vast-linearity.js')
  const battr = require('@/assets/styles/vast-battr.js')
  const playbackmethod = require('@/assets/styles/vast-playbackmethod.js')
  const playbackend = require('@/assets/styles/vast-playbackend.js')
  const delivery = require('@/assets/styles/vast-delivery.js')
  const adposition = require('@/assets/styles/vast-adposition.js')
  const api = require('@/assets/styles/vast-api.js')
  const plcmt = require('@/assets/styles/vast-plcmt.js')

  import axios from 'axios'
  import { SaveSelectedVast } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { SetSelectedVast } from '@/store/mutation_types'
  export default {
    data() {
      return {
        loading: false,
        vastTitle: 'Creating a new VAST config',
        submitLoading: false,
        vast: {},
        actionsuccess: false,
        actionfail: false,
        timeout: 2000,
        messageAction: '',
        required: [(v) => !!v || 'Required'],
        requiredArray: [(v) => v.length > 0 || 'Required'],
        httpRules: [
          (v) => !!v || 'URL is required',
          (v) =>
            /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
              v
            ) || 'Please enter a valid URL.'
        ],
        mimesList: mimes,
        protocolsList: protocols,
        battrList: battr,
        playbackmethodList: playbackmethod,
        playbackendList: playbackend,
        deliveryList: delivery,
        adpositionList: adposition,
        apiList: api,
        plcmtList: plcmt,
        linearityList: linearity,
        placementList: placement,
        selectedMimes: [],
        selectedProtocols: [],
        selectedBattr: [],
        selectedPlaybackmethod: [],
        selectedDelivery: [],
        selectedApi: [],
        option_callback: 'none',
        min_duration_is_active: false,
        max_duration_is_active: false,
        startdelay_is_active: false,
        maxextended_is_active: false,
        minbitrate_is_active: false,
        maxbitrate_is_active: false,
        allowskip_is_active: false,
        boxingallowed: false,
        errors: []
      }
    },
    computed: {
      ...mapGetters(['selectedVast', 'appCustomer'])
    },
    watch: {
      allowskip_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') {
            this.vast.skipmin = null
            this.vast.skipafter = null
          } else {
            delete this.vast.skipmin
            delete this.vast.skipafter
          }
        }
      },
      min_duration_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.minduration = null
          else delete this.vast.minduration
        }
      },
      max_duration_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.maxduration = null
          else delete this.vast.maxduration
        }
      },
      startdelay_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.startdelay = null
          else delete this.vast.startdelay
        }
      },
      maxextended_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.maxextended = null
          else delete this.vast.maxextended
        }
      },
      minbitrate_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.minbitrate = null
          else delete this.vast.minbitrate
        }
      },
      maxbitrate_is_active: async function (val, oldVal) {
        if (val == false) {
          if (this.$route.name == 'vast_edit') this.vast.maxbitrate = null
          else delete this.vast.maxbitrate
        }
      },
      boxingallowed: async function (val, oldVal) {
        if (val == false) {
          this.vast.boxingallowed = 0
        } else this.vast.boxingallowed = 1
      }
    },
    async created() {
      if (this.$route.name == 'vast_edit') {
        try {
          const vastURL = await axios.get(
            `/api/getvastbyid/${this.$route.params.vast_id}`
          )
          this.SetSelectedVast(vastURL.data)
          this.vast = this.selectedVast
          this.vastTitle = this.vast.name
          this.convertDataFromApi()
        } catch (error) {
          console.log(error)
        }
      }
    },
    methods: {
      ...mapActions({
        SaveSelectedVast
      }),
      ...mapMutations([SetSelectedVast]),
      convertDataFromApi() {
        //Callback option
        if (
          this.vast.no_vast_callback != '' &&
          this.vast.no_vast_callback != null
        )
          this.option_callback = 'vast_callback'
        else if (
          this.vast.waterfall_google_tag != '' &&
          this.vast.waterfall_google_tag != null
        )
          this.option_callback = 'google_tag'
        else this.option_callback = 'none'

        //Mimes
        if (this.vast.mimes != '') {
          var str = this.vast.mimes.substring(1, this.vast.mimes.length - 1)
          var arr = str.split(',')
          arr.forEach((element) => {
            this.selectedMimes.push(element.substring(1, element.length - 1))
          })
        }
        //Protocols
        if (this.vast.protocols != '' && this.vast.protocols != null) {
          var str = this.vast.protocols.substring(
            1,
            this.vast.protocols.length - 1
          )
          this.selectedProtocols = str.split(',')
        }
        //PlayBackMethod
        if (
          this.vast.playbackmethod != '' &&
          this.vast.playbackmethod != null
        ) {
          var str = this.vast.playbackmethod.substring(
            1,
            this.vast.playbackmethod.length - 1
          )
          this.selectedPlaybackmethod = str.split(',')
        }
        //Battr
        if (this.vast.battr != '' && this.vast.battr != null) {
          var str = this.vast.battr.substring(1, this.vast.battr.length - 1)
          this.selectedBattr = str.split(',')
        }
        //Delivery
        if (this.vast.delivery != '' && this.vast.delivery != null) {
          var str = this.vast.delivery.substring(
            1,
            this.vast.delivery.length - 1
          )
          this.selectedDelivery = str.split(',')
        }
        //Api
        if (this.vast.api != '' && this.vast.api != null) {
          var str = this.vast.api.substring(1, this.vast.api.length - 1)
          this.selectedApi = str.split(',')
        }
        //Linearity
        if (this.vast.linearity != null) {
          this.vast.linearity = this.vast.linearity.toString()
        }
        //Plcmt
        if (this.vast.plcmt != null) {
          this.vast.plcmt = this.vast.plcmt.toString()
        }
        //Placement
        if (this.vast.placement != null) {
          this.vast.placement = this.vast.placement.toString()
        }
        //Playback End
        if (this.vast.playbackend != null) {
          this.vast.playbackend = this.vast.playbackend.toString()
        }
        //Ad position
        if (this.vast.pos != null) {
          this.vast.pos = this.vast.pos.toString()
        }
        //Skip
        if (this.vast.skipmin != null || this.vast.skipafter != null) {
          this.allowskip_is_active = true
        } else this.allowskip_is_active = false
        //Min duration
        if (this.vast.minduration != null) {
          this.min_duration_is_active = true
        } else this.min_duration_is_active = false
        //Max duration
        if (this.vast.maxduration != null) {
          this.max_duration_is_active = true
        } else this.max_duration_is_active = false
        //Start delay
        if (this.vast.startdelay != null) {
          this.startdelay_is_active = true
        } else this.startdelay_is_active = false
        //Max Extended
        if (this.vast.maxextended != null) {
          this.maxextended_is_active = true
        } else this.maxextended_is_active = false
        //Min bitrate
        if (this.vast.minbitrate != null) {
          this.minbitrate_is_active = true
        } else this.minbitrate_is_active = false
        //Max bitrate
        if (this.vast.maxbitrate != null) {
          this.maxbitrate_is_active = true
        } else this.maxbitrate_is_active = false
        //Boxing allowed
        if (this.vast.boxingallowed == 1) this.boxingallowed = true
        else this.boxingallowed = false
      },
      async submit() {
        this.errors = []
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          if (this.vast.name == '') {
            this.errors.push('Please provide a name to your VAST config.')
          }
          if (
            this.option_callback == 'vast_callback' &&
            (this.vast.no_vast_callback == '' ||
              this.vast.no_vast_callback == null ||
              /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                this.vast.no_vast_callback
              ))
          ) {
            this.errors.push('Please enter a valid URL for your VAST callback.')
          }
          if (
            this.option_callback == 'google_tag' &&
            (this.vast.waterfall_google_tag == '' ||
              this.vast.waterfall_google_tag == null ||
              /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                this.vast.waterfall_google_tag
              ))
          ) {
            this.errors.push(
              'Please enter a valid URL for your Google Tag for GAM competition.'
            )
          }
          console.log('No fullfilled')
          this.submitLoading = false
          return
        }
        this.vast.skip = null
        this.organizeVastItems()
        try {
          this.vast.name = this.vast.name.replace("'", ' ')
          const nameExists = await axios.get(
            `/api/vastnameexists/${this.vast.name}/${this.vast.customer_id}`
          )
          if (nameExists.data == false || this.$route.name == 'vast_edit') {
            await this.SaveSelectedVast(this.vast)
            this.actionsuccess = true
            this.messageAction = 'Vast config updated successfully'
            setTimeout(
              () =>
                this.$router.push(
                  '/management/' + this.$route.params.customer_id + '/vast'
                ),
              1000
            )
            setTimeout(() => (this.submitLoading = false), 1500)
          } else if (
            nameExists.data == true &&
            this.$route.name != 'vast_edit'
          ) {
            this.actionfail = true
            this.messageAction = 'Vast config name already exists.'
            this.submitLoading = false
          }
        } catch (error) {
          console.log(error)
          this.submitLoading = false
        }
      },
      setDisable() {
        if (this.vast.id == undefined) {
          this.id = 'ID'
          return false
        }
        return true
      },
      back() {
        this.$router.back()
      },
      organizeVastItems() {
        this.vast.customer_id = this.$route.params.customer_id
        //Callback option
        if (this.option_callback == 'none') {
          if (this.$route.name == 'vast_edit') {
            this.vast.no_vast_callback = null
            this.vast.waterfall_google_tag = null
          } else {
            this.vast.no_vast_callback = ''
            this.vast.waterfall_google_tag = ''
          }
        } else if (this.option_callback == 'vast_callback') {
          if (this.$route.name == 'vast_edit')
            this.vast.waterfall_google_tag = null
          else this.vast.waterfall_google_tag = ''
        } else {
          if (this.$route.name == 'vast_edit') this.vast.no_vast_callback = null
          else this.vast.no_vast_callback = ''
        }
        //Mimes
        let str = '['
        this.selectedMimes.forEach((item) => {
          str += '"' + item + '"' + ','
        })
        str = str.slice(0, -1)
        str += ']'
        this.vast.mimes = str
        //Protocols
        if (this.selectedProtocols.length > 0) {
          str = '['
          this.selectedProtocols.forEach((item) => {
            str += item + ','
          })
          str = str.slice(0, -1)
          str += ']'
          this.vast.protocols = str
        } else {
          if (this.$route.name == 'vast_edit') this.vast.protocols = null
        }
        //Battr
        if (this.selectedBattr.length > 0) {
          str = '['
          this.selectedBattr.forEach((item) => {
            str += item + ','
          })
          str = str.slice(0, -1)
          str += ']'
          this.vast.battr = str
        } else {
          if (this.$route.name == 'vast_edit') this.vast.battr = null
        }
        //PlayBackMethod
        if (this.selectedPlaybackmethod.length > 0) {
          str = '['
          this.selectedPlaybackmethod.forEach((item) => {
            str += item + ','
          })
          str = str.slice(0, -1)
          str += ']'
          this.vast.playbackmethod = str
        } else {
          if (this.$route.name == 'vast_edit') this.vast.playbackmethod = null
        }
        //Delivery
        if (this.selectedDelivery.length > 0) {
          str = '['
          this.selectedDelivery.forEach((item) => {
            str += item + ','
          })
          str = str.slice(0, -1)
          str += ']'
          this.vast.delivery = str
        } else {
          if (this.$route.name == 'vast_edit') this.vast.delivery = null
        }
        //Api
        if (this.selectedApi.length > 0) {
          str = '['
          this.selectedApi.forEach((item) => {
            str += item + ','
          })
          str = str.slice(0, -1)
          str += ']'
          this.vast.api = str
        } else {
          if (this.$route.name == 'vast_edit') this.vast.api = null
        }
      }
    }
  }
</script>

<style>
  @media (min-width: 1600px) {
    .div_size {
      width: 100%;
    }
  }
  @media (max-width: 1599px) {
    .div_size {
      width: 92%;
    }
  }
  .pageForm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .nospace {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    padding-left: 0px;
    letter-spacing: 2px;
    font-size: 25px;
  }
  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
</style>
