<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="850"
    @click:outside="hideDialog(), back()"
  >
    <template>
      <v-card style="height: 100%">
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="hideDialog(), back()">mdi-close</v-icon>
        </v-col>
        <v-card-title primary-title style="padding-top: 10px">
          <v-row>
            <v-col cols="12">
              <h3 style="color: black">Add multiple placements</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text style="margin-top: 10px">
          <v-container>
            In order to be efficient and fast in the placement management, you
            can create multiple placements here by indicating only their
            names.<br /><br />
            Step 1 : Enter your different placement's names. <br />Step 2 : Set
            manually the placement configuration, one by one or use the
            <strong
              ><a @click="hideDialog, $emit('showBulk')">Bulk upload</a></strong
            >
            feature. to configure your different placements with a single file
            dedicated.
          </v-container>
          <div
            style="width: 99%; height: 100%; border-color: black; border: solid"
            align="center"
            class="justify-center"
          >
            <v-row style="padding-top: 10px">
              <v-col cols="6" style="margin-left: 10px">
                <v-form ref="form" v-model="valid">
                  <p
                    align="left"
                    style="
                      margin-bottom: 3px;
                      padding-left: 3px;
                      font-size: 15px;
                    "
                  >
                    Names
                  </p>
                  <v-textarea
                    v-model="namesSR"
                    name="input-7-4"
                    outlined
                    shaped
                    style="padding-bottom: 10px"
                    :rules="rowrules"
                  ></v-textarea>
                </v-form>
                {{ lineCount }} / 100
              </v-col>
              <v-col cols="5" style="margin-top: 10%; margin-left: 10px">
                <p
                  align="left"
                  style="margin-bottom: 3px; padding-left: 3px; font-size: 14px"
                >
                  Enter one placement name per line, up to 100
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="left" justify="left">
                <span v-if="added">
                  <v-alert dense text type="success" color="green">
                    Placements added successfully</v-alert
                  >
                </span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-row style="padding: 20px">
            <v-col cols="6" />
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="border-color: black; margin-right: 10px"
              class="white--text notcapital"
              color="black"
              width="180"
              dense
              @click="submit()"
            >
              <v-icon left> mdi-content-save-move </v-icon> Save
            </v-btn>
            <v-btn
              style="border-color: black; margin-left: 10px"
              class="white--text notcapital"
              color="red"
              width="180"
              dense
              @click="$emit('hideDialog'), (uploadDialog = true)"
            >
              <v-icon left> mdi-cancel </v-icon> Cancel
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import JsonCSV from 'vue-json-csv'
  Vue.component('downloadCsv', JsonCSV)

  export default {
    components: {},
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      sradded: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        dialog: false,
        site: {
          name: ''
        },
        TitleDialog: this.$t('update_site'),
        nameRules: [(v) => !!v || this.$t('name is required')],
        submitLoading: false,
        errors: [],
        btnText: this.$t('update'),
        datatoExport: [],
        uploading: false,
        uploadDialog: false,
        namesSR: '',
        rowrules: [(v) => this.lineCount < 100 || 'Maximum rows exceeded'],
        valid: false,
        added: false
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'appCustomer']),
      lineCount: function () {
        // Return number of lines using regex if not empty
        return this.namesSR.length ? this.namesSR.split(/\r\n|\r|\n/).length : 0
      }
    },
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog
      },
      sradded() {
        this.added = this.sradded
      }
    },
    async created() {},
    methods: {
      back() {
        this.$router.push(this.$route.path)
      },
      submit() {
        if (this.valid) {
          this.submitLoading = true
          this.$emit('submitted', this.namesSR)
          setTimeout(() => (this.submitLoading = false), 1500)
          setTimeout(() => this.$emit('hideDialog'), 3000)
          setTimeout(() => (this.namesSR = ''), 3000)
          setTimeout(() => this.back(), 3000)
        }
      },
      hideDialog() {
        this.namesSR = ''
        this.$emit('hideDialog')
      }
    }
  }
</script>
