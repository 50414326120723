<template>
  <v-container style="padding: 0px">
    <v-card>
      <v-card-text>
        <v-form ref="form">
          <v-row style="margin-top: 20px">
            <v-col>
              <h2 style="margin-left: 0px; margin-top: 0px">Blocklist</h2>
            </v-col>
          </v-row>
          <v-row style="margin-top: 20px; margin-bottom: 20px">
            <v-col cols="3">
              <v-switch v-model="iab_is_active" label="IAB Category"></v-switch>
            </v-col>
            <v-col cols="5" v-if="iab_is_active">
              <v-autocomplete
                v-model="selectedIAB"
                :items="iab_list"
                clearable
                item-text="label"
                item-value="value"
                label="Choose categories"
                multiple
                dense
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggleIAB">
                    <v-list-item-action>
                      <v-icon
                        :color="selectedIAB.length > 0 ? 'indigo darken-4' : ''"
                      >
                        {{ iconIAB }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row style="margin-top: 20px; margin-bottom: 20px">
            <v-col cols="3">
              <v-switch
                v-model="ad_domain_is_active"
                label="Advertiser domain"
              ></v-switch>
            </v-col>
            <v-col cols="7" v-if="ad_domain_is_active">
              <v-textarea
                v-model="selectedDomainsBlocklist"
                ref="domain"
                name="input-7-4"
                placeholder="Select your domain's URLs"
                rows="10"
                outlined
                shaped
                :rules="domainRules"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row style="margin-top: 20px; margin-bottom: 20px">
            <v-col cols="3">
              <v-switch
                v-model="geotargeting_is_active"
                label="GeoTargeting"
              ></v-switch>
            </v-col>
            <v-col cols="5" v-if="geotargeting_is_active">
              <v-autocomplete
                v-model="selectedCountriesBlocklist"
                :items="countriesShown"
                item-text="name"
                item-value="code"
                label="Choose countries"
                clearable
                multiple
                dense
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleCountries"
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          selectedCountriesBlocklist.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                      >
                        {{ iconCountries }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="2" />
            <tr style="padding-left: 10px" v-if="geotargeting_is_active">
              <td style="padding-right: 0px">
                <p class="include_targeting" align="right" justify-end>
                  Include
                </p>
              </td>
              <td style="padding-left: 5px">
                <v-switch
                  v-model="exclude_geotargeting"
                  label="Exclude"
                  style="padding: 0px; margin-left: 5px"
                ></v-switch>
              </td>
            </tr>
          </v-row>
          <v-divider inset></v-divider>
          <v-row style="margin-top: 20px; margin-bottom: 20px">
            <v-col cols="3">
              <v-switch
                v-model="languagetargeting_is_active"
                label="Authorized language"
              ></v-switch>
            </v-col>
            <v-col cols="5" v-if="languagetargeting_is_active">
              <v-autocomplete
                v-model="selectedLanguagesBlocklist"
                :items="computedLanguages"
                item-text="code"
                item-value="name"
                label="Choose languages"
                clearable
                multiple
                dense
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleLanguages"
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          selectedLanguagesBlocklist.length > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                      >
                        {{ iconLanguages }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          style="margin: 10px"
          class="white--text notcapital"
          color="red"
          width="180"
          dense
          @click="back"
        >
          <v-icon left> mdi-cancel </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitLoading"
          :disabled="submitLoading"
          style="margin: 10px"
          class="white--text notcapital"
          color="black"
          width="180"
          dense
          @click="submit"
        >
          <v-icon left> mdi-content-save-move </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
  const iab = require('@/assets/styles/iab.js')
  const countries = require('@/assets/styles/countries.js')
  const languages = require('@/assets/styles/languages.js')

  import axios from 'axios'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import { GetSites } from '@/store/action_types'

  export default {
    data() {
      return {
        is_domain_valid: true,
        domainRules: [
          (v) => this.is_domain_valid || 'Please enter a valid domain URL.'
        ],
        headers: [
          {
            text: 'Name',
            value: 'name'
          },
          { text: 'Type', value: 'type' },
          { text: 'Targeting', value: 'targeting' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions' }
        ],
        countryList: countries,
        languageList: languages,
        menu: false,
        iabList: iab,
        selectedIAB: [],
        selectedCountriesBlocklist: [],
        selectedLanguagesBlocklist: [],
        selectedDomainsBlocklist: '',
        blocklist_items: [],
        iab_is_active: false,
        ad_domain_is_active: false,
        geotargeting_is_active: false,
        languagetargeting_is_active: false,
        exclude_geotargeting: false,
        submitLoading: false
      }
    },
    computed: {
      ...mapGetters(['sites', 'selectedCustomer']),

      selectAllIAB() {
        return this.selectedIAB.length === this.iab_list.length
      },
      selectSomeIAB() {
        return this.selectedIAB.length > 0 && !this.selectAllIAB
      },
      selectAllCountries() {
        return (
          this.selectedCountriesBlocklist.length === this.countryList.length
        )
      },
      selectSomeCountries() {
        return (
          this.selectedCountriesBlocklist.length > 0 && !this.selectAllCountries
        )
      },
      selectAllLanguages() {
        return (
          this.selectedLanguagesBlocklist.length === this.languageList.length
        )
      },
      selectSomeLanguages() {
        return (
          this.selectedLanguagesBlocklist.length > 0 && !this.selectAllLanguages
        )
      },
      computedLanguages() {
        let arr = []
        this.languageList.forEach((element) => {
          arr.push({
            name: element.name,
            code: element.code + ' (' + element.name + ')'
          })
        })
        return arr
          .filter((el) => {
            return true
          })
          .sort((a, b) => a.code.localeCompare(b.code))
      },
      iconIAB() {
        if (this.selectAllIAB) return 'mdi-close-box'
        if (this.selectSomeIAB) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconCountries() {
        if (this.selectAllCountries) return 'mdi-close-box'
        if (this.selectSomeCountries) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconLanguages() {
        if (this.selectAllLanguages) return 'mdi-close-box'
        if (this.selectSomeLanguages) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      countriesShown() {
        let arr = []
        this.countryList.forEach((element) => {
          arr.push({
            code: element.code,
            name: element.name + ' (' + element.code + ')'
          })
        })
        return arr
      },
      iab_list() {
        var list = []
        this.iabList.forEach((element) => {
          list.push({
            label: element.code + ' - ' + element.category,
            value: element.code
          })
        })
        return list
      }
    },
    watch: {
      selectedCustomer: async function (val, oldVal) {
        if (val.id != undefined) {
          this.getBlocklistData()
        }
      },

      selectedDomainsBlocklist: async function (val, oldVal) {
        if (val.length > 0) {
          const str = val.split(/\r?\n/)
          var valid = true
          str.forEach((element) => {
            if (
              /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
                element
              ) == false
            )
              valid = false
          })

          this.is_domain_valid = valid
          if (this.$refs.domain != undefined) this.$refs.domain.validate()
        }
      }
    },
    async created() {
      if (this.selectedCustomer.id != undefined) this.getBlocklistData()
    },
    methods: {
      ...mapMutations([]),
      ...mapActions({ GetSites }),

      initializeBlocklist() {
        this.selectedCustomer.iab_blocklist = ''
        this.selectedCustomer.language_blocklist = ''
        this.selectedCustomer.domain_blocklist = ''
        this.selectedCustomer.geotargeting_blacklist = ''
        this.selectedCustomer.geotargeting_whitelist = ''
      },
      manageBlocklist() {
        //Manage IAB
        if (this.iab_is_active == true && this.selectedIAB.length > 0) {
          let str = '['
          this.selectedIAB.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.selectedCustomer.iab_blocklist = str
        } else {
          this.selectedIAB = []
          this.selectedCustomer.iab_blocklist = ''
        }
        //Advertiser domains
        if (
          this.ad_domain_is_active == true &&
          this.selectedDomainsBlocklist != ''
        ) {
          this.selectedDomainsBlocklist =
            this.selectedDomainsBlocklist.toLowerCase()
          this.selectedDomainsBlocklist =
            this.selectedDomainsBlocklist.replaceAll('https://', '')
          this.selectedDomainsBlocklist =
            this.selectedDomainsBlocklist.replaceAll('http://', '')
          let arrDomains = this.selectedDomainsBlocklist.split(/\r?\n/)
          let str = '['
          arrDomains.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.selectedCustomer.domain_blocklist = str
        } else {
          this.selectedDomainsBlocklist = []
          this.selectedCustomer.domain_blocklist = ''
        }
        //GeoTargeting
        if (
          this.geotargeting_is_active == true &&
          this.selectedCountriesBlocklist.length > 0
        ) {
          let str = '['
          this.selectedCountriesBlocklist.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          if (this.exclude_geotargeting) {
            this.selectedCustomer.geotargeting_blacklist = str
            this.selectedCustomer.geotargeting_whitelist = ''
          } else {
            this.selectedCustomer.geotargeting_whitelist = str
            this.selectedCustomer.geotargeting_blacklist = ''
          }
        } else {
          this.selectedCountriesBlocklist = []
          this.selectedCustomer.geotargeting_whitelist = ''
          this.selectedCustomer.geotargeting_blacklist = ''
          this.exclude_geotargeting = false
        }
        //Languages
        if (
          this.languagetargeting_is_active == true &&
          this.selectedLanguagesBlocklist.length > 0
        ) {
          let str = '['
          this.selectedLanguagesBlocklist.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.selectedCustomer.language_blocklist = str
        } else {
          this.selectedLanguagesBlocklist = []
          this.selectedCustomer.language_blocklist = ''
        }
      },
      submit() {
        this.submitLoading = true
        this.manageBlocklist()
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          this.submitLoading = false
          return
        }
        this.$emit('savecustomer')
        setTimeout(() => (this.submitLoading = false), 1500)
      },
      getBlocklistData() {
        if (this.selectedCustomer.iab_blocklist != '') {
          this.iab_is_active = true
          this.selectedIAB = this.selectedCustomer.iab_blocklist
            .substring(1, this.selectedCustomer.iab_blocklist.length - 1)
            .replaceAll('"', '')
            .split(',')
        } else {
          this.iab_is_active = false
          this.selectedIAB = []
        }
        if (this.selectedCustomer.language_blocklist != '') {
          this.languagetargeting_is_active = true
          this.selectedLanguagesBlocklist =
            this.selectedCustomer.language_blocklist
              .substring(1, this.selectedCustomer.language_blocklist.length - 1)
              .replaceAll('"', '')
              .split(',')
        } else {
          this.languagetargeting_is_active = false
          this.selectedLanguagesBlocklist = []
        }
        if (this.selectedCustomer.domain_blocklist != '') {
          this.ad_domain_is_active = true
          let listDomains = this.selectedCustomer.domain_blocklist
            .substring(1, this.selectedCustomer.domain_blocklist.length - 1)
            .replaceAll('"', '')
            .split(',')
          this.selectedDomainsBlocklist = ''
          listDomains.forEach((domain) => {
            this.selectedDomainsBlocklist += domain + '\n'
          })
          this.selectedDomainsBlocklist = this.selectedDomainsBlocklist.slice(
            0,
            -1
          )
        } else {
          this.ad_domain_is_active = false
          this.selectedDomainsBlocklist = []
        }
        if (this.selectedCustomer.geotargeting_blacklist != '') {
          this.geotargeting_is_active = true
          this.exclude_geotargeting = true
          this.selectedCountriesBlocklist =
            this.selectedCustomer.geotargeting_blacklist
              .substring(
                1,
                this.selectedCustomer.geotargeting_blacklist.length - 1
              )
              .replaceAll('"', '')
              .split(',')
        } else {
          this.geotargeting_is_active = false
          this.selectedCountriesBlocklist = []
        }
        if (this.selectedCustomer.geotargeting_whitelist != '') {
          this.geotargeting_is_active = true
          this.exclude_geotargeting = false
          this.selectedCountriesBlocklist =
            this.selectedCustomer.geotargeting_whitelist
              .substring(
                1,
                this.selectedCustomer.geotargeting_whitelist.length - 1
              )
              .replaceAll('"', '')
              .split(',')
        } else {
          this.geotargeting_is_active = false
          this.selectedCountriesBlocklist = []
        }
      },
      toggleIAB() {
        this.$nextTick(() => {
          if (this.selectAllIAB) {
            this.selectedIAB = []
          } else {
            this.selectedIAB = this.iab_list.slice()
          }
        })
      },
      toggleCountries() {
        this.$nextTick(() => {
          if (this.selectAllCountries) {
            this.selectedCountriesBlocklist = []
          } else {
            this.selectedCountriesBlocklist = this.countryList.slice()
          }
        })
      },
      toggleLanguages() {
        this.$nextTick(() => {
          if (this.selectAllLanguages) {
            this.selectedLanguagesBlocklist = []
          } else {
            this.selectedLanguagesBlocklist = this.languageList.slice()
          }
        })
      },
      back() {
        this.$router.push({
          name: 'customer_list'
        })
      }
    }
  }
</script>

<style scoped>
  .iab_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
</style>
