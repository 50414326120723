import { GetVasts } from '@/store/action_types'

import { SetVasts, ClearVasts } from '@/store/mutation_types'

import axios from 'axios'

export const vastsState = {
  vasts: []
}

export const vastsGetters = {
  vasts: (state) => state.vasts
}

export const vastsActions = {
  async [GetVasts]({ commit }, customer_id) {
    commit(ClearVasts)
    const response = await axios.get(`/api/getvasts/${customer_id}`)
    commit(SetVasts, response.data)
    return response
  }
}

export const vastsMutations = {
  [SetVasts](state, vasts) {
    state.vasts = vasts
  },
  [ClearVasts](state) {
    state.vasts = []
  }
}
