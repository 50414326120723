<template>
  <v-container fluid>
    <v-card>
      <v-card-title v-if="$route.name == 'deal_edit'" primary-title>
        Updating the deal "{{ deal.name }}"
      </v-card-title>
      <v-card-title v-else primary-title> Creating a new deal </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>
                  {{ $t('deal_name') }}<strong style="color: red"> *</strong>
                </p>
                <v-text-field
                  ref="Name"
                  v-model="deal.name"
                  :rules="required"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="red"
            width="180"
            dense
            @click="back"
          >
            <v-icon left> mdi-cancel </v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="180"
            dense
            @click="createDeal"
          >
            <v-icon left> mdi-content-save-move </v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="dealcreated" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ dealUpdatedMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SetSelectedDeal } from '@/store/mutation_types'
  import {
    GetSelectedDeal,
    SaveSelectedDeal,
    GetSelectedProposal
  } from '@/store/action_types'
  import axios from 'axios'

  export default {
    name: 'UserEdit',
    components: {},
    data() {
      return {
        deal: {
          name: '',
          proposal_id: ''
        },
        submitLoading: false,
        dealcreated: false,
        timeout: 3000,
        required: [(v) => !!v || 'Required'],
        dealUpdatedMessage:
          this.$route.params.deal_id == undefined
            ? 'Deal created successfully'
            : 'Deal updated successfully',
        errors: []
      }
    },
    computed: {
      ...mapGetters(['selectedDeal', 'selectedProposal'])
    },
    watch: {},
    async created() {
      await this.GetSelectedProposal(this.$route.params.proposal_id)
      this.proposal = this.selectedProposal
      this.deal.proposal_id = this.selectedProposal.id
    },
    methods: {
      ...mapMutations([SetSelectedDeal]),
      ...mapActions([GetSelectedDeal, SaveSelectedDeal, GetSelectedProposal]),
      async submit() {},
      back() {
        this.$router.push({
          name: 'deal_list',
          params: {
            proposal_id: this.$route.params.proposal_id
          }
        })
      },
      async createDeal() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        const dealResponse = await this.SaveSelectedDeal(this.deal).catch(
          () => {
            this.errors = this.getErrors
            this.submitLoading = false
            return false
          }
        )
        this.dealcreated = true
        this.SetSelectedDeal(dealResponse)
        setTimeout(
          () =>
            this.$router.push({
              name: 'deal_edit',
              params: {
                deal_id: dealResponse.id
              }
            }),
          2000
        )
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
</style>
