import {
  GetSelectedOptionsCookieless,
  SaveSelectedOptionsCookieless,
  UpdateSelectedOptionsCookieless
} from '@/store/action_types'

import {
  SetSelectedOptionsCookieless,
  ClearSelectedOptionsCookieless
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedOptionsCookielessState = {
  selectedOptionsCookieless: {}
}

export const selectedOptionsCookielessGetters = {
  selectedOptionsCookieless: (state) => state.selectedOptionsCookieless
}

export const selectedOptionsCookielessActions = {
  async [GetSelectedOptionsCookieless]({ commit }, site_id) {
    commit(ClearSelectedOptionsCookieless)

    const response = await axios.get(`/api/optionsbyid/${site_id}`)

    commit(SetSelectedOptionsCookieless, response.data)
    return response.data
  },
  async [SaveSelectedOptionsCookieless]({ dispatch }, optionscookieless) {
    if (optionscookieless.id !== undefined) {
      return dispatch(UpdateSelectedOptionsCookieless, optionscookieless)
    }
    const response = await axios.post(
      '/api/optionscookieless',
      optionscookieless
    )
    return response
  },
  async [UpdateSelectedOptionsCookieless]({ commit }, optionscookieless) {
    const response = await axios.put(
      `/api/optionscookieless/${optionscookieless.id}`,
      optionscookieless
    )
    //commit(SetSelectedOptionsCookieles, OptionsCookieles)
    return response
  }
}

export const selectedOptionsCookielessMutations = {
  [SetSelectedOptionsCookieless](state, optionscookieless) {
    state.selectedOptionsCookieless = optionscookieless
  },
  [ClearSelectedOptionsCookieless](state) {
    state.selectedOptionsCookieless = {}
  }
}
