module.exports = [
    {
        id: '1',
        label: '1 - Initiates on Page Load with Sound On'
    },
    {
        id: '2',
        label: '2 - Initiates on Page Load with Sound Off by Default'
    },
    {
        id: '3',
        label: '3 - Initiates on Click with Sound On'
    },
    {
        id: '4',
        label: '4 - Initiates on Mouse-Over with Sound On'
    },
    {
        id: '5',
        label: '5 - Initiates on Entering Viewport with Sound On'
    },
    {
        id: '6',
        label: '6 - Initiates on Entering Viewport with Sound Off by Default'
    }
]
