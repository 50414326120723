module.exports = [
    {
        id: "FR-MTP",
        label: "Métropole",
        children: [
            {
                id: "FR-ARA",
                label: "Auvergne-Rhône-Alpes",
                children: [
                    {
                        id: "FR-01",
                        label: "Ain"
                    },
                    {
                        id: "FR-03",
                        label: "Allier"
                    },
                    {
                        id: "FR-07",
                        label: "Ardèche"
                    },
                    {
                        id: "FR-15",
                        label: "Cantal"
                    },
                    {
                        id: "FR-26",
                        label: "Drôme"
                    },
                    {
                        id: "FR-38",
                        label: "Isère"
                    },
                    {
                        id: "FR-42",
                        label: "Loire"
                    }, {
                        id: "FR-43",
                        label: "Haute-Loire"
                    },
                    {
                        id: "FR-63",
                        label: "Puy-de-Dôme"
                    },
                    {
                        id: "FR-69",
                        label: "Rhône"
                    },
                    {
                        id: "FR-73",
                        label: "Savoie"
                    }, {
                        id: "FR-74",
                        label: "Haute-Savoie",
                    }

                ]
            }, {
                id: "FR-BFC",
                label: "Bourgogne-Franche-Comté",
                children: [
                    {
                        id: "FR-21",
                        label: "Côte-d'Or"
                    },
                    {
                        id: "FR-25",
                        label: "Doubs"
                    },
                    {
                        id: "FR-39",
                        label: "Jura"
                    },
                    {
                        id: "FR-58",
                        label: "Nièvre"
                    },
                    {
                        id: "FR-70",
                        label: "Haute-Saône"
                    }, {
                        id: "FR-71",
                        label: "Saône-et-Loire"
                    }, {
                        id: "FR-89",
                        label: "Yonne"
                    }, {
                        id: "FR-90",
                        label: "Territoire de Belfort"
                    }
                ]
            }, {
                id: "FR-BRE",
                label: "Bretagne",
                children: [
                    {
                        id: "FR-22",
                        label: "Côtes-d'Armor",
                    },
                    {
                        id: "FR-29",
                        label: "Finistère"
                    },
                    {
                        id: "FR-35",
                        label: "Ille-et-Vilaine"
                    },
                    {
                        id: "FR-56",
                        label: "Morbihan"
                    }
                ]
            }, {
                id: "FR-CVL",
                label: "Centre-Val de Loire",
                children: [
                    {
                        id: "FR-18",
                        label: "Cher",
                    },
                    {
                        id: "FR-28",
                        label: "Eure-et-Loir"
                    },
                    {
                        id: "FR-36",
                        label: "Indre"
                    },
                    {
                        id: 37,
                        label: "Indre-et-Loire"
                    },
                    {
                        id: "FR-41",
                        label: "Loir-et-Cher"
                    },
                    {
                        id: "FR-45",
                        label: "Loiret",
                    }
                ]
            }, {
                id: "FR-20R",
                label: "Corse",
                children: [
                    {
                        id: "FR-2A",
                        label: "Corse-du-Sud"
                    },
                    {
                        id: "FR-2B",
                        label: "Haute-Corse"
                    },
                ]
            }, {
                id: "FR-GES",
                label: "Grand Est",
                children: [
                    {
                        id: "FR-08",
                        label: "Ardennes"
                    },
                    {
                        id: "FR-10",
                        label: "Aube"
                    },
                    {
                        id: "FR-51",
                        label: "Marne"
                    },
                    {
                        id: "FR-52",
                        label: "Haute-Marne"
                    },
                    {
                        id: "FR-54",
                        label: "Meurthe-et-Moselle"
                    },
                    {
                        id: "FR-55",
                        label: "Meuse"
                    },
                    {
                        id: "FR-57",
                        label: "Moselle"
                    },
                    {
                        id: "FR-67",
                        label: "Bas-Rhin"
                    },
                    {
                        id: "FR-68",
                        label: "Haut-Rhin"
                    },
                    {
                        id: "FR-88",
                        label: "Vosges"
                    }
                ]
            }, {
                id: "FR-HDF",
                label: "Hauts-de-France",
                children: [
                    {
                        id: "FR-02",
                        label: "Aisne"
                    },
                    {
                        id: "FR-59",
                        label: "Nord"
                    },
                    {
                        id: "FR-60",
                        label: "Oise"
                    },
                    {
                        id: "FR-62",
                        label: "Pas-de-Calais"
                    },
                    {
                        id: "FR-80",
                        label: "Somme"
                    },
                ]
            }, {
                id: "FR-IDF",
                label: "Île-de-France",
                children: [
                    {
                        id: "FR-75",
                        label: "Paris"
                    },
                    {
                        id: "FR-77",
                        label: "Seine-et-Marne"
                    },
                    {
                        id: "FR-78",
                        label: "Yvelines"
                    },
                    {
                        id: "FR-91",
                        label: "Essonne"
                    },
                    {
                        id: "FR-92",
                        label: "Hauts-de-Seine"
                    },
                    {
                        id: "FR-93",
                        label: "Seine-Saint-Denis"
                    },
                    {
                        id: "FR-94",
                        label: "Val-de-Marne"
                    },
                    {
                        id: "FR-95",
                        label: "Val-d'Oise"
                    }
                ]
            }, {
                id: "FR-NOR",
                label: "Normandie",
                children: [
                    {
                        id: "FR-14",
                        label: "Calvados"
                    },
                    {
                        id: "FR-27",
                        label: "Eure"
                    },
                    {
                        id: "FR-50",
                        label: "Manche"
                    },
                    {
                        id: "FR-61",
                        label: "Orne"
                    },
                    {
                        id: "FR-76",
                        label: "Seine-Maritime"
                    }
                ]
            }, {
                id: "FR-NAQ",
                label: "Nouvelle-Aquitaine",
                children: [
                    {
                        id: "FR-16",
                        label: "Charente"
                    },
                    {
                        id: "FR-17",
                        label: "Charente-Maritime"
                    },
                    {
                        id: "FR-19",
                        label: "Corrèze"
                    },
                    {
                        id: "FR-23",
                        label: "Creuse"
                    },
                    {
                        id: "FR-24",
                        label: "Dordogne"
                    },
                    {
                        id: "FR-33",
                        label: "Gironde"
                    },
                    {
                        id: "FR-40",
                        label: "Landes"
                    },
                    {
                        id: "FR-47",
                        label: "Lot-et-Garonne"
                    },
                    {
                        id: "FR-64",
                        label: "Pyrénées-Atlantiques"
                    },
                    {
                        id: "FR-79",
                        label: "Deux-Sèvres"
                    },
                    {
                        id: "FR-86",
                        label: "Vienne"
                    },
                    {
                        id: "FR-87",
                        label: "Haute-Vienne"
                    }
                ]
            }, {
                id: "FR-OCC",
                label: "Occitanie",
                children: [
                    {
                        id: "FR-09",
                        label: "Ariège"
                    },
                    {
                        id: "FR-11",
                        label: "Aude"
                    },
                    {
                        id: "FR-12",
                        label: "Aveyron"
                    },
                    {
                        id: "FR-30",
                        label: "Gard"
                    },
                    {
                        id: "FR-31",
                        label: "Haute-Garonne"
                    },
                    {
                        id: "FR-32",
                        label: "Gers"
                    },
                    {
                        id: "FR-34",
                        label: "Hérault"
                    },
                    {
                        id: "FR-46",
                        label: "Lot"
                    },
                    {
                        id: "FR-48",
                        label: "Lozère"
                    },
                    {
                        id: "FR-65",
                        label: "Hautes-Pyrénées"
                    },
                    {
                        id: "FR-66",
                        label: "Pyrénées-Orientales"
                    },
                    {
                        id: "FR-81",
                        label: "Tarn"
                    },
                    {
                        id: "FR-82",
                        label: "Tarn-et-Garonne"
                    }
                ]
            }, {
                id: "FR-PDL",
                label: "Pays de la Loire",
                children: [
                    {
                        id: "FR-44",
                        label: "Loire-Atlantique"
                    },
                    {
                        id: "FR-49",
                        label: "Maine-et-Loire"
                    },
                    {
                        id: "FR-53",
                        label: "Mayenne"
                    },
                    {
                        id: "FR-72",
                        label: "Sarthe"
                    },
                    {
                        id: "FR-85",
                        label: "Vendée"
                    }
                ]
            }, {
                id: "FR-PAC",
                label: "Provence-Alpes-Côte d'Azur",
                children: [
                    {
                        id: "FR-04",
                        label: "Alpes-de-Haute-Provence"
                    },
                    {
                        id: "FR-05",
                        label: "Hautes-Alpes"
                    },
                    {
                        id: "FR-06",
                        label: "Alpes-Maritimes"
                    },
                    {
                        id: "FR-13",
                        label: "Bouches-du-Rhône"
                    },
                    {
                        id: "FR-83",
                        label: "Var"
                    },
                    {
                        id: "FR-84",
                        label: "Vaucluse"
                    }
                ]
            }
        ]
    }, {
        id: "FR-OUTRE",
        label: "Outre-mer",
        children: [{
            id: "FR-GUA",
            label: "Guadeloupe",
            children: [
                {
                    id: "FR-971",
                    label: "Guadeloupe"
                }
            ]
        }, {
            id: "FR-MTQ",
            label: "Martinique",
            children: [
                {
                    id: "FR-972",
                    label: "Martinique"
                },
            ]
        }, {
            id: "FR-GUF",
            label: "Guyane",
            children: [
                {
                    id: "FR-973",
                    label: "Guyane"
                }
            ]
        }, {
            id: "FR-LRE",
            label: "La Réunion",
            children: [
                {
                    id: "FR-974",
                    label: "La Réunion"
                }
            ]
        }, {
            id: "FR-MAY",
            label: "Mayotte",
            children: [
                {
                    id: "FR-976",
                    label: "Mayotte"
                }
            ]
        }]
    }
]
