module.exports = [
  {
    name: '120x600',
    value: '120x600'
  },
  {
    name: '160x600',
    value: '160x600'
  },
  {
    name: '250x250',
    value: '250x250'
  },
  {
    name: '300x250',
    value: '300x250'
  },
  {
    name: '300x600',
    value: '300x600'
  },
  {
    name: '300x1050',
    value: '300x1050'
  },
  {
    name: '320x50',
    value: '320x50'
  },
  {
    name: '320x100',
    value: '320x100'
  },
  {
    name: '468x60',
    value: '468x60'
  },
  {
    name: '728x90',
    value: '728x90'
  },
  {
    name: '970x90',
    value: '970x90'
  },
  {
    name: '970x250',
    value: '970x250'
  },
  {
    name: '990x90',
    value: '990x90'
  },
  {
    name: '1000x90',
    value: '1000x90'
  },
  {
    name: '1000x250',
    value: '1000x250'
  },
  {
    name: '1800x1000',
    value: '1800x1000'
  }
]