<template>
  <v-container fluid>
    <v-card>
      <app-overlay :loading="loading" />
      <v-card-title v-if="$route.params.site_id == 'new'" primary-title>
        New Site
      </v-card-title>
      <v-card-title v-else primary-title> Site {{ sitename }} </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
                style="padding-left: 10px; padding-right: 50px"
              >
                <v-text-field
                  v-model="site.name"
                  label="Name"
                  :rules="nameRules"
                  required
                  dense
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-for="siteElement in siteElements" :key="siteElement.name">
              <v-col cols="4" style="padding-left: 10px; padding-right: 0px">
                <v-switch
                  v-model="site['is' + siteElement.name + 'Active']"
                  :label="'Is ' + siteElement.label + ' Active'"
                ></v-switch>
              </v-col>
              <v-col
                v-for="item in siteElement.items"
                :key="item"
                cols="12"
                md="4"
                style="padding-left: 0px; padding-right: 50px"
              >
                <v-text-field
                  v-if="site['is' + siteElement.name + 'Active'] == 1"
                  v-model="site[item]"
                  :label="item"
                  dense
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row v-if="updated == true" style="padding-top: 20px">
          <v-col>
            <v-alert dense text type="success" color="green">
              Site updated successfully</v-alert
            >
          </v-col>
        </v-row>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="130"
            dense
            @click="submit"
          >
            <v-icon left> mdi-check-circle</v-icon>
            {{ $t('save') }}
          </v-btn>
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="130"
            dense
            @click="back"
          >
            <v-icon left> mdi-arrow-left </v-icon>
            {{ $t('back') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Overlay from '@/pages/tag_management/sites/overlay.vue'
  export default {
    components: {
      AppOverlay: Overlay
    },
    data() {
      return {
        sitename: '',
        site: {},
        loading: false,
        nameRules: [(v) => !!v || 'Name is required'],
        submitLoading: false,
        updated: false,
        siteElements: [
          { name: 'Didomi', label: 'Didomi', items: ['didomiId'] },
          { name: 'Facebook', label: 'Facebook', items: ['facebookId'] },
          {
            name: 'Mediarythmics',
            label: 'Mediarythmics',
            items: ['mediarythmicsToken']
          },
          { name: 'Pubstack', label: 'Pubstack', items: ['pubstackTagId'] },
          { name: 'Refresh', label: 'Refresh', items: ['refreshCampaignIds'] },
          { name: 'Yieldkit', label: 'Yieldkit', items: ['yieldkitSiteId'] },
          // { name: "Kvm", label: "Kvm", items: ["kvmEndpoint"]},
          { name: 'Permutive', label: 'Permutive', items: [] },
          //{ name : 'AdsWrapper', items: []},
          {
            name: 'Gravity',
            label: 'Gravity',
            items: ['gravitySiteToken', 'gravityProcessingToken']
          },
          { name: 'TcfV2', label: 'TcfV2', items: [] },
          { name: 'Adagio', label: 'Adagio', items: [] },
          {
            name: 'RemoveHeaderOnOop',
            label: 'Remove Header On Oop',
            items: []
          }
        ]
      }
    },
    watch: {},
    created() {
      if (this.$route.params.site_id == 'new') this.initializeSite()
      else this.getSite(this.$route.params.site_id)
    },
    methods: {
      async submit() {
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.updated = true
        this.verifyDeactivatedToggles()
        if (this.$route.params.site_id == 'new') {
          const response = await axios.post(`/api/wsites`, this.site)
        } else {
          const response = await axios.put(
            `/api/wsites/${this.site.id}`,
            this.site
          )
        }
        setTimeout(() => (this.updated = false), 2000)
        setTimeout(() => this.back(), 1000)
      },
      back() {
        this.$router.push('/tag_management/sites')
      },
      async getSite(site_id) {
        const response = await axios.get(`/api/getwsitebyid/${site_id}`)
        this.site = response.data
        this.sitename = this.site.name
      },
      async initializeSite() {
        this.site = {
          id: '',
          name: '',
          isDidomiActive: '',
          didomiId: '',
          isFacebookActive: '',
          facebookId: '',
          isMediarythmicsActive: '',
          mediarythmicsToken: '',
          isPubstackActive: '',
          pubstackTagId: '',
          isYieldkitActive: '',
          yieldkitSiteId: '',
          isKvmActive: '',
          kvmEndpoint: '',
          isPermutiveActive: '',
          isAdsWrapperActive: '',
          isGravityActive: '',
          gravitySiteToken: '',
          gravityProcessingToken: '',
          isAdagioActive: '',
          isRemoveHeaderOnOopActive: '',
          refresh_is_active: '',
          isRefreshCampaignIds: ''
        }
        return
      },
      verifyDeactivatedToggles() {
        if (this.site.isDidomiActive == false) this.site.didomiId = ''
        if (this.site.isFacebookActive == false) this.site.facebookId = ''
        if (this.site.isMediarythmicsActive == false)
          this.site.mediarythmicsToken = ''
        if (this.site.isPubstackActive == false) this.site.pubstackTagId = ''
        if (this.site.isYieldkitActive == false) this.site.yieldkitSiteId = ''
        if (this.site.isKvmActive == false) this.site.kvmEndpoint = ''
        if (this.site.isGravityActive == false) {
          this.site.gravitySiteToken = ''
          this.site.gravityProcessingToken = ''
        }
        if (this.site.refresh_is_active == false)
          this.site.refreshCampaignIds = ''
      }
    }
  }
</script>
