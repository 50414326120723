module.exports = [
    {
        id: '1',
        label: '1 - VPAID 1.0'
    },
    {
        id: '2',
        label: '2 - VPAID 2.0'
    },
    {
        id: '3',
        label: '3 - MRAID-1'
    },
    {
        id: '4',
        label: '4 - ORMMA'
    },
    {
        id: '5',
        label: '5 - MRAID-2'
    },
    {
        id: '6',
        label: '6 - MRAID-3'
    }
]
