<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    max-width="800"
    @click:outside="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="submitLoading"
        :disabled="submitLoading"
        style="margin: 10px"
        class="white--text notcapital"
        color="red"
        width="200"
        dense
        v-bind="attrs"
        @click="dialog = true"
        v-on="on"
      >
        Reject proposal
        <v-icon right> mdi-close-circle </v-icon>
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="dialog = false">mdi-close</v-icon>
        </v-col>
        <v-card-text style="padding-bottom: 0px">
          <v-container style="padding: 30px">
            <v-row>
              <v-col cols="12" style="padding: 0px">
                <h2 style="color: black">
                  Are you sure you want to reject this proposal request ?
                </h2>
              </v-col>
              <br />
              <v-row v-if="proposalRejected" style="margin-top: 30px">
                <v-col>
                  <v-alert dense text type="success" color="green">
                    Proposal request rejected successfully</v-alert
                  >
                </v-col>
              </v-row>
              <br />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="12" align="center" style="padding-top: 0px">
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              width="250"
              style="height: 40px; margin: 20px"
              class="white--text"
              color="black"
              @click="submit"
            >
              Yes
            </v-btn>
            <v-btn
              width="250"
              style="height: 40px"
              class="white--text"
              color="black"
              @click="dialog = false"
            >
              No
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    props: ['item'],
    data() {
      return {
        dialog: false,
        pageName: '',
        proposalRejected: false,
        submitLoading: false
      }
    },
    methods: {
      async submit() {
        this.submitLoading = true
        setTimeout(() => (this.proposalRejected = true), 1000)
        this.$emit('proposalRejected', this.item)
        setTimeout(() => (this.submitLoading = false), 2000)
        setTimeout(() => (this.proposalRejected = false), 2000)
        setTimeout(() => (this.dialog = false), 1200)
      }
    }
  }
</script>
