module.exports = [
    {
        id: '1',
        label: '1 - VAST 1.0'
    },
    {
        id: '2',
        label: '2 - VAST 2.0'
    },
    {
        id: '3',
        label: '3 - VAST 3.0'
    },
    {
        id: '4',
        label: '4 - VAST 1.0 Wrapper'
    },
    {
        id: '5',
        label: '5 - VAST 2.0 Wrapper'
    },
    {
        id: '6',
        label: '6 - VAST 3.0 Wrapper'
    },
    {
        id: '7',
        label: '7 - VAST 4.0'
    },
    {
        id: '8',
        label: '8 - VAST 4.0 Wrapper'
    },
    {
        id: '9',
        label: '9 - DAAST 1.0'
    },
    {
        id: '10',
        label: '10 - DAAST 1.0 Wrapper'
    }
]
