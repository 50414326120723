<template>
  <v-container fluid style="background-color: #f9f9fb">
    <!--<v-row v-if="options == true" no-gutters style="padding-top: 20px">-->
    <v-row no-gutters style="padding-top: 20px">
      <v-col cols="12">
        <v-container style="padding-left: 90px">
          <v-row no-gutters dense>
            <v-col
              style="margin-left: auto; margin-right: auto"
              cols="3"
              v-if="
                $store.getters.appUser.is_superadmin ||
                $store.state.appCustomer.adapter_is_active
              "
            >
              <!-- <v-card width="280" height="270">-->
              <v-card width="280" height="200">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    <!-- {{ $t('billing') }}-->
                    {{ $t('setup_prebid_booster') }}
                  </span>
                </v-card-title>
                <!--<v-card-text style="height: 80px; width: 270px">-->
                <v-card-text style="height: 30px; width: 270px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <!--  <p style="font-size: 15px">
                          {{ $t('facturation_dashboard_description') }}
                        </p>--></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="padding-top: 50px; text-align: center">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        width="150"
                        class="ma-2 notcapital"
                        tile
                        outlined
                        color="black"
                        @click="
                          $router.push({
                            name: 'site_list',
                            params: {
                              customer_id: $store.state.appCustomer.id
                            }
                          })
                        "
                      >
                        {{ $t('access') }}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              style="margin-left: auto; margin-right: auto"
              cols="3"
              v-if="
                $store.getters.appUser.is_superadmin ||
                $store.state.appCustomer.web_is_active
              "
            >
              <!-- <v-card width="280" height="270">-->
              <v-card width="280" height="200">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    <!-- {{ $t('billing') }}-->
                    {{ $t('setup_Web_stack_nexx360') }}
                  </span>
                </v-card-title>
                <!--<v-card-text style="height: 80px; width: 270px">-->
                <v-card-text style="height: 30px; width: 270px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <!--  <p style="font-size: 15px">
                          {{ $t('facturation_dashboard_description') }}
                        </p>--></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="padding-top: 50px; text-align: center">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        width="150"
                        class="ma-2 notcapital"
                        tile
                        outlined
                        color="black"
                        @click="
                          $router.push({
                            name: 'site_list',
                            params: {
                              customer_id: $store.state.appCustomer.id
                            }
                          })
                        "
                      >
                        {{ $t('access') }}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              style="margin-left: auto; margin-right: auto"
              cols="3"
              v-if="$store.getters.appUser.is_superadmin"
            >
              <!-- <v-card width="280" height="270">-->
              <v-card width="280" height="200">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    <!-- {{ $t('billing') }}-->
                    {{ $t('setup_AMP_amp_nexx360') }}
                  </span>
                </v-card-title>
                <!--<v-card-text style="height: 80px; width: 270px">-->
                <v-card-text style="height: 30px; width: 270px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <!--  <p style="font-size: 15px">
                          {{ $t('facturation_dashboard_description') }}
                        </p>--></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="padding-top: 50px; text-align: center">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        width="150"
                        class="ma-2 notcapital"
                        tile
                        outlined
                        color="black"
                        @click="
                          $router.push({
                            name: 'site_list',
                            params: {
                              customer_id: $store.state.appCustomer.id
                            }
                          })
                        "
                      >
                        {{ $t('access') }}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <accessdenied
              style="margin-right: 15%"
              v-if="
                !$store.getters.appUser.is_superadmin &&
                !$store.state.appCustomer.stack_is_active &&
                $store.state.appCustomer.id != '1086' &&
                $store.state.appCustomer.id != '1094' &&
                $store.state.appUser.email != 'alfonso@revenuemaker.es'
              "
            />
            <v-col cols="2" />
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import accessdenied from '@/pages/accessdenied.vue'
  export default {
    name: 'CookielessContent',
    components: { accessdenied },
    props: {
      parentExists: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        options: ''
      }
    },
    watch: {
      parentExists() {
        this.options = this.parentExists
      }
    },
    created() {
      this.options = this.parentExists
    },
    methods: {}
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
</style>
