<template>
  <v-app id="inspire" style="background-color: #f9f9fb">
    <app-topbar></app-topbar>
    <appSidebarr></appSidebarr>
    <v-main style="padding: 40px 0px 0px 256px">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import topbar from '@/components/layout/topbar.vue'
  import sidebar from '@/components/layout/sidebar.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { GetSites } from '@/store/action_types'

  export default {
    components: {
      appTopbar: topbar,
      appSidebarr: sidebar
    },
    data() {
      return {
        type: ''
      }
    },
    computed: {
      ...mapGetters(['sites', 'appCustomer', 'appUser'])
    },
    watch: {
      $route(to, from) {
        // Update the data type when the route changes.
        this.type = to.name
      }
    },
    mounted() {
      this.type = this.$route.name
    },
    async created() {
      await this.GetSites(this.appCustomer.id)
      if (
        this.$route.name == 'dashboard' ||
        this.$route.name == 'home' ||
        this.$route.name == 'Home'
      ) {
        this.$router.push({
          name: 'site_list',
          params: {
            customer_id: this.appCustomer.id
          }
        })
      }
    },
    methods: {
      ...mapActions({ GetSites })
    }
  }
</script>
