import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import config from '@/config.js'

axios.defaults.baseURL = config.baseURL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    console.log(error)
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      // store.dispatch('Logout')
    }
    store.commit('Error', error)
    return Promise.reject(error)
  }
)

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

var store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})

export default store
