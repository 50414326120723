module.exports = [
    {
        id: '1',
        label: '1 - Audio Ad (Auto-Play)'
    },
    {
        id: '2',
        label: '2 - Audio Ad (User Initiated)'
    },
    {
        id: '3',
        label: '3 - Expandable (Automatic)'
    },
    {
        id: '4',
        label: '4 - Expandable (User Initiated - Click)'
    },
    {
        id: '5',
        label: '5 - Expandable (User Initiated - Rollover)'
    },
    {
        id: '6',
        label: '6 - In-Banner Video Ad (Auto-Play)'
    },
    {
        id: '7',
        label: '7 - In-Banner Video Ad (User Initiated)'
    },
    {
        id: '8',
        label: '8 - Pop (e.g., Over, Under, or Upon Exit)'
    },
    {
        id: '9',
        label: '9 - Provocative or Suggestive Imagery'
    },
    {
        id: '10',
        label: '10 - Shaky, Flashing, Flickering, Extreme Animation, Smileys'
    },
    {
        id: '11',
        label: '11 - Surveys'
    },
    {
        id: '12',
        label: '12 - Text Only'
    },
    {
        id: '13',
        label: '13 - User Interactive (e.g., Embedded Games)'
    },
    {
        id: '14',
        label: '14 - Windows Dialog or Alert Style'
    },
    {
        id: '15',
        label: '15 - Has Audio On/Off Button'
    },
    {
        id: '16',
        label: '16 - Ad Provides Skip Button (e.g. VPAID-rendered skip button on pre-roll video)'
    },
    {
        id: '17',
        label: '17 - Adobe Flash'
    }
]
