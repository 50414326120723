module.exports = [
    {
        id: 'video/3gpp',
        label: 'video/3gpp'
    },
    {
        id: 'video/3gpp2',
        label: 'video/3gpp2'
    },
    {
        id: 'video/mp2t',
        label: 'video/mp2t'
    },
    {
        id: 'video/mp4',
        label: 'video/mp4'
    },
    {
        id: 'video/mpeg',
        label: 'video/mpeg'
    },
    {
        id: 'video/ogg',
        label: 'video/ogg'
    },
    {
        id: 'video/x-flv',
        label: 'video/x-flv'
    },
    {
        id: 'video/x-msvideo',
        label: 'video/x-msvideo'
    },
    {
        id: 'video/webm',
        label: 'video/webm'
    },
    {
        id: 'application/x-shockwave-flash',
        label: 'application/x-shockwave-flash'
    },
    {
        id: 'application/javascript',
        label: 'application/javascript'
    }
]
