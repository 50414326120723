<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="1000"
    style="height: 600px"
    @click:outside="hideDialog()"
  >
    <template>
      <v-card style="height: 100%; overflow-y: hidden">
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="hideDialog()">mdi-close</v-icon>
        </v-col>
        <v-card-title primary-title style="padding-top: 10px">
          <v-row>
            <v-col cols="12">
              <h3 style="color: black">Adding an existing creative</h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-form ref="form" style="padding: 10px; padding-left: 0px">
          <v-stepper v-model="index" vertical style="margin-left: 10px">
            <v-stepper-step
              :complete="index > 1"
              step="1"
              style="cursor: pointer"
              @click="moveTo(1)"
            >
              Select size
              <small v-if="size_chosen" style="color: red"
                >Please choose a size</small
              >
              <small v-else>Please choose a size</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-col cols="5" style="padding-bottom: 0px">
                <v-select
                  v-model="selected_size"
                  :items="size_choices"
                  item-text="name"
                  clearable
                  dense
                  outlined
                  return-object
                >
                </v-select
              ></v-col>
              <v-btn color="primary" @click="moveTo(2)"> Continue </v-btn>
            </v-stepper-content>

            <v-stepper-step
              :complete="index > 2"
              step="2"
              style="cursor: pointer"
              @click="index == 3 ? moveTo(2) : null"
            >
              Select criteria
              <small v-if="criteria_chosen" style="color: red"
                >Please choose a criteria</small
              >
              <small v-else>Please choose a criteria</small>
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-col cols="5" style="padding-bottom: 0px">
                <v-select
                  v-model="selected_criteria"
                  :items="criteria_choices"
                  item-text="name"
                  item-value="value"
                  clearable
                  dense
                  outlined
                >
                </v-select
              ></v-col>
              <v-btn color="primary" @click="moveTo(3)"> Continue </v-btn>
              <v-btn
                style="margin-left: 20px"
                class="white--text notcapital"
                color="#f9a32a"
                width="130"
                dense
                @click="moveTo(1)"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t('back') }}
              </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="index > 3" step="3">
              Select creatives
              <small v-if="creative_chosen" style="color: red"
                >Please choose at least one creative</small
              >
              <small v-else>Please choose at least one creative</small>
            </v-stepper-step>
            <v-stepper-content
              step="3"
              style="padding: 0px !important; width: 100%; margin-left: 0px"
            >
              <v-row v-if="items.length == 0">
                <v-col style="margin-left: 60px; margin-top: 20px"
                  ><p style="color: red">
                    No creatives to show, please try another criteria
                  </p></v-col
                >
              </v-row>
              <v-container
                v-else
                class="pa-4"
                style="
                  width: 97.7%;
                  padding: 0px !important;
                  margin-bottom: 20px;
                "
              >
                <v-row>
                  <v-col col="7" />
                  <v-col cols="5" style="text-align: right: padding : 10px">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" style="padding-left: 0px !important">
                    <v-treeview
                      :active.sync="active"
                      :items="items"
                      :open.sync="open"
                      :search="search"
                      activatable
                      color="#FFA500"
                      return-object
                      transition
                      multiple-active
                      style="font-size: 13px; cursor: pointer"
                      @update:active="getActiveValue"
                    >
                      <template v-slot:prepend="{ item }">
                        <v-icon v-if="!item.children">
                          mdi-mirror-rectangle
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-col>

                  <v-divider vertical></v-divider>

                  <v-col class="d-flex text-center">
                    <v-scroll-y-transition mode="out-in">
                      <v-card
                        v-if="selected.id != undefined"
                        flat
                        style="padding-top: 0px; width: 95%"
                      >
                        <div style="width: 100%; padding-bottom: 10px">
                          <p
                            style="font-size: 15px; color: black; padding: 5px"
                          >
                            <strong> {{ selected.name }}</strong>
                          </p>
                        </div>
                        <v-divider style="padding: 10px"></v-divider>
                        <v-row class="text-left">
                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding: 15px;
                              padding-top: 7px !important;
                              padding-left: 20px;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Order name<span style="color: black">
                                : {{ selected.order_name }}</span
                              >
                            </p>
                          </v-col>
                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding-right: 0px;
                              padding: 15px;
                              padding-top: 7px !important;
                              border-left: 0.5px solid gray;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Line item name<span style="color: black">
                                : {{ selected.line_item_name }}</span
                              >
                            </p>
                          </v-col>
                        </v-row>

                        <v-row class="text-left">
                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding: 10px;
                              padding-top: 7px !important;
                              padding-left: 20px;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Media type<span style="color: black">
                                : {{ selected.mediatype }}</span
                              >
                            </p>
                          </v-col>
                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding-right: 0px;
                              padding: 10px;
                              padding-top: 7px !important;
                              border-left: 0.5px solid gray;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Width<span style="color: black">
                                : {{ selected.w }}</span
                              >
                            </p>
                          </v-col>
                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding-right: 0px;
                              padding: 10px;
                              padding-top: 7px !important;
                              padding-left: 20px;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Domain name<span style="color: black">
                                : {{ selected.adomain }}</span
                              >
                            </p>
                          </v-col>

                          <v-col
                            class="text-left"
                            cols="6"
                            style="
                              margin-right: 0px;
                              padding-right: 0px;
                              padding: 10px;
                              padding-top: 7px !important;
                              border-left: 0.5px solid gray;
                              font-size: 14px;
                            "
                          >
                            <p style="color: orange; margin-bottom: 0px">
                              Height<span style="color: black">
                                : {{ selected.h }}</span
                              >
                            </p>
                          </v-col>
                        </v-row>
                        <v-row v-if="selected.content != ''">
                          <v-col
                            class="text-left"
                            cols="12"
                            style="
                              margin-right: 0px;
                              padding-right: 0px !important;
                              padding: 10px;
                              padding-top: 7px !important;
                              border-left: 0.5px solid gray;
                              font-size: 14px;
                            "
                          >
                            <v-expansion-panels>
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  ><p style="color: orange; margin-bottom: 0px">
                                    Content
                                  </p></v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                  {{ selected.content }}
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-container>
              <v-btn
                style="margin-left: 20px; margin-top: 10px"
                class="white--text notcapital"
                color="#f9a32a"
                width="130"
                dense
                @click="moveTo(2)"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                {{ $t('back') }}
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-form>
        <v-card-actions align="right" class="justify-end">
          <v-row>
            <v-col cols="8" />
            <v-col cols="2">
              <v-btn
                :loading="submitLoading"
                :disabled="index != 3"
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="150"
                dense
                @click="submit()"
              >
                <v-icon left> mdi-content-save-move </v-icon> Save
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="red"
                width="150"
                dense
                @click="$emit('hideDialog')"
              >
                <v-icon left @click="index = 1"> mdi-cancel </v-icon> Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    components: {},
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        creative: { name: '' },
        required: [(v) => !!v || 'Required'],
        submitLoading: false,
        dialog: false,
        index: 1,
        search: '',
        selected_size: '',
        size_choices: [],
        selected_criteria: '',
        criteria_choices: [
          { name: 'All Creatives', value: 'all' },
          { name: 'Order', value: 'order' },
          { name: 'Line Item', value: 'line_item' }
        ],
        size_chosen: false,
        criteria_chosen: false,
        creative_chosen: false,
        active: [],
        avatar: null,
        open: [],
        creatives: [],
        selected: {},
        selectedCreatives: [],
        creativesByCriterias: {}
      }
    },
    computed: {
      ...mapGetters(['selectedCreative', 'selectedLineItem']),
      items() {
        return this.creatives
      }
    },
    watch: {
      async parentDialog() {
        await this.getSizes()
        this.dialog = this.parentDialog
      }
    },
    async created() {
      await this.getSizes()
    },
    methods: {
      getActiveValue(value) {
        this.selected = Object.assign({}, value[value.length - 1])
        this.selectedCreatives = value
        this.creative_chosen = false
      },
      async getSizes() {
        const sizes_response = await axios.get(
          `/api/getdspcreativesizes/${this.$route.params.customer_id}/${this.$route.params.lineitem_id}/${this.selectedLineItem.mediatype}`
        )
        let sizes = sizes_response.data
        if (sizes.length > 0) {
          sizes.forEach((size) => {
            this.size_choices.push({
              name: size.w + ' x ' + size.h,
              width: size.w,
              height: size.h
            })
          })
        }
      },
      async moveTo(number) {
        switch (number) {
          case 2:
            if (this.selected_size == '') {
              this.size_chosen = true
            } else {
              await this.getCreativeListByCriteria()
              this.index = number
              this.size_chosen = false
            }
            setTimeout(() => (this.creatives = []), 1000)
            break
          case 3:
            if (this.selected_criteria == '') {
              this.criteria_chosen = true
            } else {
              this.creatives = []
              if (this.selected_criteria == 'order') {
                this.creativesByCriterias['order'].forEach((element) => {
                  this.creatives.push(element)
                })
              } else if (this.selected_criteria == 'line_item') {
                this.creativesByCriterias['line_item'].forEach((element) => {
                  this.creatives.push(element)
                })
              } else {
                this.creativesByCriterias['all'].forEach((element) => {
                  this.creatives.push(element)
                })
              }
              this.index = number
              this.criteria_chosen = false
            }
            break
          default:
            this.index = number
            break
        }
      },
      async getCreativeListByCriteria() {
        var payload = {
          width: this.selected_size.width,
          height: this.selected_size.height,
          mediatype: this.selectedLineItem.mediatype,
          order_id: this.$route.params.order_id,
          line_item_id: this.$route.params.lineitem_id,
          customer_id: this.$route.params.customer_id
        }
        const response = await axios.post(
          `/api/getdspcreativesbycriteria`,
          payload
        )
        this.creativesByCriterias = Object.assign({}, response.data)
      },
      submit() {
        this.submitLoading = true
        this.selectedCreatives.forEach((creative) => {
          this.$emit('associate', creative)
        })
        setTimeout(() => (this.submitLoading = false), 1500)
        setTimeout(() => this.hideDialog(), 500)
      },
      hideDialog() {
        this.moveTo(1)
        this.$refs.form.reset()
        this.$emit('hideDialog')
      }
    }
  }
</script>

<style scoped>
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
</style>
