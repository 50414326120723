var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding-top":"30px"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding-left":"50px"},attrs:{"lg10":"","offset-lg1":""}},[_c('h1',{staticClass:"text-xs-center"},[_vm._v(_vm._s(_vm.$t('welcome on Nexx360')))])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding-left":"50px"},attrs:{"lg10":"","offset-lg1":""}},[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('home_description_firstline'))+"."),_c('br'),_vm._v(_vm._s(_vm.$t('home_description_secondline'))+". ")])])],1),_c('v-row',{staticStyle:{"padding-top":"20px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticStyle:{"padding-left":"90px"}},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"width":"280","height":"270"}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"black","font-size":"17px","text-indent":"10px"}},[_vm._v(" "+_vm._s(_vm.$t('amp_header_biding'))+" ")])]),_c('v-card-text',{staticStyle:{"height":"80px","width":"250px"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('amp_header_biding_description'))+" ")])])],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding-top":"50px","text-align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"ma-2 notcapital",attrs:{"width":"150","tile":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.$router.push({
                          name: 'site_list',
                          params: {
                            customer_id: _vm.$store.state.appCustomer.id
                          }
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('Set_Up'))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"width":"280","height":"270"}},[_c('v-card-title',[_c('span',{staticStyle:{"color":"black","font-size":"17px","text-indent":"10px"}},[_vm._v(" "+_vm._s(_vm.$t('web_server_side'))+" ")])]),_c('v-card-text',{staticStyle:{"height":"80px","width":"270px"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.$t('web_server_side_description'))+" ")])])],1)],1)],1),_c('v-card-actions',{staticStyle:{"padding-top":"50px","text-align":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-center":""}},[_c('v-btn',{staticClass:"ma-2 notcapital",attrs:{"width":"150","tile":"","outlined":"","color":"black"},on:{"click":function($event){return _vm.$router.push({
                          name: 'site_list',
                          params: {
                            customer_id: _vm.$store.state.appCustomer.id
                          }
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('Set_Up'))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center","padding":"20px 0px 0px"},attrs:{"cols":"12"}},[_c('p',{staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.$t('or')))])])],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center","padding":"0px 0px 0px"},attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text",staticStyle:{"border-color":"black"},attrs:{"width":"370px","height":"40px","color":"black","dense":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" "+_vm._s(_vm.$t('access_console'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }