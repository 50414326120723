export const sspDetails = {
  thirtythreeacross: {
    thirtythreeacross_is_active: 0,
    thirtythreeacross_site_id: '',
    thirtythreeacross_product_id: ''
  },
  adbility: {
    adbility_is_active: 0,
    adbility_zone_id: '',
    adbility_script: '',

  },
  adform: {
    adform_is_active: 0,
    adform_mid: ''
  },
  adnuntius: {
    adnuntius_is_active: 0,
    adnuntius_au_id: ''
  },
  adot: {
    adot_is_active: 0,
    adot_publisher_id: '',
    adot_placement_id: ''
  },
  adyoulike: {
    adyoulike_is_active: 0,
    adyoulike_placement: ''
  },
  adswizz: {
    adswizz_is_active: 0,
    adswizz_url_prefix: '',
    adswizz_preroll_zonealias: '',
    adswizz_midroll_zonealias: '',
    adswizz_preroll_companion_zonealias: '',
    adswizz_midroll_companion_zonealias: '',
  },
  amx: {
    amx_is_active: 0,
    amx_tag_id: ''
  },
  appnexus: {
    appnexus_is_active: 0,
    appnexus_placement_id: ''
  },
  appnexusbis: {
    appnexusbis_is_active: 0,
    appnexusbis_placement_id: ''
  },
  audienzz: {
    audienzz_is_active: 0,
    audienzz_tag_id: ''
  },
  bliink: {
    bliink_is_active: 0,
    bliink_tag_id: ''
  },
  colossus: {
    colossus_is_active: 0,
    colossus_tag_id: '',
    colossus_group_id: ''
  },
  connectad: {
    connectad_is_active: 0,
    connectad_site_id: '',
    connectad_network_id: ''
  },
  conversant: {
    conversant_is_active: 0,
    conversant_site_id: ''
  },
  criteo: {
    criteo_is_active: 0,
    criteo_network_id: '',
    criteo_zone_id: ''
  },
  cwire: {
    cwire_is_active: 0,
    cwire_page_id: '',
    cwire_placement_id: ''
  },
  datablocks: {
    datablocks_is_active: 0,
    datablocks_source_id: '',
  },
  dax: {
    dax_is_active: 0,
    dax_cid: ''
  },
  definemedia: {
    definemedia_is_active: 0,
    definemedia_mandant_id: '',
    definemedia_adslot_id: ''
  },
  eplanning: {
    eplanning_is_active: 0,
    eplanning_cid: '',
    eplanning_endpoint_code: ''
  },
  evolution: {
    evolution_is_active: 0,
    evolution_key: '',
  },
  firstid: {
    firstid_is_active: 0,
    firstid_tag_id: '',
  },
  freewheel: {
    freewheel_is_active: 0,
    freewheel_zone_id: '',
  },
  fueldigital: {
    fueldigital_is_active: 0,
    fueldigital_site_id: '',
    fueldigital_page_id: '',
    fueldigital_format_id: '',
  },
  fueldigitalix: {
    fueldigitalix_is_active: 0,
    fueldigitalix_site_id: '',
  },
  fueldigitalmedia: {
    fueldigitalmedia_is_active: 0,
    fueldigitalmedia_tag_id: '',
  },
  galaxiemedia: {
    galaxiemedia_is_active: 0,
    galaxiemedia_tag_id: ''
  },
  gingerad: {
    gingerad_is_active: 0,
    gingerad_tag_id: ''
  },
  goldbach: {
    goldbach_is_active: 0,
    goldbach_placement_id: ''
  },
  goodad: {
    goodad_is_active: 0,
    goodad_member: '',
    goodad_invcode: ''
  },
  gravity: {
    gravity_is_active: 0,
    gravity_placement_id: ''
  },
  groupm: {
    groupm_is_active: 0,
    groupm_placement_id: ''
  },
  gumgum: {
    gumgum_is_active: 0,
    gumgum_zone: '',
    gumgum_pub_id: '',
    gumgum_slot: '',
    gumgum_product: ''
  },
  improve: {
    improve_is_active: 0,
    improve_placement_id: '',
    improve_publisher_id: ''
  },
  inmobi: {
    inmobi_is_active: 0,
    inmobi_plc: '',
  },
  /*invibes: {
    invibes_is_active: 0,
    invibes_placement_id: ''
  },*/
  ix: {
    ix_is_active: 0,
    ix_site_id: ''
  },
  /*mediasquare: {
    mediasquare_is_active: 0,
    mediasquare_zone: '',
    mediasquare_code: ''
  },*/
  medianet: {
    medianet_is_active: 0,
    medianet_cid: '',
    medianet_crid: '',
  },
  moneytag: {
    moneytag_is_active: 0,
    moneytag_placement_id: ''
  },
  netpoint: {
    netpoint_is_active: 0,
    netpoint_tag_id: ''
  },
  nextmillennium: {
    nextmillennium_is_active: 0,
    nextmillennium_placement_id: '',
    nextmillennium_group_id: ''
  },
  nexx360: {
    nexx360_is_active: 0,
    nexx360_tag_id: '',
  },
  onetag: {
    onetag_is_active: 0,
    onetag_pub_id: '',
    onetag_ext: ''
  },
  openx: {
    openx_is_active: 0,
    openx_del_domain: '',
    openx_unit: ''
  },
  orbidder: {
    orbidder_is_active: 0,
    orbidder_account_id: '',
    orbidder_placement_id: '',
  },
  outbrain: {
    outbrain_is_active: 0,
    outbrain_publisher_id: '',
    outbrain_tag_id: ''
  },
  plista: {
    plista_is_active: 0,
    plista_placement_id: ''
  },
  prisma: {
    prisma_is_active: 0,
    prisma_tag_id: ''
  },
  pubmatic: {
    pubmatic_is_active: 0,
    pubmatic_publisher_id: '',
    pubmatic_placement_id: '',
    pubmatic_ad_slot: ''
  },
  pubmaticbis: {
    pubmaticbis_is_active: 0,
    pubmaticbis_publisher_id: '',
    pubmaticbis_placement_id: '',
    pubmaticbis_ad_slot: ''
  },
  pulsepoint: {
    pulsepoint_is_active: 0,
    pulsepoint_cp: '',
    pulsepoint_ct: '',
  },
  quantum: {
    quantum_is_active: 0,
    quantum_placement_id: ''
  },
  quantumimprove: {
    quantumimprove_is_active: 0,
    quantumimprove_placement_id: '',
  },
  revenuemaker: {
    revenuemaker_is_active: 0,
    revenuemaker_tag_id: ''
  },
  richaudience: {
    richaudience_is_active: 0,
    richaudience_pid: ''
  },
  rise: {
    rise_is_active: 0,
    rise_org: '',
    rise_placement_id: ''
  },
  rtbhouse: {
    rtbhouse_is_active: 0,
    rtbhouse_endpoint: '',
  },
  rubicon: {
    rubicon_is_active: 0,
    rubicon_account_id: '',
    rubicon_site_id: '',
    rubicon_zone_id: '',
    rubicon_position: ''
  },
  rubiconbis: {
    rubiconbis_is_active: 0,
    rubiconbis_account_id: '',
    rubiconbis_site_id: '',
    rubiconbis_zone_id: '',
    rubiconbis_position: ''
  },
  sharethrough: {
    sharethrough_is_active: 0,
    sharethrough_pkey: ''
  },
  smaato: {
    smaato_is_active: 0,
    smaato_publisher_id: '',
    smaato_adspace_id: ''
  },
  smartadserver: {
    smartadserver_is_active: 0,
    smartadserver_network_id: '',
    smartadserver_site_id: '',
    smartadserver_page_id: '',
    smartadserver_format_id: ''
  },
  smartadserverbis: {
    smartadserverbis_is_active: 0,
    smartadserverbis_network_id: '',
    smartadserverbis_site_id: '',
    smartadserverbis_page_id: '',
    smartadserverbis_format_id: ''
  },
  smartclip: {
    smartclip_is_active: 0,
    smartclip_s: '',
    smartclip_sz: ''
  },
  smartyads: {
    smartyads_is_active: 0
  },
  smilewanted: {
    smilewanted_is_active: 0,
    smilewanted_zone_id: '',
    smilewanted_position_type: ''
  },
  sovrn: {
    sovrn_is_active: 0,
    sovrn_tag_id: ''
  },
  staila: {
    staila_is_active: 0,
    staila_placement_id: ''
  },
  tappx: {
    tappx_is_active: 0,
    tappx_key: '',
    tappx_host: ''
  },
  targetspot: {
    targetspot_is_active: 0,
    targetspot_publisher_id: ''
  },
  mediagrid: {
    mediagrid_is_active: 0,
    mediagrid_uuid: '',
  },
  ttd: {
    ttd_is_active: 0,
    ttd_endpoint: ''
  },
  traffective: {
    traffective_is_active: 0,
    _placement_id: ''
  },
  triplelift: {
    triplelift_is_active: 0,
    triplelift_inventory_code: ''
  },
  triton: {
    triton_is_active: 0,
    triton_stid: ''
  },
  unruly: {
    unruly_is_active: 0,
    unruly_site_id: ''
  },
  verizon: {
    verizon_is_active: 0,
    verizon_dcn: '',
    verizon_pos: ''
  },
  vidoomy: {
    vidoomy_is_active: 0,
    vidoomy_zone_id: '',
  },
  visx: {
    visx_is_active: 0,
    visx_uid: '',
  },
  yieldlab: {
    yieldlab_is_active: 0,
    yieldlab_adslot_id: '',
    yieldlab_supply_id: ''
  },
  test: {
    test_is_active: 0
  },
}

export const formatStoredRequest = (sspDetails) => {
  const output = {
    id: '',
    name: '',
    sizes: [],
    is_archived: false,
    type: [],
    site_id: '',
    pricefloor_is_active: false,
    pricefloor_cpm: '',
    goodad_is_active: 0,
    goodad_member: '',
    goodad_invcode: '',
    thirtythreeacross_is_active: 0,
    thirtythreeacross_site_id: '',
    thirtythreeacross_product_id: '',
    adbility_is_active: 0,
    adbility_zone_id: '',
    adbility_script: '',
    adform_is_active: 0,
    adform_mid: '',
    adnuntius_is_active: 0,
    adnuntius_au_id: '',
    adot_is_active: 0,
    adot_publisher_id: '',
    adot_placement_id: '',
    adswizz_is_active: 0,
    adswizz_url_prefix: '',
    adswizz_preroll_zonealias: '',
    adswizz_midroll_zonealias: '',
    adswizz_preroll_companion_zonealias: '',
    adswizz_midroll_companion_zonealias: '',
    adyoulike_is_active: 0,
    adyoulike_placement: '',
    amx_is_active: 0,
    amx_tag_id: '',
    quantum_is_active: 0,
    quantum_placement_id: '',
    revenuemaker_is_active: 0,
    revenuemaker_tag_id: '',
    richaudience_is_active: 0,
    richaudience_pid: '',
    rise_is_active: 0,
    rise_org: '',
    rtbhouse_is_active: 0,
    rtbhouse_endpoint: '',
    appnexus_is_active: 0,
    appnexus_placement_id: '',
    appnexusbis_is_active: 0,
    appnexusbis_placement_id: '',
    audienzz_is_active: 0,
    audienzz_tag_id: '',
    criteo_is_active: 0,
    criteo_network_id: '',
    criteo_zone_id: '',
    colossus_is_active: 0,
    colossus_tag_id: '',
    colossus_group_id: '',
    connectad_is_active: 0,
    connectad_site_id: '',
    connectad_network_id: '',
    conversant_is_active: 0,
    conversant_site_id: '',
    bliink_is_active: 0,
    bliink_tag_id: '',
    cwire_is_active: 0,
    cwire_page_id: '',
    cwire_placement_id: '',
    datablocks_is_active: 0,
    datablocks_source_id: '',
    dax_is_active: 0,
    dax_cid: '',
    definemedia_is_active: 0,
    definemedia_mandant_id: '',
    definemedia_adslot_id: '',
    eplanning_is_active: 0,
    eplanning_cid: '',
    eplanning_endpoint_code: '',
    evolution_is_active: 0,
    evolution_key: '',
    firstid_is_active: 0,
    firstid_tag_id: '',
    freewheel_is_active: 0,
    freewheel_zone_id: '',
    fueldigital_is_active: 0,
    fueldigital_site_id: '',
    fueldigital_page_id: '',
    fueldigital_format_id: '',
    fueldigitalix_is_active: 0,
    fueldigitalix_site_id: '',
    fueldigitalmedia_is_active: 0,
    fueldigitalmedia_tag_id: '',
    ix_is_active: 0,
    ix_site_id: '',
    // ix_endpoint: '',
    sharethrough_is_active: 0,
    sharethrough_pkey: '',
    smaato_is_active: 0,
    smaato_publisher_id: '',
    smaato_adspace_id: '',
    smartadserver_is_active: 0,
    smartadserver_network_id: '',
    smartadserver_site_id: '',
    smartadserver_page_id: '',
    smartadserver_format_id: '',
    smartadserverbis_is_active: 0,
    smartadserverbis_network_id: '',
    smartadserverbis_site_id: '',
    smartadserverbis_page_id: '',
    smartadserverbis_format_id: '',
    smartclip_is_active: 0,
    smartclip_s: '',
    smartclip_sz: '',
    smartyads_is_active: 0,
    rubicon_is_active: 0,
    rubicon_account_id: '',
    rubicon_site_id: '',
    rubicon_zone_id: '',
    rubicon_position: '',
    rubiconbis_is_active: 0,
    rubiconbis_account_id: '',
    rubiconbis_site_id: '',
    rubiconbis_zone_id: '',
    rubiconbis_position: '',
    onetag_is_active: 0,
    onetag_pub_id: '',
    onetag_ext: '',
    openx_is_active: 0,
    openx_del_domain: '',
    openx_unit: '',
    orbidder_is_active: 0,
    orbidder_account_id: '',
    orbidder_placement_id: '',
    outbrain_is_active: 0,
    outbrain_publisher_id: '',
    outbrain_tag_id: '',
    plista_is_active: 0,
    plista_placement_id: '',
    prisma_is_active: 0,
    prisma_tag_id: '',
    galaxiemedia_is_active: 0,
    galaxiemedia_tag_id: '',
    gingerad_is_active: 0,
    gingerad_tag_id: '',
    goldbach_is_active: 0,
    goldbach_placement_id: '',
    gravity_is_active: 0,
    gravity_placement_id: '',
    groupm_is_active: 0,
    groupm_placement_id: '',
    gumgum_is_active: 0,
    gumgum_zone: '',
    gumgum_pub_id: '',
    gumgum_slot: '',
    gumgum_product: '',
    improve_is_active: 0,
    improve_placement_id: '',
    improve_publisher_id: '',
    inmobi_is_active: 0,
    inmobi_plc: '',
    quantumimprove_is_active: 0,
    quantumimprove_placement_id: '',
    invibes_is_active: 0,
    invibes_placement_id: '',
    pubmatic_is_active: 0,
    pubmatic_publisher_id: '',
    pubmatic_placement_id: '',
    pubmatic_ad_slot: '',
    pubmaticbis_is_active: 0,
    pubmaticbis_publisher_id: '',
    pubmaticbis_placement_id: '',
    pubmaticbis_ad_slot: '',
    pulsepoint_is_active: 0,
    pulsepoint_cp: '',
    pulsepoint_ct: '',
    verizon_is_active: 0,
    verizon_dcn: '',
    verizon_pos: '',
    visx_is_active: 0,
    visx_uid: '',
    vidoomy_is_active: 0,
    vidoomy_zone_id: '',
    triplelift_is_active: 0,
    triplelift_inventory_code: '',
    triton_is_active: 0,
    triton_stid: '',
    unruly_is_active: 0,
    unruly_site_id: '',
    test_is_active: 0,
    ttd_is_active: 0,
    ttd_endpoint: '',
    traffective_is_active: 0,
    traffective_placement_id: '',
    smilewanted_is_active: 0,
    smilewanted_zone_id: '',
    smilewanted_position_type: '',
    targetspot_is_active: 0,
    targetspot_publisher_id: '',
    sovrn_is_active: 0,
    sovrn_tag_id: '',
    staila_is_active: 0,
    staila_placement_id: '',
    tappx_is_active: 0,
    tappx_key: '',
    tappx_host: '',
    mediasquare_is_active: 0,
    mediasquare_zone: '',
    mediasquare_code: '',
    mediagrid_is_active: 0,
    mediagrid_uuid: '',
    medianet_is_active: 0,
    medianet_cid: '',
    medianet_crid: '',
    moneytag_is_active: 0,
    moneytag_placement_id: '',
    netpoint_is_active: 0,
    netpoint_tag_id: '',
    nextmillennium_is_active: 0,
    nextmillennium_placement_id: '',
    nextmillennium_group_id: '',
    nexx360_is_active: 0,
    nexx360_tag_id: '',
    yieldlab_is_active: 0,
    yieldlab_adslot_id: '',
    yieldlab_supply_id: ''

  }
  Object.keys(sspDetails).forEach((ssp) => {
    Object.assign(output, sspDetails[ssp])
  })
  return output
}

export const formatSspDetails = (sspList, storedRequest) => {
  const output = {}
  sspList.forEach((ssp) => {
    output[ssp] = {}
    Object.keys(storedRequest).forEach((key) => {
      if (key.substr(0, ssp.length) == ssp) {
        output[ssp][key] = storedRequest[key]
      }
    })
  })
  return output
}

const getSspList = (sspDetails) => {
  const output = []
  Object.keys(sspDetails).forEach((ssp) => output.push(ssp))
  return output
}

export const sampleStoredRequest = formatStoredRequest(sspDetails)
export const sspList = getSspList(sspDetails)
