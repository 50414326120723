import {
  GetSelectedOrder,
  SaveSelectedOrder,
  UpdateSelectedOrder
} from '@/store/action_types'

import {
  SetSelectedOrder,
  ClearSelectedOrder
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedOrderState = {
  selectedOrder: {}
}

export const selectedOrderGetters = {
  selectedOrder: (state) => state.selectedOrder
}

export const selectedOrderActions = {
  async [GetSelectedOrder]({ commit }, orderId) {
    const response = await axios.get('/api/dsporder/' + orderId)
    commit(SetSelectedOrder, response.data)
    return response
  },
  async [SaveSelectedOrder]({ dispatch }, order) {
    if (order.id) {
      return dispatch(UpdateSelectedOrder, order)
    }
    const response = await axios.post('/api/dsporder', order)
    return response.data
  },

  async [UpdateSelectedOrder]({ commit }, order) {
    const response = await axios.put(`/api/dsporder/${order.id}`, order)
    return response
  }
}

export const selectedOrderMutations = {
  [SetSelectedOrder](state, order) {
    state.selectedOrder = order
  },
  [ClearSelectedOrder](state) {
    state.selectedOrder = {}
  }
}
