<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    @click:outside="dialog = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        style="margin: 10px; right: 0px"
        class="white--text notcapital"
        color="black"
        v-bind="attrs"
        v-on="on"
        width="200"
        dense
      >
        Add a note
        <v-icon right> mdi-note-plus-outline </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-col
        cols="12"
        style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
      >
        <v-icon color="black" @click="dialog = false">mdi-close</v-icon>
      </v-col>
      <v-card-title>
        <span class="text-h5">Add a note</span>
      </v-card-title>
      <v-card-text style="padding-bottom: 0px">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="note.content"
                outlined
                name="input-7-4"
                label="Content"
                value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-row v-if="noteAdded" style="margin-top: 30px">
        <v-col>
          <v-alert dense text type="success" color="green">
            Note added successfully</v-alert
          >
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="submitLoading"
          :disabled="note.content.length == 0"
          style="margin: 10px"
          class="white--text notcapital"
          color="green"
          width="150"
          dense
          @click="submit"
        >
          Send note
          <v-icon right> mdi-email-fast-outline </v-icon>
        </v-btn>
        <v-btn
          style="margin: 10px"
          class="white--text notcapital"
          color="red"
          width="120"
          dense
          @click="cancel"
        >
          Cancel
          <v-icon right> mdi-close-circle-outline </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['item'],
    data() {
      return {
        dialog: false,
        pageName: '',
        note: {
          content: '',
          proposal_id: this.item.id,
          proposal_id_api: this.item.proposal_id,
          revision_number: this.item.revision_number,
          created_by: 'Seller'
        },
        noteAdded: false,
        submitLoading: false
      }
    },
    methods: {
      cancel() {
        this.note.content = ''
        this.dialog = false
      },
      async submit() {
        this.submitLoading = true
        setTimeout(() => (this.noteAdded = true), 1000)
        this.$emit('noteAdded', this.note)
        setTimeout(() => (this.submitLoading = false), 2000)
        setTimeout(() => (this.noteAdded = false), 2000)
        setTimeout(() => (this.dialog = false), 2000)
      }
    }
  }
</script>
