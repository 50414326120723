module.exports = [
    {
        id: '0',
        label: '0 - Unknown'
    },
    {
        id: '1',
        label: '1 - Above the Fold'
    },
    {
        id: '2',
        label: '2 - DEPRECATED - May or may not be initially visible depending on screen size/resolution.'
    },
    {
        id: '3',
        label: '3 - Below the Fold'
    },
    {
        id: '4',
        label: '4 - Header'
    },
    {
        id: '5',
        label: '5 - Footer'
    },
    {
        id: '6',
        label: '6 - Sidebar'
    },
    {
        id: '7',
        label: '7 - Full Screen'
    }
]
