<template>
  <v-container fluid style="background-color: #f9f9fb">
    <v-row>
      <v-col>
        <h1 class="cnx">
          <strong> {{ $t('sites management') }}</strong>
        </h1>
        <p style="padding-left: 30px; font-size: 14px">
          {{ $t('cookieless') }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-row>
          <siteMenu
            @selectedSiteChanged="CheckOptionsCookieless"
            @showDialog="ShowEdit"
          />
        </v-row>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="3" align="right" justify="right">
        <v-btn
          v-if="optionsExists == 'empty'"
          class="black--text notcapital"
          color="white"
          dense
          @click="DefineOptions"
        >
          <v-icon left> mdi-tune </v-icon>
          {{ $t('define options') }}
        </v-btn>
      </v-col>
      <v-col cols="2" align="left" justify="left">
        <v-btn
          style="border-color: black"
          class="white--text notcapital"
          color="black"
          dense
        >
          {{ $t('generate snippet') }}
        </v-btn>
      </v-col>
    </v-row>
    <contentCookie
      :parent-exists="optionsExists"
      @defineOptions="DefineOptions"
      @update="DefineOptionsUpdate"
    />
  </v-container>
</template>

<script>
  import { GetSites, GetSelectedOptionsCookieless } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import {
    SetSelectedSite,
    SetSelectedStoredRequest
  } from '@/store/mutation_types'
  import siteMenu from '@/pages/management/site/menuSite.vue'
  import contentCookie from './content.vue'

  export default {
    name: 'Cookieless',
    components: { siteMenu, contentCookie },
    data() {
      return {
        actionType: '',
        search: '',
        loading: false,
        show_tag: false,
        tag_content: '',
        optionsExists: 'empty',
        siteEditDialog: false,
        rtcConfigDialog: false,
        codeGenerated: ''
      }
    },
    computed: {
      ...mapGetters(['sites', 'selectedSite', 'selectedOptionsCookieless'])
    },
    created() {},
    methods: {
      ...mapActions({
        GetSites,
        GetSelectedOptionsCookieless
      }),
      ...mapMutations([SetSelectedSite, SetSelectedStoredRequest]),
      ShowEdit(actionType) {
        this.actionType = actionType
        this.siteEditDialog = true
      },
      async ConfirmEdit() {
        await this.SaveSelectedSite(this.site)
        if (this.actionType == 'update') {
          await this.SetSelectedSite(this.site)
          await this.GetSites(this.appCustomer.id)
        }
      },
      DefineOptions() {
        this.$router.push({
          name: 'optionscookieless_new',
          params: {
            site_id: this.selectedSite.id
          }
        })
      },
      DefineOptionsUpdate() {
        this.$router.push({
          name: 'optionscookieless_edit',
          params: {
            site_id: this.selectedSite.id,
            oc_id: this.selectedOptionsCookieless[0].id
          }
        })
      },
      async CheckOptionsCookieless(selectedSite) {
        const opt = await this.GetSelectedOptionsCookieless(selectedSite.id)
        if (opt.length > 0) {
          this.optionsExists = 'defined'
        } else this.optionsExists = 'empty'
      }
    }
  }
</script>

<style scoped>
  .cnx {
    letter-spacing: 2px;
    font-size: 30px;
    padding-left: 10px;
  }

  .combobox {
    padding-left: 50px;
    width: 370px;
  }
  .notcapital {
    text-transform: none !important;
  }
  .parameters {
    background-color: black;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
</style>
