<template>
  <v-card style="margin-left: 10px">
    <v-overlay :value="loading" :absolute="true" color="#fff" :opacity="1">
      <v-progress-circular indeterminate :value="60" color="blue-grey">
      </v-progress-circular>
    </v-overlay>
    <v-divider></v-divider>
    <v-form ref="form" lazy-validation>
      <v-tabs v-model="selectedIndex" background-color="#374789" dark>
        <v-tab @click="goTo('general')"> Set up </v-tab>
        <v-tab-item>
          <v-card-title
            primary-title
            style="background-color: #f9f9fb; padding-bottom: 0px"
            ><v-container>
              <v-row>
                <v-col>
                  <h3 class="cnx">
                    <strong> {{ siteName }}</strong>
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text style="padding-left: 50px">
            <v-row style="padding-top: 10px">
              <v-col cols="4">
                <p style="margin-bottom: 5px">Name of the site</p>
                <v-text-field v-model="site.name" :rules="required" dense solo>
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider />
            <v-row no-gutters dense style="padding-top: 20px">
              <v-col cols="3">
                <p style="margin-bottom: 5px">Bid reduction</p>
                <v-text-field
                  v-model="site.cpm_adjustment"
                  :rules="floatRule"
                  dense
                  solo
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider />
            <table style="width: 100%; padding-top: 30px">
              <tr>
                <td rowspan="2">
                  <v-switch
                    v-model="site.schain_is_active"
                    label="Is Schain active"
                  ></v-switch>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <p style="margin-bottom: 5px">Seller ID (sid)</p>
                  <v-text-field
                    v-model="site.schain_sid"
                    :disabled="!site.schain_is_active"
                    style="width: 220px"
                    dense
                    solo
                  ></v-text-field>
                </td>
                <td colspan="3">
                  <p style="margin-bottom: 5px">
                    Advertising system domain (asi)
                  </p>
                  <v-text-field
                    v-model="site.schain_asi"
                    :disabled="!site.schain_is_active"
                    :rules="site.schain_is_active ? domainRule : []"
                    style="width: 220px"
                    dense
                    solo
                  ></v-text-field>
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              style="margin-left: 10px"
              class="white--text notcapital"
              color="red"
              width="180"
              dense
              @click="back"
            >
              <v-icon left> mdi-cancel </v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="180"
              dense
              @click="submit"
            >
              <v-icon right> mdi-content-save-outline </v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab @click="goTo('alias')"> Schain info for SSPs using Alias </v-tab>
        <v-tab-item>
          <v-card-title
            primary-title
            style="background-color: #f9f9fb; padding-bottom: 0px"
            ><v-container>
              <v-row>
                <v-col>
                  <h3 class="cnx">
                    <strong> {{ siteName }}</strong>
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text style="padding-left: 50px">
            <template v-for="(value, key) in schainList">
              <v-container :key="key">
                <v-row style="width: 100%">
                  <v-col
                    cols="3"
                    style="
                      margin-top: auto;
                      margin-bottom: auto;
                      font-size: 16px;
                    "
                  >
                    <p>{{ $t(key) }}</p>
                  </v-col>
                  <v-col cols="3">
                    <p style="margin-bottom: 5px">Seller ID (sid)</p>
                    <v-text-field
                      v-model="schainList[key].value"
                      style="width: 220px"
                      dense
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container> </template
          ></v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              style="margin-left: 10px"
              class="white--text notcapital"
              color="red"
              width="180"
              dense
              @click="back"
            >
              <v-icon left> mdi-cancel </v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="180"
              dense
              @click="submit"
            >
              <v-icon right> mdi-content-save-outline </v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab @click="goTo('blocklist')"> Blocklist </v-tab>
        <v-tab-item>
          <v-card-title
            primary-title
            style="background-color: #f9f9fb; padding-bottom: 0px"
            ><v-container>
              <v-row>
                <v-col>
                  <h3 class="cnx">
                    <strong> {{ siteName }}</strong>
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text style="padding-left: 50px">
            <v-row style="margin-top: 20px; margin-bottom: 20px">
              <v-col cols="3">
                <v-switch
                  v-model="iab_is_active"
                  label="IAB Category"
                ></v-switch>
              </v-col>
              <v-col
                cols="7"
                v-if="iab_is_active"
                style="padding-top: 0px; padding-bottom: 0px"
              >
                <v-autocomplete
                  v-model="selectedIAB"
                  :items="iab_list"
                  clearable
                  item-text="label"
                  item-value="value"
                  label="Choose categories"
                  multiple
                  dense
                  outlined
                  style="margin-top: 20px"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggleIAB">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedIAB.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ iconIAB }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-divider inset></v-divider>
            <v-row style="margin-top: 20px; margin-bottom: 20px">
              <v-col cols="3">
                <v-switch
                  v-model="ad_domain_is_active"
                  label="Advertiser domain"
                ></v-switch>
              </v-col>
              <v-col
                cols="7"
                v-if="ad_domain_is_active"
                style="padding-top: 0px; padding-bottom: 0px"
              >
                <v-textarea
                  v-model="selectedDomainsBlocklist"
                  ref="domain"
                  name="input-7-4"
                  placeholder="Select your domain's URLs"
                  rows="10"
                  outlined
                  shaped
                  :rules="domainRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider inset></v-divider>
            <v-row v-if="geotargeting_is_active"> </v-row>
            <v-row>
              <v-col cols="3" style="margin-top: 20px; margin-bottom: 20px">
                <v-switch
                  v-model="geotargeting_is_active"
                  label="GeoTargeting"
                ></v-switch>
              </v-col>
              <v-col
                cols="7"
                v-if="geotargeting_is_active"
                style="margin-top: 30px"
              >
                <v-autocomplete
                  v-model="selectedCountriesBlocklist"
                  :items="countriesShown"
                  item-text="name"
                  item-value="code"
                  label="Choose countries"
                  clearable
                  multiple
                  dense
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleCountries"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedCountriesBlocklist.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ iconCountries }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
              <div v-if="geotargeting_is_active" style="margin-top: 43px">
                <div class="post" id="fact">
                  <p
                    class="include_targeting"
                    align="right"
                    justify-end
                    style="margin-top: 7px"
                  >
                    Include
                  </p>
                </div>

                <div class="post" id="sortbar">
                  <v-switch
                    v-model="exclude_geotargeting"
                    label="Exclude"
                    style="padding: 0px; margin-left: 5px; margin-top: 5px"
                  ></v-switch>
                </div>
              </div>
            </v-row>
            <v-divider v-if="$store.getters.is_superadmin" inset></v-divider>
            <v-row
              v-if="$store.getters.is_superadmin"
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <v-col cols="3">
                <v-switch
                  v-model="languagetargeting_is_active"
                  label="Authorized language"
                ></v-switch>
              </v-col>
              <v-col
                cols="7"
                v-if="languagetargeting_is_active"
                style="margin-top: 20px; padding-top: 0px; padding-bottom: 0px"
              >
                <v-autocomplete
                  v-model="selectedLanguagesBlocklist"
                  :items="computedLanguages"
                  item-text="code"
                  item-value="name"
                  label="Choose languages"
                  clearable
                  multiple
                  dense
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleLanguages"
                    >
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedLanguagesBlocklist.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ iconLanguages }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              style="margin-left: 10px"
              class="white--text notcapital"
              color="red"
              width="180"
              dense
              @click="back"
            >
              <v-icon left> mdi-cancel </v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="180"
              dense
              @click="submit"
            >
              <v-icon right> mdi-content-save-outline </v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab
          v-if="selectedCustomer.stack_is_active == '1'"
          @click="goTo('stack')"
        >
          Direct call (Stack Nexx360)
        </v-tab>
        <v-tab-item v-if="selectedCustomer.stack_is_active == '1'">
          <v-card-title
            primary-title
            style="background-color: #f9f9fb; padding-bottom: 0px"
            ><v-container>
              <v-row>
                <v-col>
                  <h3 class="cnx">
                    <strong> {{ siteName }}</strong>
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-card-text style="padding-left: 50px">
            <table style="width: 100%; padding-top: 30px">
              <tr>
                <td rowspan="2">
                  <v-switch
                    v-model="site.refresh_is_active"
                    label="Is Refresh Active"
                  ></v-switch>
                </td>
                <td width="10px">
                  <p
                    style="
                      font-size: 15px;
                      color: rgba(0, 0, 0, 0.6);
                      font-family: Roboto;
                      margin: 0px;
                      padding-right: 10px;
                      padding-bottom: 8px;
                    "
                    align="right"
                    justify-end
                  >
                    Exclude
                  </p>
                </td>
                <td>
                  <v-switch
                    v-model="selection_type"
                    label="Include"
                    style="padding: 0px"
                    :disabled="!site.refresh_is_active"
                  ></v-switch>
                </td>
                <td colspan="3">
                  <p style="margin-bottom: 5px">Orders IDs</p>
                  <v-text-field
                    v-model="site.orders_ids"
                    :rules="
                      site.refresh_is_active && site.orders_ids
                        ? arrayRules
                        : []
                    "
                    :disabled="!site.refresh_is_active"
                    dense
                    solo
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <p style="margin-bottom: 5px">Time interval</p>
                  <v-text-field
                    v-model="site.refresh_time"
                    :rules="site.refresh_is_active ? integerRequiredRule : []"
                    :disabled="!site.refresh_is_active"
                    type="o"
                    min="0"
                    suffix="seconds"
                    style="width: 200px"
                    value="30"
                    dense
                    solo
                  ></v-text-field>
                </td>
                <td colspan="3">
                  <p style="margin-bottom: 5px">
                    Maximum repetitions (Optional)
                  </p>
                  <v-text-field
                    v-model="site.nb_repetition"
                    :disabled="!site.refresh_is_active"
                    style="width: 220px"
                    type="number"
                    min="0"
                    dense
                    solo
                  ></v-text-field>
                </td>
              </tr>
            </table>
            <v-divider />
            <table style="width: 100%; margin-top: 15px">
              <tr>
                <td>
                  <v-switch
                    v-model="site.noconsent_traffic_blocking"
                    label="No consent traffic blocking"
                  ></v-switch>
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn
              style="margin-left: 10px"
              class="white--text notcapital"
              color="red"
              width="180"
              dense
              @click="back"
            >
              <v-icon left> mdi-cancel </v-icon>
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              style="margin: 10px"
              class="white--text notcapital"
              color="black"
              width="180"
              dense
              @click="submit"
            >
              <v-icon right> mdi-content-save-outline </v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ messageAction }}
    </v-snackbar>
    <v-snackbar v-model="actionfail" color="red" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-alert-circle</v-icon>
      {{ messageAction }}
    </v-snackbar>
  </v-card>
</template>

<script>
  const iab = require('@/assets/styles/iab.js')
  const countries = require('@/assets/styles/countries.js')
  const languages = require('@/assets/styles/languages.js')

  import axios from 'axios'
  import { SaveSelectedSite } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import { SetSelectedSite, SetSelectedCustomer } from '@/store/mutation_types'
  export default {
    data() {
      return {
        loading: false,
        siteName: 'Creating a new website',
        nameRules: [(v) => !!v || 'Name is required'],
        integerRequiredRule: [
          (v) => /^\d+$/.test(v) || 'Required (Only numbers allowed)'
        ],
        sidRule: [
          (v) =>
            /^[a-zA-Z 0-9\-]*$/.test(v) ||
            'Special characters are not allowed (except - )'
        ],
        integerRule: [(v) => /^\d+$/.test(v) || 'Only numbers allowed'],
        arrayRules: [
          (v) => (v && this.isJsonString(v)) || 'Needs to be an array / object'
        ],
        submitLoading: false,
        //items: ['Desktop', 'Tablet', 'Mobile', 'Video'],
        tab: null,
        site: {
          cpm_adjustment: 1
        },
        actionsuccess: false,
        actionfail: false,
        timeout: 2000,
        messageAction: '',
        required: [(v) => !!v || 'Required'],
        floatRule: [
          (v) => !!v || 'Required',
          (v) => /^\d+((\.\d+)|(\,\d+))?$/.test(v) || 'Decimal only',
          (v) => (v && this.comma(v) <= 1) || 'Max value : 1',
          (v) => (v && Number(v) > 0) || 'Value must be above 0'
        ],
        nospecialRule: [
          (v) =>
            /^[a-zA-Z 0-9\_]*$/.test(v) ||
            'Special characters are not allowed (except _ )'
        ],
        selection_type: true,
        refresh_time: 30,
        iab_is_active: false,
        ad_domain_is_active: false,
        geotargeting_is_active: false,
        domainRule: [
          (v) => !!v || 'Required',
          (v) =>
            /^[-a-zA-Z0-9@:%._\+~#=]+\.[a-zA-Z]+?$/.test(v) ||
            'Domain must be in this format (domain.com)'
        ],
        domainRules: [
          (v) =>
            this.is_domain_valid || this.falseDomain + ' is not a valid domain'
        ],
        iabList: iab,
        selectedIAB: [],
        countryList: countries,
        languageList: languages,
        selectedIndex: 0,
        is_domain_valid: true,
        falseDomain: '',
        selectedDomainsBlocklist: '',
        exclude_geotargeting: false,
        selectedCountriesBlocklist: [],
        selectedLanguagesBlocklist: [],
        languagetargeting_is_active: false,

        schainList: {
          fueldigital: { is_active: false, value: '' },
          fueldigitalix: { is_active: false, value: '' },
          goodad: { is_active: false, value: '' },
          gravity: { is_active: false, value: '' },
          groupm: { is_active: false, value: '' },
          moneytag: { is_active: false, value: '' },
          plista: { is_active: false, value: '' },
          quantum: { is_active: false, value: '' },
          quantumimprove: { is_active: false, value: '' },
          staila: { is_active: false, value: '' },
          traffective: { is_active: false, value: '' }
        }
      }
    },
    computed: {
      ...mapGetters(['selectedSite', 'appCustomer', 'selectedCustomer']),
      selectAllIAB() {
        return this.selectedIAB.length === this.iab_list.length
      },
      selectSomeIAB() {
        return this.selectedIAB.length > 0 && !this.selectAllIAB
      },
      selectAllCountries() {
        return (
          this.selectedCountriesBlocklist.length === this.countriesShown.length
        )
      },
      selectSomeCountries() {
        return (
          this.selectedCountriesBlocklist.length > 0 && !this.selectAllCountries
        )
      },
      selectAllLanguages() {
        return (
          this.selectedLanguagesBlocklist.length === this.languageList.length
        )
      },
      selectSomeLanguages() {
        return (
          this.selectedLanguagesBlocklist.length > 0 && !this.selectAllLanguages
        )
      },
      countriesShown() {
        let arr = []
        this.countryList.forEach((element) => {
          arr.push({
            code: element.code,
            name: element.name + ' (' + element.code + ')'
          })
        })
        return arr
      },
      computedLanguages() {
        let arr = []
        this.languageList.forEach((element) => {
          arr.push({
            name: element.name,
            code: element.code + ' (' + element.name + ')'
          })
        })
        return arr
          .filter((el) => {
            return true
          })
          .sort((a, b) => a.code.localeCompare(b.code))
      },
      iconIAB() {
        if (this.selectAllIAB) return 'mdi-close-box'
        if (this.selectSomeIAB) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconCountries() {
        if (this.selectAllCountries) return 'mdi-close-box'
        if (this.selectSomeCountries) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iconLanguages() {
        if (this.selectAllLanguages) return 'mdi-close-box'
        if (this.selectSomeLanguages) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      iab_list() {
        var list = []
        this.iabList.forEach((element) => {
          list.push({
            label: element.code + ' - ' + element.category,
            value: element.code
          })
        })
        return list
      }
    },
    watch: {
      selectedDomainsBlocklist: async function (val, oldVal) {
        const str = val.split(/\r?\n/)
        var valid = true
        str.forEach((element) => {
          if (
            /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,16}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
              element
            ) == false
          ) {
            valid = false
            this.falseDomain = element
            return false
          }
        })

        this.is_domain_valid = valid
        if (this.$refs.domain != undefined) this.$refs.domain.validate()
      }
    },
    async created() {
      switch (this.$route.query.settings) {
        case 'general':
          this.selectedIndex = 0
          break
        case 'alias':
          this.selectedIndex = 1
          break
        case 'blocklist':
          this.selectedIndex = 2
          break
        case 'stack':
          this.selectedIndex = 3
          break
        default:
          this.selectedIndex = 0
      }

      if (this.$route.name == 'site_edit') {
        try {
          const siteURL = await axios.get(
            `/api/getsitebyid/${this.$route.params.site_id}`
          )
          this.SetSelectedSite(siteURL.data)
          const customerURL = await axios.get(
            `/api/getcustomerbyid/${this.$route.params.customer_id}`
          )
          this.SetSelectedCustomer(customerURL.data)
          this.site = this.selectedSite
          this.siteName = this.site.name
          this.getBlocklistData()
          var that = this
          Object.keys(this.schainList).forEach(function (key) {
            if (that.site[key + '_schain_id'] != '') {
              that.schainList[key].value = that.site[key + '_schain_id']
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    methods: {
      ...mapActions({
        SaveSelectedSite
      }),
      ...mapMutations([SetSelectedSite, SetSelectedCustomer]),
      comma(number) {
        return Number(number.toString().replace(',', '.'))
      },
      isJsonString(str) {
        try {
          JSON.parse(str)
        } catch (e) {
          return false
        }
        return true
      },
      async submit() {
        this.submitLoading = true
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          this.submitLoading = false
          return
        }
        this.organizeSiteItems()
        try {
          this.site.name = this.site.name.replace("'", ' ')
          const nameExists = await axios.get(
            `/api/sitenameexists/${this.site.name}/${this.site.customer_id}`
          )
          if (nameExists.data == false || this.$route.name == 'site_edit') {
            await this.manageBlocklist()
            await this.SaveSelectedSite(this.site)
            this.actionsuccess = true
            this.messageAction = 'Site updated successfully'
            setTimeout(
              () =>
                this.$router.push(
                  '/management/' + this.appCustomer.id + '/sites'
                ),
              1000
            )
            setTimeout(() => (this.submitLoading = false), 1500)
          } else if (
            nameExists.data == true &&
            this.$route.name != 'site_edit'
          ) {
            this.actionfail = true
            this.messageAction = 'Site name already exists.'
            this.submitLoading = false
          }
        } catch (error) {
          console.log(error)
          this.submitLoading = false
        }
      },
      back() {
        this.$router.back()
      },
      getBlocklistData() {
        if (this.site.iab_blocklist.length > 0) {
          this.iab_is_active = true
          this.selectedIAB = this.site.iab_blocklist
            .substring(1, this.site.iab_blocklist.length - 1)
            .replaceAll('"', '')
            .split(',')
        }
        if (this.site.language_blocklist != '') {
          this.languagetargeting_is_active = true
          this.selectedLanguagesBlocklist = this.site.language_blocklist
            .substring(1, this.site.language_blocklist.length - 1)
            .replaceAll('"', '')
            .split(',')
        }
        if (this.site.domain_blocklist.length > 0) {
          this.ad_domain_is_active = true
          let listDomains = this.site.domain_blocklist
            .substring(1, this.site.domain_blocklist.length - 1)
            .replaceAll('"', '')
            .split(',')
          listDomains.forEach((domain) => {
            this.selectedDomainsBlocklist += domain + '\n'
          })
          this.selectedDomainsBlocklist = this.selectedDomainsBlocklist.slice(
            0,
            -1
          )
        }
        if (this.site.geotargeting_blacklist.length > 0) {
          this.geotargeting_is_active = true
          this.exclude_geotargeting = true
          this.selectedCountriesBlocklist = this.site.geotargeting_blacklist
            .substring(1, this.site.geotargeting_blacklist.length - 1)
            .replaceAll('"', '')
            .split(',')
        }
        if (this.site.geotargeting_whitelist.length > 0) {
          this.geotargeting_is_active = true
          this.exclude_geotargeting = false
          this.selectedCountriesBlocklist = this.site.geotargeting_whitelist
            .substring(1, this.site.geotargeting_whitelist.length - 1)
            .replaceAll('"', '')
            .split(',')
        }
      },
      manageBlocklist() {
        //Manage IAB
        if (this.iab_is_active == true && this.selectedIAB.length > 0) {
          let str = '['
          this.selectedIAB.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.site.iab_blocklist = str
        } else {
          if (this.site.iab_blocklist != undefined)
            this.site.iab_blocklist = null
        }
        //Advertiser domains
        if (
          this.ad_domain_is_active == true &&
          this.selectedDomainsBlocklist.length > 0
        ) {
          let arrDomains = this.selectedDomainsBlocklist.split(/\r?\n/)
          let str = '['
          arrDomains.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.site.domain_blocklist = str
        } else {
          if (this.site.domain_blocklist != undefined)
            this.site.domain_blocklist = null
        }
        //GeoTargeting
        if (
          this.geotargeting_is_active == true &&
          this.selectedCountriesBlocklist.length > 0
        ) {
          let str = '['
          this.selectedCountriesBlocklist.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          if (this.exclude_geotargeting) {
            this.site.geotargeting_blacklist = str
            this.site.geotargeting_whitelist = ''
          } else {
            this.site.geotargeting_whitelist = str
            this.site.geotargeting_blacklist = ''
          }
        } else {
          if (this.site.geotargeting_blacklist != undefined)
            this.site.geotargeting_blacklist = null
          if (this.site.geotargeting_whitelist != undefined)
            this.site.geotargeting_whitelist = null
        }
        //Languages
        if (
          this.languagetargeting_is_active == true &&
          this.selectedLanguagesBlocklist.length > 0
        ) {
          let str = '['
          this.selectedLanguagesBlocklist.forEach((item) => {
            str += '"' + item + '",'
          })
          str = str.slice(0, -1)
          str += ']'
          this.site.language_blocklist = str
        } else {
          if (this.site.language_blocklist != undefined)
            this.site.language_blocklist = null
        }
      },

      organizeSiteItems() {
        this.site.customer_id = this.$route.params.customer_id
        if (this.site.refresh_is_active == false) {
          this.site.selection_type = 'include'
          this.site.orders_ids = ''
          this.site.refresh_time = ''
          this.site.nb_repetition = ''
        }
        /* if (this.site.schain_is_active == false) {
          this.site.schain_asi = ''
          this.site.schain_sid = ''
        }*/
        if (this.selection_type == true) this.site.selection_type = 'include'
        else this.site.selection_type = 'exclude'

        this.site.cpm_adjustment = Number(
          this.site.cpm_adjustment.toString().replace(',', '.')
        )

        //SCHAI  ID SSPS
        var that = this
        Object.keys(this.schainList).forEach(function (key) {
          that.site[key + '_schain_id'] = that.schainList[key].value
        })
      },
      newStoredRequest() {
        if (this.site.id !== undefined) {
          this.$router.push({
            name: 'stored_request_new',
            params: {
              site_id: this.site.id,
              stored_request_id: 'new'
            }
          })
        }
      },
      goTo(view) {
        if (this.$route.fullPath != this.$route.path + '?settings=' + view)
          this.$router.push(this.$route.path + '?settings=' + view)
      },
      toggleIAB() {
        this.$nextTick(() => {
          if (this.selectAllIAB) {
            this.selectedIAB = []
          } else {
            this.selectedIAB = this.iab_list.slice()
          }
        })
      },
      toggleLanguages() {
        this.$nextTick(() => {
          if (this.selectAllLanguages) {
            this.selectedLanguagesBlocklist = []
          } else {
            this.selectedLanguagesBlocklist = this.languageList.slice()
          }
        })
      },
      toggleCountries() {
        this.$nextTick(() => {
          if (this.selectAllCountries) {
            this.selectedCountriesBlocklist = []
          } else {
            this.selectedCountriesBlocklist = this.countryList.slice()
          }
        })
      }
    }
  }
</script>

<style>
  .pageForm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    padding-left: 0px;
    letter-spacing: 2px;
    font-size: 25px;
  }
  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
    border: 0.3px solid gray;
    text-transform: capitalize;
  }
  .theme--dark.v-tabs > .v-tabs-bar .v-tab.v-tab--active,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab.v-tab--active > .v-icon,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab.v-tab--active > .v-btn,
  .theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
    background-color: orange;
    text-transform: capitalize;
  }
  #fact,
  #sortbar {
    display: inline-block;
  }
</style>
