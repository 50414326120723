import {
  AccountNameAlreadyExists,
  AccountNameAlreadyExistsUpdate
} from '@/store/action_types'
import { SetAppCustomer } from '@/store/mutation_types'
import axios from 'axios'

export const appCustomerState = {
  appCustomer: {}
}

export const appCustomerGetters = {
  appCustomer: (state) => state.appCustomer
}

export const appCustomerActions = {
  async [AccountNameAlreadyExists]({ commit }, name) {
    const response = await axios.get('/api/nameexists/' + name)
    return response.data
  },
  async [AccountNameAlreadyExistsUpdate]({ commit }, name) {
    const response = await axios.get('/api/nameexistsupdate/' + name)
    return response.data
  }
}

export const appCustomerMutations = {
  [SetAppCustomer](state, customer) {
    state.appCustomer = customer

  }
}
