import {
  GetSelectedGamSetup,
  SaveSelectedGamSetup,
  UpdateSelectedGamSetup
} from '@/store/action_types'

import {
  SetSelectedGamSetup,
  ClearSelectedGamSetup
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedGamSetupState = {
  selectedGamSetup: {}
}

export const selectedGamSetupGetters = {
  selectedGamSetup: (state) => {
    return state.selectedGamSetup
  }
}

export const selectedGamSetupActions = {
  async [GetSelectedGamSetup]({ commit }, gamSetupId) {
    const response = await axios.get('/api/gamsetup/' + gamSetupId)
    commit(SetSelectedGamSetup, response.data)
    return response
  },
  async [SaveSelectedGamSetup]({ dispatch }, gamsetup) {
    if (gamsetup.id !== undefined) {
      return dispatch(UpdateSelectedGamSetup, gamsetup)
    }
    const response = await axios.post('/api/gamsetup', gamsetup)
    return response
  },
  async [UpdateSelectedGamSetup]({ commit }, gamsetup) {
    const response = await axios.put(`/api/gamsetup/${gamsetup.id}`, gamsetup)
    //commit(SetSelectedGamSetup, gamsetup)
    return response
  }
}

export const selectedGamSetupMutations = {
  [SetSelectedGamSetup](state, gamSetup) {
    state.selectedGamSetup = gamSetup
  },
  [ClearSelectedGamSetup](state) {
    state.selectedGamSetup = {}
  }
}
