const setConfig = function () {
  //console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV == 'development') {
    return {
      oidc: {
        clientId: '0oa9off8qjR4uREVj5d5',
        issuer: 'https://dev-7652709.okta.com/oauth2/default',
        redirectUri: `${process.env.VUE_APP_BASE_URL || 'https://dev.console.nexx360.io'
          }/implicit/callback`,
        scopes: ['openid', 'profile', 'email', 'groups'],
        pkce: true
      },
      baseURL: process.env.VUE_APP_BASE_URL || 'https://dev.console.nexx360.io'
    }
  }
  if (process.env.NODE_ENV == 'production') {
    return {
      oidc: {
        clientId: '0oakdhan2Z5qMMcuj5d5',
        issuer: 'https://dev-7652709.okta.com/oauth2/default',
        redirectUri: 'https://console.nexx360.io/implicit/callback',
        scopes: ['openid', 'profile', 'email', 'groups'],
        pkce: true
      },
      baseURL: 'https://console.nexx360.io/'
    }
  }
}

export default setConfig()
