<template>
  <div id="segmentContent">
    <v-container
      v-if="
        $store.getters.is_superadmin == false &&
        $store.state.appCustomer.id != $route.params.customer_id &&
        $store.state.appCustomer.id != '1086' &&
        $store.state.appCustomer.id != '1094' &&
        $store.state.appUser.email != 'alfonso@revenuemaker.es'
      "
    >
      <accessdenied />
    </v-container>
    <v-container v-else fluid style="padding-top: 0px">
      <v-card id="segmentList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col>
              <h3 class="cnx">
                <strong> First party segments management</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="7" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="computedSegments"
            selectable-key="id"
            show-select
            hide-default-footer
            :disable-pagination="true"
            class="elevation-1"
          >
            <template v-slot:top>
              <h4 style="padding: 5px">
                Uncheck the segments that you want to deactivate and save below
                the table.
              </h4>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="180"
            dense
            @click="save"
          >
            <v-icon left> mdi-content-save-move </v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapActions, mapGetters } from 'vuex'
  import { SaveSelectedCustomer } from '@/store/action_types'

  export default {
    name: 'segmentList',
    components: {},
    data() {
      return {
        headers: [
          { text: 'ID', align: 'right', value: 'segment_id' },
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Population', align: 'right', value: 'population' }
        ],
        customer: {
          id: this.$route.params.customer_id
        },
        loading: false,
        search: '',
        showArchived: false,
        actionsuccess: false,
        timeout: 3000,
        messageAction: '',
        item: [],
        segmentsList: [],
        selected: [],
        submitLoading: false
      }
    },
    computed: {
      ...mapGetters(['appCustomer']),
      computedSegments() {
        return this.segmentsList
      }
    },
    watch: {
      selected: async function (val, oldVal) {},
      appCustomer: async function (val, oldVal) {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          await this.$router.push({
            name: 'segment_management',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
        this.getActivatedSegments()
      }
    },
    async created() {
      await this.getSegmentsList()
      this.getActivatedSegments()
    },
    methods: {
      ...mapActions([SaveSelectedCustomer]),
      async getSegmentsList() {
        try {
          const segmentsResponse = await axios.get(`/api/getsegments`)
          var segments = segmentsResponse.data
          segments.forEach((segment) => {
            segment.name =
              segment.name.charAt(0).toUpperCase() + segment.name.slice(1)
            segment.population = this.numberWithSpaces(segment.population)
          })
          this.segmentsList = segments
        } catch (error) {
          console.log(error)
        }
      },
      async getActivatedSegments() {
        const customerURL = await axios.get(
          `/api/getcustomerbyid/${this.$route.params.customer_id}`
        )
        var customer_selected = customerURL.data
        var segments = customer_selected.activated_segments
        if (segments == '') {
          this.segmentsList.forEach((segment) => {
            this.selected.push(segment)
          })
        } else {
          segments = segments.substring(1)
          segments = segments.substring(0, segments.length - 1)
          var arr = segments.split(',')
          var activated_segments_bdd = []
          arr.forEach((element) => {
            this.segmentsList.forEach((segment) => {
              if (element == segment.id) {
                activated_segments_bdd.push(segment)
              }
            })
          })
          this.selected = activated_segments_bdd
        }
      },
      numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      },
      activate(item) {
        this.selected.push(item)
      },
      async save() {
        this.loading = true
        var toreturn = '['
        for (let i = 0; i < this.selected.length; i++) {
          if (i != this.selected.length - 1)
            toreturn += this.selected[i].id + ','
          else toreturn += this.selected[i].id
        }
        toreturn += ']'
        this.customer.activated_segments = toreturn

        try {
          await this.SaveSelectedCustomer(this.customer)
        } catch (error) {
          console.log(error)
          return false
        }
        this.actionsuccess = true
        this.messageAction = 'Segments updated successfully'
        this.loading = false
      }
    }
  }
</script>
<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
    width: 48%;
  }
</style>
