module.exports = [
    {
        id: '1',
        label: '1 - In-Stream'
    },
    {
        id: '2',
        label: '2 - In-Banner'
    },
    {
        id: '3',
        label: '3 - In-Article'
    },
    {
        id: '4',
        label: '4 - In-Feed'
    },
    {
        id: '5',
        label: '5 - Interstitial/Slider/Floating'
    }
]
