<template>
  <div id="siteContent">
    <v-container fluid style="padding-top: 0px">
      <v-card id="PageList">
        <v-card-title primary-title style="padding-top: 10px">
          <v-col cols="12" style="padding: 0px">
            <div>
              <v-btn
                class="notcapital"
                color="black darken-1"
                text
                @click="back"
              >
                <v-icon left> mdi-arrow-left </v-icon>
                <u>Back to orders</u>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" class="nopadding">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            Line items of the order {{ selectedOrder.name }}
          </v-col>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black; margin-left: 10px"
                class="white--text notcapital"
                color="black"
                width="200"
                dense
                @click="newLineItem()"
              >
                <v-icon left> mdi-shape-square-plus </v-icon>
                Add a new line item
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <p
                style="
                  position: relative;
                  top: 30px;
                  left: 5px;
                  font-size: 14px;
                  color: black;
                "
              >
                {{ numberOfLineItemsCreated }}

                line items created (
                <a
                  :style="[
                    showArchived ? { color: 'black' } : { color: '#f28704' }
                  ]"
                  @click="showArchived = false"
                  ><u
                    ><strong>{{ numberOfActiveLineItems }}</strong> active line
                    items</u
                  ></a
                >
                /
                <a
                  :style="[
                    showArchived ? { color: '#f28704' } : { color: 'black' }
                  ]"
                  @click="showArchived = true"
                  ><u
                    ><strong>{{ numberOfArchivedLineItems }}</strong> archived
                    line items</u
                  ></a
                >
                )
              </p>
            </v-col>
            <v-col cols="2" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col style="padding-top: 30px">
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :sort-by="['name']"
                  :items-per-page="10"
                  :items="computedLineItems"
                  :search="search"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  :disable-pagination="true"
                  style="background-color: #f9f9fb"
                >
                  <template v-slot:item="props">
                    <tr>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 25%; cursor: pointer"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-else
                        style="width: 37%; cursor: pointer; opacity: 0.5"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 15%; cursor: pointer"
                      >
                        {{ props.item.status }}
                      </td>
                      <td
                        v-else
                        style="width: 15%; cursor: pointer; opacity: 0.5"
                      >
                        {{ props.item.status }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 15%; cursor: pointer"
                      >
                        {{
                          numberWithSpaces(
                            props.item.delivery_rate *
                              props.item.delivery_quantity
                          )
                        }}
                        {{ props.item.delivery_cur }}
                      </td>
                      <td
                        v-else
                        style="width: 15%; cursor: pointer; opacity: 0.5"
                      >
                        {{
                          (props.item.delivery_rate *
                            props.item.delivery_quantity) /
                          1000
                        }}
                        {{ props.item.delivery_cur }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 15%; cursor: pointer"
                      >
                        {{
                          getDateFromTimeStamp(
                            props.item.delivery_start_date
                          ).replace('T', ' ')
                        }}
                      </td>
                      <td
                        v-else
                        style="width: 15%; cursor: pointer; opacity: 0.5"
                      >
                        {{
                          getDateFromTimeStamp(
                            props.item.delivery_start_date
                          ).replace('T', ' ')
                        }}
                      </td>
                      <td
                        v-if="props.item.is_archived == 0"
                        style="width: 15%; cursor: pointer"
                      >
                        {{
                          getDateFromTimeStamp(
                            props.item.delivery_end_date
                          ).replace('T', ' ')
                        }}
                      </td>
                      <td
                        v-else
                        style="width: 15%; cursor: pointer; opacity: 0.5"
                      >
                        {{
                          getDateFromTimeStamp(
                            props.item.delivery_end_date
                          ).replace('T', ' ')
                        }}
                      </td>
                      <td style="padding: 0px">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(props.item)"
                            >
                              <v-icon size="20">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit the line item</span>
                        </v-tooltip>

                        <v-menu rounded="lg" offset-y>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  class="ma-2"
                                  text
                                  icon
                                  color="#000"
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon size="20"
                                    >mdi-content-duplicate</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Duplicate the line item</span>
                            </v-tooltip>
                          </template>
                          <v-list style="padding: 0px">
                            <v-list-item-group>
                              <v-list-item
                                v-for="(item, index) in duplicateOptions"
                                :key="index"
                                @click="duplicateLineItem(props.item, index)"
                              >
                                <v-list-item-title>{{
                                  item.text
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                        <v-tooltip v-if="props.item.is_archived == 0" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="showConfirmationDialog(props.item)"
                            >
                              <v-icon size="20">mdi-archive-arrow-down</v-icon>
                            </v-btn>
                          </template>
                          <span>Archive the line item</span>
                        </v-tooltip>
                        <v-tooltip v-else top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ma-2"
                              text
                              icon
                              color="#000"
                              v-bind="attrs"
                              v-on="on"
                              @click="unarchiveLineItem(props.item)"
                            >
                              <v-icon size="20">mdi-archive-arrow-up</v-icon>
                            </v-btn>
                          </template>
                          <span>Unarchive the line item</span>
                        </v-tooltip>
                      </td>
                    </tr></template
                  >
                </v-data-table>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveLineItem"
      />
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import { mapMutations, mapGetters, mapActions } from 'vuex'
  import { SetSelectedLineItem } from '@/store/mutation_types'
  import {
    GetOrders,
    GetCreatives,
    GetLineItems,
    SaveSelectedLineItem,
    SaveSelectedCreative,
    UpdateSelectedLineItem
  } from '@/store/action_types'
  import axios from 'axios'
  import ConfirmAction from '@/pages/management/actions/confirmAction.vue'

  export default {
    components: { ConfirmAction },

    data() {
      return {
        headers: [
          {
            text: this.$t('name'),
            align: 'left',
            value: 'name'
          },
          {
            text: this.$t('status'),
            align: 'left',
            value: 'status'
          },
          {
            text: this.$t('total_budget'),
            align: 'left',
            value: 'total_budget'
          },
          {
            text: this.$t('start_date'),
            align: 'left',
            value: 'start_date'
          },
          {
            text: this.$t('end_date'),
            align: 'left',
            value: 'end_date'
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: '',
            sortable: false
          }
        ],
        timeout: 3000,
        actionsuccess: false,
        showArchived: false,
        search: '',
        numberOfLineItemsCreated: 0,
        numberOfActiveLineItems: 0,
        numberOfArchivedLineItems: 0,
        confirmActionDialog: false,
        confirmationAction: '',
        item: [],
        messageAction: 'Line item added successfully',
        duplicateOptions: [
          { text: 'With creatives' },
          { text: 'Without creatives' }
        ]
      }
    },
    computed: {
      ...mapGetters(['appCustomer', 'creatives', 'selectedOrder', 'lineitems']),
      computedLineItems() {
        return this.lineitems
          .filter((el) => {
            return this.showArchived ? el.is_archived == 1 : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    watch: {
      async appCustomer() {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          await this.GetOrders(this.appCustomer.id)
          this.$router.push({
            name: 'order_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
      }
    },

    async created() {
      await this.GetLineItems(this.$route.params.order_id)
      this.manageNumberOfLineItems()
    },
    methods: {
      ...mapMutations([SetSelectedLineItem]),
      ...mapActions({
        GetOrders,
        GetCreatives,
        GetLineItems,
        SaveSelectedLineItem,
        SaveSelectedCreative,
        UpdateSelectedLineItem
      }),
      newLineItem() {
        this.$router.push({
          name: 'lineitem_new',
          params: {
            order_id: this.$route.params.order_id
          }
        })
      },
      editItem(lineitem) {
        this.SetSelectedLineItem(lineitem)
        this.$router.push({
          name: 'lineitem_edit',
          params: {
            order_id: this.$route.params.order_id,
            lineitem_id: lineitem.id
          }
        })
      },
      async duplicateLineItem(item, index) {
        const lineitem_to_duplicate = Object.assign({}, item)
        const lineitem_duplicated = Object.assign({}, item)
        lineitem_duplicated.id = undefined
        let nameExists = true
        let i = 1
        while (nameExists == true) {
          lineitem_duplicated.name = item.name + ' (' + i + ')'
          const verifyNameFromApi = await axios.get(
            `/api/dsplineitemnameexists/${lineitem_duplicated.name}/${lineitem_duplicated.order_id}`
          )
          nameExists = verifyNameFromApi.data
          i++
        }
        try {
          let lineitem_created = await this.SaveSelectedLineItem(
            lineitem_duplicated
          )
          if (index == 0) {
            await this.GetCreatives(lineitem_to_duplicate.id)
            for (const element of this.creatives) {
              const creative_duplicated = Object.assign({}, element)
              creative_duplicated.id = undefined
              creative_duplicated.line_item_id = lineitem_created.id
              creative_duplicated.name = element.name
              this.SaveSelectedCreative(creative_duplicated)
            }
          }
          await this.GetLineItems(this.$route.params.order_id)
          this.messageAction = 'Line item duplicated successfully'
          this.actionsuccess = true
        } catch (error) {
          console.log(error)
        }
      },
      getLineItemsArchived() {
        return this.lineitems.filter((el) => {
          return el.is_archived == 1
        })
      },
      getLineItemsUnarchived() {
        return this.lineitems.filter((el) => {
          return el.is_archived == 0
        })
      },
      manageNumberOfLineItems() {
        var lineitems_unarchived = this.getLineItemsUnarchived()
        var lineitems_archived = this.getLineItemsArchived()
        this.numberOfLineItemsCreated =
          lineitems_unarchived.length + lineitems_archived.length
        this.numberOfActiveLineItems = lineitems_unarchived.length
        this.numberOfArchivedLineItems = lineitems_archived.length
      },
      showConfirmationDialog(item) {
        this.item = item
        this.$router.push(this.$route.path + '?action=confirmArchiving')
        this.confirmActionDialog = true
        this.confirmationAction = 'archive'
      },
      async archiveLineItem(item) {
        item.is_archived = 1
        await this.UpdateSelectedLineItem(item)
        this.SetSelectedLineItem(item)
        await this.GetLineItems(this.$route.params.order_id)
        this.messageAction = 'Line item archived successfully'
        this.actionsuccess = true
        await this.manageNumberOfLineItems()
      },
      async unarchiveLineItem(item) {
        item.is_archived = 0
        this.UpdateSelectedLineItem(item)
        this.SaveSelectedLineItem(item)
        await this.GetLineItems(this.$route.params.order_id)
        this.messageAction = 'Line item unarchived successfully'
        this.actionsuccess = true
        await this.manageNumberOfLineItems()
      },
      back() {
        this.$router.push({
          name: 'order_list',
          params: {
            customer_id: this.$route.params.customer_id
          }
        })
      },
      numberWithSpaces(x) {
        if (x != null) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        else return 0
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = day + '/' + month + '/' + year
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
  .noPaddingCenter {
    padding: 0px;
    text-align: center;
  }
  .clickable {
    cursor: pointer;
  }
</style>
