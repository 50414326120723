module.exports = [
    {
        id: '1',
        label: '1 - Instream'
    },
    {
        id: '2',
        label: '2 - Accompanying Content'
    },
    {
        id: '3',
        label: '3 - Interstitial'
    },
    {
        id: '4',
        label: '4 - No Content/Standalone'
    }
]
