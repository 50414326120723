<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title style="padding-bottom: 0px">
        Deal {{ selectedDeal.name }}
      </v-card-title>
      <p
        v-if="selectedDeal.deal_external_id != ''"
        style="padding-left: 16px; font-size: 14px"
      >
        (Deal id : {{ selectedDeal.deal_external_id }})
      </p>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>
                  {{ $t('deal_name') }}
                </p>
                <v-text-field
                  ref="Name"
                  v-model="deal.name"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="selectedProposal.deal_type == 'Guaranteed'">
              <v-col cols="2">
                <div
                  class="remove_group"
                  style="display: inline-flex; cursor: pointer"
                >
                  Guaranteed looks
                  <div class="remove_group">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="#2E4089"
                          style="padding-bottom: 2px"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        >The number of looks or views that are guaranteed to the
                        buyer. This value must be greater than 0.</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <v-text-field
                  ref="Name"
                  v-model="deal.guaranteed_looks"
                  :rules="guaranteedLooksRules"
                  type="number"
                  dense
                  solo
                  outlined
                  style="margin-top: 15px"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <div
                  class="remove_group"
                  style="display: inline-flex; cursor: pointer"
                >
                  Guaranteed impressions
                  <div class="remove_group">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="#2E4089"
                          style="padding-bottom: 2px"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information-outline</v-icon
                        >
                      </template>
                      <span
                        >The guaranteed impressions as a percentage of the
                        GuaranteedLooks value. Here's what you need to know
                        about this property:<br />
                        - This value must be between 0.01 and 1<br />
                        - The buyer is required to buy the GuaranteedLooks
                        amount multiplied by the GuaranteedImpressions decimal:
                        GuaranteedLooks * GuaranteedImpressions.</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <v-text-field
                  ref="Name"
                  v-model="deal.guaranteed_impressions"
                  :rules="guaranteedImpressionsRules"
                  type="number"
                  dense
                  solo
                  outlined
                  style="margin-top: 15px; width: 200px"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nopadding">
              <v-col class="nopadding" cols="12">
                <div>
                  <v-row>
                    <v-col class="nopadding" cols="4">
                      <p style="margin-bottom: 5px">Floor</p>
                    </v-col>
                    <v-col class="nopadding" cols="6">
                      <p style="margin-bottom: 5px">Auction type</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="nopadding" cols="2">
                      <v-text-field
                        v-model="deal.rate_amount"
                        placeholder="0"
                        :rules="cpmRules"
                        type="number"
                        dense
                        solo
                        outlined
                        text
                      >
                      </v-text-field>
                    </v-col>
                    <v-col class="nopadding" cols="2"
                      ><v-select
                        v-model="deal.rate_currency"
                        :items="currencies"
                        solo
                        dense
                        outlined
                      >
                      </v-select
                    ></v-col>
                    <v-col class="nopadding" cols="3"
                      ><v-select
                        v-model="deal.auction_type"
                        :items="price_behavior_choices"
                        item-text="name"
                        item-value="value"
                        solo
                        dense
                        outlined
                      >
                      </v-select
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="nopadding">
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Start date</p>
                        </v-col>
                        <v-col
                          v-if="no_end_date == false"
                          class="nopadding"
                          cols="2"
                        >
                          <p style="margin-bottom: 5px">End date</p>
                        </v-col>
                        <v-col class="nopadding" cols="2" />
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <input
                            v-model="start_time"
                            id="dealdatefirst"
                            style="
                              border: 1px solid black;
                              border-radius: 5px;
                              padding: 5px;
                              box-shadow: 1px 1px 2px 2px lightgray;
                            "
                            type="datetime-local"
                            :min="min_start_date"
                          />
                        </v-col>
                        <v-col
                          v-if="no_end_date == false"
                          class="nopadding"
                          cols="2"
                        >
                          <input
                            v-model="end_time"
                            style="
                              border: 1px solid black;
                              border-radius: 5px;
                              padding: 5px;
                              box-shadow: 1px 1px 2px 2px lightgray;
                            "
                            type="datetime-local"
                            id="dealdateend"
                            :min="min_end_date"
                          />
                        </v-col>
                        <v-col
                          v-show="selectedProposal.deal_type != 'Guaranteed'"
                          class="nopadding"
                          cols="2"
                        >
                          <v-checkbox
                            v-model="no_end_date"
                            style="margin-top: 3px; padding-left: 10px"
                            label="No End Date"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">
                              Day and time (UTC)
                            </p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_dayandtime_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col cols="2" class="nopadding">
                            <p style="margin-bottom: 5px">Start time</p></v-col
                          >
                          <v-col cols="2" class="nopadding">
                            <p style="margin-bottom: 5px">End time</p></v-col
                          >
                        </v-row>
                        <v-row class="nopadding">
                          <v-col cols="2" class="nopadding">
                            <treeselect
                              :multiple="false"
                              :disable-branch-nodes="true"
                              :options="dayandtimeList"
                              v-model="dayandtime_start_filters"
                              search-nested
                            />
                          </v-col>
                          <v-col cols="2" class="nopadding">
                            <treeselect
                              :multiple="false"
                              :disable-branch-nodes="true"
                              :options="dayandtimeList"
                              v-model="dayandtime_end_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" style="padding-bottom: 0px">
                            <p style="margin-bottom: 5px">
                              Repeating on
                            </p></v-col
                          >
                        </v-row>
                        <v-row class="nopadding">
                          <v-col cols="12" class="nopadding">
                            <v-chip-group
                              v-model="daysoftheweek"
                              column
                              multiple
                            >
                              <v-chip filter outlined> Monday </v-chip>
                              <v-chip filter outlined> Tuesday </v-chip>
                              <v-chip filter outlined> Wednesday </v-chip>
                              <v-chip filter outlined> Thursday </v-chip>
                              <v-chip filter outlined> Friday </v-chip>
                              <v-chip filter outlined> Saturday </v-chip>
                              <v-chip filter outlined> Sunday </v-chip>
                            </v-chip-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" style="padding-bottom: 0px">
                            <p style="margin-bottom: 5px">
                              {{ text_dayandtime }}
                            </p></v-col
                          >
                        </v-row>
                        <v-col
                          v-if="errorDayAndTime != ''"
                          cols="7"
                          align="left"
                          justify="left"
                          style="padding-left: 0px"
                        >
                          <span width="700">
                            <v-alert color="#db2727" type="error" dense>{{
                              errorDayAndTime
                            }}</v-alert>
                          </span>
                        </v-col>
                        <v-row>
                          <v-col style="padding-left: 0px; padding-top: 0px"
                            ><v-divider
                              style="margin-top: 0px; padding-top: 0px"
                            ></v-divider
                          ></v-col>
                        </v-row>
                        <v-row
                          v-for="key in dayAndTimeSegmentsCount"
                          :key="key"
                          style="padding-top: 0px"
                        >
                          <v-container>
                            <v-row
                              style="padding-top: 0px; padding-bottom: 0px"
                            >
                              <v-col cols="10" style="padding-top: 0px">
                                <v-row class="nopadding">
                                  <v-col cols="2" class="nopadding">
                                    <p style="margin-bottom: 5px">
                                      Start time
                                    </p></v-col
                                  >
                                  <v-col cols="2" class="nopadding">
                                    <p style="margin-bottom: 5px">
                                      End time
                                    </p></v-col
                                  >
                                </v-row>
                                <v-row class="nopadding">
                                  <v-col cols="2" class="nopadding">
                                    <treeselect
                                      :multiple="false"
                                      :disable-branch-nodes="true"
                                      :options="dayandtimeList"
                                      v-model="
                                        dayAndTimeStartDynamicValues[key]
                                      "
                                      search-nested
                                    />
                                  </v-col>
                                  <v-col cols="2" class="nopadding">
                                    <treeselect
                                      :multiple="false"
                                      :disable-branch-nodes="true"
                                      :options="dayandtimeList"
                                      v-model="dayAndTimeEndDynamicValues[key]"
                                      search-nested
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" style="padding-bottom: 0px">
                                    <p style="margin-bottom: 5px">
                                      Repeating on
                                    </p></v-col
                                  >
                                </v-row>
                                <v-row class="nopadding">
                                  <v-col cols="12" class="nopadding">
                                    <v-chip-group
                                      v-model="daysoftheweekDynamicValues[key]"
                                      column
                                      multiple
                                    >
                                      <v-chip filter outlined> Monday </v-chip>
                                      <v-chip filter outlined> Tuesday </v-chip>
                                      <v-chip filter outlined>
                                        Wednesday
                                      </v-chip>
                                      <v-chip filter outlined>
                                        Thursday
                                      </v-chip>
                                      <v-chip filter outlined> Friday </v-chip>
                                      <v-chip filter outlined>
                                        Saturday
                                      </v-chip>
                                      <v-chip filter outlined> Sunday </v-chip>
                                    </v-chip-group>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="4" style="padding-bottom: 0px">
                                    <p style="margin-bottom: 5px">
                                      {{ text_dayandtime }}
                                    </p></v-col
                                  >
                                </v-row>
                              </v-col>
                              <v-col
                                cols="2"
                                style="
                                  text-align: right;
                                  padding: 0px 10px 0px 40px;
                                  height: 5px;
                                "
                              >
                                <div
                                  class="remove_group"
                                  style="display: inline-flex; cursor: pointer"
                                  @click="removeDayAndTime(key)"
                                >
                                  <div>
                                    <p style="color: #2e4089">Remove Group</p>
                                  </div>
                                  <div class="remove_group">
                                    <v-icon small color="#2E4089"
                                      >mdi-close</v-icon
                                    >
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                            <v-col
                              v-if="
                                errorDayAndTimeDynamicValues[key - 1] !=
                                undefined
                              "
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorDayAndTimeDynamicValues[key - 1]
                                }}</v-alert>
                              </span>
                            </v-col>
                            <v-row>
                              <v-col
                                cols="12"
                                style="padding-left: 0px; padding-top: 0px"
                                ><v-divider
                                  style="margin-top: 0px; padding-top: 0px"
                                ></v-divider
                              ></v-col>
                            </v-row>
                          </v-container>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding" style="padding-left: 0px">
                            <div
                              class="remove_group"
                              style="
                                display: inline-flex;
                                cursor: pointer;
                                padding-left: 0px;
                              "
                              @click="addDayAndTime"
                            >
                              <div class="remove_group">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      small
                                      color="#2E4089"
                                      style="padding-bottom: 2px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-plus</v-icon
                                    >
                                  </template>
                                </v-tooltip>
                              </div>
                              <u style="color: #2e4089">Add Period </u>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <v-divider style="margin-top: 15px"></v-divider>
                      <v-row class="nopadding">
                        <v-col class="nopadding" cols="12">
                          <p style="margin: 10px 0px; font-size: 18px">
                            <strong>Targeting</strong>
                          </p></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="3">
                          <p style="margin-bottom: 5px">Inventory type</p>
                        </v-col>
                        <v-col class="nopadding" cols="6">
                          <p style="margin-bottom: 5px">Sizes</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="3"
                          ><v-select
                            v-model="deal.creative_format"
                            :items="inventory_type_choices"
                            item-text="name"
                            item-value="value"
                            solo
                            dense
                            outlined
                          >
                          </v-select
                        ></v-col>
                        <v-col class="nopadding" cols="6"
                          ><v-select
                            v-model="selected_sizes"
                            :items="size_choices"
                            item-text="name"
                            item-value="value"
                            multiple
                            clearable
                            dense
                            outlined
                          >
                          </v-select
                        ></v-col>
                      </v-row>
                      <v-row style="margin-top: 30px">
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Frequency cap</p>
                        </v-col>
                        <v-col class="nopadding" cols="2">
                          <p style="margin-bottom: 5px">Reset Interval</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="nopadding" cols="2">
                          <v-text-field
                            v-model="deal.frequency_cap"
                            type="number"
                            :rules="frequencyCapsRules"
                            dense
                            solo
                            outlined
                            text
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          class="nopadding"
                          cols="2"
                          style="padding-right: 0px"
                        >
                          <v-text-field
                            type="number"
                            v-model="deal.reset_interval"
                            dense
                            solo
                            :rules="resetIntervalRules"
                            outlined
                            text
                          >
                          </v-text-field>
                        </v-col>
                        <v-col class="nopadding" style="padding-left: 0px">
                          <v-select
                            v-model="reset_interval_type"
                            :items="time_choices"
                            item-text="name"
                            item-value="value"
                            dense
                            outlined
                            solo
                            style="width: 120px"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Sites</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_site_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  ref="siteName"
                                  v-model="site_textfield"
                                  :rules="siteRules"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  :disabled="!validUrlSiteFilters"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="addSiteFilters(site_textfield)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorSiteFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorSiteFilters
                                }}</v-alert>
                              </span>
                            </v-col>

                            <v-row
                              class="site_div"
                              v-if="sites_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedSiteFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in sites_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn @click="deleteSiteFilters(i)" icon>
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Geography</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_country_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <p style="margin-bottom: 15px; margin-left: 5px">
                              Countries / Regions (FR) / Departments (FR)
                            </p>
                          </v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :options="countriesList"
                              v-model="country_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Device</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_device_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="devicesList"
                              v-model="devices_filters"
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Connection</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_connection_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="connectionsList"
                              v-model="connections_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Browser</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_browser_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :disable-branch-nodes="true"
                              :options="browsersList"
                              v-model="browsers_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">OS</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_os_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <treeselect
                              :multiple="true"
                              :options="osList"
                              v-model="os_filters"
                              search-nested
                            />
                          </v-col>
                        </v-row>
                      </div>
                      <!--<div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Advertiser</p></v-col
                          >
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  v-model="advertiser_tf"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="addAdvertiser(advertiser_tf)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorAdvertiserFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorAdvertiserFilters
                                }}</v-alert>
                              </span>
                            </v-col>
                            <v-row
                              class="advertiser_div"
                              v-if="advertiser_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedAdvertiserFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in advertiser_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        @click="deleteAdvertiserFilters(i)"
                                        icon
                                      >
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>-->
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">First Party</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_firstparty_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row style="padding-top: 20px">
                          <v-col cols="12">
                            <v-row>
                              <v-col>
                                <v-row>
                                  <v-col>
                                    <treeselect
                                      :multiple="true"
                                      :options="firstpartyList"
                                      :searchable="true"
                                      v-model="firstparty_filters"
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          v-for="key in firstPartySegmentsCount"
                          :key="key"
                          style="padding-top: 20px"
                        >
                          <v-container>
                            <v-row
                              ><v-col style="padding-right: 0px" cols="1"
                                >AND</v-col
                              >
                              <v-col style="padding-left: 0px"
                                ><v-divider style="margin-top: 15px"></v-divider
                              ></v-col>
                            </v-row>
                            <v-row
                              style="padding-top: 10px; padding-bottom: 25px"
                            >
                              <v-col
                                cols="12"
                                style="
                                  text-align: right;
                                  padding: 10px 10px 0px 40px;
                                  height: 5px;
                                "
                              >
                                <div
                                  class="remove_group"
                                  style="display: inline-flex; cursor: pointer"
                                  @click="removeFirstParty(key)"
                                >
                                  <div>
                                    <p style="color: #2e4089">Remove Group</p>
                                  </div>
                                  <div class="remove_group">
                                    <v-icon small color="#2E4089"
                                      >mdi-close</v-icon
                                    >
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-col cols="12">
                            <v-row>
                              <v-col>
                                <v-row>
                                  <v-col>
                                    <treeselect
                                      :multiple="true"
                                      :options="firstpartyList"
                                      v-model="firstPartyDynamicValues[key]"
                                      search-nested
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div
                              class="remove_group"
                              style="display: inline-flex; cursor: pointer"
                              @click="addFirstParty"
                            >
                              AND
                              <div class="remove_group">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      small
                                      color="#2E4089"
                                      style="padding-bottom: 2px"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-information-outline</v-icon
                                    >
                                  </template>
                                  <span
                                    >Choose additional targeting details to
                                    refine your audience. These targeting
                                    details have an "and" relationship with
                                    prior selections.</span
                                  >
                                </v-tooltip>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Tag Id</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_tagid_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  ref="tagIdName"
                                  v-model="tagid_textfield"
                                  :rules="tagIdRule"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  :disabled="!validTagIdFilters"
                                  @click="addTagIdFilters(tagid_textfield)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorTagIdFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorTagIdFilters
                                }}</v-alert>
                              </span>
                            </v-col>

                            <v-row
                              class="tagid_div"
                              v-if="tagid_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedTagIdFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in tagid_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        @click="deleteTagIdFilters(i)"
                                        icon
                                      >
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">
                              Custom targeting
                            </p></v-col
                          >
                        </v-row>
                        <v-row style="padding-top: 20px">
                          <v-col cols="12">
                            <v-row>
                              <v-col>
                                <v-row>
                                  <v-col cols="3" style="padding-bottom: 0px">
                                    <v-text-field
                                      v-model="custom_filters.customKey"
                                      label="Key"
                                      dense
                                      solo
                                      outlined
                                    >
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="2" style="padding-bottom: 0px">
                                    <v-select
                                      v-model="custom_filters.customSelectMode"
                                      :items="customSelectValues"
                                      item-text="name"
                                      item-value="value"
                                      solo
                                      dense
                                      outlined
                                    >
                                    </v-select>
                                  </v-col>
                                  <v-col cols="5" style="padding-bottom: 0px">
                                    <v-combobox
                                      v-model="custom_filters.customValue"
                                      style="padding-bottom: 0px"
                                      label="Value"
                                      multiple
                                      solo
                                      dense
                                      chips
                                      deletable-chips
                                    ></v-combobox>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          v-for="key in customSegmentsCount"
                          :key="key"
                          style="padding-top: 0px"
                        >
                          <div
                            style="padding-left: 15px"
                            v-if="customDynamicValues.operator[key] == 'OR'"
                          >
                            <v-divider
                              vertical
                              style="border-color: orange"
                            ></v-divider>
                          </div>
                          <v-col v-else cols="11" style="padding-bottom: 0px">
                            <v-divider style="border-color: #2e4089"></v-divider
                          ></v-col>
                          <div v-if="customDynamicValues.operator[key] == 'OR'">
                            <p
                              style="
                                padding-top: 20px;
                                padding-left: 10px;
                                color: orange;
                              "
                            >
                              {{ customDynamicValues.operator[key] }}
                            </p>
                          </div>
                          <v-col
                            v-else
                            cols="1"
                            style="padding-top: 0px; padding-bottom: 0px"
                          >
                            <p style="color: #2e4089">
                              {{ customDynamicValues.operator[key] }}
                            </p></v-col
                          >

                          <v-col cols="12" style="margin-top: 10px">
                            <v-row>
                              <v-col cols="3" style="padding-top: 0px">
                                <v-text-field
                                  v-model="customDynamicValues.customKey[key]"
                                  label="Key"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2" style="padding-top: 0px">
                                <v-select
                                  v-model="
                                    customDynamicValues.customSelectMode[key]
                                  "
                                  :items="customSelectValues"
                                  item-text="name"
                                  item-value="value"
                                  solo
                                  dense
                                  outlined
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="5" style="padding-top: 0px">
                                <v-combobox
                                  v-model="customDynamicValues.customValue[key]"
                                  style="padding-bottom: 0px"
                                  label="Value"
                                  multiple
                                  solo
                                  dense
                                  chips
                                  deletable-chips
                                ></v-combobox>
                              </v-col>
                              <v-col
                                cols="2"
                                align="right"
                                justify="right"
                                style="padding-top: 0px"
                              >
                                <div
                                  class="remove_group"
                                  style="display: inline-flex; cursor: pointer"
                                  @click="removeCustom(key)"
                                >
                                  <div class="remove_group">
                                    <v-icon
                                      small
                                      color="black"
                                      style="font-size: 20px"
                                      >mdi-close-circle-outline</v-icon
                                    >
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="10">
                            <p
                              v-if="previousCustomRecordEmpty"
                              style="color: red"
                            >
                              Please fill all the key and value fields
                            </p>
                          </v-col>
                          <v-col>
                            <div
                              class="remove_group"
                              style="
                                display: inline-flex;
                                cursor: pointer;
                                background-color: orange;
                                border-radius: 20px;
                                color: white;
                                padding: 0px;
                                width: 60px;
                              "
                              @click="addCustom('OR')"
                            >
                              <div class="remove_group">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <p
                                      style="
                                        margin-bottom: 0px;
                                        padding-left: 13px;
                                        text-align: center;
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      OR
                                    </p>
                                  </template>
                                  <span
                                    >Choose additional targeting details to
                                    refine your audience. These targeting
                                    details have an "or" relationship with upper
                                    selection.</span
                                  >
                                </v-tooltip>
                              </div>
                            </div>
                          </v-col>
                          <v-col>
                            <div
                              class="remove_group"
                              style="
                                display: inline-flex;
                                cursor: pointer;
                                background-color: #2e4089;
                                border-radius: 20px;
                                color: white;
                                padding: 0px;
                                width: 60px;
                              "
                              @click="addCustom('AND')"
                            >
                              <div class="remove_group">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <p
                                      style="
                                        margin-bottom: 0px;
                                        padding-left: 11px;
                                        text-align: center;
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      AND
                                    </p>
                                  </template>
                                  <span
                                    >Choose additional targeting details to
                                    refine your audience. These targeting
                                    details have an "or" relationship with prior
                                    selections.</span
                                  >
                                </v-tooltip>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Grapeshot</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_grapeshot_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  ref="grapeshotName"
                                  v-model="grapeshot_textfield"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="
                                    addGrapeshotFilters(grapeshot_textfield)
                                  "
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorGrapeshotFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorGrapeshotFilters
                                }}</v-alert>
                              </span>
                            </v-col>

                            <v-row
                              class="grapeshot_div"
                              v-if="grapeshot_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedGrapeshotFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in grapeshot_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn
                                        @click="deleteGrapeshotFilters(i)"
                                        icon
                                      >
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="nopadding; expansion_panel">
                        <v-row>
                          <v-col class="nopadding" cols="9">
                            <p style="margin-bottom: 5px">Page</p></v-col
                          >
                          <v-col
                            class="nopadding"
                            cols="3"
                            align="right"
                            justify-end
                          >
                            <tr style="padding-left: 10px">
                              <td style="padding-right: 0px">
                                <p
                                  class="include_targeting"
                                  align="right"
                                  justify-end
                                >
                                  Include
                                </p>
                              </td>
                              <td style="padding-left: 5px">
                                <v-switch
                                  v-model="exclude_page_targeting"
                                  label="Exclude"
                                  style="padding: 0px; margin-left: 5px"
                                ></v-switch>
                              </td></tr
                          ></v-col>
                        </v-row>
                        <v-row class="nopadding">
                          <v-col class="nopadding">
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  ref="pageName"
                                  v-model="page_textfield"
                                  dense
                                  solo
                                  outlined
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-btn
                                  class="notcapital"
                                  color="secondary"
                                  width="75px"
                                  height="30px"
                                  style="margin-top: 3px"
                                  @click="addPageFilters(page_textfield)"
                                >
                                  <v-icon left> mdi-plus </v-icon>
                                  Add
                                </v-btn></v-col
                              >
                            </v-row>
                            <v-col
                              v-if="errorPageFilters != ''"
                              cols="7"
                              align="left"
                              justify="left"
                              style="padding-left: 0px"
                            >
                              <span width="700">
                                <v-alert color="#db2727" type="error" dense>{{
                                  errorPageFilters
                                }}</v-alert>
                              </span>
                            </v-col>

                            <v-row
                              class="page_div"
                              v-if="page_filters.length > 0"
                            >
                              <v-list dense style="width: 100%; padding: 0px">
                                <v-list-item-group
                                  v-model="selectedPageFilters"
                                  color="primary"
                                >
                                  <v-list-item
                                    style="
                                      min-height: 40px !important;
                                      height: 40px;
                                    "
                                    v-for="(item, i) in page_filters"
                                    :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                      <v-btn @click="deletePageFilters(i)" icon>
                                        <v-icon color="black lighten-1" small
                                          >mdi-close</v-icon
                                        >
                                      </v-btn>
                                    </v-list-item-action>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="red"
            width="180"
            dense
            @click="back"
          >
            <v-icon left> mdi-cancel </v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="180"
            dense
            @click="submit"
          >
            <v-icon left> mdi-content-save-move </v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="dealupdated" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ dealUpdatedMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  const devices_list = require('@/assets/styles/devices.js')
  const os_list = require('@/assets/styles/os.js')
  const browsers_list = require('@/assets/styles/browsers.js')
  const connections_list = require('@/assets/styles/connections.js')
  const dayandtime_list = require('@/assets/styles/dayandtime.js')
  const sizes_list_display = require('@/assets/styles/sizes_display.js')
  const sizes_list_video = require('@/assets/styles/sizes_video.js')
  const countries_rg_dp = require('@/assets/styles/countries_regions_departments.js')

  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SetSelectedDeal, SetSelectedProposal } from '@/store/mutation_types'
  import {
    SaveSelectedDeal,
    UpdateSelectedDeal,
    GetCustomers
  } from '@/store/action_types'

  import axios from 'axios'

  const simulateAsyncOperation = (fn) => {
    setTimeout(fn, 1000)
  }

  export default {
    name: 'dealRules',
    components: { Treeselect },
    data() {
      return {
        deal: {
          name: '',
          rate_currency: 'USD',
          auction_type: 'FirstPriceAuction'
        },
        site: '',
        date: '',
        start_time: '',
        end_time: '',
        min_start_date: '',
        min_end_date: '',
        submitLoading: false,
        currencies: ['USD', 'EUR'],
        currency: 'USD',
        price_behavior_choices: [
          { name: 'Standard', value: 'FirstPriceAuction' },
          { name: 'Fixed', value: 'FixedPrice' }
        ],
        customSelectValues: [
          { name: 'Is any of', value: 'included' },
          { name: 'Is none of', value: 'excluded' }
        ],
        inventory_type_choices: [
          { name: 'Display', value: 'Display' },
          { name: 'Video', value: 'Video' }
        ],
        time_choices: [
          { name: 'Minute', value: 'minute' },
          { name: 'Hour', value: 'hour' },
          { name: 'Day', value: 'day' }
        ],
        reset_interval_type: 'minute',
        size_choices: sizes_list_display,
        selected_sizes: '',
        no_end_date: true,
        osList: os_list,
        os_filters: [],
        devicesList: devices_list,
        devices_filters: [],
        browsersList: browsers_list,
        connectionsList: connections_list,
        dayandtimeList: dayandtime_list,
        browsers_filters: [],
        connections_filters: [],
        countriesList: [],
        countriesRgDptList: countries_rg_dp,
        country_filters: [],
        firstpartyList: [],
        customList: [],
        firstparty_filters: [],
        custom_filters: {
          customKey: '',
          customValue: [],
          customSelectMode: 'included'
        },

        sites_filters: [],
        tagid_filters: [],
        grapeshot_filters: [],
        page_filters: [],
        dayandtime_start_filters: null,
        dayandtime_end_filters: null,
        site_textfield: '',
        tagid_textfield: '',
        grapeshot_textfield: '',
        page_textfield: '',
        custom_textfield: '',
        selectedSiteFilters: null,
        selectedTagIdFilters: null,
        selectedGrapeshotFilters: null,
        selectedPageFilters: null,
        isFrequencyValid: false,
        isResetIntervalValid: false,
        daysoftheweek: [],
        text_dayandtime: 'Run on those days',
        cpmRules: [
          (v) => !!v || 'This field is required',
          (v) => (v && Number(v) > 0.0) || 'CPM should be above 0'
        ],
        guaranteedLooksRules: [
          (v) => !!v || 'This field is required',
          (v) => (v && Number(v) > 0.0) || 'Value should be above 0'
        ],
        guaranteedImpressionsRules: [
          (v) => !!v || 'This field is required',
          (v) => (v && Number(v) > 0.0) || 'Value should be at least 0.01',
          (v) => (v && Number(v) <= 1) || 'Value should not be above 1'
        ],
        frequencyCapsRules: [
          (v) => this.isFrequencyValid || 'Value should be above 0'
        ],
        resetIntervalRules: [
          (v) => this.isResetIntervalValid || 'Value should be above 0'
        ],
        required: [(v) => !!v || 'Required'],
        siteRules: [
          (v) =>
            /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
              v
            ) || 'Please enter a valid domain URL.'
        ],
        tagIdRule: [
          (v) =>
            /(^[a-zA-Z0-9]{8}?$|^$)/.test(v) ||
            'Tag ID must contain 8 caracters (only letters and numbers are allowed)'
        ],
        validUrlSiteFilters: false,
        validTagIdFilters: false,
        errorSiteFilters: '',
        errorTagIdFilters: '',
        errorGrapeshotFilters: '',
        errorPageFilters: '',
        exclude_site_targeting: false,
        exclude_tagid_targeting: false,
        exclude_grapeshot_targeting: false,
        exclude_page_targeting: false,
        exclude_country_targeting: false,
        exclude_device_targeting: false,
        exclude_browser_targeting: false,
        exclude_connection_targeting: false,
        exclude_os_targeting: false,
        exclude_firstparty_targeting: false,
        exclude_dayandtime_targeting: false,
        dealupdated: false,
        dealUpdatedMessage: 'Deal updated successfully',
        timeout: 3000,
        advertiser_tf: '',
        advertiser_filters: [],
        errorAdvertiserFilters: '',
        selectedAdvertiserFilters: null,
        targetingSite: { excluded: false, filters: [] },
        targetingTagId: { excluded: false, filters: [] },
        targetingGrapeshot: { excluded: false, filters: [] },
        targetingPage: { excluded: false, filters: [] },
        targetingDevice: { excluded: false, filters: [] },
        targetingOs: { excluded: false, filters: [] },
        targetingBrowser: { excluded: false, filters: [] },
        targetingConnection: { excluded: false, filters: [] },
        targetingCountry: { excluded: false, filters: [] },
        targetingFirstParty: { excluded: false, filters: [] },
        targetingCustom: {
          type: 'included',
          filters: [],
          recordExists: false
        },
        targetingDayAndTime: { excluded: false, filters: [] },
        firstPartySegmentsCount: 0,
        customSegmentsCount: 0,
        dayAndTimeSegmentsCount: 0,
        firstPartyDynamicValues: [],
        customDynamicValues: {
          customKey: [],
          customSelectMode: [],
          customValue: [],
          operator: []
        },
        previousCustomRecordEmpty: false,
        dayAndTimeStartDynamicValues: [],
        dayAndTimeEndDynamicValues: [],
        daysoftheweekDynamicValues: [],
        errorDayAndTimeDynamicValues: [],
        errorDayAndTime: ''
      }
    },
    computed: {
      ...mapGetters([
        'selectedDeal',
        'selectedProposal',
        'appCustomer',
        'customers'
      ])
    },
    watch: {
      appCustomer: async function (val, oldVal) {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          this.$router.push({
            name: 'proposal_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
      },
      'deal.frequency_cap': async function (val, oldVal) {
        if (
          (this.country_filters.length > 0 ||
            this.sites_filters.length > 0 ||
            this.tagid_filters.length > 0 ||
            this.grapeshot_filters.length > 0 ||
            this.page_filters.length > 0 ||
            this.devices_filters.length > 0 ||
            this.browsers_filters.length > 0 ||
            this.connections_filters.length > 0 ||
            this.os_filters.length > 0 ||
            this.firstparty_filters.length > 0 ||
            this.custom_filters.length > 0) &&
          Number(val) <= 0
        )
          this.isFrequencyValid = false
        else this.isFrequencyValid = true
      },
      'deal.reset_interval': async function (val, oldVal) {
        if (
          (this.country_filters.length > 0 ||
            this.sites_filters.length > 0 ||
            this.tagid_filters.length > 0 ||
            this.grapeshot_filters.length > 0 ||
            this.page_filters.length > 0 ||
            this.devices_filters.length > 0 ||
            this.browsers_filters.length > 0 ||
            this.connections_filters.length > 0 ||
            this.os_filters.length > 0 ||
            this.firstparty_filters.length > 0 ||
            this.custom_filters.length > 0) &&
          Number(val) <= 0
        )
          this.isResetIntervalValid = false
        else this.isResetIntervalValid = true
      },
      sites_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      tagid_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      grapeshot_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      page_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      browsers_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      connections_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      firstparty_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      custom_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      country_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      os_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      devices_filters: async function (val, oldVal) {
        if (val.length > 0 && Number(this.deal.frequency_cap) <= 0) {
          this.isFrequencyValid = false
        }
        if (val.length > 0 && Number(this.deal.reset_interval) <= 0) {
          this.isResetIntervalValid = false
        }
      },
      start_time: async function (val, oldVal) {
        this.min_end_date = val
      },
      no_end_date: async function (val, oldVal) {
        if (val == false && this.end_time == '') {
          this.min_end_date = this.start_time
          const time = this.start_time.substring(0, this.start_time.length - 5)
          this.end_time = time + '23:59'
        } else if (val == true) {
          this.end_time = ''
        }
      },
      site_textfield: async function (val, oldVal) {
        let regex =
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        this.validUrlSiteFilters = regex.test(val)
      },
      tagid_textfield: async function (val, oldVal) {
        let regex = /^[a-zA-Z0-9]{8}?$/
        this.validTagIdFilters = regex.test(val)
      },
      selectedSiteFilters: async function (val, oldVal) {
        this.selectedSiteFilters = null
      },
      selectedTagIdFilters: async function (val, oldVal) {
        this.selectedTagIdFilters = null
      },
      selectedAdvertiserFilters: async function (val, oldVal) {
        this.selectedAdvertiserFilters = null
      },
      selectedGrapeshotFilters: async function (val, oldVal) {
        this.selectedGrapeshotFilters = null
      },
      selectedPageFilters: async function (val, oldVal) {
        this.selectedPageFilters = null
      },
      exclude_dayandtime_targeting: async function (val, oldVal) {
        if (val == false) {
          this.text_dayandtime = 'Run on those days'
        } else this.text_dayandtime = 'Do not run on those days'
      },
      daysoftheweekDynamicValues: async function (val, oldVal) {},
      'deal.creative_format': async function (val, oldVal) {
        this.selected_sizes = ''
        if (val == 'Display') this.size_choices = sizes_list_display
        else this.size_choices = sizes_list_video
      },
      selected_sizes: async function (val, oldVal) {}
      /*  exclude_site_targeting: async function (val, oldVal) {
        if (this.targetingSite.filters.length != 0) {
          if (val == this.targetingSite.excluded) {
            this.sites_filters = this.targetingSite.filters
          } else this.sites_filters = []
        }
      },
      exclude_device_targeting: async function (val, oldVal) {
        if (this.targetingDevice.filters.length != 0) {
          if (val == this.targetingDevice.excluded) {
            this.devices_filters = this.targetingDevice.filters
          } else this.devices_filters = []
        }
      },
      exclude_os_targeting: async function (val, oldVal) {
        if (this.targetingOs.filters.length != 0) {
          if (val == this.targetingOs.excluded) {
            this.os_filters = this.targetingOs.filters
          } else this.os_filters = []
        }
      },
      exclude_browser_targeting: async function (val, oldVal) {
        if (this.targetingBrowser.filters.length != 0) {
          if (val == this.targetingBrowser.excluded) {
            this.browsers_filters = this.targetingBrowser.filters
          } else this.browsers_filters = []
        }
      },
      exclude_country_targeting: async function (val, oldVal) {
        if (this.targetingCountry.filters.length != 0) {
          if (val == this.targetingCountry.excluded) {
            this.country_filters = this.targetingCountry.filters
          } else this.country_filters = []
        }
      },
      exclude_firstparty_targeting: async function (val, oldVal) {
        if (this.targetingFirstParty.filters.length != 0) {
          if (val == this.targetingFirstParty.excluded) {
            this.firstparty_filters = this.targetingFirstParty.filters
          } else this.firstparty_filters = []
        }
      }*/
    },
    async created() {
      this.getCountries()
      this.getFirstPartyList()
      this.getCustomList()
      this.deal = this.selectedDeal
      if (this.$route.name == 'deal_edit')
        this.deal.proposal_id = this.selectedProposal.id
      try {
        const dealURL = await axios.get(
          `/api/getdealbyid/${this.$route.params.deal_id}`
        )
        this.SetSelectedDeal(dealURL.data)
        const proposalURL = await axios.get(
          `/api/getproposalbyid/${this.$route.params.proposal_id}`
        )
        this.SetSelectedProposal(proposalURL.data)
        this.deal = this.selectedDeal
      } catch (error) {
        console.log(error)
      }
      if (this.selectedProposal.deal_type == 'Guaranteed') {
        this.price_behavior_choices = [{ name: 'Fixed', value: 'FixedPrice' }]
        this.deal.auction_type = 'FixedPrice'
        this.no_end_date = false
      }
      this.getTargetings()
      this.start_time = this.getDateFromTimeStamp(this.deal.start_time)
      if (this.deal.start_time == 0) {
        this.start_time = this.getDefaultTimeNow()
      }
      if (this.deal.end_time != 0 && this.deal.end_time != null) {
        this.no_end_date = false
        this.end_time = this.getDateFromTimeStamp(this.deal.end_time)
      } else if (this.selectedProposal.deal_type != 'Guaranteed') {
        this.no_end_date = true
      }

      this.min_start_date = this.start_time
      if (this.deal.reset_interval > 60) {
        this.deal.reset_interval = this.deal.reset_interval / 60
        this.reset_interval_type = 'hour'
      }
      if (this.deal.creative_format == 'Display')
        this.size_choices = sizes_list_display
      else this.size_choices = sizes_list_video

      var sizes_db = this.deal.size

      if (sizes_db != '') {
        sizes_db = sizes_db.replace(/"/g, '')
        sizes_db = sizes_db.substring(1)
        sizes_db = sizes_db.substring(0, sizes_db.length - 1)
        var arr = sizes_db.split(',')
        this.selected_sizes = arr
      }
    },
    methods: {
      ...mapMutations([SetSelectedDeal, SetSelectedProposal]),
      ...mapActions([SaveSelectedDeal, UpdateSelectedDeal, GetCustomers]),

      back() {
        this.$router.push({
          name: 'deal_list',
          params: {
            proposal_id: this.$route.params.proposal_id
          }
        })
      },
      async submit() {
        if (this.selected_sizes.length > 0) {
          this.deal.size = this.manageSizes()
        }
        this.deal.name = this.deal.name.replace(/\t/g, '')
        var errorCustomEmpty = false
        if (
          this.custom_filters.customKey != '' ||
          this.custom_filters.customValue.length > 0
        ) {
          if (
            this.custom_filters.customKey == '' ||
            this.custom_filters.customValue.length == 0
          ) {
            errorCustomEmpty = true
          }

          if (this.customDynamicValues.customKey.length > 1) {
            this.customDynamicValues.customKey.forEach((element) => {
              if (element == '') errorCustomEmpty = true
            })
            this.customDynamicValues.customValue.forEach((element) => {
              if (element.length == 0) errorCustomEmpty = true
            })
          }
        }

        if (errorCustomEmpty == true) {
          this.previousCustomRecordEmpty = true
        } else {
          this.previousCustomRecordEmpty = false
        }

        if (
          !this.$refs.form.validate() ||
          this.errorDayAndTimeDynamicValues.length > 0 ||
          this.errorDayAndTime != '' ||
          this.previousCustomRecordEmpty == true
        ) {
          console.log('No fullfilled')
          return
        }

        this.submitLoading = true
        this.deal.start_time = new Date(this.start_time).getTime() / 1000
        if (this.end_time != '')
          this.deal.end_time = new Date(this.end_time).getTime() / 1000
        switch (this.reset_interval_type) {
          case 'hour':
            this.deal.reset_interval *= 60
            break
          case 'day':
            this.deal.reset_interval *= 60 * 24
            break
          default:
            break
        }
        if (this.$route.name == 'deal_edit') {
          const dealResponse = await this.UpdateSelectedDeal(this.deal).catch(
            () => {
              this.errors = this.getErrors
              this.submitLoading = false
              return false
            }
          )
          this.dealUpdatedMessage = 'Deal updated successfully'
        } else {
          const dealResponse = await this.SaveSelectedDeal(this.deal).catch(
            () => {
              this.errors = this.getErrors
              this.submitLoading = false
              return false
            }
          )
          this.dealUpdatedMessage = 'Deal created successfully'
        }
        this.manageTargeting()
        this.dealupdated = true
        this.submitLoading = false
        this.previousCustomRecordEmpty = false
        setTimeout(
          () =>
            this.$router.push({
              name: 'deal_list',
              proposal_id: this.$route.params.proposal_id
            }),
          1500
        )
      },
      manageSizes() {
        var toreturn = '['
        for (let i = 0; i < this.selected_sizes.length; i++) {
          if (i != this.selected_sizes.length - 1)
            toreturn = toreturn + '"' + this.selected_sizes[i] + '"' + ','
          else toreturn = toreturn + '"' + this.selected_sizes[i] + '"'
        }
        toreturn = toreturn + ']'
        return toreturn
      },
      async getTargetings() {
        try {
          const getTargetings = await axios.get(
            `/api/gettargetingbydealid/${this.deal.id}`
          )
          var targetings = getTargetings.data
          targetings.forEach((targeting) => {
            switch (targeting.criteria) {
              case 'Site':
                if (targeting.type == 'excluded') {
                  this.exclude_site_targeting = true
                  this.targetingSite.excluded = true
                } else {
                  this.exclude_site_targeting = false
                  this.targetingSite.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingSite.filters = newStr.split(',')
                this.targetingSite.filters.forEach((element) => {
                  this.sites_filters.push(element)
                })
                break
              case 'TagId':
                if (targeting.type == 'excluded') {
                  this.exclude_tagid_targeting = true
                  this.targetingTagId.excluded = true
                } else {
                  this.exclude_tagid_targeting = false
                  this.targetingTagId.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingTagId.filters = newStr.split(',')
                this.targetingTagId.filters.forEach((element) => {
                  this.tagid_filters.push(element)
                })
                break
              case 'Grapeshot':
                if (targeting.type == 'excluded') {
                  this.exclude_grapeshot_targeting = true
                  this.targetingGrapeshot.excluded = true
                } else {
                  this.exclude_grapeshot_targeting = false
                  this.targetingGrapeshot.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingGrapeshot.filters = newStr.split(',')
                this.targetingGrapeshot.filters.forEach((element) => {
                  this.grapeshot_filters.push(element)
                })
                break
              case 'Page':
                if (targeting.type == 'excluded') {
                  this.exclude_page_targeting = true
                  this.targetingPage.excluded = true
                } else {
                  this.exclude_page_targeting = false
                  this.targetingPage.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingPage.filters = newStr.split(',')
                this.targetingPage.filters.forEach((element) => {
                  this.page_filters.push(element)
                })
                break
              case 'Device':
                if (targeting.type == 'excluded') {
                  this.exclude_device_targeting = true
                  this.targetingDevice.excluded = true
                } else {
                  this.exclude_device_targeting = false
                  this.targetingDevice.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingDevice.filters = newStr.split(',')
                for (let i = 0; i < this.targetingDevice.filters.length; i++) {
                  switch (this.targetingDevice.filters[i]) {
                    case 'Smarttv':
                      this.targetingDevice.filters[i] = 'ConnectedTV'
                      break
                    case 'Desktop':
                      this.targetingDevice.filters[i] = 'PC'
                      break
                    case 'Mobile':
                      break
                    case 'Tablet':
                      break
                    default:
                      this.targetingDevice.filters[i] = 'Other'
                      break
                  }
                }
                this.targetingDevice.filters = [
                  ...new Set(this.targetingDevice.filters)
                ]
                this.targetingDevice.filters.sort()
                this.devices_filters = this.targetingDevice.filters
                break
              case 'Os':
                if (targeting.type == 'excluded') {
                  this.exclude_os_targeting = true
                  this.targetingOs.excluded = true
                } else {
                  this.exclude_os_targeting = false
                  this.targetingOs.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingOs.filters = newStr.split(',')
                this.os_filters = this.targetingOs.filters
                break
              case 'Browser':
                if (targeting.type == 'excluded') {
                  this.exclude_browser_targeting = true
                  this.targetingBrowser.excluded = true
                } else {
                  this.exclude_browser_targeting = false
                  this.targetingBrowser.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingBrowser.filters = newStr.split(',')
                this.browsers_filters = this.targetingBrowser.filters
                break
              case 'Connection':
                if (targeting.type == 'excluded') {
                  this.exclude_connection_targeting = true
                  this.targetingConnection.excluded = true
                } else {
                  this.exclude_connection_targeting = false
                  this.targetingConnection.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingConnection.filters = newStr.split(',')
                this.connections_filters = this.targetingConnection.filters
                break
              case 'Country':
                if (targeting.type == 'excluded') {
                  this.exclude_country_targeting = true
                  this.targetingCountry.excluded = true
                } else {
                  this.exclude_country_targeting = false
                  this.targetingCountry.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingCountry.filters = newStr.split(',')
                this.country_filters = this.targetingCountry.filters
                break
              case 'FirstParty':
                if (targeting.type == 'excluded') {
                  this.exclude_firstparty_targeting = true
                  this.targetingFirstParty.excluded = true
                } else {
                  this.exclude_firstparty_targeting = false
                  this.targetingFirstParty.excluded = false
                }
                var newStr = targeting.filters.replace(/"/g, '')
                newStr = newStr.substring(1, newStr.length - 1)
                this.targetingFirstParty.filters = newStr.split(',')
                if (this.targetingFirstParty.filters.length == 1) {
                  this.firstparty_filters = this.targetingFirstParty.filters
                } else if (this.targetingFirstParty.filters.length > 1)
                  this.getFirstPartyValues(targeting.filters)
                break
              case 'Custom':
                if (targeting.type == 'excluded') {
                  this.custom_filters.customSelectMode = 'excluded'
                  this.targetingCustom.type = 'excluded'
                } else if (targeting.type == 'included') {
                  this.custom_filters.customSelectMode = 'included'
                  this.targetingCustom.type = 'included'
                } else {
                  this.custom_filters.customSelectMode = 'custom'
                  this.targetingCustom.type = 'custom'
                }
                this.targetingCustom.recordExists = true
                this.getCustomValues(targeting.type, targeting.filters)
                break
              case 'DayAndTime':
                if (targeting.type == 'excluded') {
                  this.exclude_dayandtime_targeting = true
                  this.targetingDayAndTime.excluded = true
                } else {
                  this.exclude_dayandtime_targeting = false
                  this.targetingDayAndTime.excluded = false
                }
                this.getDayAndTimeValues(targeting.filters)
                break
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      getDayAndTimeValues(filters) {
        var arr = JSON.parse(filters)
        this.targetingCountry.filters = arr
        if (arr.length > 0) {
          this.dayAndTimeSegmentsCount = arr.length - 1
          this.dayandtime_start_filters = arr[0].hours.start
          this.dayandtime_end_filters = arr[0].hours.end
          arr[0].day.forEach((day) => {
            switch (day) {
              case 'Mon':
                this.daysoftheweek.push(0)
                break
              case 'Tue':
                this.daysoftheweek.push(1)
                break
              case 'Wed':
                this.daysoftheweek.push(2)
                break
              case 'Thu':
                this.daysoftheweek.push(3)
                break
              case 'Fri':
                this.daysoftheweek.push(4)
                break
              case 'Sat':
                this.daysoftheweek.push(5)
                break
              case 'Sun':
                this.daysoftheweek.push(6)
                break
              default:
                break
            }
          })
          for (let i = 1; i < arr.length; i++) {
            this.dayAndTimeStartDynamicValues[i] = arr[i].hours.start
            this.dayAndTimeEndDynamicValues[i] = arr[i].hours.end
            this.daysoftheweekDynamicValues[1] = [3, 4]
            this.daysoftheweekDynamicValues[2] = [0, 1]
            var array = []
            arr[i].day.forEach((day) => {
              switch (day) {
                case 'Mon':
                  array.push(0)
                  break
                case 'Tue':
                  array.push(1)
                  break
                case 'Wed':
                  array.push(2)
                  break
                case 'Thu':
                  array.push(3)
                  break
                case 'Fri':
                  array.push(4)
                  break
                case 'Sat':
                  array.push(5)
                  break
                case 'Sun':
                  array.push(6)
                  break
                default:
                  break
              }
            })
            this.daysoftheweekDynamicValues[i] = array
          }
        }
      },
      getFirstPartyValues(filters) {
        var newStr = filters.replace(/"/g, '')
        newStr = newStr.substring(1, newStr.length - 1)
        let crochet2 = newStr.indexOf(']')
        var str = newStr.substring(1, crochet2)
        if (crochet2 == -1) {
          this.targetingFirstParty.filters = newStr.split(',')
          this.firstparty_filters = this.targetingFirstParty.filters
        } else {
          this.targetingFirstParty.filters = str.split(',')
          this.firstparty_filters = this.targetingFirstParty.filters
          newStr = newStr.replace(newStr.substring(0, crochet2 + 2), '')
          if (newStr.indexOf(']') != -1) {
            this.firstPartyDynamicValues.push(null)
            do {
              this.firstPartySegmentsCount += 1
              let crochet = newStr.indexOf(']')
              var toto = newStr.substring(1, crochet)
              newStr = newStr.replace(newStr.substring(0, crochet + 2), '')
              var arr = toto.split(',')
              this.firstPartyDynamicValues.push(arr)
            } while (newStr.indexOf(']') != -1)
          }
        }
      },
      addFirstParty: function () {
        this.firstPartySegmentsCount++
      },
      removeFirstParty: function (key) {
        this.firstPartyDynamicValues.splice(key, 1)
        this.firstPartySegmentsCount--
      },
      getCustomValues(type, filters) {
        var arr = JSON.parse(filters)
        if (type != 'custom') {
          this.custom_filters.customKey = arr[0].key
          this.custom_filters.customValue = arr[0].value
          this.custom_filters.customSelectMode = type
        } else {
          if ('or' in arr[0] === true) {
            this.custom_filters.customKey = arr[0].or[0].key
            this.custom_filters.customValue = arr[0].or[0].value
            this.custom_filters.customSelectMode = arr[0].or[0].type
            for (let index = 1; index < arr[0].or.length; index++) {
              this.customDynamicValues.customKey[index] = arr[0].or[index].key
              this.customDynamicValues.customValue[index] =
                arr[0].or[index].value
              this.customDynamicValues.customSelectMode[index] =
                arr[0].or[index].type
              this.customDynamicValues.operator[index] = arr[0].or[
                index
              ].operator = 'OR'
              this.customSegmentsCount++
            }
          } else {
            for (let i = 0; i < arr[0].and.length; i++) {
              if ('or' in arr[0].and[i] == true) {
                for (let j = 0; j < arr[0].and[i].or.length; j++) {
                  if (i == 0 && j == 0) {
                    this.custom_filters.customKey = arr[0].and[i].or[j].key
                    this.custom_filters.customValue = arr[0].and[i].or[j].value
                    this.custom_filters.customSelectMode =
                      arr[0].and[i].or[j].type
                  } else {
                    this.customSegmentsCount++
                    this.customDynamicValues.customKey[
                      this.customSegmentsCount
                    ] = arr[0].and[i].or[j].key
                    this.customDynamicValues.customValue[
                      this.customSegmentsCount
                    ] = arr[0].and[i].or[j].value
                    this.customDynamicValues.customSelectMode[
                      this.customSegmentsCount
                    ] = arr[0].and[i].or[j].type
                    if (j == 0) {
                      this.customDynamicValues.operator[
                        this.customSegmentsCount
                      ] = arr[0].and[i].or[j].operator = 'AND'
                    } else {
                      this.customDynamicValues.operator[
                        this.customSegmentsCount
                      ] = arr[0].and[i].or[j].operator = 'OR'
                    }
                  }
                }
              } else {
                if (i == 0) {
                  this.custom_filters.customKey = arr[0].and[i].key
                  this.custom_filters.customValue = arr[0].and[i].value
                  this.custom_filters.customSelectMode = arr[0].and[i].type
                } else {
                  this.customSegmentsCount++
                  this.customDynamicValues.customKey[this.customSegmentsCount] =
                    arr[0].and[i].key
                  this.customDynamicValues.customValue[
                    this.customSegmentsCount
                  ] = arr[0].and[i].value
                  this.customDynamicValues.customSelectMode[
                    this.customSegmentsCount
                  ] = arr[0].and[i].type
                  this.customDynamicValues.operator[this.customSegmentsCount] =
                    arr[0].and[i].operator = 'AND'
                }
              }
            }
          }
        }
      },
      addCustom: async function (operator) {
        this.customSegmentsCount++
        this.customDynamicValues.customKey[this.customSegmentsCount] = ''
        this.customDynamicValues.customSelectMode[this.customSegmentsCount] =
          'included'
        this.customDynamicValues.customValue[this.customSegmentsCount] = []
        this.customDynamicValues.operator[this.customSegmentsCount] = operator
      },
      removeCustom: function (key) {
        this.previousCustomRecordEmpty = false
        this.customDynamicValues.customKey.splice(key, 1)
        this.customDynamicValues.customValue.splice(key, 1)
        this.customDynamicValues.customSelectMode.splice(key, 1)
        this.customDynamicValues.operator.splice(key, 1)
        this.customSegmentsCount--
      },
      addDayAndTime: function () {
        this.dayAndTimeSegmentsCount++
      },
      removeDayAndTime: function (key) {
        this.dayAndTimeStartDynamicValues.splice(key, 1)
        this.dayAndTimeEndDynamicValues.splice(key, 1)
        this.daysoftheweekDynamicValues.splice(key, 1)
        this.dayAndTimeSegmentsCount--
      },
      manageDayAndTimeTargeting() {
        this.errorDayAndTime = ''
        this.errorDayAndTimeDynamicValues = []
        var dayandtime_filters_db = ''
        if (
          this.dayandtime_start_filters == null &&
          this.dayandtime_end_filters == null
        )
          return ''
        else {
          if (
            this.dayandtime_start_filters != null &&
            this.dayandtime_end_filters == null
          ) {
            this.errorDayAndTime = 'Please select an end time for your period'
          } else if (
            this.dayandtime_start_filters == null &&
            this.dayandtime_end_filters != null
          ) {
            this.errorDayAndTime = 'Please select a start time for your period'
          } else {
            dayandtime_filters_db =
              '[' +
              this.getFormatJson(
                this.dayandtime_start_filters,
                this.dayandtime_end_filters,
                this.daysoftheweek
              )
          }
        }
        if (this.dayAndTimeSegmentsCount != 0) {
          for (let i = 1; i <= this.dayAndTimeSegmentsCount; i++) {
            if (
              this.dayAndTimeStartDynamicValues[i] != undefined &&
              this.dayAndTimeEndDynamicValues[i] == undefined
            ) {
              this.errorDayAndTimeDynamicValues.push(
                'Please select an end time for your period'
              )
            } else if (
              this.dayAndTimeStartDynamicValues[i] == undefined &&
              this.dayAndTimeEndDynamicValues[i] != undefined
            ) {
              this.errorDayAndTimeDynamicValues.push(
                'Please select a start time for your period'
              )
            } else if (
              this.dayAndTimeStartDynamicValues[i] != undefined &&
              this.dayAndTimeEndDynamicValues[i] != undefined
            ) {
              dayandtime_filters_db =
                dayandtime_filters_db +
                ',' +
                this.getFormatJson(
                  this.dayAndTimeStartDynamicValues[i],
                  this.dayAndTimeEndDynamicValues[i],
                  this.daysoftheweekDynamicValues[i]
                )
            }
          }
        }
        if (this.errorDayAndTimeDynamicValues.length == 0) {
          dayandtime_filters_db = dayandtime_filters_db + ']'
          return dayandtime_filters_db
        } else {
          return ''
        }
      },
      async manageTargeting() {
        let devices_selected = this.devices_filters
        if (devices_selected.length > 0) {
          for (let i = 0; i < devices_selected.length; i++) {
            switch (devices_selected[i]) {
              case 'ConnectedTV':
                devices_selected[i] = 'Smarttv'
                break
              case 'PC':
                devices_selected[i] = 'Desktop'
                break
              case 'Mobile':
                break
              case 'Tablet':
                break
              default:
                devices_selected[i] = 'Other'
                break
            }
          }
        }
        if (this.country_filters.includes('FR-MTP')) {
          const index = this.country_filters.indexOf('FR-MTP')
          if (index != -1) {
            this.country_filters.splice(index, 1)
            this.countriesRgDptList.forEach((element) => {
              if (element.id == 'FR-MTP') {
                element.children.forEach((rg) => {
                  this.country_filters.push(rg.id)
                })
              }
            })
          }
        }
        if (this.country_filters.includes('FR-OUTRE')) {
          const index = this.country_filters.indexOf('FR-OUTRE')
          if (index != -1) {
            this.country_filters.splice(index, 1)
            this.countriesRgDptList.forEach((element) => {
              if (element.id == 'FR-OUTRE') {
                element.children.forEach((rg) => {
                  this.country_filters.push(rg.id)
                })
              }
            })
          }
        }
        devices_selected = [...new Set(devices_selected)]
        var sites_filters_db = this.getFormatArray(this.sites_filters)
        var tagid_filters_db = this.getFormatArray(this.tagid_filters)
        var grapeshot_filters_db = this.getFormatArray(this.grapeshot_filters)
        var page_filters_db = this.getFormatArray(this.page_filters)
        var country_filters_db = this.getFormatArray(this.country_filters)
        var devices_filters_db = this.getFormatArray(devices_selected)
        var browsers_filters_db = this.getFormatArray(this.browsers_filters)
        var connections_filters_db = this.getFormatArray(
          this.connections_filters
        )
        var dayandtime_filters_db = this.manageDayAndTimeTargeting()
        var os_filters_db = this.getFormatArray(this.os_filters)
        var firstparty_filters_db = ''
        if (this.firstPartySegmentsCount == 0)
          firstparty_filters_db = this.getFormatArray(this.firstparty_filters)
        else {
          var dynamicValues = this.getFormatArray(this.firstparty_filters)
          this.firstPartyDynamicValues.forEach((value) => {
            if (value != null) {
              if (value.length != 0)
                dynamicValues = dynamicValues + ',' + this.getFormatArray(value)
            }
          })
          firstparty_filters_db = '[' + dynamicValues + ']'
        }

        var custom_filters_db = ''
        if (this.customSegmentsCount == 0)
          custom_filters_db = this.getFormatJsonCustom('single')
        else {
          custom_filters_db = this.getFormatJsonCustom('multiple')
        }

        if (
          this.errorDayAndTimeDynamicValues.length > 0 ||
          this.errorDayAndTime != ''
        ) {
          return
        } else {
          if (sites_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Site',
              type:
                this.exclude_site_targeting == false ? 'included' : 'excluded',
              filters: sites_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingSite.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Site'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (tagid_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'TagId',
              type:
                this.exclude_tagid_targeting == false ? 'included' : 'excluded',
              filters: tagid_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingTagId.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'TagId'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (grapeshot_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Grapeshot',
              type:
                this.exclude_grapeshot_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: grapeshot_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingGrapeshot.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Grapeshot'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (page_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Page',
              type:
                this.exclude_page_targeting == false ? 'included' : 'excluded',
              filters: page_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingPage.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Page'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (country_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Country',
              type:
                this.exclude_country_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: country_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingCountry.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Country'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (devices_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Device',
              type:
                this.exclude_device_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: devices_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingDevice.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Device'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (browsers_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Browser',
              type:
                this.exclude_browser_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: browsers_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingBrowser.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Browser'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (connections_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Connection',
              type:
                this.exclude_connection_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: connections_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingConnection.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Connection'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (dayandtime_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'DayAndTime',
              type:
                this.exclude_dayandtime_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: dayandtime_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingDayAndTime.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'DayAndTime'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }

          if (os_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Os',
              type:
                this.exclude_os_targeting == false ? 'included' : 'excluded',
              filters: os_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingOs.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Os'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (firstparty_filters_db != '' && firstparty_filters_db != '[]') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'FirstParty',
              type:
                this.exclude_firstparty_targeting == false
                  ? 'included'
                  : 'excluded',
              filters: firstparty_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingFirstParty.filters.length > 0) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'FirstParty'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
          if (custom_filters_db != '') {
            var targeting = {
              deal_id: this.selectedDeal.id,
              criteria: 'Custom',
              type:
                this.customDynamicValues.customKey.length > 1
                  ? 'custom'
                  : this.custom_filters.customSelectMode,
              filters: custom_filters_db
            }
            try {
              await axios.post(`/api/ttdtargeting`, targeting)
            } catch (error) {
              console.log(error)
            }
          } else {
            if (this.targetingCustom.recordExists == true) {
              var targeting = {
                deal_id: this.selectedDeal.id,
                criteria: 'Custom'
              }
              try {
                await axios.post(`/api/deletetargeting`, targeting)
              } catch (error) {
                console.log(error)
              }
            }
          }
        }
      },
      getFormatJsonCustom(type) {
        if (type == 'single') {
          if (
            this.custom_filters.customKey == '' ||
            this.custom_filters.customValue.length == 0
          )
            return ''
          else {
            var values = '['
            this.custom_filters.customValue.forEach((value) => {
              values += '"' + value + '",'
            })
            values = values.slice(0, -1)
            values += ']'
            var str_toReturn = '[{"key":"'
            str_toReturn +=
              this.custom_filters.customKey + '","value":' + values + '}]'
            return str_toReturn
          }
        } else {
          var andExists = false
          this.customDynamicValues.operator.forEach((operator) => {
            if (operator == 'AND') andExists = true
            return
          })
          if (andExists == false) {
            var str_toReturn = '[{"or":['
            str_toReturn += this.getJsonPart('default', 1)
            for (
              let i = 1;
              i < this.customDynamicValues.customKey.length;
              i++
            ) {
              str_toReturn += this.getJsonPart('dynamic', i)
            }
            str_toReturn = str_toReturn.slice(0, -1)
            str_toReturn += ']}]'

            return str_toReturn
          } else {
            var str_toReturn = '[{"and": ['
            var orOperator = 0
            var default_custom = this.getJsonPart('default', 1)
            if (this.customDynamicValues.operator[1] == 'AND') {
              if (this.customDynamicValues.operator[2] == 'OR') {
                orOperator = 1
                str_toReturn +=
                  default_custom + '{"or":[' + this.getJsonPart('dynamic', 1)
              } else {
                str_toReturn += default_custom + this.getJsonPart('dynamic', 1)
              }
            } else {
              orOperator = 1
              str_toReturn +=
                '{"or":[' + default_custom + this.getJsonPart('dynamic', 1)
            }
            if (this.customDynamicValues.customKey.length == 2) {
              str_toReturn = str_toReturn.slice(0, -1)
              str_toReturn += ']}]'
            }
            for (
              let i = 2;
              i < this.customDynamicValues.customKey.length;
              i++
            ) {
              if (this.customDynamicValues.operator[i] == 'AND') {
                if (orOperator == 1) {
                  str_toReturn = str_toReturn.slice(0, -1)
                  str_toReturn += ']},'
                  if (this.customDynamicValues.operator[i + 1] == 'OR') {
                    str_toReturn += '{"or":[' + this.getJsonPart('dynamic', i)

                    orOperator = 1
                  } else {
                    str_toReturn += this.getJsonPart('dynamic', i)
                    orOperator = 0
                  }
                } else {
                  if (this.customDynamicValues.operator[i + 1] == 'OR') {
                    str_toReturn += '{"or":[' + this.getJsonPart('dynamic', i)
                    orOperator = 1
                  } else {
                    str_toReturn += this.getJsonPart('dynamic', i)
                  }
                }
              } else {
                if (orOperator == 1) {
                  str_toReturn += this.getJsonPart('dynamic', i)
                } else {
                  str_toReturn += '{"or":[' + this.getJsonPart('dynamic', i)
                  orOperator = 1
                }
              }
              if (i == this.customDynamicValues.customKey.length - 1) {
                str_toReturn = str_toReturn.slice(0, -1)
                str_toReturn += ']}]'
              }

              /*  if (this.customDynamicValues.customKey.length > 1) {
                str_toReturn = str_toReturn.slice(0, -1)
                str_toReturn += ']}]'
              }*/
            }
            try {
              JSON.parse(str_toReturn)
            } catch (e) {
              //Error
              str_toReturn += '}]'
            }
            return str_toReturn
          }
        }
      },

      getJsonPart(type, index) {
        if (type == 'default') {
          var values = '['
          this.custom_filters.customValue.forEach((value) => {
            values += '"' + value + '",'
          })
          values = values.slice(0, -1)
          values += ']'
          return (
            '{"key":"' +
            this.custom_filters.customKey +
            '","value":' +
            values +
            ',"type":"' +
            this.custom_filters.customSelectMode +
            '"},'
          )
        } else {
          var values = '['
          this.customDynamicValues.customValue[index].forEach((value) => {
            values += '"' + value + '",'
          })
          values = values.slice(0, -1)
          values += ']'
          return (
            '{"key":"' +
            this.customDynamicValues.customKey[index] +
            '","value":' +
            values +
            ',"type":"' +
            this.customDynamicValues.customSelectMode[index] +
            '"},'
          )
        }
      },

      getFormatArray(array) {
        if (array.length == 0) return ''
        var str_toReturn = ''
        str_toReturn = '['
        array.forEach((x) => {
          if (array.indexOf(x) != array.length - 1)
            str_toReturn += '"' + x + '"' + ','
          else str_toReturn += '"' + x + '"'
        })
        str_toReturn += ']'
        return str_toReturn
      },
      getFormatJson(start, end, days) {
        if (start == null && end == null) return ''
        var daysarray = '['
        if (days != undefined && days.length > 0) {
          if (days.length > 0) {
            days.forEach((day) => {
              switch (day) {
                case 0:
                  daysarray += '"Mon",'
                  break
                case 1:
                  daysarray += '"Tue",'
                  break

                case 2:
                  daysarray += '"Wed",'
                  break

                case 3:
                  daysarray += '"Thu",'
                  break

                case 4:
                  daysarray += '"Fri",'
                  break
                case 5:
                  daysarray += '"Sat",'
                  break
                case 6:
                  daysarray += '"Sun",'
                  break
              }
            })
            daysarray = daysarray.slice(0, -1)
            daysarray += '],'
          }
        } else {
          daysarray += '],'
        }

        var str_toReturn = ''
        str_toReturn = '{'
        str_toReturn +=
          '"day":' +
          daysarray +
          '"hours":{"start":"' +
          start +
          '","end":"' +
          end +
          '"}}'
        return str_toReturn
      },
      getDateFromTimeStamp(timestamp) {
        const date = new Date(timestamp * 1000)

        var year = date.getFullYear()
        var month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
        var fulldate = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = fulldate + 'T' + time
        return dateTime
      },
      getDefaultTimeNow() {
        var today = new Date()
        var year = today.getFullYear()
        var month = today.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        var day = today.getDate()
        if (day < 10) {
          day = '0' + day
        }
        var hours = (today.getHours() < 10 ? '0' : '') + today.getHours()
        var minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
        var date = year + '-' + month + '-' + day
        var time = hours + ':' + minutes
        var dateTime = date + 'T' + time
        return dateTime
      },
      addSiteFilters(site) {
        if (site != '') {
          site = this.getWwwFormat(site)
          var exists = false
          this.sites_filters.forEach((element) => {
            if (element == site) {
              exists = true
            }
          })
          if (exists == false) {
            this.sites_filters.push(site)
            this.site_textfield = ''
          } else {
            this.errorSiteFilters = 'You have already added this site'
            setTimeout(() => (this.errorSiteFilters = ''), 2000)
          }
        }
      },
      deleteSiteFilters(index) {
        this.sites_filters.splice(index, 1)
      },
      deleteFirstPartyFilters(index) {
        this.firstparty_filters.splice(index, 1)
      },
      deleteCustomFilters(index) {
        this.custom_filters.splice(index, 1)
      },
      getWwwFormat(site) {
        if (site.toLowerCase().startsWith('https://www.') == true) {
          site = site.toLowerCase().replace('https://www.', '')
        } else if (site.toLowerCase().startsWith('http://www.') == true) {
          site = site.toLowerCase().replace('http://www.', '')
        } else if (site.toLowerCase().startsWith('https:/www.') == true) {
          site = site.toLowerCase().replace('https:/www.', '')
        } else if (site.toLowerCase().startsWith('http:/www.') == true) {
          site = site.toLowerCase().replace('http:/www.', '')
        } else if (
          site.toLowerCase().startsWith('https://') == true &&
          site.toLowerCase().substring(7, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('https://', '')
        } else if (
          site.toLowerCase().startsWith('http://') == true &&
          site.toLowerCase().substring(6, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('http://', '')
        } else if (
          site.toLowerCase().startsWith('https:/') == true &&
          site.toLowerCase().substring(6, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('https:/', '')
        } else if (
          site.toLowerCase().startsWith('http:/') == true &&
          site.toLowerCase().substring(5, 4) != 'www.'
        ) {
          site = site.toLowerCase().replace('http:/', '')
        } else if (site.toLowerCase().startsWith('www.') == true) {
          site = site.toLowerCase().replace('www.', '')
        }
        if (site.substr(site.length - 1) == '/') {
          site = site.substring(0, site.length - 1)
        }
        return site.toLowerCase()
      },
      async getCountries() {
        try {
          const countriesResponse = await axios.get(`/api/getcountries`)
          this.countriesList = countriesResponse.data
          this.countriesRgDptList.forEach((element) => {
            element.children.forEach((el) => {
              el.children.forEach((dp) => {
                const code = dp.id.toString().replace('FR-', '')
                if (dp.label.includes(code) == false) {
                  dp.label = dp.label + ' (' + code + ')'
                }
              })
            })
          })
          this.countriesList.forEach((country) => {
            if (country.id == 'FR') {
              country.children = this.countriesRgDptList
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getFirstPartyList() {
        try {
          const customerURL = await axios.get(
            `/api/getcustomerbyid/${this.$route.params.customer_id}`
          )
          var customer_selected = customerURL.data
          const firstpartyResponse = await axios.get(`/api/getfirstparty`)
          var all_segments = firstpartyResponse.data
          var activated_segments_bdd = customer_selected.activated_segments
          if (activated_segments_bdd != '') {
            activated_segments_bdd = activated_segments_bdd.substring(1)
            activated_segments_bdd = activated_segments_bdd.substring(
              0,
              activated_segments_bdd.length - 1
            )
            var arr = activated_segments_bdd.split(',')
            arr.forEach((element) => {
              all_segments.forEach((segment) => {
                if (element == segment.id_bdd) {
                  this.firstpartyList.push(segment)
                }
              })
            })
          } else {
            this.firstpartyList = all_segments
          }

          this.firstpartyList.forEach((element) => {
            element.label += this.numberWithSpaces(element.population) + ')'
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getCustomList() {
        try {
          const customerURL = await axios.get(
            `/api/getcustomerbyid/${this.$route.params.customer_id}`
          )
          var customer_selected = customerURL.data
          const customResponse = await axios.get(`/api/getcustom`)
          var all_segments = customResponse.data
          var activated_segments_bdd = customer_selected.activated_segments
          if (activated_segments_bdd != '') {
            activated_segments_bdd = activated_segments_bdd.substring(1)
            activated_segments_bdd = activated_segments_bdd.substring(
              0,
              activated_segments_bdd.length - 1
            )
            var arr = activated_segments_bdd.split(',')
            arr.forEach((element) => {
              all_segments.forEach((segment) => {
                if (element == segment.id_bdd) {
                  this.customList.push(segment)
                }
              })
            })
          } else {
            this.customList = all_segments
          }

          this.customList.forEach((element) => {
            element.label += this.numberWithSpaces(element.population) + ')'
          })
        } catch (error) {
          console.log(error)
        }
      },
      addAdvertiser(advertiser) {
        if (advertiser != '') {
          var exists = false
          this.advertiser_filters.forEach((element) => {
            if (element == advertiser) {
              exists = true
            }
          })
          if (exists == false) {
            this.advertiser_filters.push(advertiser)
            this.advertiser_tf = ''
          } else {
            this.errorAdvertiserFilters =
              'You have already added this advertiser'
            setTimeout(() => (this.errorAdvertiserFilters = ''), 2000)
          }
        }
      },
      deleteAdvertiserFilters(index) {
        this.advertiser_filters.splice(index, 1)
      },
      addTagIdFilters(tagid) {
        if (tagid != '') {
          var exists = false
          this.tagid_filters.forEach((element) => {
            if (element == tagid) {
              exists = true
            }
          })
          if (exists == false) {
            this.tagid_filters.push(tagid)
            this.tagid_textfield = ''
          } else {
            this.errorTagIdFilters = 'You have already added this tag id'
            setTimeout(() => (this.errorTagIdFilters = ''), 2000)
          }
        }
      },
      deleteTagIdFilters(index) {
        this.tagid_filters.splice(index, 1)
      },
      addGrapeshotFilters(grapeshot) {
        if (grapeshot != '') {
          var exists = false
          this.grapeshot_filters.forEach((element) => {
            if (element == grapeshot) {
              exists = true
            }
          })
          if (exists == false) {
            this.grapeshot_filters.push(grapeshot)
            this.grapeshot_textfield = ''
          } else {
            this.errorGrapeshotFilters = 'You have already added this grapeshot'
            setTimeout(() => (this.errorGrapeshotFilters = ''), 2000)
          }
        }
      },
      addPageFilters(page) {
        if (page != '') {
          var exists = false
          this.page_filters.forEach((element) => {
            if (element == page) {
              exists = true
            }
          })
          if (exists == false) {
            this.page_filters.push(page)
            this.page_textfield = ''
          } else {
            this.errorPageFilters = 'You have already added this page name'
            setTimeout(() => (this.errorPageFilters = ''), 2000)
          }
        }
      },
      deleteGrapeshotFilters(index) {
        this.grapeshot_filters.splice(index, 1)
      },
      deletePageFilters(index) {
        this.page_filters.splice(index, 1)
      },
      numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      },
      nameKeydown(e) {
        if (!/^[A-Za-z0-9]*$/.test(e.key)) {
          e.preventDefault()
        }
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .expansion_panel {
    padding: 20px;
    margin-left: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 30px;
  }
  .v-expansion-panels {
    z-index: unset;
    box-shadow: 0.2px 0.2px 0.5px 0.5px lightgrey;
  }
  .v-expansion-panel {
    border: 0.5px solid lightgrey;
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
      .v-expansion-panels--tile
    )
    > .v-expansion-panel--active
    + .v-expansion-panel {
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
      .v-expansion-panels--tile
    )
    > .v-expansion-panel--active {
    margin-top: 0px;
  }
  .float-container {
    border: 3px solid #fff;
  }
  .site_targeting_btns {
    width: 6%;
    float: left;
    padding: 5px;
  }
  .site_div,
  .tagid_div,
  .grapeshot_div,
  .page_div,
  .advertiser_div {
    width: 70%;
    margin: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: scroll;
  }

  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
  .remove_group {
    padding-left: 5px;
  }
  .float-container {
    padding: 20px;
  }

  .float-child {
    float: left;
    padding: 20px;
  }
</style>
