<template>
  <v-app id="inspire">
    <router-view></router-view>
  </v-app>
</template>

<script>
  import '@/assets/styles/style.css'

  export default {}
</script>
