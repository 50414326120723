module.exports = [
  {
    id: 'Cellular',
    label: 'Cellular'
  },
  {
    id: 'Wifi',
    label: 'Wifi'
  },
]
