module.exports = [
    {
        id: '1',
        label: '1 - Streaming'
    },
    {
        id: '2',
        label: '2 - Progressive'
    },
    {
        id: '3',
        label: '3 - Download'
    }
]
