<template>
  <v-app id="inspire" style="background-color: white">
    <app-topbarLogin></app-topbarLogin>
    <v-container fluid style="padding-top: 100px">
      <v-row>
        <v-col cols="4"
          ><h1 class="cnx">
            <strong>NEXX360 PROGRAMMATIC CLOUD</strong>
          </h1>
          <textleft />
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="7">
          <div id="okta-signin-container" style="margin-left: 25px"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> </v-col>
        <v-col cols="8" style="text-align: center">
          <p class="registerLogin">
            {{ $t('not_registered_yet') }}
            <router-link v-slot="{ navigate }" to="/register" custom>
              <span
                style="color: #2e4089"
                role="button"
                @click="navigate"
                @keypress.enter="navigate"
                ><strong>{{ $t('contact_us') }}</strong></span
              >
            </router-link>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import OktaSignIn from '@okta/okta-signin-widget'
  import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
  import topbarLogin from '@/components/layout/topbarLogin.vue'
  import textleft from '@/pages/login/components/textleft.vue'
  import '@/assets/styles/style.css'
  import config from '@/config'
  import axios from 'axios'

  export default {
    name: 'Login',
    components: { appTopbarLogin: topbarLogin, textleft },
    data() {
      return {}
    },

    async created() {
      let response = await axios.get(`/api/getappusers`)
    },
    mounted: function () {
      this.$nextTick(function () {
        this.widget = new OktaSignIn({
          /**
           * Note: when using the Sign-In Widget for an OIDC flow, it still
           * needs to be configured with the base URL for your Okta Org. Here
           * we derive it from the given issuer for convenience.
           */
          language: function (supportedLanguages) {
            supportedLanguages = ['en', 'fr']
            return supportedLanguages[0]
          },
          baseUrl: config.oidc.issuer.split('/oauth2')[0],
          clientId: config.oidc.clientId,
          redirectUri: config.oidc.redirectUri,
          //logo: require('@/assets/fnaclogo.png'),
          i18n: {
            fr: {
              'primaryauth.title': 'Se connecter'
            }
          },
          authParams: {
            pkce: true,
            issuer: config.oidc.issuer,
            display: 'page',
            scopes: config.oidc.scopes
          }
        })
        this.widget.renderEl(
          { el: '#okta-signin-container' },
          () => {
            /**
             * In this flow, the success handler will not be called because we redirect
             * to the Okta org for the authentication workflow.
             */
          },
          (err) => {
            throw err
          }
        )
      })
    },
    destroyed() {
      // Remove the widget from the DOM on path change
      this.widget.remove()
    }
  }
</script>

<style>
  #okta-sign-in.auth-container.main-container {
    background-color: white;
    font-family: Roboto;
    border-width: 0px;
    width: 90%;
    letter-spacing: 0.4px;
    box-shadow: none;
    margin-left: 8%;
  }

  #okta-sign-in.auth-container .okta-sign-in-header {
    display: none;
  }
  #okta-sign-in.auth-container .button {
    background-color: black;
    background-image: initial;
    border-width: 0px;
  }
  #okta-sign-in.auth-container .button:hover,
  #okta-sign-in.auth-container .button:focus {
    background-color: #f39200;
    background-image: initial;
    border-width: 0px;
  }
  .cnx {
    letter-spacing: 2px;
    padding-left: 1%;
  }
  .registerLogin {
    font-size: 1em;
    margin-right: 20px;
  }
</style>
