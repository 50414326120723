import { GetCreatives } from '@/store/action_types'

import { SetCreatives } from '@/store/mutation_types'

import axios from 'axios'


export const creativesState = {
  creatives: []
}

export const creativesGetters = {
  creatives: (state) => state.creatives
}

export const creativesActions = {
  async [GetCreatives]({ commit }, line_item_id) {
    let url = `/api/dsplineitems/dspcreatives/${line_item_id}`
    const response = await axios.get(url)
    commit(SetCreatives, response.data)
    return response
  },
}

export const creativesMutations = {
  [SetCreatives](state, creatives) {
    state.creatives = creatives
  }
}

export default creativesActions
