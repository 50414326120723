import { GetLineItems } from '@/store/action_types'

import { SetLineItems } from '@/store/mutation_types'

import axios from 'axios'


export const lineitemsState = {
  lineitems: []
}

export const lineitemsGetters = {
  lineitems: (state) => state.lineitems
}

export const lineitemsActions = {
  async [GetLineItems]({ commit }, order_id) {
    let url = `/api/dsporders/dsplineitems/${order_id}`
    const response = await axios.get(url)
    commit(SetLineItems, response.data)
    return response
  },
}

export const lineitemsMutations = {
  [SetLineItems](state, lineitems) {
    state.lineitems = lineitems
  }
}

export default lineitemsActions
