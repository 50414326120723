<template>
  <v-container fluid style="padding-top: 30px">
    <v-row no-gutters>
      <v-col lg10 offset-lg1 style="padding-left: 50px">
        <h1 class="text-xs-center">{{ $t('welcome on Nexx360') }}</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col lg10 offset-lg1 style="padding-left: 50px">
        <p style="font-size: 15px">
          {{ $t('home_description_firstline') }}.<br />{{
            $t('home_description_secondline')
          }}.
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters style="padding-top: 20px">
      <v-col cols="12">
        <v-container style="padding-left: 90px">
          <v-row no-gutters dense>
            <v-col cols="2" />
            <v-col cols="4">
              <v-card width="280" height="270">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    {{ $t('amp_header_biding') }}
                  </span>
                </v-card-title>
                <v-card-text style="height: 80px; width: 250px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p style="font-size: 15px">
                          {{ $t('amp_header_biding_description') }}
                        </p></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="padding-top: 50px; text-align: center">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        width="150"
                        class="ma-2 notcapital"
                        tile
                        outlined
                        color="black"
                        @click="
                          $router.push({
                            name: 'site_list',
                            params: {
                              customer_id: $store.state.appCustomer.id
                            }
                          })
                        "
                      >
                        {{ $t('Set_Up') }}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card width="280" height="270">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    {{ $t('web_server_side') }}
                  </span>
                </v-card-title>
                <v-card-text style="height: 80px; width: 270px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p style="font-size: 15px">
                          {{ $t('web_server_side_description') }}
                        </p></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="padding-top: 50px; text-align: center">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        width="150"
                        class="ma-2 notcapital"
                        tile
                        outlined
                        color="black"
                        @click="
                          $router.push({
                            name: 'site_list',
                            params: {
                              customer_id: $store.state.appCustomer.id
                            }
                          })
                        "
                      >
                        {{ $t('Set_Up') }}
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="2" />

            <!-- <v-col>
              <v-card width="280" height="380">
                <v-card-title>
                  <span
                    style="color: black; font-size: 17px; text-indent: 10px"
                  >
                    Cookie less monetisation
                  </span>
                </v-card-title>
                <v-card-text style="height: 180px">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p style="font-size: 15px">
                          Solution innovante pour gérer les publicités dans le
                          cas où une personne refuse tous les cookies.<br />Vous
                          pourrez même activer un système de campagne de
                          récupération de consentement.
                        </p></v-col
                      >
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions style="height: 180px">
                  <v-row>
                    <v-col align-center
                      ><v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="black"
                        @click="$router.push('/cookieless')"
                      >
                        Commencer
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn></v-col
                    >
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col> -->
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: center; padding: 20px 0px 0px">
        <p style="font-size: 15px">{{ $t('or') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: center; padding: 0px 0px 0px">
        <v-btn
          width="370px"
          height="40px"
          style="border-color: black"
          class="white--text"
          color="black"
          dense
          @click="$router.push('/')"
        >
          {{ $t('access_console') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    data() {
      return {
        source: '/'
      }
    },
    computed: {
      ...mapGetters(['selectedUser'])
    },
    async created() {
      //await axios.post(`/api/validateuser/${this.$store.getters.appUser.email}`)
      await axios.post(`/api/updatestatus`)
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
    border: 1px solid black;
  }
</style>
