<template>
  <v-container style="padding: 0px">
    <template>
      <td v-for="ssp in sspsactivated" :key="ssp" style="padding: 0px">
        <v-btn
          v-if="sspsactivated.indexOf(ssp) < 3"
          small
          color="white"
          class="ssp_tab"
        >
          {{ getAlias(ssp) }}
        </v-btn>
        <v-menu
          v-if="sspsactivated.indexOf(ssp) === 3"
          v-model="menu"
          bottom
          right
          transition="scale-transition"
          origin="top left"
        >
          <template v-slot:activator="{ on }">
            <span
              v-if="
                sspsactivated.indexOf(ssp) === 3 && sspsactivated.length == 4
              "
              class="grey--text text-caption"
              style="font-size: 20px; cursor: pointer; margin-left: 10px"
              v-on="on"
            >
              (+{{ sspsactivated.length - 3 }} other)
            </span>
            <span
              v-if="
                sspsactivated.indexOf(ssp) === 3 && sspsactivated.length > 4
              "
              class="grey--text text-caption"
              style="font-size: 20px; cursor: pointer; margin-left: 10px"
              v-on="on"
            >
              (+{{ sspsactivated.length - 3 }} others)
            </span>
          </template>
          <v-card width="400">
            <v-col
              cols="12"
              style="text-align: right; padding: 5px 5px 0px 40px; height: 5px"
            >
              <v-icon color="black" @click="menu = false">mdi-close</v-icon>
            </v-col>
            <v-card-text style="padding-top: 5px">
              <v-chip-group multiple column active-class="primary--text">
                <v-btn
                  v-for="el in sspsactivated.slice(3)"
                  :key="el"
                  small
                  color="white"
                  class="ssp_tab"
                  style="padding: 5px; margin: 5px"
                >
                  {{ getAlias(el) }}
                </v-btn>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </v-menu>
      </td>
    </template>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    props: ['SspList', 'item'],
    data() {
      return {
        menu: false,
        sspsactivated: []
      }
    },
    created() {
      this.sspsListByItem(this.item)
    },
    computed: {
      ...mapGetters(['appCustomer'])
    },
    methods: {
      getAlias(ssp) {
        if (this.appCustomer.alias != '') {
          let aliases = JSON.parse(this.appCustomer.alias)
          Object.keys(aliases).forEach((key) => {
            Object.defineProperty(
              aliases,
              this.$t(key),
              Object.getOwnPropertyDescriptor(aliases, key)
            )
            delete aliases[key]
          })
          if (aliases[ssp] === undefined) return ssp
          else return ssp + ' [' + aliases[ssp] + '] '
        } else return ssp
      },
      sspsListByItem(item) {
        this.SspList.forEach((element) => {
          if (item[element + '_is_active'] == 1) {
            this.sspsactivated.push(this.$t(element))
          }
        })
        return this.sspsactivated
      }
    }
  }
</script>

<style scoped>
  .ssp_tab {
    color: grey;
    margin-left: 5px;
    cursor: initial;
    text-transform: none !important;
  }
</style>
