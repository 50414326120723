<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="800"
    @click:outside="$emit('hideDialog'), back()"
  >
    <template>
      <v-card>
        <v-col
          cols="12"
          style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
        >
          <v-icon color="black" @click="$emit('hideDialog'), back()"
            >mdi-close</v-icon
          >
        </v-col>
        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <h2 style="color: black">{{ TitleDialog }}</h2>
              </v-col>
              <br />
            </v-row>
            <v-row v-if="actionType == 'duplicate'">
              <v-col cols="12">
                <v-form ref="form">
                  <p>Name</p>
                  <v-text-field
                    v-model="name_duplicate"
                    dense
                    solo
                    :rules="nameRules"
                    required
                  >
                  </v-text-field
                ></v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions align="center" class="justify-end">
          <v-col cols="6" align="right">
            <v-btn
              :loading="submitLoading"
              :disabled="submitLoading"
              width="150"
              style="height: 40px; margin-right: 10px"
              class="white--text"
              color="green"
              @click="moveToAction()"
            >
              Yes
            </v-btn>
          </v-col>
          <v-col cols="6" align="left">
            <v-btn
              width="150"
              style="height: 40px; margin-left: 10px"
              class="white--text"
              color="red"
              @click="$emit('hideDialog'), back()"
            >
              No
            </v-btn>
          </v-col>
          <br />
          <br />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      parentDialog: {
        default: false,
        type: Boolean
      },
      actionType: {
        default: '',
        type: String
      },
      item: {}
    },
    data() {
      return {
        dialog: false,
        objectitem: {},
        TitleDialog: '',
        submitLoading: false,
        errors: [],
        nameRules: [(v) => !!v || this.$t('name is required')],
        action: '',
        btnText: this.$t('update'),
        name_duplicate: ''
      }
    },
    computed: {},
    watch: {
      parentDialog() {
        this.dialog = this.parentDialog
        if (this.$route.params.action == 'confirmDuplication') {
          this.TitleDialog =
            'Are you sure you want to duplicate ' + this.item.name
        }
        if (this.$route.params.action == 'confirmArchiving') {
          this.TitleDialog =
            'Are you sure you want to archive ' + this.item.name
        }
      },
      actionType() {
        this.action = this.actionType
        if (this.action == 'duplicate') {
          this.TitleDialog =
            'Please choose a name for the duplicated placement of ' +
            this.item.name
        }
        if (this.action == 'archive') {
          this.TitleDialog =
            'Are you sure you want to archive ' + this.item.name
        }
      },
      item() {
        this.objectitem = this.item
        if (this.action == 'duplicate') {
          this.TitleDialog =
            'Please choose a name for the duplicated placement of ' +
            this.item.name
        }
        if (this.action == 'archive') {
          this.TitleDialog =
            'Are you sure you want to archive ' + this.item.name
        }
      }
    },
    async created() {
      if (this.action == 'duplicate') {
        this.TitleDialog =
          'Please choose a name for the duplicated placement of ' +
          this.item.name
      }
      if (this.action == 'archive') {
        this.TitleDialog = 'Are you sure you want to archive ' + this.item.name
      }
    },
    methods: {
      back() {
        this.$router.push(this.$route.path)
      },
      moveToAction() {
        this.submitLoading = true
        if (this.actionType == 'duplicate')
          this.$emit('duplicate', this.objectitem, this.name_duplicate)
        else this.$emit('archive', this.objectitem)
        setTimeout(() => (this.submitLoading = false), 700)
        setTimeout(() => this.$emit('hideDialog'), 500)
        this.name_duplicate = ''
        this.back()
      }
    }
  }
</script>
