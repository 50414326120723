module.exports = [
  {
    id: '00:00',
    label: '12:00 AM'
  },
  {
    id: '00:15',
    label: '0:15 AM'
  },
  {
    id: '00:30',
    label: '0:30 AM'
  },
  {
    id: '00:45',
    label: '0:45 AM'
  },
  {
    id: '01:00',
    label: '1:00 AM'
  },
  {
    id: '01:15',
    label: '1:15 AM'
  },
  {
    id: '01:30',
    label: '1:30 AM'
  },
  {
    id: '01:45',
    label: '1:45 AM'
  },
  {
    id: '02:00',
    label: '2:00 AM'
  },
  {
    id: '02:15',
    label: '2:15 AM'
  },
  {
    id: '02:30',
    label: '2:30 AM'
  },
  {
    id: '02:45',
    label: '2:45 AM'
  },
  {
    id: '03:00',
    label: '3:00 AM'
  },
  {
    id: '03:15',
    label: '3:15 AM'
  },
  {
    id: '03:30',
    label: '3:30 AM'
  },
  {
    id: '03:45',
    label: '3:45 AM'
  },
  {
    id: '04:00',
    label: '4:00 AM'
  },
  {
    id: '04:15',
    label: '4:15 AM'
  },
  {
    id: '04:30',
    label: '4:30 AM'
  },
  {
    id: '04:45',
    label: '4:45 AM'
  },
  {
    id: '05:00',
    label: '5:00 AM'
  },
  {
    id: '05:15',
    label: '5:15 AM'
  },
  {
    id: '05:30',
    label: '5:30 AM'
  },
  {
    id: '05:45',
    label: '5:45 AM'
  },
  {
    id: '06:00',
    label: '6:00 AM'
  },
  {
    id: '06:15',
    label: '6:15 AM'
  },
  {
    id: '06:30',
    label: '6:30 AM'
  },
  {
    id: '06:45',
    label: '6:45 AM'
  },
  {
    id: '07:00',
    label: '7:00 AM'
  },
  {
    id: '07:15',
    label: '7:15 AM'
  },
  {
    id: '07:30',
    label: '7:30 AM'
  },
  {
    id: '07:45',
    label: '7:45 AM'
  },
  {
    id: '08:00',
    label: '8:00 AM'
  },
  {
    id: '08:15',
    label: '8:15 AM'
  },
  {
    id: '08:30',
    label: '8:30 AM'
  },
  {
    id: '08:45',
    label: '8:45 AM'
  },
  {
    id: '09:00',
    label: '9:00 AM'
  },
  {
    id: '09:15',
    label: '9:15 AM'
  },
  {
    id: '09:30',
    label: '9:30 AM'
  },
  {
    id: '09:45',
    label: '9:45 AM'
  },
  {
    id: '10:00',
    label: '10:00 AM'
  },
  {
    id: '10:15',
    label: '10:15 AM'
  },
  {
    id: '10:30',
    label: '10:30 AM'
  },
  {
    id: '10:45',
    label: '10:45 AM'
  },
  {
    id: '11:00',
    label: '11:00 AM'
  },
  {
    id: '11:15',
    label: '11:15 AM'
  },
  {
    id: '11:30',
    label: '11:30 AM'
  },
  {
    id: '11:45',
    label: '11:45 AM'
  },
  {
    id: '12:00',
    label: '12:00 PM'
  },

  {
    id: '12:15',
    label: '0:15 PM'
  },
  {
    id: '12:30',
    label: '0:30 PM'
  },
  {
    id: '12:45',
    label: '0:45 PM'
  },
  {
    id: '13:00',
    label: '1:00 PM'
  },
  {
    id: '13:15',
    label: '1:15 PM'
  },
  {
    id: '13:30',
    label: '1:30 PM'
  },
  {
    id: '13:45',
    label: '1:45 PM'
  },
  {
    id: '14:00',
    label: '2:00 PM'
  },
  {
    id: '14:15',
    label: '2:15 PM'
  },
  {
    id: '14:30',
    label: '2:30 PM'
  },
  {
    id: '14:45',
    label: '2:45 PM'
  },
  {
    id: '15:00',
    label: '3:00 PM'
  },
  {
    id: '15:15',
    label: '3:15 PM'
  },
  {
    id: '15:30',
    label: '3:30 PM'
  },
  {
    id: '15:45',
    label: '3:45 PM'
  },
  {
    id: '16:00',
    label: '4:00 PM'
  },
  {
    id: '16:15',
    label: '4:15 PM'
  },
  {
    id: '16:30',
    label: '4:30 PM'
  },
  {
    id: '16:45',
    label: '4:45 PM'
  },
  {
    id: '17:00',
    label: '5:00 PM'
  },
  {
    id: '17:15',
    label: '5:15 PM'
  },
  {
    id: '17:30',
    label: '5:30 PM'
  },
  {
    id: '17:45',
    label: '5:45 PM'
  },
  {
    id: '18:00',
    label: '6:00 PM'
  },
  {
    id: '18:15',
    label: '6:15 PM'
  },
  {
    id: '18:30',
    label: '6:30 PM'
  },
  {
    id: '18:45',
    label: '6:45 PM'
  },
  {
    id: '19:00',
    label: '7:00 PM'
  },
  {
    id: '19:15',
    label: '7:15 PM'
  },
  {
    id: '19:30',
    label: '7:30 PM'
  },
  {
    id: '19:45',
    label: '7:45 PM'
  },
  {
    id: '20:00',
    label: '8:00 PM'
  },
  {
    id: '20:15',
    label: '8:15 PM'
  },
  {
    id: '20:30',
    label: '8:30 PM'
  },
  {
    id: '20:45',
    label: '8:45 PM'
  },
  {
    id: '21:00',
    label: '9:00 PM'
  },
  {
    id: '21:15',
    label: '9:15 PM'
  },
  {
    id: '21:30',
    label: '9:30 PM'
  },
  {
    id: '21:45',
    label: '9:45 PM'
  },
  {
    id: '22:00',
    label: '10:00 PM'
  },
  {
    id: '22:15',
    label: '10:15 PM'
  },
  {
    id: '22:30',
    label: '10:30 PM'
  },
  {
    id: '22:45',
    label: '10:45 PM'
  },
  {
    id: '23:00',
    label: '11:00 PM'
  },
  {
    id: '23:15',
    label: '11:15 PM'
  },
  {
    id: '23:30',
    label: '11:30 PM'
  },
  {
    id: '23:45',
    label: '11:45 PM'
  },
]
