<template>
  <div id="siteContent">
    <v-container
      v-if="
        !conditionSuperAdmin &&
        $store.state.appCustomer.id != $route.params.customer_id &&
        $store.state.appCustomer.id != '1086' &&
        $store.state.appCustomer.id != '1094' &&
        $store.state.appUser.email != 'alfonso@revenuemaker.es'
      "
    >
      <accessdenied />
    </v-container>
    <v-container v-else fluid style="padding-top: 0px">
      <v-card id="siteList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col cols="4">
              <h3 class="cnx">
                <strong> {{ $t('sites management') }}</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-menu
                v-model="menuAddingWebsite"
                :close-on-content-click="true"
                :nudge-width="20"
                offset-y
                style="width: 100%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="border-color: black"
                    class="white--text notcapital"
                    color="black"
                    width="200"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-plus </v-icon>
                    {{ $t('add_site') }}
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-btn
                          style="border-color: black"
                          class="white--text notcapital"
                          color="black"
                          width="250"
                          dense
                          @click="newSite()"
                        >
                          <v-icon left> mdi-plus </v-icon> Add a single website
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-btn
                          style="border-color: black"
                          class="white--text notcapital"
                          color="black"
                          width="250"
                          dense
                          @click="
                            $router.push($route.path + '?action=addmultiple'),
                              (multipleSitesDialog = true)
                          "
                        >
                          <v-icon left> mdi-plus </v-icon> Add multiple websites
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="3" align="right" justify="right">
              <v-menu
                v-model="menuBulkUpload"
                :close-on-content-click="true"
                :nudge-width="20"
                offset-y
                style="width: 100%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="border-color: black; margin-right: 20px"
                    class="white--text notcapital"
                    color="black"
                    width="200"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-download-outline </v-icon> Bulk upload
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-btn
                          style="border-color: black"
                          class="white--text notcapital"
                          color="black"
                          width="250"
                          dense
                          @click="
                            $router.push(
                              $route.path + '?action=bulkuploadsite'
                            ),
                              (bulkUploadDialog = true),
                              (bulkforsite = true)
                          "
                        >
                          <v-icon left> mdi-download-outline </v-icon> Sites
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-btn
                          style="border-color: black"
                          class="white--text notcapital"
                          color="black"
                          width="250"
                          dense
                          @click="
                            $router.push(
                              $route.path + '?action=bulkuploadplacement'
                            ),
                              (bulkUploadDialog = true),
                              (bulkforsite = false)
                          "
                        >
                          <v-icon left> mdi-download-outline </v-icon>
                          Placements
                        </v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <p
                style="
                  position: relative;
                  top: 30px;
                  left: 5px;
                  font-size: 14px;
                  color: black;
                "
              >
                {{ numberOfSitesCreated }}

                sites created (
                <a
                  :style="[
                    showArchived ? { color: 'black' } : { color: '#f28704' }
                  ]"
                  @click="showArchived = false"
                  ><u
                    ><strong>{{ numberOfActiveSites }}</strong> active sites</u
                  ></a
                >
                /
                <a
                  :style="[
                    showArchived ? { color: '#f28704' } : { color: 'black' }
                  ]"
                  @click="showArchived = true"
                  ><u
                    ><strong>{{ numberOfArchivedSites }}</strong> archived
                    sites</u
                  ></a
                >
                )
              </p>
            </v-col>
            <v-col cols="2" />
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="computedSites"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, -1]
            }"
            :sort-by="['name']"
            :items-per-page="20"
            class="elevation-1"
            :loading="loading"
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr>
                <td
                  v-if="props.item.is_archived == 0"
                  style="width: 50%; cursor: pointer"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-if="props.item.is_archived == 1"
                  style="width: 50%; cursor: pointer; opacity: 0.5"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-if="props.item.is_archived == 0"
                  style="width: 30%; cursor: pointer"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.nb_placements }}
                </td>
                <td
                  v-if="props.item.is_archived == 1"
                  style="width: 30%; cursor: pointer; opacity: 0.5"
                  @click="selectItem(props.item)"
                >
                  {{ props.item.nb_placements }}
                </td>
                <td style="padding: 0px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="updateSite(props.item)"
                      >
                        <v-icon size="20">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit the website</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectItem(props.item)"
                      >
                        <v-icon size="20">mdi-table-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Manage the placements</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="duplicateSite(props.item)"
                      >
                        <v-icon size="20">mdi-content-duplicate</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate the website</span>
                  </v-tooltip>
                  <v-tooltip v-if="props.item.is_archived == 0" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="showConfirmationDialog(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Archive the website</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="unarchiveSite(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Unarchive the website</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <bulkupload
        :parent-dialog="bulkUploadDialog"
        :bulkforsite="bulkforsite"
        @hideDialog="bulkUploadDialog = false"
        @hideUploadDialog="bulkUploadDialog = true"
      />
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveSite"
      />

      <rtcConfig
        :code="codeGenerated"
        :parent-dialog="rtcConfigDialog"
        @sendToEmail="SendConfigToEmail"
        @hideDialog="rtcConfigDialog = false"
      />
      <multipleSites
        :parent-dialog="multipleSitesDialog"
        :siteadded="multipleSitesadded"
        @hideDialog="multipleSitesDialog = false"
        @showBulk="
          $router.push($route.path + '?action=bulkuploadsite'),
            (bulkUploadDialog = true),
            (bulkforsite = true)
        "
        @submitted="addMultipleSites"
      />
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import {
    GetSites,
    GetStoredRequests,
    SaveSelectedStoredRequest,
    SaveSelectedSite,
    UpdateSelectedSite,
    UpdateSelectedStoredRequest
  } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import {
    SetSelectedSite,
    ClearSelectedSite,
    SetAppCustomer
  } from '@/store/mutation_types'
  import bulkupload from '@/pages/management/actions/bulkUpload.vue'
  import rtcConfig from '@/pages/management/site/rtc-config.vue'
  import multipleSites from '@/pages/management/site/addMultipleSite.vue'
  import accessdenied from '@/pages/accessdenied.vue'
  import JsonCSV from 'vue-json-csv'
  import ConfirmAction from '@/pages/management/actions/confirmAction.vue'

  Vue.component('downloadCsv', JsonCSV)

  export default {
    name: 'SiteList',
    components: {
      bulkupload,
      rtcConfig,
      accessdenied,
      ConfirmAction,
      multipleSites
    },
    data() {
      return {
        headers: [
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Placements', align: 'left', value: 'nb_placements' },
          // { text: 'Placements', align: 'left', value: 'placements' },
          { text: 'Action', align: 'center', value: 'action' }
        ],
        site: {},
        stored_request_duplicated: {},
        site_duplicated: {},
        loading: false,
        search: '',
        generateButtonLoading: false,
        stored_request_siteIDduplicated: '',
        showArchived: false,
        actionType: '',
        bulkUploadDialog: false,
        bulkforsite: false,
        codeGenerated: '',
        rtcConfigDialog: false,
        actionsuccess: false,
        confirmActionDialog: false,
        confirmationAction: '',
        timeout: 3000,
        messageAction: '',
        numberOfSitesCreated: 0,
        numberOfActiveSites: 0,
        numberOfArchivedSites: 0,
        item: [],
        menuAddingWebsite: false,
        menuBulkUpload: false,
        multipleSitesDialog: false,
        multipleSitesadded: false,
        conditionSuperAdmin: false,

        fieldsToExport: [
          'name',
          'customer_id',
          'refresh_is_active',
          'selection_type',
          'orders_ids',
          'refresh_time',
          'nb_repetition',
          'noconsent_traffic_blocking',
          'pricefloor_is_active',
          'pricefloor_cpm'
        ]
      }
    },
    computed: {
      ...mapGetters(['sites', 'selectedSite', 'appCustomer', 'storedRequests']),
      computedSites() {
        return this.sites
          .filter((el) => {
            return this.showArchived ? el.is_archived == 1 : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    watch: {
      appCustomer: async function (val, oldVal) {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          this.$router.push({
            name: 'site_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
        await this.GetSites(this.appCustomer.id)
        this.manageNumberOfSites()
      }
    },
    async created() {
      this.loading = true
      if (this.$route.fullPath != this.$route.path)
        this.$router.push(this.$route.path)
      if (
        this.$store.getters.is_superadmin &&
        this.$store.state.appCustomer.id != this.$route.params.customer_id
      ) {
        await this.GetSites(this.$route.params.customer_id)
      } else {
        await this.GetSites(this.appCustomer.id)
      }
      if (this.$store.getters.appUser.is_superadmin) {
        this.conditionSuperAdmin = true
      } else this.conditionSuperAdmin = false
      this.manageNumberOfSites()
      this.loading = false
      await axios.post(`/api/updatestatus`)
    },
    methods: {
      ...mapActions({
        GetSites,
        GetStoredRequests,
        SaveSelectedStoredRequest,
        SaveSelectedSite,
        UpdateSelectedSite,
        UpdateSelectedStoredRequest
      }),
      ...mapMutations([SetSelectedSite, ClearSelectedSite, SetAppCustomer]),
      async archiveSite(item) {
        item.is_archived = 1
        await this.UpdateSelectedSite(item)
        this.SetSelectedSite(item)
        await this.GetStoredRequests(item.id)
        this.storedRequests.forEach((sr) => {
          sr.is_archived = 1
          this.UpdateSelectedStoredRequest(sr)
        })
        await this.GetSites(this.appCustomer.id)
        await this.GetStoredRequests(item.id)
        this.actionsuccess = true
        this.messageAction = 'Site archived successfully'
      },
      async unarchiveSite(item) {
        item.is_archived = 0
        this.UpdateSelectedSite(item)
        this.SaveSelectedSite(item)
        await this.GetSites(this.appCustomer.id)
      },
      async duplicateSite(item) {
        this.SetSelectedSite(item)
        const site_to_duplicate = Object.assign({}, this.selectedSite)
        const site_duplicated = Object.assign({}, this.selectedSite)
        site_duplicated.id = undefined
        let nameExists = true
        let i = 1
        while (nameExists == true) {
          site_duplicated.name = item.name + ' (' + i + ')'
          const verifyNameFromApi = await axios.get(
            `/api/sitenameexists/${site_duplicated.name}/${site_duplicated.customer_id}`
          )
          nameExists = verifyNameFromApi.data
          i++
        }
        try {
          await this.SaveSelectedSite(site_duplicated)
          await this.GetStoredRequests(site_to_duplicate.id)
          this.storedRequests.forEach((element) => {
            const stored_request_duplicated = Object.assign({}, element)
            stored_request_duplicated.id = ''
            stored_request_duplicated.name = element.name
            stored_request_duplicated.site_id = this.selectedSite.id
            this.SaveSelectedStoredRequest(stored_request_duplicated)
          })
          this.actionsuccess = true
          this.messageAction = 'Site duplicated successfully'
        } catch (error) {
          console.log(error)
        }
      },
      async ConfirmEdit(siteName, actionType) {
        try {
          if (actionType == 'update') {
            this.selectedSite.name = siteName
            try {
              await this.SaveSelectedSite(this.selectedSite)
              this.actionsuccess = true
              this.messageAction = this.$t('success_website_updated')
              await this.GetSites(this.appCustomer.id)
              await this.SetSelectedSite(this.site)
            } catch (error) {}
          }
          if (actionType == 'add') {
            this.site.name = siteName
            this.site.customer_id = this.appCustomer.id
            try {
              await this.SaveSelectedSite(this.site)
              this.actionsuccess = true
              this.messageAction = this.$t('success_website_added')
              this.$router.push($route.path)
            } catch (error) {}
          }
          this.siteEditDialog = false
          this.alertSiteAdded = true
          setTimeout(() => (this.alertSiteAdded = false), 3000)
        } catch (error) {
          console.log(error)
        }
      },
      async SendConfigToEmail(email) {
        const siteId = this.selectedSite.id
        const response = await axios.get(`/api/rtc-email/${siteId}/${email}`)
      },
      showDialog(actionType) {
        this.actionType = actionType
        this.siteEditDialog = true
        if (actionType == 'update') {
          this.$router.push(
            this.$route.path +
              '/' +
              this.selectedSite.id +
              '?action=' +
              actionType
          )
        } else this.$router.push(this.$route.path + '?action=' + actionType)
      },
      selectItem(item) {
        this.SetSelectedSite(item)
        this.GetStoredRequests(item.id)
        this.$router.push(
          '/management/' +
            this.appCustomer.id +
            '/sites/' +
            this.selectedSite.id +
            '/storedrequests'
        )
      },
      goToInjectionList(item) {
        this.SetSelectedSite(item)
        this.GetStoredRequests(item.id)
        this.$router.push(
          '/management/' +
            this.appCustomer.id +
            '/sites/' +
            this.selectedSite.id +
            '/injections'
        )
      },
      async getCountStoredRequests(site_id) {
        var response = await this.GetStoredRequests(site_id)
        var placements = response.data
        return placements.length
      },
      newSite() {
        this.$router.push({
          name: 'site_new',
          params: {
            customer_id: this.appCustomer.id
          }
        })
      },
      updateSite(item) {
        this.SetSelectedSite(item)
        this.$router.push({
          name: 'site_edit',
          params: {
            customer_id: this.appCustomer.id,
            site_id: this.selectedSite.id
          }
        })
      },
      csvExport() {
        let csvContent = 'data:Gsheet;charset=utf-8,'
        csvContent += [
          Object.keys(this.computedSites[0]).join(';'),
          ...this.computedSites.map((item) => Object.values(item).join(';'))
        ]
          .join('\n')
          .replace(/(^\[)|(\]$)/gm, '')

        const data = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', data)
        link.setAttribute('download', 'export.csv')
        link.click()
      },
      showConfirmationDialog(item) {
        this.item = item
        this.$router.push(this.$route.path + '?action=confirmArchiving')
        this.confirmActionDialog = true
        this.confirmationAction = 'archive'
      },
      getSitesArchived() {
        return this.sites.filter((el) => {
          return el.is_archived == 1
        })
      },
      getSitesUnarchived() {
        return this.sites.filter((el) => {
          return el.is_archived == 0
        })
      },
      async addMultipleSites(input) {
        var arrayOfSites = input.split('\n')
        arrayOfSites = arrayOfSites.filter(
          (item, index) => arrayOfSites.indexOf(item) === index
        )
        var sites = []
        arrayOfSites.forEach(async (name) => {
          var site = {}
          site.id = ''
          site.name = name.trim().replace("'", ' ')
          site.customer_id = this.$route.params.customer_id
          sites.push(site)
        })
        try {
          await axios.post(`/api/addmultiplesites`, sites)
          setTimeout(() => (this.multipleSitesadded = true), 1000)
          setTimeout(() => (this.multipleSitesadded = false), 2000)
          setTimeout(() => this.GetSites(this.$route.params.customer_id), 2500)
          this.manageNumberOfSites()
        } catch (error) {
          console.log(error)
        }
      },
      manageNumberOfSites() {
        var sites_unarchived = this.getSitesUnarchived()
        var sites_archived = this.getSitesArchived()
        this.numberOfSitesCreated =
          sites_unarchived.length + sites_archived.length
        this.numberOfActiveSites = sites_unarchived.length
        this.numberOfArchivedSites = sites_archived.length
      }
    }
  }
</script>
<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
  }
</style>
