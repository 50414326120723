<template>
  <v-dialog v-model="dialog" max-width="1000px" @click:outside="hideDialog">
    <v-card style="height: 100%">
      <v-col
        cols="12"
        style="text-align: right; padding: 10px 10px 0px 40px; height: 5px"
      >
        <v-icon color="black" @click="hideDialog">mdi-close</v-icon>
      </v-col>
      <v-card-title primary-title style="padding-top: 10px">
        <v-row>
          <v-col cols="12">
            <h3 style="color: black">
              Uploading your file of {{ typeUpload }}
            </h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer />
      <br />
      <v-row>
        <v-col cols="12" align="left" justify="left">
          <span v-if="updated">
            <v-alert dense text type="success" color="green">
              Placements updated successfully</v-alert
            >
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <v-card-text
            :class="{ 'orange dark-3': dragover }"
            style="
              padding-top: 2%;
              margin-left: 5%;
              background-color: lightgray;
              border: dashed;
              border-color: #bababa;
              height: 400px;
            "
            align="center"
            justify="center"
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
          >
            <v-row
              class="d-flex flex-column"
              dense
              align="center"
              justify="center"
            >
              <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                mdi-cloud-upload
              </v-icon>
              <p>Drop your file here</p>
            </v-row>
            <v-row>
              <v-col cols="10">
                <v-file-input
                  v-model="fileInput"
                  accept=".csv, .xlsx"
                  label="Click here to select a file"
                  outlined
                  dense
                  @change="onValidate"
                >
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-btn
                  :loading="submitLoading"
                  :disabled="fileInput == null || fileInput.length == 0"
                  width="110"
                  style="height: 40px"
                  class="white--text notcapital"
                  color="black"
                  @click.stop="submit"
                >
                  <v-icon left> mdi-check-circle</v-icon>
                  Upload
                </v-btn>
              </v-col>
            </v-row>
            <v-virtual-scroll
              v-if="uploadedFiles.length > 0"
              :items="uploadedFiles"
              height="70"
              item-height="50"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.name">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span class="ml-3 text--secondary">
                        {{ item.size }} bytes</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click.stop="removeFile(item.name)">
                      <v-icon> mdi-close-circle </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
            <v-row>
              <v-col cols="12" style="padding: 40px 0px 0px 10px">
                <v-progress-linear
                  v-if="progressionBar"
                  v-model="progressionBarValue"
                  color="light-blue"
                  height="20"
                  striped
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="1" />
      </v-row>
      <v-row style="padding: 5px">
        <v-col cols="11" align="left" justify="left" style="margin-left: 4%">
          <span v-if="errors.length > 0">
            <v-alert
              v-for="error in errors"
              :key="error"
              color="#db2727"
              type="error"
              dense
              >{{ error }}</v-alert
            >
          </span>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  const countries = require('@/assets/styles/countries.js')
  const iab = require('@/assets/styles/iab.js')

  import XLSX from 'xlsx'
  import axios from 'axios'
  import {
    GetSelectedCustomer,
    GetStoredRequests,
    GetSites
  } from '@/store/action_types'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Upload',

    props: {
      dialog: {
        type: Boolean,
        required: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      bulkforsite: {
        default: false,
        type: Boolean
      }
    },

    data() {
      return {
        dragover: false,
        uploadedFiles: [],
        submitLoading: false,
        fileInput: [],
        headersImportedSite: [],
        headersExportedSite: [
          'id',
          'name',
          'cpm_adjustment',
          'schain_is_active',
          'schain_asi',
          'schain_sid',
          'iab_blocklist',
          'domain_blocklist',
          'geotargeting_whitelist',
          'geotargeting_blacklist',
          'goodad_schain_id',
          'gravity_schain_id',
          'groupm_schain_id',
          'fueldigital_schain_id',
          'fueldigitalix_schain_id',
          'moneytag_schain_id',
          'plista_schain_id',
          'quantum_schain_id',
          'quantumimprove_schain_id',
          'staila_schain_id',
          'traffective_schain_id'
        ],
        headersImportedPlacement: [],
        headersExportedPlacement: [
          'id',
          'name',
          'is_archived',
          'site_name',
          'cpm_adjustment',
          'pricefloor_is_active',
          'pricefloor_cpm'
        ],
        activatedBidders: [],
        errors: [],
        dataImported: [],
        updated: false,
        progressionBarValue: '0',
        progressionBar: false,
        countryList: countries,
        iabList: iab,
        typeUpload: 'placements',
        optionals: [
          'smilewanted_position_type',
          'nextmillennium_group_id',
          'nextmillennium_placement_id',
          'ix_endpoint',
          'goodad_member',
          'onetag_ext',
          'adot_placement_id',
          'gumgum_zone',
          'gumgum_pub_id',
          'gumgum_slot',
          'gumgum_product',
          'pubmatic_ad_slot',
          'pubmatic_placement_id',
          'pubmaticbis_ad_slot',
          'pubmaticbis_placement_id',
          'rubicon_position',
          'rubiconbis_position',
          'rise_placement_id',
          'criteo_zone_id',
          'criteo_network_id',
          'adswizz_preroll_zonealias',
          'adswizz_midroll_zonealias',
          'adswizz_preroll_companion_zonealias',
          'adswizz_midroll_companion_zonealias'
        ]
      }
    },
    computed: {
      ...mapGetters([
        'selectedSite',
        'appCustomer',
        'SspList',
        'SampleStoredRequest',
        'selectedCustomer',
        'SelectedStoredRequest'
      ]),
      formattedSspList() {
        const output = {}
        let listSspSorted = this.SspList
        listSspSorted = listSspSorted.sort((a, b) => a.localeCompare(b))
        listSspSorted.forEach((ssp) => {
          const foundssp = this.activatedBidders.find(
            (element) => element == ssp
          )
          if (foundssp != undefined) {
            output[ssp] = {}
            Object.keys(this.SelectedStoredRequest).forEach((key) => {
              if (
                key.substr(0, this.getPosition(key, '_', 1)) == ssp &&
                key != 'ix_endpoint'
              ) {
                output[ssp][key] = this.SelectedStoredRequest[key]
              }
            })
          }
        })
        return output
      },
      headersExportedPlacementComputed() {
        let arr = []
        this.headersExportedPlacement.forEach((element) => {
          arr.push(element)
        })
        let listSspSorted = this.SspList
        listSspSorted = listSspSorted.sort((a, b) => a.localeCompare(b))
        listSspSorted.forEach((ssp) => {
          const foundssp = this.activatedBidders.find(
            (element) => element == ssp
          )
          if (foundssp != undefined) {
            Object.keys(this.SampleStoredRequest).forEach((key) => {
              if (key.substr(0, this.getPosition(key, '_', 1)) == ssp) {
                arr.push(key)
              }
            })
          }
        })
        return arr
      }
    },
    watch: {
      bulkforsite() {
        if (this.bulkforsite == true) this.typeUpload = 'sites'
        else this.typeUpload = 'placements'
      }
    },
    async created() {
      if (!this.bulkforsite) {
        await this.GetSelectedCustomer(this.$route.params.customer_id)
        var response = await axios.get(
          `/api/getactivatedbidders/${this.$route.params.customer_id}`
        )
        this.activatedBidders = response.data
        this.typeUpload = 'placements'
      } else this.typeUpload = 'sites'
    },
    methods: {
      ...mapActions([GetSelectedCustomer, GetStoredRequests, GetSites]),
      removeFile(fileName) {
        // Find the index of the
        const index = this.uploadedFiles.findIndex(
          (file) => file.name === fileName
        )

        // If file is in uploaded files remove it
        if (index > -1) {
          this.uploadedFiles.splice(index, 1)
          this.fileInput = null
        }
      },

      onDrop(e) {
        this.dragover = false
        this.errors = []

        // If there are already uploaded files remove them
        if (this.uploadedFiles.length > 0) this.uploadedFiles = []

        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          console.log('error multiple files')
        }
        if (
          e.dataTransfer.files[0].type != 'text/csv' &&
          e.dataTransfer.files[0].type !=
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          this.errors.push('File extension not supported')
        }
        // Add each file to the array of uploaded files
        else {
          for (let index = 0; index < e.dataTransfer.files.length; index++) {
            this.uploadedFiles.push(e.dataTransfer.files[index])
          }
          if (this.uploadedFiles.length == 0) this.fileInput = null
          else this.fileInput = this.uploadedFiles[0]
          if (this.fileInput.type == 'text/csv')
            this.readCsvFile(this.fileInput)
          if (
            this.fileInput.type ==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          )
            this.readXLFile(this.fileInput)
        }
      },
      async onValidate(file) {
        this.errors = []
        if (file == null) this.uploadedFiles = []
        else if (
          file.type != 'text/csv' &&
          file.type !=
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          this.errors.push('File extension not supported')
          this.fileInput = null
        } else {
          this.uploadedFiles = []
          this.uploadedFiles.push(file)
          if (file.type == 'text/csv') this.readCsvFile(file)
          if (
            file.type ==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          )
            this.readXLFile(file)
        }
      },
      async submit() {
        if (this.fileInput != null) {
          this.submitLoading = true
          let response = {}
          if (!this.bulkforsite) {
            if (this.$route.name == 'stored_request_list')
              response = await axios.get(
                `/api/getdatatoexport/${this.appCustomer.id}/${this.$route.params.site_id}`
              )
            else
              response = await axios.get(
                `/api/getdatatoexport/${this.appCustomer.id}/0`
              )
          } else {
            response = await axios.get(
              `/api/getsitesdatatoexport/${this.$route.params.customer_id}`
            )
          }
          this.dataExported = response.data
          this.verifyRowsNumber()
          if (this.errors.length == 0) await this.verifyHeaders()
          if (this.errors.length == 0) await this.verifyRequiredFields()
          if (this.errors.length == 0 && !this.bulkforsite)
            await this.verifySSPFields()
          if (this.errors.length == 0) await this.update()
          await this.activateProgressBar()
        }
      },
      async activateProgressBar() {
        if (this.errors.length == 0) {
          this.progressionBar = true
          setTimeout(() => (this.progressionBarValue = '10'), 200)
          setTimeout(() => (this.progressionBarValue = '20'), 900)
          setTimeout(() => (this.progressionBarValue = '30'), 1100)
          setTimeout(() => (this.progressionBarValue = '40'), 1300)
          setTimeout(() => (this.progressionBarValue = '80'), 1500)
          setTimeout(() => (this.progressionBarValue = '90'), 1700)
          setTimeout(() => (this.progressionBarValue = '100'), 1800)
          setTimeout(() => (this.submitLoading = false), 1800)
          setTimeout(() => (this.progressionBar = false), 2500)
          setTimeout(() => (this.updated = true), 2500)
          this.progressionBarValue = '0'
          setTimeout(() => (this.updated = false), 4200)
          setTimeout(() => this.hideDialog(), 4000)
          setTimeout(() => (this.uploadedFiles = []), 4200)
          setTimeout(() => (this.fileInput = null), 4200)
        }
        setTimeout(() => (this.submitLoading = false), 2500)
        if (
          this.$route.params.site_id != '0' &&
          this.$route.params.site_id != undefined
        ) {
          await this.GetStoredRequests(this.$route.params.site_id)
          await this.GetSites(this.$route.params.customer_id)
        }
      },
      async update() {
        if (this.bulkforsite) {
          await this.updateSites()
        } else await this.updatePlacements()
      },
      async updateSites() {
        const nbRequests = this.dataImported.length / 200
        if (nbRequests <= 1) {
          try {
            await axios.post(`/api/updatemultiplesites`, this.dataImported)
          } catch (error) {
            this.errors.push(error)
          }
        } else {
          for (let i = 1; i <= Math.ceil(nbRequests); i++) {
            var request = []
            var start = 200 * (i - 1)
            var end =
              this.dataImported.length - 200 * i >= 0
                ? 200 * i
                : this.dataImported.length
            for (let j = start; j < end; j++) {
              request.push(this.dataImported[j])
            }
            try {
              await axios.post(`/api/updatemultiplesites`, request)
            } catch (error) {
              this.errors.push(error)
            }
          }
        }
      },
      async updatePlacements() {
        console.log('imported -->', this.dataImported)
        for (let i = 0; i < this.dataImported.length; i++) {
          delete this.dataImported[i]['site_name']
        }
        const nbRequests = this.dataImported.length / 200
        if (nbRequests <= 1) {
          try {
            await axios.post(`/api/updatemultipleplacements`, this.dataImported)
          } catch (error) {
            this.errors.push(error)
          }
        } else {
          for (let i = 1; i <= Math.ceil(nbRequests); i++) {
            var request = []
            var start = 200 * (i - 1)
            var end =
              this.dataImported.length - 200 * i >= 0
                ? 200 * i
                : this.dataImported.length
            for (let j = start; j < end; j++) {
              request.push(this.dataImported[j])
            }
            try {
              await axios.post(`/api/updatemultipleplacements`, request)
            } catch (error) {
              this.errors.push(error)
            }
          }
        }
      },
      CleanSSPs() {
        for (let i = 0; i < this.dataImported.length; i++) {
          var storedRequest = this.dataImported[i]
          if (storedRequest.galaxiemedia_is_active == false) {
            storedRequest.galaxiemedia_tag_id = ''
          }
          if (storedRequest.gingerad_is_active == false) {
            storedRequest.gingerad_tag_id = ''
          }
          if (storedRequest.goldbach_is_active == false) {
            storedRequest.goldbach_placement_id = ''
          }
          if (storedRequest.goodad_is_active == false) {
            storedRequest.goodad_member = ''
            storedRequest.goodad_invcode = ''
          }
          if (storedRequest.adyoulike_is_active == false)
            storedRequest.adyoulike_placement = ''
          if (storedRequest.amx_is_active == false)
            storedRequest.amx_tag_id = ''
          if (storedRequest.adform_is_active == false)
            storedRequest.adform_mid = ''
          if (storedRequest.adbility_is_active == false) {
            storedRequest.adbility_zone_id = ''
            storedRequest.adbility_script = ''
          }
          if (storedRequest.thirtythreeacross_is_active == false) {
            storedRequest.thirtythreeacross_site_id = ''
            storedRequest.thirtythreeacross_product_id = ''
          }
          if (storedRequest.traffective_is_active == false)
            storedRequest.traffective_placement_id = ''
          if (storedRequest.adot_is_active == false) {
            storedRequest.adot_publisher_id = ''
            storedRequest.adot_placement_id = ''
          }
          if (storedRequest.firstid_is_active == false)
            storedRequest.firstid_tag_id = ''
          if (storedRequest.nexx360_is_active == false)
            storedRequest.nexx360_tag_id = ''
          if (storedRequest.nextmillennium_is_active == false) {
            storedRequest.nextmillennium_placement_id = ''
            storedRequest.nextmillennium_group_id = ''
          }
          if (storedRequest.cwire_is_active == false) {
            storedRequest.cwire_page_id = ''
            storedRequest.cwire_placement_id = ''
          }
          if (storedRequest.datablocks_is_active == false) {
            storedRequest.datablocks_source_id = ''
          }
          if (storedRequest.definemedia_is_active == false) {
            storedRequest.definemedia_mandant_id = ''
            storedRequest.definemedia_adslot_id = ''
          }
          if (storedRequest.eplanning_is_active == false) {
            storedRequest.eplanning_cid = ''
            storedRequest.eplanning_endpoint_code = ''
          }
          if (storedRequest.evolution_is_active == false) {
            storedRequest.evolution_key = ''
          }
          if (storedRequest.freewheel_is_active == false)
            storedRequest.freewheel_zone_id = ''
          if (storedRequest.adnuntius_is_active == false)
            storedRequest.adnuntius_au_id = ''
          if (storedRequest.revenuemaker_is_active == false) {
            //storedRequest.revenuemaker_account = ''
            storedRequest.revenuemaker_tag_id = ''
          }
          if (storedRequest.quantum_is_active == false) {
            storedRequest.quantum_placement_id = ''
          }
          if (storedRequest.onetag_is_active == false) {
            storedRequest.onetag_pub_id = ''
            storedRequest.onetag_ext = ''
          }
          if (storedRequest.outbrain_is_active == false) {
            storedRequest.outbrain_publisher_id = ''
            storedRequest.outbrain_tag_id = ''
          }
          if (storedRequest.orbidder_is_active == false) {
            storedRequest.orbidder_account_id = ''
            storedRequest.orbidder_placement_id = ''
          }
          if (storedRequest.plista_is_active == false) {
            storedRequest.plista_placement_id = ''
          }
          if (storedRequest.prisma_is_active == false) {
            storedRequest.prisma_tag_id = ''
          }
          if (storedRequest.yieldlab_is_active == false) {
            storedRequest.yieldlab_adslot_id = ''
            storedRequest.yieldlab_supply_id = ''
          }

          if (storedRequest.richaudience_is_active == false)
            storedRequest.richaudience_pid = ''
          if (storedRequest.rise_is_active == false) {
            storedRequest.rise_org = ''
            storedRequest.rise_placement_id = ''
          }
          if (storedRequest.appnexus_is_active == false)
            storedRequest.appnexus_placement_id = ''
          if (storedRequest.appnexusbis_is_active == false)
            storedRequest.appnexusbis_placement_id = ''
          if (storedRequest.audienzz_is_active == false)
            storedRequest.audienzz_tag_id = ''
          if (storedRequest.criteo_is_active == false) {
            storedRequest.criteo_network_id = ''
            storedRequest.criteo_zone_id = ''
          }
          if (storedRequest.bliink_is_active == false)
            storedRequest.bliink_tag_id = ''
          if (storedRequest.conversant_is_active == false)
            storedRequest.conversant_site_id = ''
          if (storedRequest.connectad_is_active == false) {
            storedRequest.connectad_site_id = ''
            storedRequest.connectad_network_id = ''
          }
          if (storedRequest.colossus_is_active == false) {
            storedRequest.colossus_tag_id = ''
            storedRequest.colossus_group_id = ''
          }
          if (storedRequest.fueldigital_is_active == false) {
            storedRequest.fueldigital_site_id = ''
            storedRequest.fueldigital_page_id = ''
            storedRequest.fueldigital_format_id = ''
          }
          if (storedRequest.fueldigitalmedia_is_active == false) {
            storedRequest.fueldigitalmedia_tag_id = ''
          }
          if (storedRequest.fueldigitalix_is_active == false)
            storedRequest.fueldigitalix_site_id = ''

          if (storedRequest.gravity_is_active == false)
            storedRequest.gravity_placement_id = ''
          if (storedRequest.groupm_is_active == false)
            storedRequest.groupm_placement_id = ''
          if (storedRequest.gumgum_is_active == false) {
            storedRequest.gumgum_zone = ''
            storedRequest.gumgum_pub_id = ''
            storedRequest.gumgum_slot = ''
            storedRequest.gumgum_product = ''
          }
          if (storedRequest.improve_is_active == false) {
            storedRequest.improve_publisher_id = ''
            storedRequest.improve_placement_id = ''
          }
          if (storedRequest.inmobi_is_active == false) {
            storedRequest.inmobi_plc = ''
          }
          if (storedRequest.quantumimprove_is_active == false) {
            storedRequest.quantumimprove_placement_id = ''
          }
          if (storedRequest.ix_is_active == false) {
            storedRequest.ix_site_id = ''
            // storedRequest.ix_endpoint = ''
          }
          if (storedRequest.mediasquare_is_active == false) {
            storedRequest.mediasquare_zone = ''
            storedRequest.mediasquare_code = ''
          }
          if (storedRequest.medianet_is_active == false) {
            storedRequest.medianet_cid = ''
            storedRequest.medianet_crid = ''
          }
          if (storedRequest.mediagrid_is_active == false)
            storedRequest.mediagrid_uuid = ''
          if (storedRequest.moneytag_is_active == false)
            storedRequest.moneytag_placement_id = ''
          if (storedRequest.netpoint_is_active == false)
            storedRequest.netpoint_tag_id = ''
          if (storedRequest.invibes_is_active == false)
            storedRequest.invibes_placement_id = ''
          if (storedRequest.openx_is_active == false) {
            storedRequest.openx_del_domain = ''
            storedRequest.openx_unit = ''
          }
          if (storedRequest.pulsepoint_is_active == false) {
            storedRequest.pulsepoint_cp = ''
            storedRequest.pulsepoint_ct = ''
          }
          if (storedRequest.pubmatic_is_active == false) {
            storedRequest.pubmatic_placement_id = ''
            storedRequest.pubmatic_publisher_id = ''
            storedRequest.pubmatic_ad_slot = ''
          }
          if (storedRequest.pubmaticbis_is_active == false) {
            storedRequest.pubmaticbis_placement_id = ''
            storedRequest.pubmaticbis_publisher_id = ''
            storedRequest.pubmaticbis_ad_slot = ''
          }
          if (storedRequest.rubicon_is_active == false) {
            storedRequest.rubicon_account_id = ''
            storedRequest.rubicon_site_id = ''
            storedRequest.rubicon_zone_id = ''
            storedRequest.rubicon_position = ''
          }
          if (storedRequest.rubiconbis_is_active == false) {
            storedRequest.rubiconbis_account_id = ''
            storedRequest.rubiconbis_site_id = ''
            storedRequest.rubiconbis_zone_id = ''
            storedRequest.rubiconbis_position = ''
          }
          if (storedRequest.rtbhouse_is_active == false) {
            storedRequest.rtbhouse_endpoint = ''
          }
          if (storedRequest.smartadserver_is_active == false) {
            storedRequest.smartadserver_network_id = ''
            storedRequest.smartadserver_site_id = ''
            storedRequest.smartadserver_page_id = ''
            storedRequest.smartadserver_format_id = ''
          }
          if (storedRequest.smartadserverbis_is_active == false) {
            storedRequest.smartadserverbis_network_id = ''
            storedRequest.smartadserverbis_site_id = ''
            storedRequest.smartadserverbis_page_id = ''
            storedRequest.smartadserverbis_format_id = ''
          }
          /*if (storedRequest.smartyads_is_active == false) {
            storedRequest.smartyads_host = ''
            storedRequest.smartyads_account_id = ''
          }*/
          if (storedRequest.vidoomy_is_active == false) {
            storedRequest.vidoomy_zone_id = ''
          }
          if (storedRequest.smaato_is_active == false) {
            storedRequest.smaato_publisher_id = ''
            storedRequest.smaato_adspace_id = ''
          }
          if (storedRequest.sharethrough_is_active == false)
            storedRequest.sharethrough_pkey = ''
          if (storedRequest.smilewanted_is_active == false) {
            storedRequest.smilewanted_zone_id = ''
            storedRequest.smilewanted_position_type = ''
          }
          if (storedRequest.sovrn_is_active == false) {
            storedRequest.sovrn_tag_id = ''
          }
          if (storedRequest.staila_is_active == false) {
            storedRequest.staila_placement_id = ''
          }
          if (storedRequest.triplelift_is_active == false)
            storedRequest.triplelift_inventory_code = ''
          if (storedRequest.unruly_is_active == false)
            storedRequest.unruly_site_id = ''
          if (storedRequest.triton_is_active == false)
            storedRequest.triton_stid = ''
          if (storedRequest.ttd_is_active == false)
            storedRequest.ttd_endpoint = ''
          if (storedRequest.verizon_is_active == false) {
            storedRequest.verizon_dcn = ''
            storedRequest.verizon_pos = ''
          }
          if (storedRequest.tappx_is_active == false) {
            storedRequest.tappx_key = ''
            storedRequest.tappx_host = ''
          }
          if (storedRequest.targetspot_is_active == false) {
            storedRequest.targetspot_publisher_id = ''
          }
          if (storedRequest.visx_is_active == false) {
            storedRequest.visx_uid = ''
          }
          if (storedRequest.pricefloor_is_active == false) {
            storedRequest.pricefloor_cpm = ''
          }
        }
      },
      verifyRowsNumber() {
        console.log('Impored')
        for (let index = 0; index < this.dataImported.length; index++) {
          if (this.dataImported[index].id == '"')
            console.log('Row number =====>', index + 1)
        }

        if (this.dataImported.length > this.dataExported.length) {
          let type = ''
          if (this.bulkforsite) type = 'sites'
          else 'placements'
          this.errors.push(
            'Only ' +
              this.dataExported.length +
              ' ' +
              type +
              " are created and you're trying to import " +
              this.dataImported.length +
              ' ' +
              type +
              '.'
          )
        }
      },
      verifyHeaders() {
        if (this.bulkforsite) {
          this.verifySitesHeaders()
        } else {
          this.verifyPlacementsHeaders()
        }
      },
      verifySitesHeaders() {
        console.log('imported->', this.headersImportedSite)
        console.log('exported->', this.headersExportedSite)
        if (
          this.headersImportedSite.length != this.headersExportedSite.length
        ) {
          this.errors.push('The number of columns in your file is not correct')
        } else
          for (
            let index = 0;
            index < this.headersImportedSite.length;
            index++
          ) {
            if (
              this.headersImportedSite[index] != this.headersExportedSite[index]
            ) {
              this.errors.push(
                "Problem header : Column '" +
                  this.headersImportedSite[index] +
                  "' should be called '" +
                  this.headersExportedSite[index] +
                  "'"
              )
            }
          }
      },
      verifyPlacementsHeaders() {
        console.log('imported->', this.headersImportedPlacement)
        console.log('exported->', this.headersExportedPlacementComputed)
        if (
          this.headersImportedPlacement.length !=
          this.headersExportedPlacementComputed.length
        ) {
          this.errors.push('The number of columns in your file is not correct')
        } else
          for (
            let index = 0;
            index < this.headersImportedPlacement.length;
            index++
          ) {
            if (
              this.headersImportedPlacement[index] !=
              this.headersExportedPlacementComputed[index]
            ) {
              this.errors.push(
                "Problem header : Column '" +
                  this.headersImportedPlacement[index] +
                  "' should be called '" +
                  this.headersExportedPlacementComputed[index] +
                  "'"
              )
            }
          }
      },

      async verifyRequiredFields() {
        if (this.bulkforsite) {
          await this.verifySites()
        } else await this.verifyPlacements()
      },
      async verifySites() {
        console.log(this.dataImported)
        for (let i = 0; i < this.dataImported.length; i++) {
          var nbrow = i + 2
          var row = this.dataImported[i]
          //SITE ID
          const response = await axios.get(
            `/api/siteexists/${this.dataImported[i].id}`
          )
          var siteinfo = response.data
          if (siteinfo.length == 0) {
            this.errors.push(
              "Couldn't find the site with id = " +
                this.dataImported[i].id +
                '.'
            )
          }
          //CPM_ADJUSTMENT
          if (isNaN(row.cpm_adjustment)) {
            if (row.cpm_adjustment.toString().indexOf(',') != -1) {
              var foo = row.cpm_adjustment.replace(',', '.')
              if (isNaN(foo)) {
                this.errors.push(
                  'Format error : cpm_adjustment should be a number for site in row ' +
                    nbrow
                )
              } else row.cpm_adjustment = row.cpm_adjustment.replace(',', '.')
            } else {
              this.errors.push(
                'Format error : cpm_adjustment should be a number for site in row ' +
                  nbrow
              )
            }
          } else if (
            Number(row.cpm_adjustment) > 1 ||
            Number(row.cpm_adjustment) < 0
          ) {
            this.errors.push(
              'Format error : cpm_adjustment should be between 0 and 1 in row ' +
                nbrow
            )
          }

          //SCHAIN_IS_ACTIVE
          if (row.schain_is_active != 0 && row.schain_is_active != 1) {
            this.errors.push(
              'Format error : schain_is_active should be 0 or 1 in row ' + nbrow
            )
          }
          if (
            row.schain_is_active == 1 ||
            row.schain_is_active.toLowerCase() == true ||
            row.schain_is_active.toLowerCase() == 'vrai'
          ) {
            if (
              row.schain_asi == '' ||
              row.schain_asi == undefined ||
              row.schain_sid == '' ||
              row.schain_sid == undefined
            )
              this.errors.push(
                'You have chosen to activate schain without providing its parameters in row ' +
                  nbrow
              )
          }
          //SCHAIN_ASI
          if (row.schain_asi != '') {
            if (!/^[-a-zA-Z0-9@:%._\+~#=]+\.[a-zA-Z]+?$/.test(row.schain_asi)) {
              this.errors.push(
                'Format error : schain_asi must be in this format (domain.com) in row ' +
                  nbrow
              )
            }
          }

          //SCHAIN_SID
          if (row.schain_sid != '') {
            if (!/^[a-zA-Z 0-9\-]*$/.test(row.schain_sid)) {
              this.errors.push(
                'Format error : schain_sid should not contain special characters (except -) in row ' +
                  nbrow
              )
            }
          }

          //IAB_BLOCKLIST
          if (row.iab_blocklist.length > 0) {
            row.iab_blocklist = row.iab_blocklist.replaceAll('"', '')
            row.iab_blocklist = row.iab_blocklist.replaceAll('[', '')
            row.iab_blocklist = row.iab_blocklist.replaceAll(']', '')
            let listIAB = row.iab_blocklist.split(',')
            listIAB.forEach((iab) => {
              let exists = false
              this.iabList.forEach((element) => {
                if (iab == element.code) {
                  exists = true
                  return false
                }
              })
              if (exists == false)
                this.errors.push(
                  'There is no IAB category has a code named ' +
                    iab +
                    ' in row ' +
                    nbrow
                )
            })

            let str = '['
            listIAB.forEach((item) => {
              str += '"' + item + '",'
            })
            str = str.slice(0, -1)
            str += ']'
            row.iab_blocklist = str
          }

          //GEOTARGETING
          if (
            row.geotargeting_blacklist.length > 0 &&
            row.geotargeting_whitelist.length > 0
          ) {
            this.errors.push(
              'You need to choose between geotargeting blacklist or whitelist, only one is possible in row ' +
                nbrow
            )
          }

          //GEOTARGETING_BLACKLIST
          if (row.geotargeting_blacklist.length > 0) {
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              '"',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              "'",
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              '[',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              '{',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              '}',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              '(',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              ')',
              ''
            )
            row.geotargeting_blacklist = row.geotargeting_blacklist.replaceAll(
              ']',
              ''
            )
            let listBlacklist = row.geotargeting_blacklist.split(',')
            listBlacklist.forEach((iab) => {
              let exists = false
              this.countryList.forEach((element) => {
                if (
                  iab.toLowerCase() == element.code.toLowerCase() ||
                  iab.toLowerCase() == element.name.toLowerCase()
                ) {
                  exists = true
                  return false
                }
              })
              if (exists == false)
                this.errors.push(
                  'There is no IAB category has a code named ' +
                    iab +
                    ' in row ' +
                    nbrow
                )
            })

            let str = '['
            listBlacklist.forEach((item) => {
              let pays =
                item.length > 2
                  ? this.getCode(item.toLowerCase())
                  : item.toUpperCase()
              str += '"' + pays + '",'
            })
            str = str.slice(0, -1)
            str += ']'
            row.geotargeting_blacklist = str
          }
          //GEOTARGETING_WHITELIST
          if (row.geotargeting_whitelist.length > 0) {
            row.geotargeting_whitelist = row.geotargeting_whitelist.replaceAll(
              '"',
              ''
            )
            row.geotargeting_whitelist = row.geotargeting_whitelist.replaceAll(
              '[',
              ''
            )
            row.geotargeting_whitelist = row.geotargeting_whitelist.replaceAll(
              ']',
              ''
            )
            let listWhitelist = row.geotargeting_whitelist.split(',')

            let str = '['
            listWhitelist.forEach((item) => {
              let pays =
                item.length > 2
                  ? this.getCode(item.toLowerCase())
                  : item.toUpperCase()

              str += '"' + pays + '",'
            })
            str = str.slice(0, -1)
            str += ']'
            row.geotargeting_whitelist = str
          }

          //DOMAINS_BLOCKLIST
          if (row.domain_blocklist.length > 0) {
            row.domain_blocklist = row.domain_blocklist.replaceAll('"', '')
            row.domain_blocklist = row.domain_blocklist.replaceAll('[', '')
            row.domain_blocklist = row.domain_blocklist.replaceAll(']', '')
            row.domain_blocklist = row.domain_blocklist.replaceAll(' ', '')
            let listDomains = row.domain_blocklist.split(',')
            listDomains.forEach((domain) => {
              if (!/^[-a-zA-Z0-9@:%._\+~#=]+\.[a-zA-Z]+?$/.test(domain)) {
                this.errors.push(
                  'Format error : All domains in domain_blocklist must be in this format (domain.com) in row ' +
                    nbrow
                )
              }
            })
            let str = '['
            listDomains.forEach((item) => {
              str += '"' + item + '",'
            })
            str = str.slice(0, -1)
            str += ']'
            row.domain_blocklist = str
          }
        }
      },
      getCode(str) {
        this.countryList.forEach((element) => {
          if (element.name.toLowerCase() == str.toLowerCase) {
            return element.code
          } else return ''
        })
      },
      async verifyPlacements() {
        for (let i = 0; i < this.dataImported.length; i++) {
          var nbrow = i + 2
          const response = await axios.get(
            `/api/srexists/${this.dataImported[i].id}`
          )
          var placements = response.data
          if (placements.length == 0) {
            this.errors.push(
              "Couldn't find the placement with id = '" +
                this.dataImported[i].id +
                "'"
            )
          } else {
            if (placements[0].name != this.dataImported[i].name) {
              this.errors.push(
                'There is no existing placement with id = ' +
                  this.dataImported[i].id +
                  ' and name = ' +
                  this.dataImported[i].name +
                  ', check row ' +
                  nbrow
              )
            }
          }
        }
      },
      async verifyPlacementsName() {
        for (let i = 0; i < this.dataImported.length; i++) {
          if (this.dataImported[i].name != this.dataExported[i].name) {
            let nrow = i + 2

            this.errors.push(
              'Placement name in row number ' +
                nrow +
                ' should be ' +
                this.dataExported[i].name
            )
          }
        }
      },
      async verifySitesIds() {
        for (let i = 0; i < this.dataImported.length; i++) {
          if (this.dataImported[i].site_id != this.dataExported[i].site_id) {
            let nrow = i + 1
            this.errors.push(
              'Site id in row number ' +
                nrow +
                ' should be ' +
                this.dataExported[i].site_id
            )
          }
        }
      },
      async verifySitesNames() {
        for (let i = 0; i < this.dataImported.length; i++) {
          if (
            this.dataImported[i].site_name != this.dataExported[i].site_name
          ) {
            let nrow = i + 1
            this.errors.push(
              'Site name in row number ' +
                nrow +
                ' should be ' +
                this.dataExported[i].site_name
            )
          }
        }
      },
      verifySSPFields() {
        for (let i = 0; i < this.dataImported.length; i++) {
          var nbrow = i + 2
          var row = this.dataImported[i]

          for (const property in row) {
            if (property.match('_is_active')) {
              if (row[property] == undefined) {
                row[property] = '0'
              }
              if (
                row[property] != 0 &&
                row[property] != 1 &&
                row[property] != 2 &&
                row[property] != ''
              ) {
                this.errors.push(
                  'Invalid entry, ' +
                    property +
                    ' should be 0 or 1 in the row ' +
                    nbrow
                )
              }
            }
          }

          for (const property in this.formattedSspList) {
            let keys = Object.keys(this.formattedSspList[property])
            if (keys[0] == 'gumgum_is_active') {
              if (
                row[keys[0]] == 1 ||
                row[keys[0]].toLowerCase() == true ||
                row[keys[0]].toLowerCase() == 'vrai'
              ) {
                let err = 0
                if (
                  row['gumgum_zone'] == '' &&
                  row['gumgum_pub_id'] == '' &&
                  row['gumgum_product'] == '' &&
                  row['gumgum_slot'] == ''
                ) {
                  err = err + 1
                }
                if (err > 0)
                  this.errors.push(
                    'You have chosen to activate ' +
                      property +
                      ' without providing any parameter for placement ' +
                      row.id +
                      ' in row ' +
                      nbrow
                  )
              }
            } else if (keys[0] == 'criteo_is_active') {
              if (
                row[keys[0]] == 1 ||
                row[keys[0]].toLowerCase() == true ||
                row[keys[0]].toLowerCase() == 'vrai'
              ) {
                let err = 0
                if (
                  row['criteo_zone_id'] == '' &&
                  row['criteo_network_id'] == ''
                ) {
                  err = err + 1
                }
                if (err > 0)
                  this.errors.push(
                    'You have chosen to activate ' +
                      property +
                      ' without providing any parameter for placement ' +
                      row.id +
                      ' in row ' +
                      nbrow
                  )
              }
            } else if (keys[0] == 'nextmillennium_is_active') {
              if (
                row[keys[0]] == 1 ||
                row[keys[0]].toLowerCase() == true ||
                row[keys[0]].toLowerCase() == 'vrai'
              ) {
                let err = 0
                if (
                  row['nextmillennium_placement_id'] == '' &&
                  row['nextmillennium_group_id'] == ''
                ) {
                  err = err + 1
                }
                if (err > 0)
                  this.errors.push(
                    'You have chosen to activate ' +
                      property +
                      ' without providing any parameter for placement ' +
                      row.id +
                      ' in row ' +
                      nbrow
                  )
              }
            } else {
              if (
                row[keys[0]] == 1 ||
                row[keys[0]].toLowerCase() == true ||
                row[keys[0]].toLowerCase() == 'vrai'
              ) {
                let err = 0
                for (let j = 1; j < keys.length; j++) {
                  if (this.optionals.includes(keys[j]) == false) {
                    if (row[keys[j]] == '' || row[keys[j]] == undefined)
                      err = err + 1
                  }
                }
                if (err > 0)
                  this.errors.push(
                    'You have chosen to activate ' +
                      property +
                      ' without providing its parameters for placement ' +
                      row.id +
                      ' in row ' +
                      nbrow
                  )
              }
            }
          }

          if (
            row.pricefloor_is_active != 0 &&
            row.pricefloor_is_active != 1 &&
            row.pricefloor_is_active != ''
          ) {
            this.errors.push(
              'Invalid entry, pricefloor_is_active should be 0 or 1 in the row ' +
                nbrow
            )
          }

          if (
            row.pricefloor_is_active == 1 ||
            row.pricefloor_is_active.toLowerCase() == true ||
            row.pricefloor_is_active.toLowerCase() == 'vrai'
          ) {
            if (row.pricefloor_cpm == '' || row.pricefloor_cpm == undefined)
              this.errors.push(
                'You have chosen to set a pricefloor without providing Pricefloor CPM for placement ' +
                  row.id +
                  ' in row ' +
                  nbrow
              )
            if (isNaN(row.pricefloor_cpm)) {
              if (row.pricefloor_cpm.toString().indexOf(',') != -1) {
                var foo = row.pricefloor_cpm.replace(',', '.')
                if (isNaN(foo)) {
                  this.errors.push(
                    'Format error : cpm_pricefloor should be a number for placement ' +
                      row.id +
                      ' in row ' +
                      nbrow
                  )
                } else row.pricefloor_cpm = row.pricefloor_cpm.replace(',', '.')
              } else {
                this.errors.push(
                  'Format error : cpm_pricefloor should be a number for placement ' +
                    row.id +
                    ' in row ' +
                    nbrow
                )
              }
            }
          }
        }
      },

      async readCsvFile(file) {
        const reader = new FileReader()
        var text = ''
        var that = this
        reader.onload = await function (e) {
          text = e.target.result
          var data = that.csvToArray(text, ',')
          that.dataImported = data
        }
        reader.readAsText(file)
      },
      csvToArray(str, delimiter = ',') {
        var headers = str.slice(0, str.indexOf('\n') - 1).split(delimiter)
        if (headers.length == 1) {
          delimiter = ';'
          headers = str.slice(0, str.indexOf('\n') - 1).split(';')
        }
        if (this.bulkforsite) this.headersImportedSite = headers
        else this.headersImportedPlacement = headers
        const rows = str.split(/\r?\n/)
        rows.shift()
        const arr = rows.map(function (row) {
          const values = row.split(delimiter)
          var object = {}
          for (let index = 0; index < headers.length; index++) {
            object[headers[index]] = values[index]
          }

          return object
        })
        if (arr[arr.length - 1].id == '') arr.pop()
        // return the array
        return arr
      },
      readXLFile(file) {
        const reader = new FileReader()
        var that = this
        var data = []
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })

          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          try {
            ws['!ref'] = ws['!autofilter']['ref']
          } catch (error) {}

          /* Convert array of arrays */
          const elements = XLSX.utils.sheet_to_json(ws, { header: 1 })

          data = that.XLSXToArray(elements)

          for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
              if (data[i][key] == undefined) data[i][key] = ''
            }
          }
          that.dataImported = data
        }
        reader.readAsBinaryString(file)
        return data
      },
      XLSXToArray(data) {
        const headers = []
        var arr = []
        data[0].forEach((header) => {
          headers.push(header)
        })
        if (this.bulkforsite) this.headersImportedSite = headers
        else this.headersImportedPlacement = headers
        for (let i = 1; i < data.length; i++) {
          var object = {}
          for (let index = 0; index < headers.length; index++) {
            object[headers[index]] = data[i][index]
          }
          arr.push(object)
        }
        return arr
      },
      back() {
        this.fileInput = null
        this.uploadedFiles = []
        if (this.bulkforsite) this.headersImportedSite = []
        else this.headersImportedPlacement = []
        this.headersImportedPlacement = []
        this.$emit('back')
      },
      hideDialog() {
        this.fileInput = null
        this.uploadedFiles = []
        if (this.bulkforsite) this.headersImportedSite = []
        else this.headersImportedPlacement = []
        this.errors = []
        this.$emit('hideDialog')
      },
      getPosition(text, subString, index) {
        return text.split(subString, index).join(subString).length
      }
    }
  }
</script>
