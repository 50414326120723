<template>
  <v-container fluid>
    <v-card>
      <v-card-title v-if="$route.name == 'order_edit'" primary-title>
        Updating the order "{{ orderName }}"
      </v-card-title>
      <v-card-title v-else primary-title> Creating an order </v-card-title>
      <v-form ref="form" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row class="nopadding">
              <v-col class="nopadding" cols="5">
                <p>
                  {{ $t('order_name') }}<strong style="color: red"> *</strong>
                </p>
                <v-text-field
                  v-model="order.name"
                  :rules="required"
                  :loading="submitLoading"
                  :disabled="submitLoading"
                  dense
                  solo
                  outlined
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="nopadding">
              <v-col cols="6" class="nopadding"
                ><p>{{ $t('advertiser') }}</p>
                <v-autocomplete
                  v-model="order.advertiser_id"
                  :items="advertisers"
                  :rules="required"
                  clearable
                  item-text="name"
                  item-value="id"
                  solo
                  dense
                  outlined
                >
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                    <addAdvertiser @advertiserAdded="addAdvertiser">
                    </addAdvertiser>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex flex-row-reverse">
          <v-btn
            style="margin: 10px"
            class="white--text notcapital"
            color="red"
            width="150"
            dense
            @click="back"
          >
            <v-icon left> mdi-cancel </v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :loading="submitLoading"
            :disabled="submitLoading"
            style="margin: 10px"
            class="white--text notcapital"
            color="black"
            width="150"
            dense
            @click="createOrder"
          >
            {{ $t('save') }}
            <v-icon right> mdi-content-save-outline </v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-snackbar v-model="orderCreated" color="#4CAF4F" :timeout="timeout">
      <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
      {{ orderUpdatedMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import add_advertiser from './add_advertiser.vue'
  import { mapActions, mapGetters } from 'vuex'
  import { GetSelectedOrder, SaveSelectedOrder } from '@/store/action_types'

  export default {
    name: 'OrderEdit',
    components: { addAdvertiser: add_advertiser },
    data() {
      return {
        order: {
          name: ''
        },
        advertisers: [],
        submitLoading: false,
        required: [(v) => !!v || 'Required'],
        orderCreated: false,
        timeout: 3000,
        orderUpdatedMessage:
          this.$route.params.order_id == undefined
            ? 'Order created successfully'
            : 'Order updated successfully',
        errors: [],
        orderName: ''
      }
    },
    computed: {
      ...mapGetters(['selectedOrder'])
    },
    watch: {},
    async created() {
      await this.getAdvertisers()
      if (this.$route.name != 'order_new') {
        await this.GetSelectedOrder(this.$route.params.order_id)
        this.order = this.selectedOrder
        this.orderName = this.selectedOrder.name
      }
      this.order.customer_id = this.$route.params.customer_id
    },
    methods: {
      ...mapActions([GetSelectedOrder, SaveSelectedOrder]),
      back() {
        this.$router.push({
          name: 'order_list'
        })
      },
      async createOrder() {
        if (!this.$refs.form.validate()) {
          console.log('No fullfilled')
          return
        }
        this.submitLoading = true
        this.order.name = this.order.name.replace(/\t/g, '')
        const output = await this.SaveSelectedOrder(this.order).catch(() => {
          this.submitLoading = false
          return false
        })
        this.orderUpdatedMessage = 'Order created successfully'
        this.orderCreated = true
        setTimeout(
          () =>
            this.$router.push({
              name: 'order_list'
            }),
          2000
        )
      },
      async getAdvertisers() {
        const response_advertiser = await axios.get(
          `/api/dspadvertiserbycustomer/${this.$route.params.customer_id}`
        )
        this.advertisers = response_advertiser.data
      },
      async addAdvertiser(advertiser) {
        await axios.post(`/api/dspadvertiser`, advertiser)
        this.orderUpdatedMessage = 'Advertiser added successfully'
        this.orderCreated = true
        await this.getAdvertisers()
      }
    }
  }
</script>

<style scoped>
  .notcapital {
    text-transform: none !important;
  }
  .nopadding {
    padding-top: 5px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .expansion_panel {
    padding: 20px;
    margin-left: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 30px;
  }
  .v-expansion-panels {
    z-index: unset;
    box-shadow: 0.2px 0.2px 0.5px 0.5px lightgrey;
  }
  .v-expansion-panel {
    border: 0.5px solid lightgrey;
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
      .v-expansion-panels--tile
    )
    > .v-expansion-panel--active
    + .v-expansion-panel {
    margin-top: 0px;
  }
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(
      .v-expansion-panels--tile
    )
    > .v-expansion-panel--active {
    margin-top: 0px;
  }
  .float-container {
    border: 3px solid #fff;
  }
  .site_targeting_btns {
    width: 6%;
    float: left;
    padding: 5px;
  }
  .site_div {
    width: 70%;
    margin: 0px;
    border: 1px solid lightgray;
    border-radius: 5px;
    overflow: scroll;
  }
  .include_targeting {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    margin: 0px;
    padding-right: 0px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
</style>
