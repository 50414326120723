module.exports = [
    {
        id: '1',
        label: '1 - On Video Completion or when Terminated by User'
    },
    {
        id: '2',
        label: '2 - On Leaving Viewport or when Terminated by User'
    },
    {
        id: '3',
        label: '3 - On Leaving Viewport Continues as a Floating/Slider Unit until Video Completion or when terminated by User'
    }
]
