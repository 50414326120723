<template>
  <div id="siteContent">
    <v-container fluid style="padding-top: 0px">
      <v-card id="siteList">
        <v-card-title primary-title style="padding-top: 30px">
          <v-row>
            <v-col cols="4">
              <h3 class="cnx">
                <strong> VAST Configuration</strong>
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-btn
                style="border-color: black"
                class="white--text notcapital"
                color="black"
                width="250"
                dense
                @click="newVast()"
              >
                <v-icon left> mdi-plus </v-icon> Add a VAST config
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <p
                style="
                  position: relative;
                  top: 30px;
                  left: 5px;
                  font-size: 14px;
                  color: black;
                "
              >
                {{ numberOfVastsCreated }}

                VAST config created (
                <a
                  :style="[
                    showArchived ? { color: 'black' } : { color: '#f28704' }
                  ]"
                  @click="showArchived = false"
                  ><u
                    ><strong>{{ numberOfActiveVasts }}</strong> active VAST
                    config</u
                  ></a
                >
                /
                <a
                  :style="[
                    showArchived ? { color: '#f28704' } : { color: 'black' }
                  ]"
                  @click="showArchived = true"
                  ><u
                    ><strong>{{ numberOfArchivedVasts }}</strong> archived VAST
                    config</u
                  ></a
                >
                )
              </p>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
          <v-data-table
            :search="search"
            :headers="headers"
            :items="computedVasts"
            :sort-by="['name']"
            class="elevation-1"
            :loading="loading"
            hide-default-footer
            :disable-pagination="true"
            style="background-color: #f9f9fb"
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.item.id }}
                </td>
                <td
                  v-if="props.item.is_archived == 0"
                  style="width: 30%; cursor: pointer"
                  @click="updateVast(props.item)"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-if="props.item.is_archived == 1"
                  style="width: 30%;t cursor: pointer; opacity: 0.5"
                >
                  {{ props.item.name }}
                </td>
                <td style="width: 45%">
                  <v-chip-group multiple column active-class="primary--text">
                    <v-btn
                      v-if="
                        props.item.no_vast_callback != null &&
                        props.item.no_vast_callback != ''
                      "
                      small
                      color="#04778c"
                      style="padding: 5px; margin: 5px; color: white"
                    >
                      VAST CALLBACK
                    </v-btn>
                    <v-btn
                      v-if="
                        props.item.waterfall_google_tag != '' &&
                        props.item.waterfall_google_tag != null
                      "
                      small
                      color="#b4b700"
                      style="padding: 5px; margin: 5px; color: white"
                    >
                      GOOGLE TAG
                    </v-btn>
                  </v-chip-group>
                </td>
                <td style="padding: 0px">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="updateVast(props.item)"
                      >
                        <v-icon size="20">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit the VAST config</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="duplicateVast(props.item)"
                      >
                        <v-icon size="20">mdi-content-duplicate</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate the VAST config</span>
                  </v-tooltip>
                  <v-tooltip v-if="props.item.is_archived == 0" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="showConfirmationDialog(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Archive the VAST config</span>
                  </v-tooltip>
                  <v-tooltip v-else top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ma-2"
                        text
                        icon
                        color="#000"
                        v-bind="attrs"
                        v-on="on"
                        @click="unarchiveVast(props.item)"
                      >
                        <v-icon size="20">mdi-archive-arrow-up</v-icon>
                      </v-btn>
                    </template>
                    <span>Unarchive the VAST config</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <confirm-action
        :parent-dialog="confirmActionDialog"
        :item="item"
        :action-type="confirmationAction"
        @hideDialog="confirmActionDialog = false"
        @archive="archiveVast"
      />
      <v-snackbar v-model="actionsuccess" color="#4CAF4F" :timeout="timeout">
        <v-icon size="25" style="padding: 5px">mdi-check-circle-outline</v-icon>
        {{ messageAction }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import {
    GetVasts,
    SaveSelectedVast,
    UpdateSelectedVast
  } from '@/store/action_types'
  import { mapActions, mapMutations, mapGetters } from 'vuex'
  import {
    SetSelectedVast,
    ClearSelectedSite,
    SetAppCustomer
  } from '@/store/mutation_types'
  import ConfirmAction from '@/pages/management/actions/confirmAction.vue'

  export default {
    name: 'SiteList',
    components: {
      ConfirmAction
    },
    data() {
      return {
        headers: [
          { text: 'VF ID', align: 'left', value: 'id' },
          { text: 'Name', align: 'left', value: 'name' },
          { text: 'Callback option', align: 'left', value: 'callback_option' },
          // { text: 'Placements', align: 'left', value: 'placements' },
          { text: 'Action', align: 'center', value: 'action' }
        ],
        site: {},
        site_duplicated: {},
        loading: false,
        search: '',
        generateButtonLoading: false,
        showArchived: false,
        actionType: '',
        bulkUploadDialog: false,
        bulkforsite: false,
        codeGenerated: '',
        actionsuccess: false,
        confirmActionDialog: false,
        confirmationAction: '',
        timeout: 3000,
        messageAction: '',
        numberOfVastsCreated: 0,
        numberOfActiveVasts: 0,
        numberOfArchivedVasts: 0,
        item: [],
        menuAddingVast: false,
        menuBulkUpload: false,
        vastEditDialog: false,

        fieldsToExport: [
          'name',
          'customer_id',
          'refresh_is_active',
          'selection_type',
          'orders_ids',
          'refresh_time',
          'nb_repetition',
          'noconsent_traffic_blocking',
          'pricefloor_is_active',
          'pricefloor_cpm'
        ]
      }
    },
    computed: {
      ...mapGetters(['vasts', 'selectedVast', 'appCustomer']),
      computedVasts() {
        return this.vasts
          .filter((el) => {
            return this.showArchived ? el.is_archived == 1 : el.is_archived == 0
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      }
    },
    watch: {
      appCustomer: async function (val, oldVal) {
        if (this.$route.params.customer_id != this.appCustomer.id) {
          this.$router.push({
            name: 'vast_list',
            params: {
              customer_id: this.appCustomer.id
            }
          })
        }
        await this.GetVasts(this.appCustomer.id)
        this.manageNumberOfVasts()
      }
    },
    async created() {
      this.loading = true
      await this.GetVasts(this.appCustomer.id)
      this.manageNumberOfVasts()
      this.loading = false
    },
    methods: {
      ...mapActions({
        GetVasts,
        SaveSelectedVast,
        UpdateSelectedVast
      }),
      ...mapMutations([SetSelectedVast, ClearSelectedSite, SetAppCustomer]),
      async archiveVast(item) {
        item.is_archived = 1
        await this.UpdateSelectedVast(item)
        this.SetSelectedVast(item)
        await this.GetVasts(this.appCustomer.id)
        this.actionsuccess = true
        this.messageAction = 'Vast archived successfully'
        this.manageNumberOfVasts()
      },
      async unarchiveVast(item) {
        item.is_archived = 0
        this.UpdateSelectedVast(item)
        this.SaveSelectedVast(item)
        await this.GetVasts(this.appCustomer.id)
      },
      async duplicateVast(item) {
        const vastURL = await axios.get(`/api/getvastbyid/${item.id}`)
        this.SetSelectedVast(vastURL.data)
        const vast_duplicated = Object.assign({}, this.selectedVast)
        vast_duplicated.id = undefined
        vast_duplicated.name = 'copy of ' + item.name
        try {
          await this.SaveSelectedVast(vast_duplicated)
          this.actionsuccess = true
          this.messageAction = 'Vast duplicated successfully'
        } catch (error) {
          console.log(error)
        }
        await this.GetVasts(this.appCustomer.id)
      },
      showDialog(actionType) {
        this.actionType = actionType
        this.vastEditDialog = true
        if (actionType == 'update') {
          this.$router.push(
            this.$route.path +
              '/' +
              this.selectedVast.id +
              '?action=' +
              actionType
          )
        } else this.$router.push(this.$route.path + '?action=' + actionType)
      },
      newVast() {
        this.$router.push({
          name: 'vast_new',
          params: {
            customer_id: this.appCustomer.id
          }
        })
      },
      updateVast(item) {
        this.SetSelectedVast(item)
        this.$router.push({
          name: 'vast_edit',
          params: {
            customer_id: this.appCustomer.id,
            vast_id: this.selectedVast.id
          }
        })
      },
      showConfirmationDialog(item) {
        this.item = item
        this.$router.push(this.$route.path + '?action=confirmArchiving')
        this.confirmActionDialog = true
        this.confirmationAction = 'archive'
      },
      GetVastsArchived() {
        return this.vasts.filter((el) => {
          return el.is_archived == 1
        })
      },
      GetVastsUnarchived() {
        return this.vasts.filter((el) => {
          return el.is_archived == 0
        })
      },
      manageNumberOfVasts() {
        var vasts_unarchived = this.GetVastsUnarchived()
        var vasts_archived = this.GetVastsArchived()
        this.numberOfVastsCreated =
          vasts_unarchived.length + vasts_archived.length
        this.numberOfActiveVasts = vasts_unarchived.length
        this.numberOfArchivedVasts = vasts_archived.length
      }
    }
  }
</script>
<style scoped>
  .avatar {
    padding-left: 20px;
    padding-bottom: 23px;
  }
  .no-shadow,
  .no-shadow::before {
    box-shadow: none;
    background-color: transparent !important;
  }
  .combobox {
    padding-left: 50px;
  }
  .cnx {
    letter-spacing: 2px;
    font-size: 28px;
    background-color: #dddddd;
    border-radius: 5px;
    padding: 5px;
  }
</style>
