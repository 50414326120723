<template>
  <v-autocomplete
    v-model="customer"
    :label="$t('customer')"
    single-line
    hide-details
    :items="computedCustomers"
    item-text="code"
    item-value="id"
    return-object
    style="width: 320px; z-index: 99"
    @input="saveInSessionAndEmit($event)"
  ></v-autocomplete>
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex'
  import { GetSelectedUser, GetCustomers } from '@/store/action_types'
  import axios from 'axios'
  import { mapMutations } from 'vuex'

  export default {
    name: 'CustomerSelector',
    props: ['customerid'],
    data() {
      return {
        customer: {},
        alfonsoAccess: [1011, 1056, 1108, 1121, 1124]
      }
    },
    computed: {
      ...mapState(['customers']),
      ...mapGetters(['appCustomer']),
      computedCustomers() {
        if (
          this.appCustomer.code.startsWith('prisma') &&
          !this.$store.getters.is_superadmin
        ) {
          return this.customers
            .filter((el) => {
              return el.code.startsWith('prisma')
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        }
        if (
          this.appCustomer.code.startsWith('dailymotion') &&
          !this.$store.getters.is_superadmin
        ) {
          return this.customers
            .filter((el) => {
              return el.code.startsWith('dailymotion')
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        } else if (
          this.$store.state.appUser.email == 'alfonso@revenuemaker.es' &&
          !this.$store.getters.is_superadmin
        ) {
          return this.customers
            .filter((el) => {
              return this.alfonsoAccess.includes(el.id)
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        } else {
          return this.customers
            .filter((el) => {
              return true
            })
            .sort((a, b) => a.name.localeCompare(b.name))
        }
      }
    },
    watch: {
      '$route.name': async function (val) {
        if (val == 'customer_edit' || val == 'stored_request_edit') {
          const customerURL = await axios.get(
            `/api/getcustomerbyid/${this.$route.params.customer_id}`
          )
          var customer = customerURL.data
          this.customer = customer
        }
      }
    },
    async created() {
      this.GetCustomers()
      if (this.$route.name == 'user_edit') {
        const response = await this.GetSelectedUser(this.$route.params.userId)
        var user = response.data
        const customerURL = await axios.get(
          `/api/getcustomerbyid/${user.customer_id}`
        )
        this.customer = customerURL.data
      } else this.customer = this.appCustomer
      if (this.$route.name == 'customer_edit') {
        const customerURL = await axios.get(
          `/api/getcustomerbyid/${this.$route.params.customer_id}`
        )
        this.customer = customerURL.data
      }
      if (this.$route.params.customer_id != undefined) {
        if (
          this.$store.getters.is_superadmin &&
          this.$store.state.appCustomer.id != this.$route.params.customer_id
        ) {
          const customerURL = await axios.get(
            `/api/getcustomerbyid/${this.$route.params.customer_id}`
          )
          var customer = customerURL.data
          this.customer = customer
        }
      }
    },
    methods: {
      ...mapActions([GetCustomers, GetSelectedUser]),
      async saveInSessionAndEmit(customer) {
        if (
          this.$store.getters.is_superadmin ||
          customer.code.startsWith('prisma') ||
          customer.code.startsWith('dailymotion')
        ) {
          await axios.post(`/api/setcustomer`, customer)
        }
        this.$emit('update-customer', customer)
      }
    }
  }
</script>

<style scoped>
  .v-autocomplete__content.v-menu__content .v-select-list {
    padding: 0px;
  }
</style>
