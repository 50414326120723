var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"segmentContent"}},[(
      _vm.$store.getters.is_superadmin == false &&
      _vm.$store.state.appCustomer.id != _vm.$route.params.customer_id &&
      _vm.$store.state.appCustomer.id != '1086' &&
      _vm.$store.state.appCustomer.id != '1094' &&
      _vm.$store.state.appUser.email != 'alfonso@revenuemaker.es'
    )?_c('v-container',[_c('accessdenied')],1):_c('v-container',{staticStyle:{"padding-top":"0px"},attrs:{"fluid":""}},[_c('v-card',{attrs:{"id":"segmentList"}},[_c('v-card-title',{staticStyle:{"padding-top":"30px"},attrs:{"primary-title":""}},[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"cnx"},[_c('strong',[_vm._v(" First party segments management")])])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.computedSegments,"selectable-key":"id","show-select":"","hide-default-footer":"","disable-pagination":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h4',{staticStyle:{"padding":"5px"}},[_vm._v(" Uncheck the segments that you want to deactivate and save below the table. ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"d-flex flex-row-reverse"},[_c('v-btn',{staticClass:"white--text notcapital",staticStyle:{"margin":"10px"},attrs:{"loading":_vm.submitLoading,"disabled":_vm.submitLoading,"color":"black","width":"180","dense":""},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save-move ")]),_vm._v(" "+_vm._s(_vm.$t('save'))+" ")],1)],1)],1),_c('v-snackbar',{attrs:{"color":"#4CAF4F","timeout":_vm.timeout},model:{value:(_vm.actionsuccess),callback:function ($$v) {_vm.actionsuccess=$$v},expression:"actionsuccess"}},[_c('v-icon',{staticStyle:{"padding":"5px"},attrs:{"size":"25"}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.messageAction)+" ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }