import {
  GetSelectedCreative,
  SaveSelectedCreative,
  UpdateSelectedCreative
} from '@/store/action_types'

import {
  SetSelectedCreative,
  ClearSelectedCreative
} from '@/store/mutation_types'

import axios from 'axios'

export const selectedCreativeState = {
  selectedCreative: {}
}

export const selectedCreativeGetters = {
  selectedCreative: (state) => state.selectedCreative
}

export const selectedCreativeActions = {
  async [GetSelectedCreative]({ commit }, creative_id) {
    const response = await axios.get('/api/dspcreative/' + creative_id)
    commit(SetSelectedCreative, response.data)
    return response
  },
  async [SaveSelectedCreative]({ dispatch }, creative) {
    if (creative.id) {
      return dispatch(UpdateSelectedCreative, creative)
    }
    const response = await axios.post('/api/dspcreative', creative)
    return response.data
  },

  async [UpdateSelectedCreative]({ commit }, creative) {
    const response = await axios.put(`/api/dspcreative/${creative.id}`, creative)
    return response
  }
}

export const selectedCreativeMutations = {
  [SetSelectedCreative](state, creative) {
    state.selectedCreative = creative
  },
  [ClearSelectedCreative](state) {
    state.selectedCreative = {}
  }
}
