import { UserAlreadyExists } from '@/store/action_types'
import { SetOnboardingUser } from '@/store/mutation_types'
import axios from 'axios'

export const onboardingUserState = {
  onboardingUser: {}
}

export const onboardingUserGetters = {}

export const onboardingUserActions = {
  async [UserAlreadyExists]({ commit }, email) {
    const response = await axios.get('/api/userexists/' + email)
    return response.data
  }
}

export const onboardingUserMutations = {
  [SetOnboardingUser](state, user) {
    state.onboardingUser = user
  }
}
