<template>
  <v-container style="margin-top: 10px; padding: 0px 0px 0px 0px">
    <v-row>
      <v-col style="padding-bottom: 0px">
        <p style="font-family: Roboto; font-size: 15px; margin-left: 0px">
          A server-side bidding platform that enables programmatic players to
          work together more profitably, efficiently and responsibly.
        </p>
      </v-col>
    </v-row>
    <v-list disabled>
      <v-list-item-group color="black" style="margin: 0px 0px 0px 0px">
        <v-list-item v-for="(item, i) in items" :key="i" style="padding: 0px">
          <v-container style="padding: 0px">
            <v-row
              ><v-col cols="1">
                <v-icon
                  color="black"
                  class="icon_padding"
                  v-text="item.icon"
                  style="margin-bottom: 0px"
                ></v-icon> </v-col
              ><v-col
                style="padding-bottom: 0px; padding-left: 0px; margin-top: 3px"
              >
                <p class="texts">
                  {{ item.text }}
                </p>
              </v-col></v-row
            >
            <v-row style="margin: 0px">
              <v-col style="padding-top: 0px; padding-bottom: 0px">
                <p>{{ item.description }}</p></v-col
              >
            </v-row>
          </v-container>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        items: [
          {
            text: 'Revenue maximized',
            icon: this.$t('check_icon'),
            description: 'Bid density increase, SPO, direct deals'
          },
          {
            text: 'Improved web performance',
            icon: this.$t('check_icon'),
            description: 'Display speed, SEO'
          },
          {
            text: 'Reduced carbon footprint',
            icon: this.$t('check_icon'),
            description: 'Lower bandwidth, throttling'
          },
          {
            text: 'Sovereignty',
            icon: this.$t('check_icon'),
            description: 'Conflict-free alternative, transparency, 100% control'
          },
          {
            text: 'Agility and autonomy',
            icon: this.$t('check_icon'),
            description: 'No-code solution, time-saving for technical teams'
          }
        ]
      }
    }
  }
</script>
<style>
  .icon_padding {
    padding: 0px;
    margin: 3px;
  }
  .texts {
    font-size: 100%;
    font-weight: bold;
    margin-left: 0px;
  }
</style>
